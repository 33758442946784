<template>
	<div class="ui-content__list">
		<div v-if="!withoutHeader" class="ui-content__list-header">
			<slot name="header" v-bind="{ listTitle }">
				<h2 v-if="listTitle" class="ui-content__list-title">{{ listTitle }}</h2>
				<UiButton 
					v-if="!projectView && canAddFrames"
					:label="addFrameLabel"
					@click="addFrame"
				/>
			</slot>
		</div>
		<UiSkeleton 
			showMode="if"
			:loadingStyle="skeletonStyles" 
			class="ui-content__list-skeleton" 
			:loading="framesLoading" 
			height="300px"
		>
			<div v-if="currentFrames?.items?.length" class="ui-content__list-items">
				<UiContentFrame
					v-for="(frame, idx) in currentFrames.items" 
					:key="idx"
					:frame-content="frame"
					@click="toFramePage(frame)" 
				/>
			</div>
			<div v-else class="ui-content__list-items no-items">
				{{ noItemsText }}
			</div>
		</UiSkeleton>
		<UiPagination
			v-if="isPaginationVisible && !projectView"
			:currentPage="currentPage"
			:title="paginationTitle"
			:total="totalCount"
			:pageSize="pageSize"
			@changePage="(number) => (currentPage = number)"
		/>
		<UiButton 
			v-if="projectView && canAddFrames"
			type="secondary"
			:label="addFrameLabel"
			@click="addFrame"
		/>
	</div>
</template>

<script>
export default {
	name: 'UiContentFrameList',
	props: {
		/** Именованный акшен для вызова списка */
		framesAction: {
			type: String,
			required: true
		},
		/** Именованный геттер для обращения к списку */
		framesGetter: {
			type: String,
			required: true
		}, 
		/** Заголовок списка */
		listTitle: {
			type: String,
			default: ''
		},
		/** Что отображать если элементы отсутствуют */
		noItemsText: {
			type: String,
			default: ''
		},
		/** Лейбл кнопки добавления элемента */
		addFrameLabel: {
			type: String,
			default: ''
		},
		/** Количество элементов в списке */
		pageSize: {
			type: Number,
			default: 10
		},
		/** Название элементов пагинации */
		paginationTitle: {
			type: String,
			default: () => {}
		},
		/** Режим просмотра списка фреймов */
		projectView: {
			type: Boolean,
			default: false
		},
		/** Страница перехода */
		framePage: {
			type: String,
			default: ''
		},
		/** Название идентафикатора фрейма для перехода по роуту */
		frameIdentificator: {
			type: String,
			default: ''
		},
		/** Дополнительные параметры запроса */
		mixData: {
			type: Object,
			default: null
		},
		/** Дополнительные параметры запроса */
		canAddFrames: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			currentPage: 1,
		}
	},
	computed: {
		withoutHeader() {
			return this.projectView;
		},
		skeletonStyles() {
			return {
				'margin': 'auto',
				'width': '80%',
				'min-width': '600px',
				'max-width': '1200px',
			}
		},
		/** Текущий список фреймов */
		currentFrames() {
			return this.$store.getters[this.framesGetter];
		},
		totalCount() {
			return this.currentFrames.totalCount;
		},
		isPaginationVisible() {
			return this.pageSize <= this.totalCount;
		},
		refetchDependencies() { 
			return `${this.currentPage} ${this.pageSize} ${this.tableAction}`;
		},
		framesLoading() {
			return this.currentFrames?.loader ?? true;
		}
	},

	watch: {
		refetchDependencies() {
			this.fetchFramesData();
		},
		'currentFrames.items': {
			handler: function () {
				if (this.currentFrames?.items?.length) {
					this.$emit('hasItems', true);
				} else {
					this.$emit('hasItems', false);
				}
			},
			immediate: true,
		},
	},

	beforeMount() {
		this.fetchFramesData();
	},

	methods: {
		fetchFramesData() {
			const data = {
				currentPage: this.currentPage,
				pageSize: this.pageSize,
				filters: {},
				mixData: this.mixData
			}
				
			this.$store.dispatch(this.framesAction, {data});
		},
		toFramePage(frame) {
			if (this.frameIdentificator) {
				this.$router.push({
					name: `${this.framePage}`,
					params: {
						frameId: frame[this.frameIdentificator]
					}
				})
			}
		},
		addFrame() {
			this.$emit('addFrame');
		}
	}
}
</script>
