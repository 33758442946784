<template>
	<ul v-if="tabs.length > 0" class="ui-segment" :class="getBorderType">
		<li 
			v-for="(tab, idx) in tabs" 
			:id="tab.id"
			:key="idx"
			:class="idx === selectedIdx ? 'active' : ''"
			:style="tab.styles"
			@click="select(idx)"
		>
			<a>{{ tab.name }}</a>
		</li>
	</ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UiSegment',

	/**
	 * Модель - id выбранного сегмента
	 */
	model: {
		prop: 'value',
		event: 'select'
	},

	props: {
		tabs: {
			/**
			 * Сегменты.
			 * Массив объектов следующей структуры:
			 * - name: String - Наименование сегмента (надпись на кнопке)
			 * - id: String | Number - Идентификатор сегмента. Уникальный код, значение которого принимает v-model при выборе сегмента
			 * - styles?: String - Дополнительные стили сегмента
			 */
			type: Array, // [{name: String | Number, id: String | Number, active?: boolean, styles?: String}]
			required: true
		},
		/**
		 * Выбранный сегмент (его id)
		 */
		value: {
			type: [String, Number]
		}
	},

	data() {
		return {
			selectedIdx: -1,
		}
	},

	computed: {
		...mapGetters({ currentBrowser: 'currentBrowser' }),

		getBorderType() {
			return this.currentBrowser === this.$store.state.BROWSER_LIST.Safari ? 'bordered' : 'outlined';
		}
	},

	watch: {
		value(val) {
			const idx = this.findIdx(val);
			this.select(idx);
		},

		tabs(arr) {
			if (arr && Array.isArray(arr) && arr.length > 0) {
				let idx = this.selectedIdx;
				if (this.value) {
					idx = this.findIdx(this.value);
				}
				this.select(Math.min(idx, arr.length - 1));
			} else {
				this.select(-1);
			}
		},
	},

	mounted() {
		this.select(this.findIdx(this.value));
	},

	methods: {
		findIdx(val) {
			return this.tabs.findIndex(itm => itm.id == val);
		},

		select(idx) {
			const oldIdx = this.selectedIdx;
			let newValue = undefined;
			if (idx >= 0 && idx < this.tabs.length) {
				this.selectedIdx = idx;
				newValue = this.tabs[idx].id;
			} else {
				this.selectedIdx = -1;
			}
			if (idx != oldIdx) {
				this.$emit("select", newValue);
			}
		},

	},

}
</script>
