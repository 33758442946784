var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g(_vm._b({
    staticClass: "ui-smart-icon",
    style: {
      color: _vm.color
    },
    attrs: {
      "role": "img",
      "width": _vm.width || _vm.size,
      "height": _vm.height || _vm.size,
      "aria-hidden": "true"
    }
  }, 'svg', _vm.$attrs, false), _vm.$listeners), [_vm.title ? _c('title', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('use', {
    staticClass: "ui-smart-icon__use",
    attrs: {
      "title": _vm.title,
      "xlink:href": ("/icon-sprite.svg#sprite-" + (_vm.name))
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }