<template>
	<Portal>
		<div class="ui-base-modal__container" @click.self="closeModal">
			<div ref="modalContent" class="ui-base-modal" :style="modalStyles">
				<UiSkeleton :loading="skeletonLoading"
					:height="skeletonHeight"
					:message="skeletonMessage">
					<slot name="header">
						<div class="ui-base-modal__header">
							<h3 class="ui-base-modal__title">{{ title }}</h3>
							<div class="close-icon ui-base-modal__close-icon" @click="closeModal"/>
						</div>
					</slot>

					<slot name="body"/>
					<div class="ui-base-modal__footer">
						<slot name="footer" :closeModal="closeModal" :confirmModal="confirmModal">
							<a class="button-outline default-link ui-base-modal__cancel" @click="closeModal">Отмена</a>
							<button class="default-btn ui-base-modal__submit" @click="closeModal">Отправить</button>
						</slot>
					</div>
				</UiSkeleton>
			</div>
		</div>
	</Portal>
</template>

<script>
import useFocusTab from '@utils/focusTab';

export default {
	name: 'UiBaseModal',
	provide: {
		scope: 'dialog',
	},
	props: {
		title: {
			type: String,
		},
		height: {
			type: String,
			required: false
		},
		width: {
			type: String,
			required: false
		},
		skeletonHeight: {
			type: String,
			default: '463px'
		},
		skeletonMessage: {
			type: String,
		},
		skeletonLoading: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const focusTab = useFocusTab();
		return { focusTab };
	},
	computed: {
		modalStyles() {
			let res = '';
			res += this.height ? `max-height:${this.height};` : '';
			res += this.width ? `width:${this.width};` : '';
			return res;
		}
	},
	mounted() {
		this.disableScroll()
		document.addEventListener('keydown', this.onKeypress)
		this.$nextTick(() => {
			this.focusTab.init(this.$refs.modalContent)
		})
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.onKeypress)
		this.enableScroll()
	},
	methods: {
		preventDefault(e) {
			e.preventDefault();
		},
		disableScroll() {
			const body = document.body;
			body.style.height = '100vh';
			body.style.overflowY = 'hidden';
			document.body.addEventListener(
				'touchmove',
				this.preventDefault,
				{passive: false}
			);
		},
		enableScroll() {
			const body = document.body;
			body.style.height = '';
			body.style.overflowY = '';
			document.body.removeEventListener(
				'touchmove',
				this.preventDefault,
				{passive: false}
			);
		},
		closeModal() {
			this.$emit('close')
		},
		confirmModal() {
			this.$emit('confirm')
		},
		onKeypress(e) {
			const { shiftKey, key } = e;

			if (!shiftKey && key === 'Tab') {
				e.preventDefault();
				this.focusTab.move('next');
			} else if (shiftKey && key === 'Tab') {
				e.preventDefault();
				this.focusTab.move('prev');
			} else if (key === 'Escape') {
				e.preventDefault();
				this.closeModal()
			} else if (key === 'Enter') {
				e.preventDefault();
				this.confirmModal()
			}
		}
	},
}
</script>
