var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "blur",
      rawName: "v-blur",
      value: _vm.blurConfig,
      expression: "blurConfig"
    }],
    staticClass: "ui-skeleton",
    class: {
      'ui-skeleton--shaded': _vm.shaded,
      'ui-skeleton--container': _vm.container
    }
  }, [_vm.vIfFlag ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.vShowFlag,
      expression: "vShowFlag"
    }],
    key: ((_vm.key) + "_spinner"),
    staticClass: "ui-skeleton__spinner",
    style: _vm.loadingStyleCalc
  }, [_c('div', {
    staticClass: "ui-skeleton__spinner-wrapper"
  }, [_c('UiSpinner', {
    staticClass: "ui-skeleton__spinner",
    attrs: {
      "size": _vm.spinnerSize
    }
  }), _vm.message ? _c('span', {
    staticClass: "ui-skeleton__spinner-message",
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm.vIfFlagNegative ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.vShowFlagNegative,
      expression: "vShowFlagNegative"
    }],
    key: ((_vm.key) + "_content"),
    staticClass: "ui-skeleton__content"
  }, [_vm._t("default")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }