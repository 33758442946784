import { postApiRequestBuilder, getApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder } from '@/utils/RequestBuilder';
import { ORDER_BY } from '../tables';

export function createPlatformPromotionsItem(promotions) {
	return postApiRequestBuilder(`platform-promotions`).send(promotions);
}

export function editPlatformPromotionsItem(promotions) {
	return putApiRequestBuilder(`platform-promotions`).send(promotions);
}

export function getPlatformPromotionsItem(promotionId) {
	return getApiRequestBuilder(`platform-promotions/${promotionId}`).public.send();
}

export function getPlatformPromotions() {
	return getApiRequestBuilder(`platform-promotions`)
		.public.query(
			Object.entries({ sortBy: 'created', sortDirection: ORDER_BY.ASC }),
		)
		.send();
}

export function deletePlatformPromotionsItem(promotionId) {
	return deleteApiRequestBuilder(`platform-promotions/${promotionId}`).send();
}
