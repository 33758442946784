<template>
	<div class="ui-html-page__block">
		<div v-if="title" class="ui-html-page__block--title">
			<h1>{{title}}</h1>
		</div>
		<div v-if="html" class="ui-html-page__block--content" v-html="html" />
		<div v-else class="ui-html-page__block--content">
			<slot name="html"/>
		</div>
	</div>
</template>

<script>
export default {
	name: "UiHtmlPage",
	props: {
		title: {
			type: String,
		},
		html: {
			type: String,
		},
	}
}
</script>