<template>
	<div>
		<div v-if="label" class="radio__label">
			{{ label }}
		</div>
		<div v-if="hint" class="radio__hint">
			{{ hint }}
		</div>
		<div class="radio__list" :class="{ 'radio__list--horizontal': horizontal }">
			<UiRadio
				v-for="(opt, idx) in options"
				:key="`${groupId}-${idx}`"
				:value="opt.value"
				:title="opt.label"
				:group="groupId"
				:disabled="disabled || opt.disabled"
				:readonly="readonly"
				:modelValue="value"
				@change="val => $emit('change', val)"
			/>
			<div v-if="error" class="radio__error">
				{{ error }}
			</div>
		</div>
	</div>
</template>

<script>
import { randomKey } from '@utils/common'

export default {
	name: 'UiRadioGroup',
	/**
	 * В модель передается id выбранного переключателя 
	 */
	model: {
		prop: 'value',
		event: 'change'
	},

	props: {
		/**
		 * Значение группы - соответствует значению выбранного элемента
		 */
		value: {
			type: String,
		},
		/**
		 * Массив опций выбора. В котором элементы имеют:
		 * - value - значение выбора, вернется как значение контрола
		 * - label - заголовок выбора, так будет подписан радиобаттон
		 */
		options: {
			type: Array,
			required: true,
		},
		/**
		 * Заголовок группы выбора
		 */
		horizontal: {
			type: Boolean,
			default: false,
		},
		/**
		 * Заголовок группы выбора
		 */
		label: {
			type: String,
		},
		/**
		 * Пояснение группы выбора. Появляется под заголовком
		 */
		hint: {
			type: String,
		},
		/**
		 * Значение ошибки. Появляется под группой выбора.
		 * Устанавливается, например, валидатором при проверке значений формы.
		 */
		error: {
			type: String,
		},
		/**
		 * Признак блокировки группы выбора
		 */
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			groupId: "",
		}
	},

	created() {
		this.groupId = randomKey('gr_');
	},

}
</script>
