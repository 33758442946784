import RequestBuilder from '@utils/RequestBuilder/RequestBuilder';
import api from "@configs/api";

/**
 * Returns RequestBuilder with get request
 *
 * @param url
 * @return {RequestBuilder}
 */
export function getRequestBuilder(url) {
	return new RequestBuilder(url).get;
}

/**
 * Returns RequestBuilder with post request
 *
 * @param url
 * @return {RequestBuilder}
 */
export function postRequestBuilder(url) {
	return new RequestBuilder(url).post;
}

/**
 * Returns RequestBuilder with put request
 *
 * @param url
 * @return {RequestBuilder}
 */
export function putRequestBuilder(url) {
	return new RequestBuilder(url).put;
}

/**
 * Returns RequestBuilder with get request
 *
 * @param url
 * @return {RequestBuilder}
 */
export function deleteRequestBuilder(url) {
	return new RequestBuilder(url).delete;
}

/**
 * Билдер get-запроса к API
 *
 * @param url url запроса относительно базового url API
 * @return {RequestBuilder}
 */
export function getApiRequestBuilder(url) {
	return new RequestBuilder(`/${api.url}${url}`).get;
}

/**
 * Билдер post-запроса к API
 *
 * @param url url запроса относительно базового url API
 * @return {RequestBuilder}
 */
export function postApiRequestBuilder(url) {
	return new RequestBuilder(`/${api.url}${url}`).post;
}

/**
 * Билдер put-запроса к API
 *
 * @param url url запроса относительно базового url API
 * @return {RequestBuilder}
 */
export function putApiRequestBuilder(url) {
	return new RequestBuilder(`/${api.url}${url}`).put;
}

/**
 * Билдер delete-запроса к API
 *
 * @param url url запроса относительно базового url API
 * @return {RequestBuilder}
 */
export function deleteApiRequestBuilder(url) {
	return new RequestBuilder(`/${api.url}${url}`).delete;
}
