var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasHistory ? _c('div', {
    staticClass: "ui-nav"
  }, [_c('UiButton', {
    attrs: {
      "label": _vm.navBtnLabel,
      "type": "secondary",
      "prepend-icon": "prev-button",
      "size": _vm.navBtnSize,
      "title": "Назад"
    },
    on: {
      "click": _vm.navigateBack
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }