<template>
	<div class="ui-date__wrapper">
		<p v-if="label" :class="labelClasses">{{ label }}</p>

		<div class="ui-date__inner">
			<DatePicker
				ref="uiDate"
				v-model="dateRange"
				class="ui-date"
				:singleDatePicker="true"
				:ranges="false"
				:locale-data="DatePickerConfig"
				:linked-calendars="false"
				:show-dropdowns="true"
				:auto-apply="true"
				:disabled="isDisabled"
				v-on="$listeners"
			> 
				<template #input>
					<input
						:value="value"
						type="date"
						:class="inputStyles"
						:placeholder="placeholder"
						:readonly="readonly"
						:disabled="isDisabled"
						@focus="$emit('focus')"
						@blur="$emit('blur')"
						@input="convertInputDate"
					>
					<div v-if="!isDisabled" class="ui-date__icons">
						<UiSmartIcon
							v-if="clearable && !isPlaceholderActive"
							size="16"
							name="close"
							color="var(--body-color)"
							class="ui-date__clear"
							@click.stop="clearDateRange"
						/>
						<div class="ui-date__calendar">
							<UiSmartIcon
								size="16"
								name="icons-calendar"
								color="var(--body-color)"
								class="ui-date__calendar-icon"
								@click="toggleDate"
							/>
							<div class="ui-date__calendar-background"/>
						</div>
					</div>
				</template> 
			</DatePicker>
		</div>
		<span 
			v-if="caption || error"
			class="input-caption"
			:class="error ? 'input-caption__error' : ''"
		>
			{{ error ? error : caption }}
		</span>
	</div>
</template>

<script>
require('vue2-daterange-picker/dist/vue2-daterange-picker.css');

export default {
	name: "UiDate",
	components: {
		DatePicker: () => import('vue2-daterange-picker'),
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		/**
     	* Значение поля
     	* @type {Object}
     	*/
		value: {
			type: [Object, String],
			default: null
		},
		/**
     	* Изначальное значение поля
     	* @type {Object}
     	*/
		initialValue: {
			type: Object,
			default: null,
		},
		/**
     	* Аттрибут [placeholder]
     	* @type {string}
     	*/
		placeholder: {
			type: String,
			default: 'Выберите дату...'
		},
		/**
     	* Текст для <label>
     	* @type {string}
     	*/
		label: {
			type: String,
			default: ''
		},
		/**
     	* Добавить кнопку сброса поля
     	* @type {boolean}
     	*/
		clearable: {
			type: Boolean,
			default: true
		},
		/**
     	* Аттрибут [disabled]
     	* @type {boolean}
     	*/
	 	disabled: {
			type: Boolean,
			default: false
		},
		/**
     	* Аттрибут [readonly]
     	* @type {boolean}
     	*/
	 	readonly: {
			type: Boolean,
			default: false
		},
		/**
		 * Сообщение об ошибке
		 * @type {string}
		 */
		error: {
			type: String,
			default: ''
		},
		/**
		 * Подсказка
		 * @type {string}
		 */
		caption: {
			type: String,
			default: ''
		},
		/**
		 * Label nowrap
		 */
		nowrap: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dateRange: {},
			focused: false,
			btnsStatus: false,
			DatePickerConfig: {
				direction: 'ltr',
				format: 'dd.mm.yyyy',
				applyLabel: 'Сохранить',
				Today: 'Сохранить',
				cancelLabel: 'Отмена',
				customRangeLabel: 'Custom Range',
				weekLabel: 'W',
				daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
				monthNames: ['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
				firstDay: 0,
			},
		}
	},
	computed: {
		isPlaceholderActive() {
			return !this.value;
		},
		isDisabled() {
			return this.disabled || this.readonly
		},
		labelClasses() {
			return {
				'ui-date__label': true,
				'input-label': true,
				'nw': this.nowrap
			}
		},
		inputStyles() {
			return {
				'ui-date__input': true,
				'disabled': this.isDisabled,
			}
		}
	},
	watch: {
		dateRange: {
			handler: function (newVal) {
				if (Object.keys(newVal).length) {
					if (newVal.startDate instanceof Date) {
						this.updateDateRange(newVal.startDate.toISOString().slice(0, 10));
					} else {
						this.updateDateRange(newVal.startDate);
					}
				}
			},
			deep: true,
		},
		value(newVal) {
			this.setDate(newVal)
		}
	},
	beforeMount() {
		if (this.initialValue) this.setDate(this.initialValue);
		if (this.value) this.setDate(this.value);
	},
	methods: {
		clearDateRange() {
			this.dateRange = {};
			this.updateDateRange(null);
			this.$refs.uiDate.togglePicker(false);
		},
		updateDateRange(data) {
			this.$emit('change', data);
		},
		setDate(data) {
			this.dateRange = {
				startDate: data,
				endDate: data,
			}
		},
		convertInputDate(event) {
			const data = event.target.value;
			this.updateDateRange(data);
		},
		toggleDate($event) {
			this.focused = $event;
			this.$emit(this.focused ? 'focus' : 'blur');
		},
	}
}
</script>
