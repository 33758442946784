var render = function () {
  var _vm$currentFrames, _vm$currentFrames$ite;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-content__list"
  }, [!_vm.withoutHeader ? _c('div', {
    staticClass: "ui-content__list-header"
  }, [_vm._t("header", function () {
    return [_vm.listTitle ? _c('h2', {
      staticClass: "ui-content__list-title"
    }, [_vm._v(_vm._s(_vm.listTitle))]) : _vm._e(), !_vm.projectView && _vm.canAddFrames ? _c('UiButton', {
      attrs: {
        "label": _vm.addFrameLabel
      },
      on: {
        "click": _vm.addFrame
      }
    }) : _vm._e()];
  }, null, {
    listTitle: _vm.listTitle
  })], 2) : _vm._e(), _c('UiSkeleton', {
    staticClass: "ui-content__list-skeleton",
    attrs: {
      "showMode": "if",
      "loadingStyle": _vm.skeletonStyles,
      "loading": _vm.framesLoading,
      "height": "300px"
    }
  }, [(_vm$currentFrames = _vm.currentFrames) !== null && _vm$currentFrames !== void 0 && (_vm$currentFrames$ite = _vm$currentFrames.items) !== null && _vm$currentFrames$ite !== void 0 && _vm$currentFrames$ite.length ? _c('div', {
    staticClass: "ui-content__list-items"
  }, _vm._l(_vm.currentFrames.items, function (frame, idx) {
    return _c('UiContentFrame', {
      key: idx,
      attrs: {
        "frame-content": frame
      },
      on: {
        "click": function ($event) {
          return _vm.toFramePage(frame);
        }
      }
    });
  }), 1) : _c('div', {
    staticClass: "ui-content__list-items no-items"
  }, [_vm._v(" " + _vm._s(_vm.noItemsText) + " ")])]), _vm.isPaginationVisible && !_vm.projectView ? _c('UiPagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "title": _vm.paginationTitle,
      "total": _vm.totalCount,
      "pageSize": _vm.pageSize
    },
    on: {
      "changePage": function (number) {
        return _vm.currentPage = number;
      }
    }
  }) : _vm._e(), _vm.projectView && _vm.canAddFrames ? _c('UiButton', {
    attrs: {
      "type": "secondary",
      "label": _vm.addFrameLabel
    },
    on: {
      "click": _vm.addFrame
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }