<template>
	<div id="app" ref="app">
		<component :is="layout">
			<router-view />
		</component>

		<UiNotify/>
	</div>
</template>

<script>
import { syncUser } from '@services/user';
import store from './store';
import KC from '@/keycloak';
import {saveTokenToIndexedDB} from "@utils/indexedDB";
import {getEnv, VUE_APP_SERVICE_NAME} from "@services/env";
import { computed } from '@vue/composition-api';

function getDebugToken(token) {
	return token
		? token.length <= 32
			? token
			: token.substring(0, 16) + "..." + token.substring(token.length - 16)
		: "<null>";
}

async function setTokenHandler(userToken) {
	console.log("setTokenHandler: " + getDebugToken(userToken?.token))
	await saveTokenToIndexedDB(userToken)
}

async function kcTokenHandler() {
	const token = KC.instance()._keycloak.token;
	const exp = KC.instance()._keycloak.tokenParsed?.exp;
	await setTokenHandler({token, exp});
}
function clearTokenHandler() {
	setTokenHandler(null);
}

async function updateSentryUser() {
	const { setUser: setSentryUser } = await import('@sentry/browser');

	const kc = KC.instance();
	
	if (!kc.isAuthenticated()) {
		setSentryUser(null);
		return;
	}
	
	const token = kc.tokenParsed();
	setSentryUser({ 
		username: token.username,
		email: token.email,
	});
}

export default {
	name: "App",

	provide() {
		return {
			mediaForDesktop: computed(() => this.mediaForDesk)
		}
	},

	data() {
		return {
			map: new Map(),
			mediaForDesk: true,
		}
	},

	computed: {
		metaTitle() {
			const appServiceName = getEnv(VUE_APP_SERVICE_NAME);
			if (!this.$route.meta?.title) {
				return appServiceName;
			}
			return appServiceName + ' - ' + this.$route.meta.title;
		},
		layoutName() {
			return this.$route.meta.layout || 'DefaultLayout';
		},
		kcRole() {
			return this.$kc.kcRole();
		},
		layout() {
			return this.map.get(this.layoutName);
		}
	},

	watch: {
		layoutName: {
			handler(newValue) {
				if (!this.map.has(newValue)) {
					/** рефрешим layout только если он меняется */
					this.map.set(newValue, () => import(`@/layouts/${this.kcRole}/${newValue}.vue`));
				}
			},
			immediate: true
		}
	},

	created() {
		// загрузить справочники
		store.dispatch("dictionary/__initDictionaries");
		window.addEventListener('resize', this.displayForDesktop);
		this.displayForDesktop();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.displayForDesktop);
	},

	metaInfo() {
		return {
			title: this.metaTitle,
		};
	},

	methods: {
		async onAuthSuccess() {
			console.log("onAuthSuccess")
			// отправить токен в ServiceWorker
			await kcTokenHandler();
			updateSentryUser();

			// синхронизировать пользователя
			syncUser()
				.catch((err) => {
					console.error(err);
				});

			// запросить участников и установить чарактер (Инвестор/ЛПИ)
			await store.dispatch("userParticipant/loadEntitiesList");
		},

		async onTokenChange() {
			console.log("onTokenChange")
			// отправить токен в ServiceWorker
			await kcTokenHandler();
			updateSentryUser();
		},

		onAuthLogout() {
			console.log("onAuthLogout")
			// отправить токен в ServiceWorker
			clearTokenHandler();
			updateSentryUser();
		},

		displayForDesktop() {
			this.mediaForDesk = window.innerWidth > 1024
		},
	}
};
</script>
