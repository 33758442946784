import {getOperatorCounter} from "@services/operator";

const INTERVAL = 1 * 60 * 1000;

export default {
	namespaced: true,

	state: {
		participantModerateCount: 0,
		entityPatchRequestModerateCount: 0,
		projectModerateCount: 0,
		pitchModerateCount: 0,
		chatNewMessageCount: 0,
		contractInvestmentRequestsCount: 0,
		withdrawalFundsCount: 0,
	},
	actions: {
		intOperatorCounterAction({ dispatch }) {
			const fetch = () => {
				dispatch('refreshOperatorCounterAction');
			}

			fetch();

			setInterval(fetch, INTERVAL);
		},
		refreshOperatorCounterAction({ commit }) {
			getOperatorCounter().then((r) => {
				commit('operatorCounterMutation', r.data);
			})
		},
	},
	mutations: {
		operatorCounterMutation(state, data) {
			state.participantModerateCount = data.participantModerateCount;
			state.entityPatchRequestModerateCount = data.entityPatchRequestModerateCount;
			state.projectModerateCount = data.projectModerateCount;
			state.pitchModerateCount = data.pitchModerateCount;
			state.chatNewMessageCount = data.chatNewMessageCount;
			state.contractInvestmentRequestsCount = data.contractInvestmentRequestsCount;
			state.withdrawalFundsCount = data.withdrawalFundsCount;
		},
	},
	getters: {
		participantModerationWidgetGetter(state) {
			return state.participantModerateCount + state.entityPatchRequestModerateCount;
		},
		projectModerationWidgetGetter(state) {
			return state.projectModerateCount + state.pitchModerateCount;
		},
		hasParticipantModerationCountGetter(state) {
			console.log(state.participantModerateCount)
			return state.participantModerateCount > 0;
		},
		hasEntityPatchRequestModerationCountGetter(state) {
			return state.entityPatchRequestModerateCount > 0;
		},
		hasProjectModerationCountGetter(state) {
			return state.projectModerateCount > 0;
		},
		hasPitchModerationCountGetter(state) {
			return state.pitchModerateCount;
		},
		chatNewMessageCountGetter(state) {
			return state.chatNewMessageCount;
		},
		contractInvestmentRequestsCountGetter(state) {
			return state.contractInvestmentRequestsCount;
		},
		withdrawalFundsCountGetter(state) {
			return state.withdrawalFundsCount;
		},
		hasOperatorMyTasksGetter(state) {
			const total = state.participantModerateCount
                + state.entityPatchRequestModerateCount
                + state.projectModerateCount
                + state.pitchModerateCount
                + state.chatNewMessageCount
                + state.contractInvestmentRequestsCount
                + state.withdrawalFundsCount;

			return total > 0;
		}
	},
};