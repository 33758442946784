<template>
	<span class="cipher-link">
		<router-link :to="linkLocation" v-bind="$attrs" v-on="$listeners">
			<slot v-bind="{ displayName, linkLocation }">{{ displayName }}</slot>
		</router-link>
		<UiSmartIcon
			class="cipher-link__icon"
			name="icons-copy"
			width="20"
			height="18"
			@click="copyLinkToClipboard"
		/>
	</span>
</template>

<script>
import { ERROR, SUCCESS } from '@/configs/ui';
import { getObjectLocation, objectDisplayCipher } from '@services/cipher';

export default {
	name: 'CipherLink',
	props: {
		obj: {
			type: Object,
			required: true,
		},
	},
	computed: {
		displayName() {
			return objectDisplayCipher(this.obj);
		},
		linkLocation() {
			return getObjectLocation(this.obj);
		},
	},
	methods: {
		async copyLinkToClipboard() {
			const href = new URL(
				this.$router.resolve(this.linkLocation).href,
				window.location.origin,
			).href;

			try {
				await navigator.clipboard.writeText(decodeURI(href));
				this.$notify({
					type: SUCCESS,
					title: 'Ссылка скопирована в буфер обмена',
				});
			} catch(err) {
				this.$notify({
					type: ERROR,
					title: 'Не удалось скопировать ссылку',
					text: err.response?.data.message,
				});
			}
		},
	},
};
</script>
