<template>
	<li class="ui-info__block">
		<span class="ui-info__block-label">
			<slot name="label">
				{{ label }}
			</slot>
		</span>
		<span :style="valueStyles" class="ui-info__block-value">
			<slot name="value">
				{{ infoValue }}
			</slot>
		</span>
	</li>
</template>

<script>
export default {
	name: "UiInfoBlock",
	props: {
		/**
        * Заголовок блока (если есть)
        */
		label: {
			type: String,
			default: '',
		},
		/**
        * Value блока
        */
		value: {
			type: [String, Number],
			default: '',
		},
		valueColor: {
			type: String,
			default: '#3589cf'
		}
	},
	computed: {
		infoValue() {
			return this.value ?? '-';
		},
		valueStyles() {
			return {
				color: this.valueColor
			}
		}
	}
}
</script>
