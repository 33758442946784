import Vue from "vue";
import {DOCUMENT_TYPE_SCOPES} from "@configs/documents";
import {upperCaseFirst} from "@utils/common";
import {getUserDocuments} from "@services/user";

function getMethodName(scope, pref) {
	return pref + 'UserDoc' + upperCaseFirst(scope.toLowerCase());
}

let actions = {};
let getters = {};

for (let scope in DOCUMENT_TYPE_SCOPES) {
	actions[getMethodName(scope, 'action')] = async ({ dispatch, state }) => {
		if (state.documents[scope] === undefined) {
			return dispatch('__getDocuments', scope);
		}
	}

	getters[getMethodName(scope, 'getter')] = (state) => {
		return state.documents[scope] || [];
	}
}

export default {
	namespaced: true,

	/**
     * @property {Function} actionUserDocParticipant
     * @property {Function} actionUserDocEntity
     * @property {Function} actionUserDocPerson
     * @property {Function} actionUserDocProject
     */
	actions: {
		async __getDocuments({ commit }, code) {
			return getUserDocuments(code).then(r => {
				const data = r.data.items;
				commit('setData', { code, data })
			})
		},

		...actions,
	},
	mutations: {
		setData(state, { code, data }) {
			Vue.set(state.documents, code, data);
		},
	},
	state: {
		documents: {},
	},
	/**
     * @property {Function} getterUserDocParticipant
     * @property {Function} getterUserDocEntity
     * @property {Function} getterUserDocPerson
     * @property {Function} getterUserDocProject
     */
	getters: {
		...getters,
	},
};