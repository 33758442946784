var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "ui-spinner",
    attrs: {
      "height": _vm.sizeInPixels,
      "width": _vm.sizeInPixels,
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21V24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C12.8284 0 13.5 0.671573 13.5 1.5C13.5 2.32843 12.8284 3 12 3Z",
      "fill": "#3589CF"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21V24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0V3Z",
      "fill": "url(#paint0_linear_130_92433)"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_130_92433",
      "x1": "15.9375",
      "y1": "4.125",
      "x2": "23.1562",
      "y2": "12.6562",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#3589CF",
      "stop-opacity": "0"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#3589CF"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }