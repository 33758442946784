<template>
	<svg
		v-bind="$attrs"
		role="img"
		class="ui-smart-icon"
		:width="width || size"
		:height="height || size"
		aria-hidden="true"
		:style="{ color }"
		v-on="$listeners"
	>
		<title v-if="title">{{ title }}</title>
		<use class="ui-smart-icon__use" :title="title" :xlink:href="`/icon-sprite.svg#sprite-${name}`" />
	</svg>
</template>

<script>
export default {
	name: 'UiSmartIcon',

	props: {
		// название иконки, (название файла svg в icons-pack)
		// подпапки указываются префиксом, через '-'
		// пробелы убираются из имени файла
		// icons-pack/add.svg === name="add"
		// icons-pack/icons/add.svg === name="icons-add"
		// icons-pack/icon-base (1).svg === name="icon-base(1)"
		name: {
			type: String,
			required: true,
		},

		// размер иконки
		size: {
			type: [Number, String],
			default: 18,
		},

		// ширина иконки, приоритет выше чем у size
		width: {
			type: [Number, String],
			default: undefined,
		},

		// высота иконки, приоритет выше чем у size
		height: {
			type: [Number, String],
			default: undefined,
		},

		// цвет иконки (currentColor - цвет текста элемента родителя)
		color: {
			type: String,
			default: 'currentColor',
		},

		title: {
			type: String,
			default: undefined,
		},
	},
};
</script>
