<template>
	<div class="ui-progress-bar"
		:style="{
			'border-radius': borderRadius,
			'background': 'var(--grey)'
		}"
	>
		<div v-if="!isPublished && showProgressBarStatus" class="ui-progress-bar__progress-status">
			<span v-if="succesCollect" class="progress-status__success">
				Средства собраны
			</span>
			<span v-else class="progress-status__unsuccess">
				Средства не собраны
			</span>
		</div>
		<div
			class="ui-progress-bar__progress"
			:style="{
				'background': progressBarColor,
				'width': calculatedPercent + '%',
				'height': thickness + 'px',
				'border-radius': borderRadius,
			}"
		/>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import modeTypeMixin from "@/mixins/modeTypeMixin";

export default {
	name: 'UiProgressBar',
	mixins: [modeTypeMixin],
	props: {
		showStatus: {
			type: Boolean,
			default: false
		},
		succesCollect: {
			type: Boolean,
		},
		isPublished: {
			type: Boolean,
		},
		collected: {
			type: Number,
		},
		target: {
			type: Number,
		},
		color: {
			type: String,
			default: 'var(--additional-3)'
		},
		thickness: {
			type: Number,
			required: true
		},
		borderRadius: {
			type: String,
			default: '30px'
		}
	},
	computed: {
		...mapGetters({
			project: 'project/getProject',
			isOwner: "project/getterIsOwner",
		}),
		isSuccess() {
			return this.project.released !== null;
		},
		lpiOrInvestorReleased() {
			return this.isSuccess && (this.isOwner || this.project.investor || this.isOperator);
		},
		calculatedPercent() {
			return (this.collected / this.target) * 100
		},
		progressBarColor() {
			return !this.isPublished ? this.succesCollect ? this.color : 'var(--greyscale-5)' : this.color;
		},
		showProgressBarStatus() {
			return this.showStatus && !this.lpiOrInvestorReleased;
		}
	},
}
</script>
