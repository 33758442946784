import UiInput from './UiInput/UiInput.vue';
import UiArrayInput from './UiArrayInput/UiArrayInput.vue';
import UiButton from './UiButton/UiButton.vue';
import UiOperatorContract from './UiOperatorContract/UiOperatorContract.vue';
import UiPages from './UiPages/UiPages.vue';
import UiPage from './UiPages/UiPage.vue';
import UiSlider from './UiSlider/UiSlider.vue';
import UiSelect from './UiSelect/UiSelect.vue';
import UiFile from './UiFile/UiFile.vue';
import UiModal from './UiModal/UiModal.vue';
import UiDialog from './UiDialog/UiDialog.vue';
import UiBaseModal from './UiBaseModal/UiBaseModal.vue';
import UiRadio from './UiRadio/UiRadio.vue';
import UiRadioGroup from './UiRadioGroup/UiRadioGroup.vue';
import UiPopup from './UiPopup/UiPopup.vue';
import UiCheckbox from './UiCheckbox/UiCheckbox.vue';
import UiCheckboxSwitch from './UiCheckboxSwitch/UiCheckboxSwitch.vue';
import UiSegment from './UiSegment/UiSegment.vue';
import UiProgressBar from './UiProgressBar/UiProgressBar.vue';
import UiBlock from './UiBlock/UiBlock.vue';
import UiTable from './UiTable/UiTable.vue';
import UiPagination from './UiPagination/UiPagination';
import UiDropdown from '@components/UI/UiDropdown/UiDropdown';
import UiSpinner from '@components/UI/UiSpinner/UiSpinner';
import UiSkeleton from '@components/UI/UiSkeleton/UiSkeleton';
import UiForm from '@components/UI/UiForm/UiForm';
import UiDocs from '@components/UI/UiDocs/UiDocs.vue';
import UiSmartIcon from '@components/UI/UiSmartIcon/UiSmartIcon';
import UiTip from '@components/UI/UiTip/UiTip';
import UiBlur from '@components/UI/UiBlur/UiBlur'
import UiSidebar from '@components/UI/UiSidebar/UiSidebar'
import UiBookmarks from '@components/UI/UiBookmarks/UiBookmarks';
import UiParticipantStatus from '@components/UI/UiParticipantStatus/UiParticipantStatus';
import UiParticipantStatusHistory from '@components/UI/UiParticipantStatusHistory/UiParticipantStatusHistory';
import UiProjectStatus from '@components/UI/UiProjectStatus/UiProjectStatus';
import UiNotify from '@components/UI/UiNotify/UiNotify';
import UiDocuments from '@components/UI/UiDocuments/UiDocuments';
import UiDocument from '@components/UI/UiDocument/UiDocument';
import UiTableList from '@components/UI/UiTableList/UiTableList';
import UiDate from '@components/UI/UiDate/UiDate';
import UiContentFrame from '@components/UI/UiContentFrame/UiContentFrame';
import UiContentFrameList from '@components/UI/UiContentFrameList/UiContentFrameList';
import UiCommentsFrameList from '@components/UI/UiCommentsFrameList/UiCommentsFrameList';
import UiShowcase from '@components/UI/UiShowcase/UiShowcase';
import UiExportToExcel from '@components/UI/UiExportToExcel/UiExportToExcel';
import UiFilterList from '@components/UI/UiFilterList/UiFilterList';
import UiInfoBlock from '@components/UI/UiInfoBlock/UiInfoBlock';
import UiNav from '@components/UI/UiNav/UiNav';
import UiHtmlPage from "@components/UI/UiHtmlPage/UiHtmlPage.vue";

export default [
	UiButton,
	UiInput,
	UiOperatorContract,
	UiSelect,
	UiPages,
	UiPage,
	UiSlider,
	UiFile,
	UiModal,
	UiDialog,
	UiBaseModal,
	UiRadio,
	UiRadioGroup,
	UiPopup,
	UiSegment,
	UiCheckbox,
	UiCheckboxSwitch,
	UiProgressBar,
	UiBlock,
	UiTable,
	UiPagination,
	UiDropdown,
	UiSpinner,
	UiSkeleton,
	UiForm,
	UiDocs,
	UiSmartIcon,
	UiTip,
	UiBlur,
	UiSidebar,
	UiBookmarks,
	UiParticipantStatus,
	UiParticipantStatusHistory,
	UiProjectStatus,
	UiNotify,
	UiArrayInput,
	UiDate,
	UiDocuments,
	UiDocument,
	UiTableList,
	UiContentFrame,
	UiContentFrameList,
	UiCommentsFrameList,
	UiShowcase,
	UiExportToExcel,
	UiFilterList,
	UiInfoBlock,
	UiHtmlPage,
	UiNav
];
