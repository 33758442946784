var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-blur"
  }, [_c('div', {
    directives: [{
      name: "blur",
      rawName: "v-blur",
      value: _vm.blurConfig,
      expression: "blurConfig"
    }],
    class: {
      'blur-element': true,
      'blur-element--noselect': _vm.blurred
    }
  }, [_vm._t("default")], 2), _vm.showAuthRequest ? _c('div', {
    staticClass: "auth-request"
  }, [_c('h2', {
    staticClass: "auth-request__title"
  }, [_vm._v("Чтобы увидеть содержимое проекта, необходимо зарегистрироваться!")]), _c('UiButton', {
    staticClass: "auth-request__btn-login",
    attrs: {
      "label": "Войти"
    },
    on: {
      "click": _vm.logIn
    }
  }), _c('UiButton', {
    staticClass: "auth-request__btn-register",
    attrs: {
      "label": "Зарегистрироваться",
      "type": "secondary"
    },
    on: {
      "click": _vm.logIn
    }
  })], 1) : _vm._e(), _vm.showInvestorNotify ? _c('div', {
    staticClass: "auth-request"
  }, [_c('h2', {
    staticClass: "auth-request__title"
  }, [_vm._v("Чтобы увидеть содержимое проекта, необходимо добавить анкету инвестора!")]), _c('UiButton', {
    staticClass: "auth-request__btn-login",
    attrs: {
      "label": "Добавить анкету инвестора",
      "to": _vm.questionnairesPageRoute
    }
  }), _c('UiButton', {
    staticClass: "auth-request__btn-register",
    attrs: {
      "label": "Назад",
      "type": "secondary"
    },
    on: {
      "click": _vm.goBack
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }