<template>
	<div class="b26 form-block">
		<div class="form-block__header">
			<h3 v-if="title || $slots.title" :class="titleClasses">
				<div v-if="tip" :class="tipClasses">
					<UiTip
						:position="tip.position"
						:message="tip.message"
						:tip-styles="tip.styles"
					/>
				</div>
				<slot name="title">
					<p v-html="title"/>
				</slot>
			</h3>
			<UiButton 
				v-if="showVisibilityToggle"
				size="xs"
				type="link"
				:label="isBlockVisible ? 'Скрыть' : 'Показать'"
				@click="toggleVisibility"
			/>
		</div>
		<div>
			<slot name="additionalSlot"/>
		</div>
		<div 
			ref="toggleHeightTarget"
			v-toggle-height
			class="form-block__content"
		>
			<slot/>
		</div>
	</div>
</template>

<script>
import toggleHeight from '@/directives/toggleHeight';

export default {
	name: "UiBlock",
	directives: {
		toggleHeight,
	},
	props: {
		title: {
			type: String,
			default: undefined,
		},
		tip: {
			type: Object,
			default: null,
		},
		/**
		 * Признак показа кнопки скрытия содержимого блока
		 */
		showVisibilityToggle: {
			type: Boolean,
			default: false,
		},
		/**
		 * Признак видимости блока
		 */
		visible: {
			type: Boolean,
			default: true,
		},
		/**
		 * Признак видимости блока
		 */
		withoutVisibleAnimation: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		isBlockVisible: true
	}),
	computed: {
		titleClasses() {
			return {
				'form-block__title': true,
				'flex-grow': true,
				'flex': this.title
			}
		},
		tipClasses() {
			return {
				'form-block__tip': true,
				'blocked': this.title,
				'form__tip': true,
			}
		},	
	},
	watch: {
		visible: {
			handler(newValue) {
				if (!this.withoutVisibleAnimation) {
					this.isBlockVisible = newValue;
					this.$refs.toggleHeightTarget?.toggleHeight(this.isBlockVisible);
				}
			},
			immediate: true
		}
	},
	mounted() {
		this.isBlockVisible = this.visible;
		if (!this.withoutVisibleAnimation) {
			this.$refs.toggleHeightTarget?.toggleHeight(this.isBlockVisible);
		}
	},
	methods: {
		toggleVisibility() {
			this.isBlockVisible = !this.isBlockVisible;
			this.$refs.toggleHeightTarget.toggleHeight(this.isBlockVisible);
		},
	}
}
</script>
