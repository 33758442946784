var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "content-pages"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "buttons-block",
    class: {
      'buttons-block--right': _vm.buttonsRight
    }
  }, [_c('ul', {
    staticClass: "ui-slider__buttons"
  }, [_c('li', [_c('UiButton', {
    staticStyle: {
      "margin-top": "0px"
    },
    attrs: {
      "label": "Предыдущий шаг",
      "prependIcon": "prev-button",
      "type": "secondary",
      "disabled": !_vm.canPrevStep
    },
    on: {
      "click": _vm.goPrev
    }
  })], 1), _c('li', [_c('UiButton', {
    staticStyle: {
      "margin-top": "0px"
    },
    attrs: {
      "label": "Следующий шаг",
      "appendIcon": "show-more",
      "type": "secondary",
      "disabled": !_vm.canNextStep
    },
    on: {
      "click": _vm.goNext
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }