import createDictionary from './TypesDictionary';

export {
	commonSettings,
	konturSettings,
	businessTypes,
	calendar,
	investmentRanges,
	documentTypes,
	documentTemplates,
	investorGroupTypes,
	stocksTypes,
	utilityRightsTypes,
	collateralTypes,
	paymentSchemes,
	loanPurposes,
	realEstateObjectsTypes,
	paymentPurpose,
	messageTexts,
	lpiRatings,
	loanOdds,
	platformLoanLimits,
	platformConvLoanLimits,
	convLoanTerm,
	projectTypes,
	publishTerm,
	loanTaxes,
	convertibleLoanTaxes,
	stockTaxes,
	ucpTaxes,
	loanTaxRanges,
	convertibleLoanTaxRanges,
	stockTaxRanges,
	ucpTaxRanges,
	platformContacts,
	paymentDelay,
	faqCategories
};

const commonSettings = createDictionary({ endpoint: 'common-settings' });

const konturSettings = createDictionary({ endpoint: 'kontur-settings' });

const calendar = createDictionary({ endpoint: 'calendar' });

const businessTypes = createDictionary({ endpoint: 'business-types' });

const investmentRanges = createDictionary({ endpoint: 'investment-ranges' });

const documentTypes = createDictionary({ endpoint: 'document-types' });

const documentTemplates = createDictionary({ endpoint: 'document-templates' });

const investorGroupTypes = createDictionary({ endpoint: 'investor-group-types' });

const stocksTypes = createDictionary({ endpoint: 'share-types' });

const utilityRightsTypes = createDictionary({ endpoint: 'utility-types' });

const collateralTypes = createDictionary({ endpoint: 'collateral-types' });

const paymentSchemes = createDictionary({ endpoint: 'payment-schemes' });

const loanPurposes = createDictionary({ endpoint: 'loan-purposes' });

const realEstateObjectsTypes = createDictionary({ endpoint: 'real-estate-objects' });

const paymentPurpose = createDictionary({ endpoint: 'payment-purpose' });

const messageTexts = createDictionary({ endpoint: 'message-texts' });

const lpiRatings = createDictionary({ endpoint: 'lpi-ratings' });

const loanOdds = createDictionary({ endpoint: 'lpi-odds' });

const platformLoanLimits = createDictionary({ endpoint: 'platform-loan-limits' });

const platformConvLoanLimits = createDictionary({ endpoint: 'platform-conv-loan-limits' });

const convLoanTerm = createDictionary({ endpoint: 'conv-loan-term' });

const projectTypes = createDictionary({ endpoint: 'project-types' });

const publishTerm = createDictionary({ endpoint: 'publish-term' });

const platformContacts = createDictionary({ endpoint: 'platform-contacts' });

const paymentDelay = createDictionary({ endpoint: 'payment-delay' });

const loanTaxes = createDictionary({ endpoint: 'loan-taxes' });

const convertibleLoanTaxes = createDictionary({ endpoint: 'convertible-loan-taxes' });

const stockTaxes = createDictionary({ endpoint: 'stock-taxes' });

const ucpTaxes = createDictionary({ endpoint: 'ucp-taxes' });

const loanTaxRanges = createDictionary({ endpoint: 'loan-tax-ranges' });

const convertibleLoanTaxRanges = createDictionary({ endpoint: 'convertible-loan-tax-ranges' });

const stockTaxRanges = createDictionary({ endpoint: 'stock-tax-ranges' });

const ucpTaxRanges = createDictionary({ endpoint: 'ucp-tax-ranges' });

const faqCategories = createDictionary({ endpoint: 'faq-categories' });
