var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('notifications', {
    attrs: {
      "position": "bottom right",
      "width": "350px",
      "classes": "custom-notify",
      "duration": 4000
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          class: _vm.getClasses(item)
        }, [_c('div', {
          staticClass: "notification-title"
        }, [_vm._v(_vm._s(item.title))]), _c('div', {
          staticClass: "notification-content"
        }, [item.text && item.type === 'error' ? _c('button', {
          staticClass: "custom-notify__button",
          on: {
            "click": function ($event) {
              return _vm.openErrorDialog(item);
            }
          }
        }, [_vm._v(" Подробнее ")]) : [_vm._v(" " + _vm._s(item.text) + " ")]], 2)])];
      }
    }])
  }), _c('UiDialog', {
    ref: "dialog",
    attrs: {
      "withoutFooter": "",
      "isNotify": "",
      "title": _vm.errorTitle,
      "width": "80vw"
    }
  }, [_vm.errorMessage ? _c('UiPopup', {
    staticClass: "mt-16",
    attrs: {
      "type": "error",
      "title": _vm.errorMessage
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "whitespace-pre-wrap break-words"
        }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])];
      },
      proxy: true
    }], null, false, 1299677430)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }