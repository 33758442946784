var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "showcase"
  }, [_c('h1', {
    staticClass: "showcase__title"
  }, [_vm._t("title")], 2), _vm._t("default"), _c('div', {
    staticClass: "showcase__inner"
  }, [_c('div', {
    staticClass: "showcase__inner-left"
  }, [_vm._t("left")], 2), _c('div', {
    staticClass: "showcase__inner-right"
  }, [_vm._t("right")], 2)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }