var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-bookmarks"
  }, [_vm.showTitle ? _c('div', {
    staticClass: "ui-bookmarks__title-block ui-bookmarks__item"
  }, [_c('h2', {
    staticClass: "ui-bookmarks__title-name"
  }, [_vm._t("title", function () {
    return [_c('div', {
      staticClass: "flex"
    }, [_c('UiNav', {
      staticClass: "ui-bookmarks__nav mr-12",
      attrs: {
        "navBtnLabel": ""
      }
    }), _vm._v(" " + _vm._s(_vm.title) + " ")], 1)];
  })], 2)]) : _vm._e(), !_vm.titleOnly ? _c('div', {
    class: _vm.bookmarkClasses
  }, [_c('ul', {
    ref: "bookmarksList",
    staticClass: "ui-bookmarks__list"
  }, [_vm._t("button"), _vm._l(_vm.bookmarks, function (bookmark) {
    return _c('router-link', {
      key: bookmark.name,
      attrs: {
        "to": bookmark.to,
        "custom": ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var href = _ref.href,
            navigate = _ref.navigate;
          return [_c('li', {
            class: _vm.getListItemClasses(bookmark)
          }, [_c('a', {
            staticClass: "ui-bookmarks__list-link",
            attrs: {
              "href": href
            },
            on: {
              "click": navigate
            }
          }, [_vm.hasSlot(bookmark) ? [_vm._t(bookmark.slot, null, {
            "bookmark": bookmark
          })] : [_vm._v(" " + _vm._s(bookmark.name) + " "), bookmark !== null && bookmark !== void 0 && bookmark.read ? _c('div', {
            staticClass: "read-mark ml-4"
          }) : _vm._e()]], 2)])];
        }
      }], null, true)
    });
  })], 2)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }