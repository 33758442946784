export * as moderation from './moderation';
export * from './project';

const PROJECT_TYPE = {
	STOCK: 'Акции',
	LOAN: 'Заём',
	CONVERTIBLE_LOAN: 'Конверт. заём',
	UCP: 'УЦП',
}

export const getProjectTypeName = (type) => PROJECT_TYPE[type.toUpperCase()];
