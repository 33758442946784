import Keycloak from 'keycloak-js';
import { fio } from '@utils/common';
import {getEnv, VUE_APP_KEYCLOAK_CLIENT_ID, VUE_APP_KEYCLOAK_REALM, VUE_APP_KEYCLOAK_URL} from "@services/env";

export const KC_ROLES = {
	ADMINISTRATOR: 'administrator',
	OPERATOR: 'operator',
	USER: 'user'
}

export const ALL_ROLES_ARR = Object.entries(KC_ROLES).map(pp => pp[1]);

const __KC_CALLBACKS = [
	'onReady',
	'onAuthSuccess',
	'onAuthError',
	'onAuthRefreshSuccess',
	'onAuthRefreshError',
	'onAuthLogout',
	'onTokenExpired',
	'onActionUpdate',
];

class KC {
	static _instance = null;
	static _TOKEN_MIN_VALIDITY_SECONDS = 65;
	static _TOKEN_FORCED_MIN_VALIDITY_SECONDS = 86400;
	_keycloak;
	#ensureTokenCallbacks;

	constructor(keycloak) {
		this._keycloak = keycloak;
	}

	/**
	 * 
	 * @returns {KC}
	 */
	static instance() {
		if (!KC._instance) {
			KC._instance = new KC(new Keycloak({
				url: getEnv(VUE_APP_KEYCLOAK_URL),
				realm: getEnv(VUE_APP_KEYCLOAK_REALM),
				clientId: getEnv(VUE_APP_KEYCLOAK_CLIENT_ID),
			}));
		}
		return this._instance;
	}

	setCallbacks(callbacks) {
		Object.entries(callbacks)
			.filter((ent) => __KC_CALLBACKS.includes(ent[0]))
			.forEach((ent) => {
				const name = ent[0];
				const callback = ent[1];
				if (callback) {
					this._keycloak[name] = callback;
				}
			});
		return this;
	}

	async init() {
		return this._keycloak.init({
			onLoad: 'check-sso',
			checkLoginIframe: false,
			enableLogging: process.env.NODE_ENV === 'development',
		});
	}

	async updateToken() {
		await this._keycloak.updateToken(KC._TOKEN_MIN_VALIDITY_SECONDS);
	}
	async forceUpdateToken() {
		await this._keycloak.updateToken(KC._TOKEN_FORCED_MIN_VALIDITY_SECONDS);
	}

	async #innerEnsureToken(redirectUri) {
		this.#ensureTokenCallbacks = [];
		try {
			if (!this.isAuthenticated()) {
				await this._keycloak.login({ redirectUri });
			} else {
				await this._keycloak.updateToken(KC._TOKEN_MIN_VALIDITY_SECONDS);
			}
			this.#ensureTokenCallbacks.forEach(cb => cb.resolve(this._keycloak.token));
			return this._keycloak.token;
		} catch (e) {
			this.#ensureTokenCallbacks.forEach(cb => cb.reject(e));
			throw e;
		} finally {
			this.#ensureTokenCallbacks = null
		}
	}

	ensureToken(redirectUri) {
		if (this.#ensureTokenCallbacks) {
			const promise = new Promise((resolve, reject) => {
				this.#ensureTokenCallbacks.push({resolve, reject});
			});
			return promise;
		}
		return this.#innerEnsureToken(redirectUri);
	}

	logout(redirectUri) {
		if (this._keycloak.onAuthLogout) this._keycloak.onAuthLogout();
		return this._keycloak.logout({ redirectUri });
	}

	isAuthenticated() {
		return this._keycloak.authenticated;
	}

	hasRole(role) {
		return this.isAuthenticated()
			? this._keycloak.hasRealmRole(role)
			: false;
	}

	kcRole() {
		const KCrole = [KC_ROLES.OPERATOR, KC_ROLES.ADMINISTRATOR].map((role) => {
			if (this.hasRole(role)) return role;
		})
		return KCrole.join('') || KC_ROLES.USER;
	}

	tokenParsed() {
		if (this.isAuthenticated()) {
			const token = this._keycloak.tokenParsed;
			return {
				username: token.preferred_username ?? '',
				firstName: token.given_name ?? '',
				lastName: token.family_name ?? '',
				middleName: token.middle_name ?? '',
				birthdate: token.birthdate ?? '',
				email: token.email ?? '',
				jobTitle: token.job_title ?? '',
				emailConfirmed: token.email_verified ?? false,
				phone: token.phone_number ?? '',
				phoneConfirmed: token.phone_number_verified ?? false,
				managePasswordDisabled: token.manage_password_disabled ?? false,
				enabledBusiness: token.enabled_business ?? true,
			}
		}
	}

	username() {
		return this.isAuthenticated()
			? this._keycloak.tokenParsed?.preferred_username
			: '';
	}

	userFirstName() {
		return this.isAuthenticated()
			? this._keycloak.tokenParsed?.given_name
			: '';
	}

	userNameShort() {
		const token = this._keycloak.tokenParsed;
		return this.isAuthenticated()
			? fio(
				token?.family_name,
				token?.given_name,
				token?.middle_name,
				true
			)
			: '';
	}

	userNameFull() {
		const token = this._keycloak.tokenParsed;
		return this.isAuthenticated()
			? fio(token?.family_name, token?.given_name, token?.middle_name)
			: '';
	}

	isRefreshTokenExpired() {
		if (!this._keycloak.refreshTokenParsed) return false;
		return this._keycloak.refreshTokenParsed.exp * 1000 < Date.now();
	}

	isTokenExpired() {
		if (!this._keycloak.tokenParsed) return false;
		return this._keycloak.tokenParsed.exp * 1000 < Date.now();
	}
}

export default KC;

export function install(Vue) {
	Vue.mixin({
		beforeCreate: function () {
			this.$kc = KC.instance();
		},
	});
}
