var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.className
  }, [_c('input', {
    ref: "input",
    attrs: {
      "id": ((_vm.group) + "#" + (_vm.value)),
      "type": "radio",
      "name": _vm.group,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "tabindex": "-1"
    },
    domProps: {
      "checked": _vm.isChecked,
      "value": _vm.value
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change', $event.target.value);
      }
    }
  }), _c('label', {
    attrs: {
      "tabindex": "0",
      "for": ((_vm.group) + "#" + (_vm.value))
    },
    on: {
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) { return null; }
        $event.preventDefault();
        return _vm.$refs.input.click();
      }
    }
  }, [_c('div', {
    staticClass: "radio"
  }), _vm._v(" " + _vm._s(_vm.title) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }