import {getLoanCalculate} from "@services/projects/projectComputedData";

export default {
	namespaced: true,

	actions: {
		async actionSetPaymentSchedule({ state, rootGetters }) {

			const project = rootGetters['project/getProject'];
			const paymentDelay = rootGetters['dictionary/paymentDelay'];

			state.loading = true;
			state.paymentSchedule = [];

			let delayDays = 0;
			if (project.paymentDelayCode) {
				const pd = paymentDelay.find(pd => pd.code === project.paymentDelayCode);
				delayDays = pd.days ?? 0;
			}
			const startDate = new Date(Date.now() + 86400000 * project.publishTerm);
			const paymentScheduleParams = {
				type: project.paymentSchemeCode,
				amount: project.maximumTarget,
				rate: project.interestRate,
				start: startDate.toJSON(),
				term: project.term,
				delay: delayDays,
			}

			if (paymentScheduleParams.delay >= paymentScheduleParams.term) {
				throw new Error('Отсрочка должна быть меньше срока займа');
			}

			state.refModal.show();

			return getLoanCalculate(paymentScheduleParams)
				.then(res => {
					state.paymentSchedule = res.data.items;
					state.loading = false;
				});

		},
		actionSetRefModal({ state }, refModal) {
			state.refModal = refModal;
		},
	},

	state: {
		paymentSchedule: [],
		refModal: null,
		loading: true,
	},

	getters: {
		getterLoading(state) {
			return state.loading;
		},
		getterPaymentSchedule(state) {
			return state.paymentSchedule;
		},
	},
};