const not = {
	inert: ':not([inert]):not([inert] *)',
	negTabIndex: ':not([tabindex^="-"])',
	disabled: ':not(:disabled)',
};

const focusableSelectors = [
	`a[href]${not.inert}${not.negTabIndex}`,
	`area[href]${not.inert}${not.negTabIndex}`,
	`input:not([type="hidden"]):not([type="radio"])${not.inert}${not.negTabIndex}${not.disabled}`,
	`input[type="radio"]${not.inert}${not.negTabIndex}${not.disabled}`,
	`select${not.inert}${not.negTabIndex}${not.disabled}`,
	`textarea${not.inert}${not.negTabIndex}${not.disabled}`,
	`button${not.inert}${not.negTabIndex}${not.disabled}`,
	`details${not.inert} > summary:first-of-type${not.negTabIndex}`,
	`iframe${not.inert}${not.negTabIndex}`,
	`audio[controls]${not.inert}${not.negTabIndex}`,
	`video[controls]${not.inert}${not.negTabIndex}`,
	`[contenteditable]${not.inert}${not.negTabIndex}`,
	`[tabindex]${not.inert}${not.negTabIndex}`,
];

/**
 * Function to move focus not next or prev focusable element
 */
function useFocusTab() {

	let containerElement = null;

	const getFocusableElements = () =>
		Array.from(
			containerElement?.querySelectorAll(focusableSelectors.join(',')) || [],
		);

	const getHeadingElement = () =>
		containerElement?.querySelector(
			'h1, h2, h3, h4, h5, h6',
		);

	function init(container) {
		containerElement = container;
		const focusableElements = getFocusableElements();
		const headingElement = getHeadingElement();
		// focus on heading or first element
		(headingElement || focusableElements[0])?.focus();
	}

	/**
	 *  @param {'next' | 'prev'} mode
	 */
	function move(mode) {
		const activeElement = document.activeElement;
		const focusableElements = getFocusableElements();

	  const activeIndex = focusableElements.indexOf(activeElement);
	  const count =  focusableElements.length;

	  let nextIndex = activeIndex;
	  for (let attempts = 0; attempts < count; attempts++) {
			if (mode === 'next') {
				nextIndex = (nextIndex + 1) % count;
			} else {
				nextIndex = (nextIndex >= 0)
					? (nextIndex - 1 + count) % count
					: count - 1;
			}

			const el = focusableElements[nextIndex];
			el.focus();

			if (document.activeElement === el) {
				return; // If focus is successful, exit the function
			}
	  }
	}

	return {
		init,
		move
	}
}

export default useFocusTab

