// @ts-check

import { editSettings, getCotactSettings } from '@/services/settings';

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,
	state: {
		settings: {},
		formData: {},
		loading: false,
	},

	getters: {
		getterSettings: (state) => state.settings,
		getterFormData: (state) => state.formData,
		getterLoading: (state) => state.loading,
		getterMessengers: (state) => [
			{
				icon: 'icons-vk',
				href: state.settings.vkLink,
				label: 'Вконтакте',
				class: 'contacts-page__button contacts-page__button--vk',
			},
			{
				icon: 'icons-telega',
				href: state.settings.telegramLink,
				label: 'Telegram',
				class: 'contacts-page__button contacts-page__button--telegram',
			},
		],
	},

	mutations: {
		setSettings(state, payload) {
			state.settings = payload;
		},
		setFormData(state, payload) {
			state.formData = payload;
		},
		setLoading(state, payload) {
			state.loading = payload;
		},
	},

	actions: {
		async fetchSettings({ commit }) {
			commit('setLoading', true);
			try {
				const settings = await getCotactSettings();
				commit('setSettings', settings);
				commit('setFormData', settings);
			} finally {
				commit('setLoading', false);
			}
		},

		async saveSettings({ commit, state, dispatch }) {
			commit('setLoading', true);

			try {
				await editSettings(state.formData);
			} finally {
				commit('setLoading', false);
				dispatch('fetchSettings');
			}
		},
	},
};
