var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Portal', [_c('div', {
    staticClass: "ui-base-modal__container",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.closeModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    ref: "modalContent",
    staticClass: "ui-base-modal",
    style: _vm.modalStyles
  }, [_c('UiSkeleton', {
    attrs: {
      "loading": _vm.skeletonLoading,
      "height": _vm.skeletonHeight,
      "message": _vm.skeletonMessage
    }
  }, [_vm._t("header", function () {
    return [_c('div', {
      staticClass: "ui-base-modal__header"
    }, [_c('h3', {
      staticClass: "ui-base-modal__title"
    }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
      staticClass: "close-icon ui-base-modal__close-icon",
      on: {
        "click": _vm.closeModal
      }
    })])];
  }), _vm._t("body"), _c('div', {
    staticClass: "ui-base-modal__footer"
  }, [_vm._t("footer", function () {
    return [_c('a', {
      staticClass: "button-outline default-link ui-base-modal__cancel",
      on: {
        "click": _vm.closeModal
      }
    }, [_vm._v("Отмена")]), _c('button', {
      staticClass: "default-btn ui-base-modal__submit",
      on: {
        "click": _vm.closeModal
      }
    }, [_vm._v("Отправить")])];
  }, {
    "closeModal": _vm.closeModal,
    "confirmModal": _vm.confirmModal
  })], 2)], 2)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }