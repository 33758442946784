
import {
	createProjectDiscussItem,
	getProjectDiscussItem,
	editProjectDiscussItem,
	deleteProjectDiscussItem
} from "@services/projectDiscuss";

export default {
	namespaced: true,

	state: {
		discussItem: {
			title: '',
			content: '',
		},
		projectId: null,
		hasItems: false,
		loader: true
	},
	actions: {
		async actionGetProjectDiscussItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return getProjectDiscussItem(data.projectId, data.discussId).then(r => {
				commit('mutationSetProjectDiscussItem', r.data);
			})
		},
		async actionCreateProjectDiscussItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return createProjectDiscussItem(data.projectId, data.discussData).then(r => {
				commit('mutationSetProjectDiscussItem', r.data);
			})
		},
		actionEditDiscussItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return editProjectDiscussItem(data.projectId, data).then(r => {
				commit('mutationSetProjectDiscussItem', r.data);
			})
		},
		actionSetProjectDiscussItem({ commit }, data) {
			commit('mutationSetProjectDiscussItem', data);
		},
		actionSetHasDiscusses({ commit }, bool) {
			commit('mutationHasDiscusses', bool);
		},
		actionDeleteProjectDiscItem({ commit }, data) {
			return deleteProjectDiscussItem(data.projectId, data.discussId).then(() => {
				commit('mutationSetProjectDiscussItem', {});
			})
		},
	},
	mutations: {
		mutationSetProjectDiscussItem(state, data) {
			state.discussItem = data;
			state.loader = false;
		},
		mutationSetLoader(state, bool) {
			state.loader = bool;
		},
		mutationHasDiscusses(state, bool) {
			state.hasItems = bool;
		},
	},
	getters: {
		projectDiscussItemGetter(state) {
			return state.discussItem;
		},
		projectDiscussItemOwner(state) {
			return state.discussItem.isOwner;
		},
		projectDiscussItemLoaderGetter(state) {
			return state.loader;
		},
		projectDiscussHasItems(state) {
			return state.hasItems;
		},
	},
};