<template>
	<div 
		v-if="showEntityConditionStatus"
		:class="{
			'entity-status': true,
			'entity-status--warn': isParticipantRejected
		}"
	>
		<h3 class="entity-status__title" 
			:class="{
				'entity-status__title--warn': isParticipantRejected || isEntityBlocked,
				'entity-status__title--moderation': isParticipantModeration
			}"
		>
			<UiSmartIcon 
				:name="conditionStatus.icon"
				:color="entityIconColor" 
				:size="20"
				class="entity-status__title-icon" 
			/>
			{{ conditionStatus.title }}
		</h3>

		<div v-if="isEntityRejected" class="entity-status__reject-block">
			<slot v-if="entity.rejectReason" v-bind="{ entity, rejectReason: convertedString(entity.rejectReason) }">
				<p class="entity-status__reject-reason" v-html="convertedString(entity.rejectReason)" />
			</slot>
			<p v-else class="entity-status__reject-reason" v-html="convertedString('- Причина не указана')"/>
			<div class="entity-status__buttons">
				<UiButton
					v-if="enableEditButton"
					label="Редактировать анкету"
					size="xs"
					class="entity-status__reject-btn"
					@click="submitToEdit"
				/>
				<UiButton
					label="Подать апелляцию"
					type="secondary"
					size="xs"
					class="entity-status__reject-btn"
					@click="submitAppeal"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { STATUS } from '@/configs/participants';
import { convertedString } from '@utils/common';

export default {
	name: 'UiParticipantStatus',
	props: {
		/**
		 * Информационный объект участника/проекта, который передается в компонент и односторонне отображает информацию о ее статусе
		 */
		entity: {
			type: Object,
			required: true
		},
		roleLabel: {
			type: String,
			default: ''
		},
		/**
		 * Показ кнопки редактирования при статусе STATUS.REJECTED
		 */
		enableEditButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		/**
		 * Показ кнопки отправки на модерацию в статусе STATUS.DRAFT
		 */
		enableModerateButton: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	data() {
		return {
			convertedString,
			entityConditionStatus: {
				[STATUS.DRAFT]: {
					title: 'Анкета ' + this.roleLabel + ' в состоянии черновика',
					icon: '',
				},
				[STATUS.REJECTED]: {
					title: 'Анкета ' + this.roleLabel + ' не прошла модерацию по причине:',
					icon: 'icons-attention',
				},
				[STATUS.PREPARED]: {
					title: 'Анкета ' + this.roleLabel + ' находится на модерации',
					icon: 'icons-clock'
				},
				[STATUS.BLOCKED]: {
					title: 'Анкета ' + this.roleLabel + ' заблокирована',
					icon: 'icons-off'
				},
			},
		}
	},

	computed: {
		isEntityBlocked() {
			return this.entity.status === STATUS.BLOCKED;
		},
		isEntityRejected() {
			return this.entity.status === STATUS.REJECTED;
		},
		entityIconColor() {
			return (this.isEntityRejected || this.isEntityBlocked)
				? '#DE2D31'
				: '#818586'
		},
		isParticipantDraft() {
			return this.entity.status === STATUS.DRAFT;
		},
		isParticipantRejected() {
			return this.entity.status === STATUS.REJECTED
				|| this.entity.status === STATUS.BLOCKED
		},
		isParticipantModeration() {
			return this.entity.status === STATUS.PREPARED
				|| this.entity.status === STATUS.ON_115FZ
		},
		showEntityConditionStatus() {
			return this.isParticipantDraft || this.isParticipantRejected || this.isParticipantModeration
		},
		conditionStatus() {
			return this.entityConditionStatus[this.entity.status]
		},
	},

	methods: {
		submitAppeal() {
			this.$emit('appealRequest')
		},
		submitToEdit() {
			this.$emit('toEdit')
		},
	}
}
</script>
