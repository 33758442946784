<template>
	<div class="ui-blur">
		<div 
			v-blur="blurConfig"
			:class="{'blur-element': true, 'blur-element--noselect': blurred}"
		>
			<slot/>
		</div>
		<div v-if="showAuthRequest" class="auth-request">
			<h2 class="auth-request__title">Чтобы увидеть содержимое проекта, необходимо зарегистрироваться!</h2>
			<UiButton 
				label="Войти"
				class="auth-request__btn-login"
				@click="logIn"
			/>
			<UiButton 
				label="Зарегистрироваться"
				type="secondary"
				class="auth-request__btn-register"
				@click="logIn"
			/>
		</div>
		<div v-if="showInvestorNotify" class="auth-request">
			<h2 class="auth-request__title">Чтобы увидеть содержимое проекта, необходимо добавить анкету инвестора!</h2>
			<UiButton
				label="Добавить анкету инвестора"
				class="auth-request__btn-login"
				:to="questionnairesPageRoute"
			/>
			<UiButton
				label="Назад"
				type="secondary"
				class="auth-request__btn-register"
				@click="goBack"
			/>
		</div>
	</div>
</template>

<script>
import {QUESTIONNAIRES_PAGE} from "@/router/routes/names";

export default {
	name: 'UiBlur',
	props: {
		blurred: {
			type: Boolean,
			default: false
		},
		authRequest: {
			type: Boolean,
			default: true
		},
		investorNotify: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		blurConfig() {
			return {
				isBlurred: this.blurred,
				opacity: 0.5,
				filter: 'blur(15px)',
				transition: 'all .3s linear'
			}
		},
		showAuthRequest() {
			return this.blurred && this.authRequest;
		},
		showInvestorNotify() {
			return !this.authRequest && this.blurred && this.investorNotify;
		},
		questionnairesPageRoute() {
			return {
				name: QUESTIONNAIRES_PAGE,
			}
		},
	},
	methods: {
		logIn() {
			this.$kc.ensureToken();
		},
		goBack() {
			this.$router.go(-1);
		},
	}
}
</script>
