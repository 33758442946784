<template>
	<div>
		<div class="content-pages">
			<slot/>
		</div>
		<div class="buttons-block" :class="{ 'buttons-block--right': buttonsRight }">
			<ul class="ui-slider__buttons">
				<li>
					<UiButton
						style="margin-top: 0px"
						label="Предыдущий шаг"
						prependIcon="prev-button"
						type="secondary"
						:disabled="!canPrevStep"
						@click="goPrev"
					/>
				</li>
				<li>
					<UiButton
						style="margin-top: 0px"
						label="Следующий шаг"
						appendIcon="show-more"
						type="secondary"
						:disabled="!canNextStep"
						@click="goNext"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>

export default {
	name: "UiSlider",

	provide: function () {
		return {
			registerPage: this.registerPage
		};
	},

	props: {
		pages: {
			type: Array,
			required: true,
		},
		buttonsRight: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			pageMap: {},
			currentIdx: 0,
		};
	},

	computed: {
		pageArray() {
			if (this.pages) {
				return this.pages
					.map(k => ({ name: k, page: this.pageMap[k] }))
					.filter(o => !!o.page);
			}
			return Object.keys(this.pageMap)
				.sort((k1, k2) => k1.localeCompare(k2))
				.map(k => ({ name: k, page: this.pageMap[k] }));
		},
		pageCount() {
			return this.pageArray.length;
		},
		canNextStep() {
			return this.currentIdx < this.pageCount - 1
		},
		canPrevStep() {
			return this.currentIdx > 0
		}
	},

	watch: {
		pageCount(value) {
			this.currentIdx = value > 0 ? 0 : -1;
		},
		pages() {
			this.setVisibility();
		},
	},

	mounted() {
		this.setVisibility();
	},

	methods: {
		registerPage(page, name) {
			this.$set(this.pageMap, name, page);
			this.setVisibility();
		},

		setVisibility() {
			if (this.pageCount > 0) {
				this.currentIdx = Math.min(this.currentIdx, this.pageCount - 1);
			} else {
				this.currentIdx = -1;
			}
			// видимость
			Object.values(this.pageMap).forEach(page => {
				page.setVisible(false);
			});
			if (this.currentIdx >= 0) {
				this.pageArray[this.currentIdx].page.setVisible(true);
			}
		},

		goNext() {
			console.log(this.canNextStep);
			if (this.canNextStep) {
				if (!this.checkCanGo(this.currentIdx + 1)) return;
				this.currentIdx++;
				this.setVisibility();
			}
		},

		goPrev() {
			if (this.canPrevStep) {
				if (!this.checkCanGo(this.currentIdx - 1)) return;
				this.currentIdx--;
				this.setVisibility();
			}
		},

		checkCanGo(toIdx) {
			let eventObj = {
				fromIdx: this.currentIdx,
				fromName: this.pageArray[this.currentIdx].name,
				toIdx,
				toName: this.pageArray[toIdx].name,
				accepted: true
			};
			this.$emit("step", eventObj);
			return eventObj.accepted;
		}
	}
};
</script>
