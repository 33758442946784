<template>
	<div 
		:class="{'ui-content__frame': true, ...frameHoveredClass}"
		@mouseover="changeFrameHover(true)" 
		@mouseleave="changeFrameHover(false)"
		@click="emitClick"
	>
		<div class="ui-content__header">
			<div v-if="!isComment" class="ui-content__header-content">
				<span :class="{'ui-content__date': true, ...frameHoveredClass}">{{ dataTime }}</span>
				<div v-if="!onlyInfo" :class="{'ui-content__comments': true, ...frameHoveredClass}">
					<UiSmartIcon
						size="18"
						name="icons-message-square"
						:color="contentColor"
						class="ui-content__comments-icon"
					/>
					<span :class="{'ui-content__comments-count': true, ...frameHoveredClass}">{{ frameContent.countComments }}</span>
				</div>
			</div>
			<div v-else class="ui-content__header-comment">
				<h3 class="ui-content__comment-title">
					{{ frameContent.fio }}
				</h3>
				<div class="ui-content__subinfo-date">
					<span class="date">{{ dataTime }}, </span>
					<span class="time">{{ hourTime }}</span>
				</div>
				<UiSmartIcon 
					v-if="canDeleteComment"
					name="icons-trash" 
					color="var(--greyscale-5)" 
					class="ui-content__subinfo-del"
					@click="deleteComment"
				/>
			</div>
		</div>
		<h4 v-if="!isComment" :class="titleClasses">{{ frameContent.title }}</h4>
		<p class="ui-content__content">{{ content }}</p>
		<a 
			v-if="isComment && commentsEnale" 
			class="ui-content__answer-btn" 
			@click="emitAnwer"
		>
			Ответить
		</a>
	</div>
</template>

<script>
import { formatDate } from '@/utils/format';
import { stringSlicer } from '@/utils/common';
import modeTypeMixin from '@/mixins/modeTypeMixin';
import { mapActions } from 'vuex';
import { PROJECT_NEWS_VIEW, PROJECT_DISCUSS_VIEW } from '@/router/routes/names';
import { ERROR, SUCCESS } from '@/configs/ui';

export default {
	name: 'UiContentFrame',
	mixins: [modeTypeMixin],
	props: {
		frameContent: {
			type: Object,
			default: () => {}
		},
		isComment: {
			type: Boolean,
			default: false
		},
		onlyInfo: {
			type: Boolean,
			default: false
		},
		isOwner: {
			type: Boolean,
			default: false
		},
		/** Признак возможности оставлять комментарии */
		commentsEnale: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			frameHover: false
		}
	},
	computed: {
		frameHoveredClass() {
			return {
				'hovered': this.frameHover
			}
		},
		canDeleteComment() {
			return (this.isOperator || this.isOwner) || this.frameContent.isOwner;
		},
		cuttedFrameContent() {
			return stringSlicer(this.frameContent.content, 300);
		},
		content() {
			return this.isComment ? this.frameContent.content : this.cuttedFrameContent;
		},
		dataTime() {
			return formatDate(this.frameContent.created, "DD.MM.YYYY");
		},
		hourTime() {
			return formatDate(this.frameContent.created, "HH:NN");
		},
		contentColor() {
			return this.frameHover ? 'var(--body-color)' : 'var(--greyscale-5)';
		},
		isHeaderShow() {
			return this.frameContent.date || this.frameContent.countComments;
		},
		titleClasses() {
			return { 'ui-content__title': true, 'one-item': !this.cuttedFrameContent };
		}
	},
	methods: {
		...mapActions({
			deleteNewsCommentItem: 'comments/actionDeleteNewsCommentItem',
			deleteDiscussCommentItem: 'comments/actionDeleteDiscussCommentItem',
		}),
		formatDate,
		async deleteComment() {
			if (this.$route.name === PROJECT_NEWS_VIEW) {
				const delData = {
					projectId: this.frameContent.projectId,
					newsId: this.$route.params.frameId,
					commentId: this.frameContent.commentId,
				}
				await this.deleteNewsCommentItem(delData).then(() => {
					this.$emit('delete');
					this.$notify({ type: SUCCESS, title: 'Комментарий удален' });
				}).catch((err) => {
					this.$notify({ 
						type: ERROR, 
						title: `При удалении комментария произошла ошибка`,
						text: err.response?.data.message,
					});
				})
			} else if (this.$route.name === PROJECT_DISCUSS_VIEW) {
				const delData = {
					projectId: this.frameContent.projectId,
					discussionId: this.$route.params.frameId,
					commentId: this.frameContent.commentId,
				}
				await this.deleteDiscussCommentItem(delData).then(() => {
					this.$emit('delete');
					this.$notify({ type: SUCCESS, title: 'Комментарий удален' });
				}).catch((err) => {
					this.$notify({ 
						type: ERROR, 
						title: `При удалении комментария произошла ошибка`,
						text: err.response?.data.message,
					});
				})
			}
		},
		emitClick() {
			return !this.isComment ? this.$emit('click') : null;
		},
		emitAnwer() {
			return this.$emit('answer');
		},
		changeFrameHover(bool) {
			if (!this.isComment) this.frameHover = bool;
		}
	}
}
</script>
