
import {
	createProjectNewsItem,
	getProjectNewsItem,
	deleteProjectNewsItem
} from "@services/projectNews";
import { getNewsDiscussionsUnreadNewCount } from "@/services/comments";
import { getNewNotificationsInfo } from '@/services/user';

export default {
	namespaced: true,

	state: {
		newsItem: {
			title: '',
			content: '',
			enableComments: true
		},
		projectId: null,
		hasItems: false,
		loader: true,
		unreadNewCount: null,
		newNotificationsInfo: null
	},
	actions: {
		async actionGetProjectNewsItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return getProjectNewsItem(data.projectId, data.newsId).then(r => {
				commit('mutationSetProjectNewsItem', r.data);
			})
		},
		async actionCreateProjectNewsItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return createProjectNewsItem(data.projectId, data.newsData).then(r => {
				commit('mutationSetProjectNewsItem', r.data);
			})
		},
		actionSetProjectNewsItem({ commit }, data) {
			commit('mutationSetProjectNewsItem', data);
		},
		actionDeleteProjectNewsItem({ commit }, data) {
			return deleteProjectNewsItem(data.projectId, data.newsId).then(() => {
				commit('mutationSetProjectNewsItem', {});
			})
		},
		actionSetHasNews({ commit }, bool) {
			commit('mutationHasNews', bool);
		},
		actionGetNewNotificationsInfo({ commit }) {
			return getNewNotificationsInfo().then(r => {
				commit('mutationSetNewNotificationsInfo', r.data);
			})
		},
		async actionReloadNewsDiscussionsUnreadsFlag({ commit }) {
			return getNewsDiscussionsUnreadNewCount().then(r => {
				commit('mutationSetNewsDiscussionsUnreadNewCount', r.data);
			})
		}
	},
	mutations: {
		mutationSetProjectNewsItem(state, data) {
			state.newsItem = data;
			state.loader = false;
		},
		mutationSetLoader(state, bool) {
			state.loader = bool;
		},
		mutationHasNews(state, bool) {
			state.hasItems = bool;
		},
		mutationSetNewsDiscussionsUnreadNewCount(state, data) {
			state.unreadNewCount = data.count;
		},
		mutationSetNewNotificationsInfo(state, data) {
			state.newNotificationsInfo = data;
		},
	},
	getters: {
		projectNewsItemGetter(state) {
			return state.newsItem;
		},
		projectNewsItemOwner(state) {
			return state.newsItem.isOwner;
		},
		projectNewsItemLoaderGetter(state) {
			return state.loader;
		},
		projectNewsHasItems(state) {
			return state.hasItems;
		},
		getteruNewsDiscussionsUnreadNewCount(state) {
			return state.unreadNewCount;
		},
		getterNewNotificationsInfo(state) {
			return state.newNotificationsInfo;
		},
	},
};