var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pagination"
  }, [_c('div', {
    staticClass: "pagination__container"
  }, [_c('a', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentPage > 1,
      expression: "currentPage > 1"
    }],
    staticClass: "pagination-button mr-16",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.prevPage();
      }
    }
  }, [_c('span', {
    staticClass: "prev-icon"
  }), _vm._v(" Предыдущая ")]), _vm.lastPage < 4 ? _c('ul', {
    staticClass: "numbers-list"
  }, [_vm.lastPage - 2 > 0 ? _c('li', {
    class: _vm.currentPage === _vm.lastPage - 2 ? 'active' : '',
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.lastPage - 2);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.lastPage - 2))])]) : _vm._e(), _vm.lastPage - 1 > 0 ? _c('li', {
    class: _vm.currentPage === _vm.lastPage - 1 ? 'active' : '',
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.lastPage - 1);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.lastPage - 1))])]) : _vm._e(), _c('li', {
    class: _vm.currentPage === _vm.lastPage ? 'active' : '',
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.lastPage);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.lastPage))])])]) : _c('ul', {
    staticClass: "numbers-list"
  }, [_vm.currentPage > 1 ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.firstPage);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.firstPage))])]) : _vm._e(), _vm.currentPage > 1 ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("...")])]) : _vm._e(), _vm.lastPage > 2 && _vm.currentPage >= _vm.lastPage ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.currentPage - 2);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.currentPage - 2))])]) : _vm._e(), _vm.lastPage > 2 && _vm.currentPage + 1 >= _vm.lastPage ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.currentPage - 1);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.currentPage - 1))])]) : _vm._e(), _c('li', {
    staticClass: "active",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.currentPage);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.currentPage))])]), _vm.currentPage < _vm.lastPage ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.currentPage + 1);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.currentPage + 1))])]) : _vm._e(), _vm.currentPage + 1 < _vm.lastPage ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.currentPage + 2);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.currentPage + 2))])]) : _vm._e(), _vm.currentPage + 3 < _vm.lastPage ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("...")])]) : _vm._e(), _vm.currentPage + 2 < _vm.lastPage ? _c('li', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setPageNumber(_vm.lastPage);
      }
    }
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_vm.lastPage))])]) : _vm._e()]), _c('a', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentPage < this.lastPage,
      expression: "currentPage < this.lastPage"
    }],
    staticClass: "pagination-button ml-16",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.nextPage();
      }
    }
  }, [_vm._v(" Следующая "), _c('span', {
    staticClass: "next-icon"
  })])]), _vm._t("total", function () {
    return [_vm.title ? _c('span', {
      staticClass: "total"
    }, [_vm._v(_vm._s(_vm.total) + " " + _vm._s(_vm.title))]) : _c('div')];
  }, null, {
    total: _vm.total,
    title: _vm.title
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }