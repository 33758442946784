import CacheBuilder from './CacheBuilder';
import { randomKey } from '../common';

export const DEFAULT_CACHE_LIFETIME = 300000; // 5 мин
export const DEFAULT_LOADER_TIMEOUT = 30000; // 30 сек

export default CacheBuilder;

export function cacheOnVar(hostObj, code) {
	return CacheBuilder.variableCache(hostObj, code);
}

export function cacheOnVuex(code) {
	return CacheBuilder.vuexCache(code);
}

let cacheObj = {};

export function cachedLoad(loader) {
	const code = randomKey();
	const bldr = CacheBuilder.variableCache(cacheObj, code);
	bldr.loader(loader);
	return bldr.build();
}

export function cachedRest(loader) {
	const code = randomKey();
	const bldr = CacheBuilder.variableCache(cacheObj, code);
	bldr.loader(loader);
	bldr.restMapper();
	return bldr.build();
}
