<template>
	<UiSkeleton :loading="loading">
		<div v-if="history" class="pb-16">
			<h4 class="h4 mb-8">История изменений</h4>
			<p v-for="entry in history" :key="entry.created" class="mb-4 fz-16">
				<time datetime="date.created" class="greyscale-5-text">
					{{ formatDate(entry.created, 'DD.MM.YYYY HH:NN') }}
				</time>
				{{ entry.text }}
			</p>
		</div>
	</UiSkeleton>
</template>

<script>
import { ERROR } from '@/configs/ui';
import { getApproveHistory } from '@/services/participant';
import { APPROVE_HISTORY_EVENT_NAMES } from '@/configs/participants';
import { formatDate } from '@/utils/format';

export default {
	name: 'UiParticipantStatusHistory',
	props: {
		entityId: {
			type: Number,
			required: true,
		},
	},

	data() {
		return {
			history: null,

			APPROVE_HISTORY_EVENT_NAMES,
		};
	},

	watch: {
		entityId: {
			handler() {
				this.fetchApproveHistory();
			},
			immediate: true,
		},
	},

	methods: {
		formatDate,
		async fetchApproveHistory() {
			try {
				this.loading = true;
				this.history = await getApproveHistory(this.entityId);
			} catch (e) {
				this.$notify({
					type: ERROR,
					title: 'Не удалось получить историю статусов',
					text: e.response?.data.message,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
