const DB_NAME = 'keycloakTokens'
const TOKEN_STORE = 'keycloakTokens'
const TOKEN_KEY = 'userToken'

export async function saveTokenToIndexedDB(token) {
	return new Promise(async (resolve, __) => {
		const db = await openIndexedDB();
		const tx = db.transaction(TOKEN_STORE, 'readwrite');
		const store = tx.objectStore(TOKEN_STORE);
		store.put(token, TOKEN_KEY);
		tx.oncomplete = (__) => resolve(token);
		tx.commit();
	});
}

async function openIndexedDB() {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(DB_NAME, 2);
		request.onerror = (event) => reject(event.target.error);
		request.onsuccess = (event) => resolve(event.target.result);
		request.onupgradeneeded = (event) => {
			const db = event.target.result;
			db.createObjectStore(TOKEN_STORE);
		}
	});
}