<template>
	<form>
		<label v-if="label" class="select-label">{{ label }}</label>
		<select
			ref="select" 
			class="ui-select w-100"
			:class="{'error': error, 'readonly': readonly}"
			:name="name"
			:disabled="disabled"
			@change="onChange"
		>
			<!-- @slot Дефольный слот, используется для передачи options -->
			<slot/>
		</select>

		<span 
			v-if="caption || error"
			class="select-caption"
			:class="error ? 'select-caption__error' : ''"
		>
			{{ error ? error : caption }}
		</span>
	</form>
</template>

<script>
export default {
	name: 'UiSelect',
	props: {

		/**
         * Заголовок. (отображается над селектом)
         *
         * @values "Любая строка"
         */
		label: {
			type: String,
			default: ''
		},

		/**
         * Подсказка. (отображается под селектом)
         *
         * @values "Любая строка"
         */
		caption: {
			type: String,
			default: ''
		},

		/**
         * Сообщение об ошибке. (отображается под селектом, заменяет caption)
         *
         * @values "Любая строка"
         */
		error: {
			type: String,
			default: ''
		},

		/**
         * Флаг, переводящий селект в неактивное состяние (делает селект некликабельным).
         *
         * @values true | false
         */
		disabled: {
			type: Boolean,
			default: false
		},

		readonly: {
			type: Boolean,
			default: false
		},

		/**
         * Устанавливает аттрибут name для селекта
         *
         * @values "select_name"
         */
		name: {
			type: String,
			required: false
		},

		/**
         * @deprecated вероятно уже никогда не будет использоваться
         * Размер - размер по фигме
         *
         * @values "l" | "m" | "s" | "xs"
         */
		size: {
			type: String,
			default: 'l'
		},
	},
	mounted(){
		if (this.$refs.select.value) {
			this.$nextTick().then(() => {
				/**
                 * Срабатывает при изменении значения
                 *
                 * @property {T} changedValue
                 */
				this.$emit('changeValue', this.$refs.select.value);
			})
		}
	},
	methods: {
		onChange(e) {
			/**
             * Срабатывает при изменении значения
             *
             * @property {T} changedValue
             */
			this.$emit('changeValue', e.target.value)
		}
	}
}
</script>
