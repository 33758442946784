import Requests from '@utils/Requests';
import { getApiRequestBuilder } from '@utils/RequestBuilder';
import { getFileBySignRequest } from '@services/files';
import { createSignature } from '@/services/crypto';
import api from '@configs/api';

export function getSignRequestDocument(requestId) {
	return getApiRequestBuilder(
		`sign/request/${requestId}/document`,
	).public.send();
}

export async function signAndSendRequestBatch(data) {
	if (data.document.documentId === undefined) {
		throw new Error('Не определено свойство documentId');
	}
	if (data.document.content.fileId === undefined) {
		throw new Error('Не определено свойство fileId');
	}
	if (data.thumbprint === undefined) {
		throw new Error('Не определено свойство thumbprint');
	}

	const file = await getFileBySignRequest(data.document.content.fileId, data.requestId);

	const signature = await createSignature(data.thumbprint, file);

	return signRequestBatch(data.requestId, data.document.documentId, signature);
}

export async function signRequestBatch(requestId, documentId, signature) {
	const END_POINT = `sign/request/${requestId}/batch`;

	const data = {
		signatureType: 'QUALIFIED',
		signatures: [{ documentId, signature }],
	};

	return Requests.postRequest(`/${api.url}${END_POINT}`, data, {});
}
