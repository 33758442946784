export const DRAFT_ENTITY_PATCH_STATUS = 'DRAFT';
export const MODERATION_ENTITY_PATCH_STATUS = 'MODERATION';
export const REJECT_ENTITY_PATCH_STATUS = 'REJECT';
export const APPLIED_ENTITY_PATCH_STATUS = 'APPLIED';

export const ENTITY_PATCH_STATUS_NAMES = {
	[DRAFT_ENTITY_PATCH_STATUS]: 'Черновик',
	[MODERATION_ENTITY_PATCH_STATUS]: 'На модерации',
	[APPLIED_ENTITY_PATCH_STATUS]: 'Применен',
	[REJECT_ENTITY_PATCH_STATUS]: 'Отклонен',
};
