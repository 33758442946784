var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isOperator ? _c('div', {
    staticClass: "ui-tip"
  }, [_c('popper', {
    attrs: {
      "trigger": "hover",
      "options": _vm.options
    }
  }, [_c('div', {
    staticClass: "popper",
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }), _c('div', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('UiSmartIcon', {
    ref: "tipIcon",
    staticClass: "ui-tip__icon",
    attrs: {
      "name": "question",
      "color": "var(--greyscale-5)"
    }
  })], 1)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }