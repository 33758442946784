import {
	getRenderedTemplateParticipant,
	addParticipant,
	deleteParticipantAccountsDraft,
	getParticipantData,
	saveDraftParticipant,
	editDraftParticipant,
	moveToDraft,
	moveToModeration,
	postParticipantAppeal,
	saveParticipantInfo,
	moderation,
} from "@services/participant";
import {createPromise, fromArrayToObject, fromObjectToArray} from "@utils/common";
import {OPF, OPF_CODES, ROLE_CODES} from '@configs/participants';
import { ENTITY_TYPES } from '@configs/investProject'
import { STATUS } from "@configs/participants";
import { DOCUMENT_TYPE_CODE } from '@configs/documents'
import {isDocumentSigned} from "@utils/documents";
import omit from 'lodash.omit';
import { fio } from '@/utils/common';
import Vue from 'vue';

const TEMPLATE_CODES = {
	INVESTOR: DOCUMENT_TYPE_CODE.INVEST_PROMOTION,
	INDIVIDUAL_INVESTOR: DOCUMENT_TYPE_CODE.INDIVIDUAL_PROMOTION,
	FOUNDER: DOCUMENT_TYPE_CODE.INVEST_ATTRACTING,
};

export default {
	actions: {
		async actionQuestionnaireProfileById(ctx, id) {
			ctx.commit('mutationQuestionnaireProfileLoader', true);
			ctx.commit('mutationEditedQuestionnaireProfile', false);
			return getParticipantData(id).then((res) => {
				ctx.commit('mutationQuestionnaireProfile', res.data);
			});
		},
		async actionFetchModerationProfile(ctx, id) {
			ctx.commit('mutationQuestionnaireProfileLoader', true);
			ctx.commit('mutationEditedQuestionnaireProfile', false);
			return moderation.getParticipantProfile(id).then((res) => {
				ctx.commit('mutationQuestionnaireProfile', res.data);
				return res;
			});
		},
		async actionFetchRating(ctx, id) {
			ctx.commit('mutationQuestionnaireProfileLoader', true);
			return moderation.getParticipantRating(id).then((res) => {
				ctx.commit('mutationQuestionnaireRating', res.data.rating);
				return res;
			});
		},
		async actionFetchForm115History(ctx, id) {
			ctx.commit('mutationQuestionnaireProfileLoader', true);
			return moderation.getForm115History(id).then((res) => {
				ctx.commit('mutationQuestionnaireForm115History', res);
				return res;
			});
		},
		// сохранить анкету по данным из ЕГРП
		async actionQuestionnaireProfilePut(ctx, data) {
			ctx.commit('mutationQuestionnaireProfileLoader', true);
			ctx.commit('mutationEditedQuestionnaireProfile', false);
			ctx.commit('mutationQuestionnaireProfile', data);
		},
		// сохранить анкету по данным из формы
		async actionQuestionnaireProfilePutFromForm(ctx, data) {
			ctx.commit('mutationQuestionnaireProfilePutFromForm', data);
		},
		async actionQuestionnaireProfileClear(ctx) {
			ctx.commit('mutationQuestionnaireProfilePutFromForm', {});
		},
		async actionQuestionnaireProfileDelete({ commit, state }) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);
			return deleteParticipantAccountsDraft(state.questionnaireProfile.participantId).then(() => {
				commit('mutationQuestionnaireProfile', {});
			})
		},
		async actionQuestionnaireProfileAdd({ commit, state }) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);
			return addParticipant(state.questionnaireProfile).then((res) => {
				commit('mutationQuestionnaireProfile', res.data);
			})
		},
		async actionQuestionnaireProfileSaveAsDraft({ commit, state }) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);
			return saveDraftParticipant(state.questionnaireProfile).then((res) => {
				commit('mutationQuestionnaireProfile', res.data);
			})
		},
		async actionQuestionnaireProfileUpdatesDraft({ commit, state }) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);
			return editDraftParticipant(state.questionnaireProfile).then((res) => {
				commit('mutationQuestionnaireProfile', res.data);
			})
		},
		async actionQuestionnaireProfileUpdate({ commit, state }) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);

			if (state.questionnaireProfile.documents) {
				state.signDocQuestionnaireProfile = null;
				state.questionnaireProfile.documents = state.questionnaireProfile.documents.filter(doc => !doc.signs || doc.signs.length === 0);
			}

			return saveParticipantInfo(omit(state.questionnaireProfile, 'documentsObj')).then(() => {
				return getParticipantData(state.questionnaireProfile.participantId).then((res) => {
					commit('mutationQuestionnaireProfile', res.data);
				});
			})
		},
		async actionQuestionnaireProfileBackToDraft({ commit, state }) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);
			return moveToDraft(state.questionnaireProfile.participantId).then(() => {
				state.questionnaireProfile.status = STATUS.DRAFT
				commit('mutationQuestionnaireProfile', state.questionnaireProfile);
			})
		},
		async actionQuestionnaireProfileMoveToModeration({ commit, state }) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);

			return moveToModeration(state.questionnaireProfile.participantId).then(() => {
				state.questionnaireProfile.status = STATUS.PREPARED
				return getParticipantData(state.questionnaireProfile.participantId).then((res) => {
					commit('mutationQuestionnaireProfile', res.data);
				});
			})
		},
		async actionQuestionnaireProfileSendAppeal({ commit, state }, text) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationEditedQuestionnaireProfile', false);
			return postParticipantAppeal(state.questionnaireProfile.participantId, text).then(() => {
				state.questionnaireProfile.status = STATUS.PREPARED;
				state.questionnaireProfile.rejectReason = null;
				commit('mutationQuestionnaireProfile', state.questionnaireProfile);
			})
		},
		async actionQuestionnaireCreateDocForSing({ commit, state }) {
			return getRenderedTemplateParticipant(state.questionnaireProfile.participantId).then((r) => {
				if (!state.questionnaireProfile.documents) {
					state.questionnaireProfile.documents = [];
				}
				state.questionnaireProfile.documents.push(r.data);
				commit('mutationQuestionnaireProfile', state.questionnaireProfile);
				commit('mutationEditedQuestionnaireProfile', false);
			});
		},
		actionQuestionnaireProfileSetEdited(ctx, bool) {
			ctx.commit('mutationEditedQuestionnaireProfile', bool);
		},
		actionQuestionnaireProfileSetLoader(ctx, bool) {
			ctx.commit('mutationQuestionnaireProfileLoader', bool);
		},
		actionQuestionnaireProfileLoaderBlockHide(ctx, bool) {
			ctx.commit('mutationQuestionnaireProfileLoaderBlockHide', bool);
		},
		actionQuestionnaireSetRefForm(ctx, form) {
			ctx.commit('mutationQuestionnaireSetRefForm', form);
		},
		actionSignDocQuestionnaireProfile({ commit }, docs) {
			commit('mutationQuestionnaireProfileLoader', true);
			commit('mutationSignDocQuestionnaireProfile', docs);
			setTimeout(() => {
				commit('mutationQuestionnaireProfileLoader', false);
			}, 500)
		},
		actionQuestionnaireSetFilesUploading({ commit }, bool) {
			commit('mutationQuestionnaireFilesUploading', bool);
		},
	},
	mutations: {
		mutationSignDocQuestionnaireProfile(state, docs) {
			state.signDocQuestionnaireProfile = docs;

			if (!state.questionnaireProfile.documents) {
				Vue.set(state.questionnaireProfile, 'documents', []);
			}

			const codes = state.signDocQuestionnaireProfile.map(doc => doc.typeCode)

			Vue.set(state.questionnaireProfile, 'documents', [
				...state.signDocQuestionnaireProfile,
				...state.questionnaireProfile.documents.filter(document => !codes.includes(document.typeCode)),
			]);

			Vue.set(state.questionnaireProfile, 'documentsObj', fromArrayToObject(state.questionnaireProfile.documents, 'typeCode'));
		},
		mutationEditedQuestionnaireProfile(state, bool) {
			state.isEditedQuestionnaireProfile = bool;
		},
		mutationQuestionnaireProfileLoader(state, bool) {
			if (!bool) {
				state.loaderResolveQuestionnaireProfile();
			} else {
				if (!state.loaderQuestionnaireProfile) {
					const { promise, resolve } = createPromise();
					state.loaderPromiseQuestionnaireProfile = promise;
					state.loaderResolveQuestionnaireProfile = resolve;
				}
			}
			state.loaderQuestionnaireProfile = bool;
		},
		mutationQuestionnaireProfileLoaderBlockHide(state, bool) {
			state.loaderBlockHideQuestionnaireProfile = bool;
		},
		mutationQuestionnaireSetRefForm(state, form) {
			state.refFormQuestionnaireProfile = form;
		},
		mutationQuestionnaireRating(state, rating) {
			state.questionnaireRating = rating;
		},
		mutationQuestionnaireForm115History(state, history) {
			state.questionnaireForm115History = history;
		},
		mutationQuestionnaireProfilePutFromForm(state, questionnaireProfile) {
			state.questionnaireProfile = questionnaireProfile;

			if (questionnaireProfile.documentsObj) {
				Vue.set(state.questionnaireProfile, 'documents', fromObjectToArray(questionnaireProfile.documentsObj));
			}
		},
		mutationQuestionnaireProfile(state, questionnaireProfile) {
			let documentsObj;
			if (questionnaireProfile.documents) {
				documentsObj = {
					...fromArrayToObject(questionnaireProfile.documents, 'typeCode'),
					OTHER_DOCS: questionnaireProfile.documents.filter(d => d.typeCode === DOCUMENT_TYPE_CODE.OTHER_DOCS),
					BANK_OPERATIONS: questionnaireProfile.documents.filter(d => d.typeCode === DOCUMENT_TYPE_CODE.BANK_OPERATIONS),
					QUAL_INVESTOR: questionnaireProfile.documents.filter(d => d.typeCode === DOCUMENT_TYPE_CODE.QUAL_INVESTOR),
				}

				const code = questionnaireProfile.role === ROLE_CODES.INVESTOR && questionnaireProfile.type === ENTITY_TYPES.INDIVIDUAL
					? 'INDIVIDUAL_INVESTOR' : questionnaireProfile.role;

				const docForSign = documentsObj[TEMPLATE_CODES[code]];
				if (docForSign) {
					state.signDocQuestionnaireProfile = Array.isArray(docForSign)
						? [...docForSign]
						: [docForSign]
				} else {
					state.signDocQuestionnaireProfile = null;
				}
			}

			state.questionnaireProfile = { ...questionnaireProfile, documentsObj };

			if (!state.loaderBlockHideQuestionnaireProfile) {
				setTimeout(() => {
					state.loaderQuestionnaireProfile = false;
					state.loaderResolveQuestionnaireProfile();
				}, 500)
			}
		},
		mutationQuestionnaireFilesUploading(state, bool) {
			state.questionnaireFilesUploading = bool;
		},
	},
	state: {
		loaderResolveQuestionnaireProfile: null,
		loaderPromiseQuestionnaireProfile: null,
		loaderQuestionnaireProfile: false,
		loaderBlockHideQuestionnaireProfile: false,
		/**
		 * анкета Entity
		 * {}
		 */
		questionnaireProfile: {},
		/**
		 * флаг обновления файлов анкеты
		 */
		questionnaireFilesUploading: true,
		/**
		 * флаг обновления файлов анкеты
		 */
		isEditedQuestionnaireProfile: false,
		signDocQuestionnaireProfile: null,
		refFormQuestionnaireProfile: null,
		/**
		 * рейтинг ЛПИ в анкете
		 */
		questionnaireRating: null,
		questionnaireForm115History: null,
	},
	getters: {
		getterQuestionnaireProfileLoader(state) {
			return state.loaderQuestionnaireProfile;
		},
		getterQuestionnaireProfileLoaderPromise(state) {
			return state.loaderPromiseQuestionnaireProfile;
		},
		getterQuestionnaireProfile(state) {
			return state.questionnaireProfile;
		},
		getterQuestionnaireRating(state) {
			return state.questionnaireRating;
		},
		getterQuestionnaireForm115History(state) {
			return state.questionnaireForm115History;
		},
		getterQuestionnaireForm115Last(state, getters) {
			return getters.getterQuestionnaireForm115History.at(-1);
		},
		getterQuestionnaireForm115First(state, getters) {
			return getters.getterQuestionnaireForm115History?.[0];
		},
		getterQuestionnaireProfileStatus(state) {
			return state.questionnaireProfile.status;
		},
		getterHasQuestionnaireProfile(state) {
			return Object.keys(state.questionnaireProfile).length > 0;
		},
		getterQuestionnaireProfileIsZAO(state) {
			return state.questionnaireProfile.organization.opf === OPF_CODES.ZAO;
		},
		getterQuestionnaireProfileIsInFounderRole(state) {
			return state.questionnaireProfile.role === ROLE_CODES.FOUNDER;
		},
		getterQuestionnaireProfileIsInStatusDraft(state) {
			return state.questionnaireProfile.status === STATUS.DRAFT;
		},
		getterQuestionnaireProfileIsInStatusPrepared(state) {
			return state.questionnaireProfile.status === STATUS.PREPARED;
		},
		getterQuestionnaireProfileIsInStatusOn115fz(state) {
			return state.questionnaireProfile.status === STATUS.ON_115FZ;
		},
		getterQuestionnaireProfileIsInStatusActive(state) {
			return state.questionnaireProfile.status === STATUS.ACTIVE;
		},
		getterQuestionnaireProfileIsEdited(state) {
			return state.isEditedQuestionnaireProfile;
		},
		getterQuestionnaireProfileIsNew(state) {
			return state.questionnaireProfile.participantId === null;
		},
		getterQuestionnaireProfileId(state) {
			return state.questionnaireProfile.participantId;
		},
		getterQuestionnaireSignDoc(state) {
			return state.signDocQuestionnaireProfile;
		},
		getterQuestionnaireHasSignDocs(state) {
			return state.signDocQuestionnaireProfile !== null;
		},
		getterQuestionnaireSignDocsFinish(state) {
			return state.signDocQuestionnaireProfile && state.signDocQuestionnaireProfile.some(isDocumentSigned);
		},
		getterQuestionnaireProfileFio(state) {
			return fio(state.questionnaireProfile.lastName, state.questionnaireProfile.firstName, state.questionnaireProfile.middleName);
		},
		getterQuestionnaireRefForm(state) {
			return  state.refFormQuestionnaireProfile;
		},
		getterQuestionnaireIsSelfQualified(state) {
			return state.questionnaireProfile.qualification && state.questionnaireProfile.qualification.selfQualified;
		},
		getterQuestionnairePreviewName(state) {
			if (state.questionnaireProfile?.organization?.shortName) {
				return state.questionnaireProfile?.organization?.shortName;
			}

			if (state.questionnaireProfile.type === ENTITY_TYPES.LEGAL_ENTITY) {
				return `${OPF[state.questionnaireProfile.organizationOpf] ?? ''} ${state.questionnaireProfile.organizationShortName ?? ''}`;
			}

			if (state.questionnaireProfile.type === ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR) {
				let previewName = state.questionnaireProfile.lastName;
				previewName += ` ${state.questionnaireProfile.firstName} `;
				previewName += state.questionnaireProfile.middleName ? `${state.questionnaireProfile.middleName}` : '';
				return `ИП ${previewName}`;
			}

			let previewName = state.questionnaireProfile.lastName;
			previewName += ` ${state.questionnaireProfile.firstName} `;
			previewName += state.questionnaireProfile.middleName ? `${state.questionnaireProfile.middleName}` : '';
			return `${previewName}`;
		},
		getterQuestionnaireMode(state) {
			if (state.questionnaireProfile.role === ROLE_CODES.INVESTOR) {
				if (state.questionnaireProfile.type === ENTITY_TYPES.LEGAL_ENTITY) {
					return 'invest-org';
				}
				if (state.questionnaireProfile.type === ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR) {
					return 'invest-ip';
				}
				return 'invest-pers';
			}

			if (state.questionnaireProfile.role === ROLE_CODES.FOUNDER) {
				if (state.questionnaireProfile.type === ENTITY_TYPES.LEGAL_ENTITY){
					return "lpu-org";
				}

				return "lpu-ip";
			}
		},
		getterQuestionnaireFilesUploading(state) {
			return state.questionnaireFilesUploading;
		},
	},
};
