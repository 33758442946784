<template>
	<div class="custom-checkbox__container">
		<div
			ref="focusable"
			class="custom-checkbox-form"
			tabindex="0"
			@focus="$emit('focus')"
			@blur="$emit('blur')"
			@keydown.enter.exact.prevent="(e) => {
				e.currentTarget.click();
				e.currentTarget.focus();
			}"
			@keydown.space.exact.prevent="(e) => {
				e.currentTarget.click();
				e.currentTarget.focus();
			}"
			v-on="listeners"
		>
			<label class="form-switch" :class="!!isError ? 'custom-checkbox__error' : ''">
				<input
					:id="linkIdValue"
					:disabled="disabled"
					type="checkbox"
					name="b28-1"
					:checked="isChecked"
					class="hidden-checkbox"
					tabindex="-1"
					@change="updateInput"
				>
				<i/>{{ label }}
			</label>
		</div>
		<div>
			<span
				v-if="isError"
				class="input-caption"
				:class="isError ? 'input-caption__error' : ''"
			>
				{{ errorMsg }}
			</span>
		</div>
	</div>
</template>

<script>
import {randomKey} from "@utils/common";

export default {
	name: "UiCheckboxSwitch",

	model: {
		prop: 'modelValue',
		event: 'change',
	},

	props: {
		modelValue: {
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		trueValue: {
			default: true,
		},
		falseValue: {
			default: false,
		},
		description: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			linkIdValue: 'CH_tmp',
		};
	},

	computed: {
		isChecked() {
			if (this.modelValue instanceof Array) {
				return this.modelValue.includes(this.value);
			}
			return this.modelValue === this.trueValue;
		},
		isError() {
			return !!this.errorMsg;
		},
		errorMsg() {
			if (this.error === true) {
				return 'Некорректное значение';
			} else {
				return this.error ? this.error : '';
			}
		},
		listeners() {
			// eslint-disable-next-line no-unused-vars
			const { change, ...other } = this.$listeners;
			return other;
		}
	},

	created() {
		this.linkIdValue = randomKey('CH_');
	},

	methods: {
		updateInput(event) {
			const isChecked = event.target.checked;
			if (this.modelValue instanceof Array) {
				const newValue = [...this.modelValue];
				if (isChecked) {
					newValue.push(this.value);
				} else {
					newValue.splice(newValue.indexOf(this.value), 1);
				}
				this.$emit('change', newValue);
			} else {
				this.$emit('change', isChecked ? this.trueValue : this.falseValue);
			}
		},
		focus() {
			this.$refs.focusable.focus();
		}
	},
}
</script>
