import {createPlatformIssues, deletePlatformIssues, getPlatformIssues, updatePlatformIssues} from "@services/issues";

const fetch = (state) => {
	state.issuesLoading = true;
	return getPlatformIssues().then(r => {
		state.issues = [
			...r.data.items
		]
	}).finally(() => {
		state.issuesLoading = false;
	});
}

export default {
	state: {
		issues: null,
		issuesLoading: true,
	},

	actions: {
		async actionRemoveIssues({state}, id) {
			return deletePlatformIssues(id).then(() => fetch(state));
		},

		async actionSaveIssues({state}, data) {
			state.issuesLoading = true;
			return (data?.id
				? updatePlatformIssues(data)
				: createPlatformIssues(data)
			).then(() => fetch(state)
			).catch((e) => {
				state.issuesLoading = false
				throw e;
			})
		},

		async actionFetchIssues({state}) {
			if (!state.issues) {
				state.issues = [];
				return fetch(state);
			}
		},
	},

	getters: {
		getterIssuesByType(state) {
			return (type) => {
				if (!state.issues) {
					return [];
				}

				return state.issues.filter(issue => issue.type === type)
			};
		},
		getterIssuesByTypeAndChunk(state) {
			return (type) => {
				if (!state.issues) {
					return [];
				}

				const filteredIssues = state.issues.filter(issue => issue.type === type);

				return filteredIssues.reduce((acc, curr, index) => {
					const chunkIndex = Math.floor(index / 2);
					if (!acc[chunkIndex]) {
						acc[chunkIndex] = [];
					}
					acc[chunkIndex].push(curr);
					return acc;
				}, []);
			};
		},
		getterIssuesLoading(state) {
			return state.issuesLoading;
		},
	},
};
