import { postApiRequestBuilder, getApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder } from '@/utils/RequestBuilder';

export function createProjectNewsItem(projectId, newsData) {
	return postApiRequestBuilder(`projects/${projectId}/news`).send(newsData);
}

export function editProjectNewsItem(projectId, newsData) {
	return putApiRequestBuilder(`projects/${projectId}/news`).send(newsData);
}

export function getProjectNewsItem(projectId, newsId) {
	return getApiRequestBuilder(`projects/${projectId}/news/${newsId}`).send();
}

export function deleteProjectNewsItem(projectId, newsId) {
	return deleteApiRequestBuilder(`projects/${projectId}/news/${newsId}`).send();
}