// @ts-check

import {getEnv, VUE_APP_BASE_URL} from "@services/env";

/**
 * @param {import('vue').VueConstructor} Vue
 * @param {import('vue-router').default} router
 */
export async function initSentry(Vue, router) {
	const { init, BrowserTracing, vueRouterInstrumentation, Replay } =
		await import('@sentry/vue');

	if (
		process.env.NODE_ENV === 'production' &&
		process.env.VUE_APP_SENTRY_DSN &&
		process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE
	) {
		console.log('Sentry init');

		init({
			Vue,
			dsn: process.env.VUE_APP_SENTRY_DSN,
			integrations: [
				new BrowserTracing({
					// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
					tracePropagationTargets: [
						'localhost',
						new RegExp(`^${getEnv(VUE_APP_BASE_URL)}/api`),
					],
					routingInstrumentation: vueRouterInstrumentation(router),
				}),
				new Replay(),
			],
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: Number(process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE),

			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: 1.0,
		});
	}
}