import Vue from 'vue';
import { getProjectsSorts, getPitchesSorts } from '@/services/projects/projectsList';
import * as DictionarySvc from '@/services/dictionaries';
import { getTemplates, createDocumentTemplate } from "@services/documents";
import { uploadFile, deleteFile } from "@services/files";
import { upperCaseFirst, getParamValue, setParamValue, mapPromise, fromArrayToObject } from '@/utils/common';
import { formatDate, formatNum } from '@/utils/format';
import store from '../index';
import {
	DOCUMENT_TYPE_SCOPE_NAMES, DOCUMENT_TYPE_SCOPE_ITEMS,
	DOCUMENT_TEMPLATE_SCOPE_NAMES, DOCUMENT_TEMPLATE_SCOPE_ITEMS,
	ACCESS_CODE_NAMES, ACCESS_CODE_ITEMS,
} from '@configs/documents';
import {RULES} from "@utils/validation";
import { editPitchStatusSettings, getPitchStatusSettings, editProjectStatusSettings, getProjectStatusSettings, getParticipantStatusSettings, editParticipantStatusSettings, getBannerSettings, editBannerSettings } from '@/services/settings';

const mapperItems = (data) => data?.items;

const dictSvcCrud = (dict) => ({
	get: () => dict.getTypes(),
	create: (item) => dict.addType(item),
	update: (item) => dict.editType(item.id, item),
	delete: (item) => dict.deleteType(item.id),
});

const dictionaries = [
	{
		code: 'participantStatusSettings',
		label: 'Статусы анкет',
		fields: [
			{ name: 'code', label: 'Код', readonly: true },
			{ name: 'name', label: 'Название', required: true },
			{
				name: 'backgroundColor',
				label: 'Цвет фона',
				type: 'string',
				required: true,
				validator: [RULES.color],
			},
			{
				name: 'color',
				label: 'Цвет текста',
				type: 'string',
				required: true,
				validator: [RULES.color],
			},
		],
		crud: {
			get: () => getParticipantStatusSettings(),
			update: (item) => editParticipantStatusSettings(item),
		},
	},
	{
		code: 'projectStatusSettings',
		label: 'Статусы проектов',
		fields: [
			{ name: 'code', label: 'Код', readonly: true },
			{ name: 'name', label: 'Название', required: true },
			{
				name: 'backgroundColor',
				label: 'Цвет фона',
				type: 'string',
				required: true,
				validator: [RULES.color],
			},
			{
				name: 'color',
				label: 'Цвет текста',
				type: 'string',
				required: true,
				validator: [RULES.color],
			},
		],
		crud: {
			get: () => getProjectStatusSettings(),
			update: (item) => editProjectStatusSettings(item),
		},
	},
	{
		code: 'pitchStatusSettings',
		label: 'Статусы драфт-проектов',
		fields: [
			{ name: 'code', label: 'Код', readonly: true },
			{ name: 'name', label: 'Название', required: true },
			{
				name: 'backgroundColor',
				label: 'Цвет фона',
				type: 'string',
				required: true,
				validator: [RULES.color],
			},
			{
				name: 'color',
				label: 'Цвет текста',
				type: 'string',
				required: true,
				validator: [RULES.color],
			},
		],
		crud: {
			get: () => getPitchStatusSettings(),
			update: (item) => editPitchStatusSettings(item),
		},
	},
	{
		code: 'bannerSettings',
		label: 'Баннер платформы',
		fields: [
			{
				name: 'title',
				label: 'Заголовок',
				type: 'string',
				required: true,
			},
			{
				name: 'content',
				label: 'Содержание',
				type: 'text',
				required: true,
			},
		],
		crud: {
			get: () => getBannerSettings(),
			update: (item) => editBannerSettings(item),
		},
	},
	{
		code: 'konturSettings',
		label: 'Настройки Контур-Фокус',
		fields: [
			{ name: 'name', label: 'Название', readonly: true },
			{
				name: 'enable',
				label: 'Значение',
				type: 'boolean',
				required: true,
				align: 'center',
			},
		],
		crud: {
			get: () => DictionarySvc.konturSettings.getTypes(),
			update: (item) =>
				DictionarySvc.konturSettings.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'commonSettings',
		label: 'Общие настройки',
		fields: [
			{ name: 'code', label: 'Код', readonly: false },
			{ name: 'name', label: 'Название', required: true },
			{
				name: 'value',
				label: 'Значение',
				type: 'string',
				required: true,
				align: 'right',
			},
		],
		crud: {
			get: () => DictionarySvc.commonSettings.getTypes(),
			update: (item) =>
				DictionarySvc.commonSettings.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'loanTaxes',
		label: 'Комиссии проектов типа «Заём»',
		crud: dictSvcCrud(DictionarySvc.loanTaxes),
		mapper: mapperItems,
	},
	{
		code: 'convertibleLoanTaxes',
		label: 'Комиссии проектов типа «Конверт. заём»',
		crud: dictSvcCrud(DictionarySvc.convertibleLoanTaxes),
		mapper: mapperItems,
	},
	{
		code: 'stockTaxes',
		label: 'Комиссии проектов типа «Акции»',
		crud: dictSvcCrud(DictionarySvc.stockTaxes),
		mapper: mapperItems,
	},
	{
		code: 'ucpTaxes',
		label: 'Комиссии проектов типа «УЦП»',
		crud: dictSvcCrud(DictionarySvc.ucpTaxes),
		mapper: mapperItems,
	},
	{
		code: 'loanTaxRanges',
		label: 'Диапазоны сумм проекта типа «Займ» для расчета комиссии',
		crud: dictSvcCrud(DictionarySvc.loanTaxRanges),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.min - i2.min,
		fields: [
			{
				name: 'min',
				label: 'Значение от, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
			{
				name: 'max',
				label: 'Значение до, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
		]
	},
	{
		code: 'convertibleLoanTaxRanges',
		label: 'Диапазоны сумм проекта типа «Конверт. заём» для расчета комиссии',
		crud: dictSvcCrud(DictionarySvc.convertibleLoanTaxRanges),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.min - i2.min,
		fields: [
			{
				name: 'min',
				label: 'Значение от, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
			{
				name: 'max',
				label: 'Значение до, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
		]
	},
	{
		code: 'stockTaxRanges',
		label: 'Диапазоны сумм проекта типа «Акции» для расчета комиссии',
		crud: dictSvcCrud(DictionarySvc.stockTaxRanges),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.min - i2.min,
		fields: [
			{
				name: 'min',
				label: 'Значение от, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
			{
				name: 'max',
				label: 'Значение до, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
		]
	},
	{
		code: 'ucpTaxRanges',
		label: 'Диапазоны сумм проекта типа «УЦП» для расчета комиссии',
		crud: dictSvcCrud(DictionarySvc.ucpTaxRanges),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.min - i2.min,
		fields: [
			{
				name: 'min',
				label: 'Значение от, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
			{
				name: 'max',
				label: 'Значение до, ₽',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: v => formatNum(v, 2, true),
			},
		]
	},
	{
		code: 'projectSortsPitch',
		label: 'Сортировка драфт-проектов',
		crud: { get: () => getPitchesSorts() },
		mapper: mapperItems,
	},
	{
		code: 'projectSortsProject',
		label: 'Сортировка проектов',
		crud: { get: () => getProjectsSorts() },
		mapper: mapperItems,
	},
	{
		code: 'businessTypes',
		label: 'Отрасли бизнеса',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Наименование' },
		],
		crud: dictSvcCrud(DictionarySvc.businessTypes),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.code > i2.code,
	},
	{
		code: 'calendar',
		label: 'Календарь',
		fields: [
			{ name: 'code', label: 'Год' },
			{ name: 'value', label: 'Выходные дни',
				mapper: (item) => JSON.parse(item).days.map(day => formatDate(day, "DD.MM.YYYY (Wd)")).join(", ")
			},
		],
		crud: {
			get: () => DictionarySvc.calendar.getTypes(),
			update: (item) => DictionarySvc.calendar.editType(item.id, item),
		},
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.code > i2.code,
	},

	{
		code: 'documentTypes',
		label: 'Типы документов',
		fields: [
			{
				name: 'code',
				label: 'Код',
				required: true,
				readonly: obj => obj.__mode === 'edit',
			},
			{
				name: 'name',
				label: 'Тип документа',
				required: true,
			},
			{
				name: 'scope',
				label: 'Принадлежность',
				required: true,
				type: 'select',
				align: 'center',
				options: DOCUMENT_TYPE_SCOPE_ITEMS,
				mapperItem: obj => DOCUMENT_TYPE_SCOPE_NAMES[obj.scope],
			},
			{
				name: 'access',
				label: 'Доступ',
				type: 'radio',
				align: 'center',
				options: ACCESS_CODE_ITEMS,
				mapperItem: obj => ACCESS_CODE_NAMES[obj.access],
			},
		],
		crud: dictSvcCrud(DictionarySvc.documentTypes),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.code.localeCompare(i2.code),
	},

	{
		code: 'documentTemplates',
		label: 'Шаблоны документов',
		fields: [
			{
				name: 'documentTypeCode',
				label: 'Код типа документа',
				required: true,
				type: 'select',
				options: () => store.getters['dictionary/documentTypes']?.map(dt => ({
					value: dt.code,
					label: `${dt.name} (${dt.code})`,
				})),
				readonly: obj => obj.__mode === 'edit',
			},
			{
				name: 'documentTypeName',
				label: 'Название типа документа',
				hidden: true,
			},
			{
				name: 'scope',
				label: 'Область действия',
				required: true,
				type: 'select',
				align: 'center',
				options: DOCUMENT_TEMPLATE_SCOPE_ITEMS,
				mapperItem: obj => DOCUMENT_TEMPLATE_SCOPE_NAMES[obj.scope],
			},
			{
				name: 'template',
				label: 'Шаблон',
				required: true,
				type: 'file',
				acceptTypes: 'docx',
				title: 'Файл шаблона',
				tips: 'Файл в формате docx',
				uploadHandler: file => uploadFile(file),
				deleteHandler: file => deleteFile(file),
			},
		],
		crud: {
			get: () => mapPromise(getTemplates(), res => ({ items: res.data.items.map(dt => ({ ...dt, id: dt.documentTypeCode })) })),
			create: item => createDocumentTemplate({ ...item, templateId: item.template.fileId }).then(res => res.data),
			update: item => createDocumentTemplate({ ...item, templateId: item.template.fileId }).then(res => res.data),
		},
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.documentTypeCode.localeCompare(i2.documentTypeCode),
		skipOnInit: true,
	},
	{
		code: 'investmentRanges',
		label: 'Диапазон инвестиций для драфт-проектов',
		fields: [
			{
				name: 'code',
				label: 'Код',
			},
			{
				name: 'amountFrom',
				label: 'Диапазон: от',
				type: 'int',
				align: 'right',
			},
			{
				name: 'amountTo',
				label: 'до',
				type: 'int',
				align: 'right',
			},
		],
		crud: dictSvcCrud(DictionarySvc.investmentRanges),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.amountFrom - i2.amountFrom,
	},
	{
		code: 'stocksTypes',
		label: 'Категории акций',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Категория' },
		],
		crud: dictSvcCrud(DictionarySvc.stocksTypes),
		mapper: mapperItems,
	},
	{
		code: 'investorGroupTypes',
		label: 'Круг инвесторов',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Круг инвесторов' },
		],
		crud: dictSvcCrud(DictionarySvc.investorGroupTypes),
		mapper: mapperItems,
	},
	{
		code: 'utilityRightsTypes',
		label: 'Виды УЦП',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Вид' },
		],
		crud: dictSvcCrud(DictionarySvc.utilityRightsTypes),
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.code > i2.code,
	},
	{
		code: 'paymentSchemes',
		label: 'Порядок погашения',
		fields: [
			{ name: 'code', label: 'Код', readonly: true },
			{ name: 'name', label: 'Название', type: 'string' },
			{ name: 'value', label: 'Коэффициент', type: 'number', fractions: 3 },
		],
		crud: {
			get: () => DictionarySvc.paymentSchemes.getTypes(),
			update: (item) => DictionarySvc.paymentSchemes.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'loanPurposes',
		label: 'Цели займа',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Название платежа' },
		],
		crud: dictSvcCrud(DictionarySvc.loanPurposes),
		mapper: mapperItems,
	},
	{
		code: 'realEstateObjectsTypes',
		label: 'Типы объектов недвижимого имущества',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Тип' },
			{ name: 'unit', label: 'Единица(цы) измерения' },
		],
		crud: dictSvcCrud(DictionarySvc.realEstateObjectsTypes),
		mapper: mapperItems,
	},
	{
		code: 'paymentPurpose',
		label: 'Справочник назначений платежа',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Назначение платежа', maxLength: 210 },
		],
		crud: dictSvcCrud(DictionarySvc.paymentPurpose),
		mapper: mapperItems,
	},
	{
		code: 'messageTexts',
		label: 'Тексты сообщений',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'text', label: 'Текст' },
		],
		crud: dictSvcCrud(DictionarySvc.messageTexts),
		mapper: mapperItems,
	},
	{
		code: 'lpiRatings',
		label: 'Панель управления Рейтингом',
		sortFn: (i1, i2) => i1.code.localeCompare(i2.code),
		fields: [
			{
				name: 'code',
				label: 'Код рейтинга',
				required: true,
			},
			{
				name: 'name',
				label: 'Наименование',
				required: true,
			},
			{
				name: 'minPoint',
				label: 'Баллов: от',
				type: 'int',
				align: 'right',
			},
			{
				name: 'maxPoint',
				label: 'Баллов: до',
				type: 'int',
				align: 'right',
			},
			{
				name: 'minBid',
				label: 'Min ставка, %',
				type: 'number',
				fractions: 2,
				align: 'right',
				render: (item) => formatNum(item.minBid, 2),
				required: true,
				validator: (v) => {
					const borrowingRate = store.getters[
						'dictionary/platformLoanLimits'
					]?.find((d) => d.code === 'BORROWING_RATE');

					if (v <= 0 || v >= 100) {
						return 'введите значение больше 0 и меньше 100';
					}

					if (!borrowingRate) {
						return true;
					}

					return (
						v >= borrowingRate.minValue ||
						`Значение не должно быть меньше минимального значения ставки из общих ограничений (${borrowingRate.minValue}%)`
					);
				},
			},
			{
				name: 'maxBid',
				label: 'Max ставка, %',
				type: 'number',
				fractions: 2,
				align: 'right',
				render: (item) => formatNum(item.maxBid, 2),
				required: true,
				validator: (v) => {
					const borrowingRate = store.getters[
						'dictionary/platformLoanLimits'
					]?.find((d) => d.code === 'BORROWING_RATE');

					if (v <= 0 || v >= 100) {
						return `введите значение больше 0 и меньше 100`;
					}

					if (!borrowingRate) {
						return true;
					}

					return (
						v <= borrowingRate.maxValue ||
						`Значение не должно быть больше максимального значения ставки из общих ограничений (${borrowingRate.maxValue}%)`
					);
				},
			},
			{
				name: 'raisingCoefficient',
				isCustom: true,
				label: 'Прирост ставки в интервале Рейтинга (рассчитывается автоматически)',
				type: 'int',
				align: 'right',
				mapper: (num) => formatNum(num, 4, true),
			},
			{
				name: 'percentageOfSalesProceeds',
				label: 'Max сумма займа: % от выручки',
				type: 'number',
				fractions: 2,
				align: 'right',
				mapper: (num) => formatNum(num, 2, true),
				validator: [RULES.percent],
			},
			{
				name: 'maxLoanAmount',
				label: 'Max сумма займа: значение',
				type: 'int',
				align: 'right',
				mapper: (num) => formatNum(num, 0, true),
			},
		],
		crud: dictSvcCrud(DictionarySvc.lpiRatings),
		mapper: mapperItems,
	},
	{
		code: 'loanOdds',
		label: 'Настройки уд. веса коэффициентов',
		fields: [
			{ name: 'code', label: 'Код', readonly: true },
			{ name: 'name', label: 'Название', required: true },
			{
				name: 'value',
				label: 'Уд. вес общего к-та в приросте ставки в Рейтинге, %',
				type: 'int',
				required: true,
				align: 'right',
				validator: [RULES.percent],
			},
		],
		crud: {
			get: () => DictionarySvc.loanOdds.getTypes(),
			update: (item) => DictionarySvc.loanOdds.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'collateralTypes',
		label: 'Виды обеспечения',
		fields: [
			{ name: 'code', label: 'Код', required: true },
			{ name: 'name', label: 'Название', required: true },
			{
				name: 'value',
				label: 'Уд. вес в к-те обеспечения, %',
				type: 'int',
				align: 'right',
				required: true,
				validator: [RULES.percent],
			},
		],
		// crud: {
		// 	get: () => DictionarySvc.collateralTypes.getTypes(),
		// 	update: (item) => DictionarySvc.collateralTypes.editType(item.id, item),
		// },
		crud: dictSvcCrud(DictionarySvc.collateralTypes),
		mapper: mapperItems,
	},
	{
		code: 'convLoanTerm',
		label: 'Срок',
		fields: [
			{
				name: 'code',
				label: 'Код',
				readonly: obj => obj.__mode === 'edit',
				required: true,
			},
			{
				name: 'label',
				label: 'Название',
				required: true,
			},
			{
				name: 'value',
				label: 'Значение',
				type: 'number',
				fractions: 0,
				align: 'right',
				required: true,
				mapper: (num) => formatNum(num, 0, true),
			},
		],
		crud: dictSvcCrud(DictionarySvc.convLoanTerm),
		mapper: mapperItems,
	},
	{
		code: 'platformConvLoanLimits',
		label: 'Параметры ограничения Конв. Займов',
		fields: [
			{ name: 'code', label: 'Код', readonly: true },
			{ name: 'label', label: 'Показатель', readonly: true },
			{
				name: 'minValue',
				label: 'Минимальное значение',
				type: 'number',
				fractions: 2,
				align: 'right',
				required: true,
				mapper: (num) => formatNum(num, 2, true),
			},
			{
				name: 'maxValue',
				label: 'Максимальное значение',
				type: 'number',
				align: 'right',
				fractions: 2,
				required: true,
				mapper: (num) => formatNum(num, 2, true),
			},
		],
		crud: {
			get: () => DictionarySvc.platformConvLoanLimits.getTypes(),
			update: (item) =>
				DictionarySvc.platformConvLoanLimits.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'platformLoanLimits',
		label: 'Параметры ограничения Займов',
		fields: [
			{ name: 'code', label: 'Код', readonly: true },
			{ name: 'name', label: 'Показатель', readonly: true },
			{
				name: 'minValue',
				label: 'Минимальное значение',
				type: 'number',
				fractions: 2,
				align: 'right',
				required: true,
			},
			{
				name: 'maxValue',
				label: 'Максимальное значение',
				type: 'number',
				fractions: 2,
				align: 'right',
				required: true,
			},
		],
		crud: {
			get: () => DictionarySvc.platformLoanLimits.getTypes(),
			update: (item) =>
				DictionarySvc.platformLoanLimits.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'projectTypes',
		label: 'Типы проектов',
		fields: [
			{ name: 'name', label: 'Тип проекта', readonly: true },
			{ name: 'subtitle-invest', label: 'Инвестиции', type: 'subtitle' },
			[
				{
					name: 'minInvest',
					label: 'Минимальная сумма инвестиций, ₽',
					type: 'number',
					fractions: 2,
					align: 'right',
					required: true,
					mapper: (num) => formatNum(num, 2, true),
				},
				{
					name: 'maxInvest',
					label: 'Максимальная сумма инвестиций, ₽',
					type: 'number',
					fractions: 2,
					align: 'right',
					required: true,
					mapper: (num) => formatNum(num, 2, true),
				},
			],
			{ name: 'subtitle-loan', label: 'Привлечение средств', type: 'subtitle' },
			[
				{
					name: 'minLoan',
					label: 'Минимальная сумма привлечения, ₽',
					type: 'number',
					fractions: 2,
					align: 'right',
					required: true,
					mapper: (num) => formatNum(num, 2, true),
				},
				{
					name: 'maxLoan',
					label: 'Максимальная сумма привлечения, ₽',
					type: 'number',
					fractions: 2,
					align: 'right',
					required: true,
					mapper: (num) => formatNum(num, 2, true),
				},
			],
		],
		crud: {
			get: async () => {
				const types = await DictionarySvc.projectTypes.getTypes()
				const items = types.items.map(tt => ({
					id: tt.id,
					type: tt.type,
					name: tt.name,
					minInvest: getParamValue(tt.parameters, "MIN_INVEST"),
					maxInvest: getParamValue(tt.parameters, "MAX_INVEST"),
					minLoan: getParamValue(tt.parameters, "MIN_LOAN"),
					maxLoan: getParamValue(tt.parameters, "MAX_LOAN"),
					validity: getParamValue(tt.parameters, "VALIDITY"),
				}));
				return { items };
			},
			update: (item) => {
				let parameters = [];
				setParamValue(parameters, "MIN_INVEST", item.minInvest);
				setParamValue(parameters, "MAX_INVEST", item.maxInvest);
				setParamValue(parameters, "MIN_LOAN", item.minLoan);
				setParamValue(parameters, "MAX_LOAN", item.maxLoan);
				setParamValue(parameters, "VALIDITY", item.validity);
				return DictionarySvc.projectTypes.editType(item.id, {
					id: item.id,
					type: item.type,
					name: item.name,
					parameters,
				});
			}
		},
		mapper: mapperItems,
	},
	{
		code: 'publishTerm',
		label: 'Сроки сбора средств (по видам проекта)',
		fields: [
			{ name: 'name', label: 'Показатель', readonly: false },
			{
				name: 'minDays',
				label: 'Минимальное кол-во дней',
				type: 'number',
				align: 'right',
				required: true,
				mapper: (num) => formatNum(num, 0, true),
			},
			{
				name: 'maxDays',
				label: 'Максимальное кол-во дней',
				type: 'number',
				align: 'right',
				required: true,
				mapper: (num) => formatNum(num, 0, true),
			},
		],
		crud: {
			get: () => DictionarySvc.publishTerm.getTypes(),
			update: (item) =>
				DictionarySvc.publishTerm.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'paymentDelay',
		label: 'Сроки отсрочки первого платежа',
		fields: [
			{ name: 'code', label: 'Код' },
			{
				name: 'days',
				label: 'Срок в днях',
				type: 'number',
				align: 'right',
				required: true,
				mapper: (num) => formatNum(num, 0, true),
			},
			{
				name: 'minTermDays',
				label: 'Минимальный срок займа, дней',
				type: 'int',
				align: 'right',
				mapper: (num) => formatNum(num, 0, true),
			},
			{
				name: 'maxTermDays',
				label: 'Максимальный срок займа, дней',
				type: 'int',
				align: 'right',
				mapper: (num) => formatNum(num, 0, true),
			},
		],
		crud: {
			get: () => DictionarySvc.paymentDelay.getTypes(),
			update: (item) =>
				DictionarySvc.paymentDelay.editType(item.id, item),
		},
		mapper: mapperItems,
		sortFn: (i1, i2) => i1.days - i2.days,
	},
	{
		code: 'platformContacts',
		label: 'Контакты платформы',
		fields: [
			{ name: 'type', label: 'Тип', readonly: true },
			{ name: 'phone', label: 'Телефон', required: true, },
			{ name: 'email', label: 'Email', required: true, },
		],
		crud: {
			get: () => DictionarySvc.platformContacts.getTypes(),
			update: (item) => DictionarySvc.platformContacts.editType(item.id, item),
		},
		mapper: mapperItems,
	},
	{
		code: 'faqCategories',
		label: 'Категории вопросов платформы',
		fields: [
			{ name: 'code', label: 'Код' },
			{ name: 'name', label: 'Название раздела', type: 'string' },
		],
		crud: dictSvcCrud(DictionarySvc.faqCategories),
		mapper: mapperItems,
	},
];

function defineDictionary(dict) {
	let dictObj = {
		...dict,
		loading: false,
		data: null,
	};
	const loaderWrapper = () => {
		Vue.set(dictObj, 'loading', true);
		return new Promise((resolve, reject) => {
			dict.crud
				.get()
				.then((res) => {
					if (dict.mapper) {
						res = dict.mapper(res);
					}
					if (dict.eachMapper) {
						res = res.map((itm) => dict.eachMapper(itm));
					}
					Vue.set(dictObj, 'data', res);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				})
				.finally(() => {
					Vue.set(dictObj, 'loading', false);
				});
		});
	};
	dictObj.crud.loader = loaderWrapper;
	return dictObj;
}
function defineDictionaries() {
	return Object.fromEntries(
		dictionaries.map((dd) => [dd.code, defineDictionary(dd)]),
	);
}

function defineLoadingPromises() {
	return Object.fromEntries(
		dictionaries.map((dd) => {
			let resolve;
			return [
				dd.code,
				{
					loading: false,
					promise: new Promise((res) => (resolve = res)),
					resolve,
				},
			];
		}),
	);
}

function getMethodName(dict) {
	return 'get' + upperCaseFirst(dict.code);
}

function generateActions() {
	let actions = {};
	dictionaries.forEach((dd) => {
		actions[getMethodName(dd)] = ({ dispatch }) =>
			dispatch('getDictionary', dd.code);
	});
	return actions;
}
function generateGetters() {
	const gettersArr = dictionaries.map((dd) => [dd.code, (s) => s[dd.code]]);
	const sortedGettersArr = dictionaries.filter((dd) => !!dd.sortFn)
		.map((dd) => [`${dd.code}Sorted`, (s) => s[dd.code].slice().sort(dd.sortFn)]);
	const loadingStatesArr = dictionaries.map((dd) => [
		`${dd.code}LoadingState`,
		(s) => s.loadingState[dd.code],
	]);

	const getters = Object.fromEntries(gettersArr);
	const sortedGetters = Object.fromEntries(sortedGettersArr);
	const loadingStates = Object.fromEntries(loadingStatesArr);
	return { ...sortedGetters, ...loadingStates, ...getters };
}

export default {
	namespaced: true,

	state: {
		__defs: defineDictionaries(),
		loadingState: defineLoadingPromises(),
	},

	mutations: {
		setData(state, { code, data }) {
			Vue.set(state, code, data);
		},
		startLoading(state, code) {
			Vue.set(state.loadingState[code], 'loading', true);
		},
		finishLoading(state, code) {
			Vue.set(state.loadingState[code], 'loading', false);
		},
	},

	actions: {
		__initDictionaries({ dispatch, state }) {
			Object.entries(state.__defs)
				.filter(ent => !ent[1].skipOnInit)
				.forEach(ent => {
					dispatch('__getDictionary', ent[0]);
				});
		},

		__getDictionary({ commit, state, dispatch }, code) {
			const dd = state.__defs[code];
			dispatch('__actionStartLoading', code);
			return new Promise((resolve, reject) => {
				dd.crud
					.loader()
					.then((res) => {
						commit('setData', { code, data: res });
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					})
					.finally(() => {
						dispatch('__actionFinishLoading', code);
					});
			});
		},

		__actionStartLoading({ commit }, code) {
			commit('startLoading', code);
		},

		__actionFinishLoading({ state, commit }, code) {
			state.loadingState[code].resolve();
			commit('finishLoading', code);
		},

		...generateActions(),
	},

	getters: {
		dictionaryDefinitionMap: (state) => state.__defs,

		...generateGetters(),

		pitchStatusSettingsObject(state, getters) {
			return fromArrayToObject(getters.pitchStatusSettings, 'code');
		},
		projectStatusSettingsObject(state, getters) {
			return fromArrayToObject(getters.projectStatusSettings, 'code');
		},
		participantStatusSettingsObject(state, getters) {
			return fromArrayToObject(getters.participantStatusSettings, 'code');
		},
	},
};
