export const ORDER_BY = {
	DESC: 'DESC',
	ASC: 'ASC',
}

export const FILTER_CASES = {
	SEARCH: 'search',
	SELECT: 'select',
	CHECKBOX: 'checkbox',
	NUMBER: 'number',
	DATEPERIOD: 'date-period',
	MONEYPERIOD: 'money-period',
	INTEGERPERIOD: 'integer-period',
}