/*eslint no-dupe-class-members: "off"*/
//? Почему-то eslint ругается, что у меня дублируется имя #request в классе
import axios from 'axios';
import store from '../store';
import KC from '@/keycloak';

export default class Requests {
	static async getRequest(url, config = {}, credentials = false, baseURL = '/') {
		return this.request('get', url, undefined, config, credentials, baseURL);
	}

	static async postRequest(url, data = {}, config = {}, credentials = false, baseURL = '/') {
		return this.request('post', url, data, config, credentials, baseURL);
	}

	static async putRequest(url, data = {}, config = {}, credentials = false, baseURL = '/') {
		return this.request('put', url, data, config, credentials, baseURL);
	}

	static async deleteRequest(url, config = {}, credentials = false, baseURL = '/') {
		return this.request('delete', url, undefined, config, credentials, baseURL);
	}

	static async requestWrapper(request) {
		if (typeof request.then !== 'function')
			throw new Error(
				'Invalid type of request. It should be async function'
			);

		const res = await request;

		if (res.status === 200 || res.status === 201) {
			return { success: true, status: res.status, data: res.data };
		}

		return { success: false, status: res.status, data: res.data };
	}

	static async request(
		method,
		url,
		data = {},
		config = {},
		credentials = false,
		baseURL = '/'
	) {
		if (credentials)
			return this.#credentialedRequest(method, url, data, config, baseURL);

		return axios.request({
			method,
			url,
			data,
			...config,
			baseURL
		});
	}

	static async #credentialedRequest(method, url, data = {}, config = {}, baseURL = '/') {
		return _request(await this.#ensureToken());
		async function _request(tok) {
			try {
				// Если мы еще не получили ответ на авторизацию пользователя, то ждем ответа
				if (!url.includes('user/data/sync')) {
					await store.state.userSync;
				}
			} catch (err) {
				throw new Error(err);
			}

			if (tok) {
				if (!config.headers) config.headers = {};
				config.headers.Authorization = 'bearer ' + tok;
			}

			config = {
				...config,
				data: data,
				method,
				url,
				withCredentials: true,
				baseURL
			};
			return axios.request(config);
		}
	}

	static async #ensureToken() { 
		return await KC.instance().ensureToken();
	}
}
