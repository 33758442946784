var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UiDialog', {
    ref: "exportToExcelModal",
    attrs: {
      "title": "Наличие АО",
      "width": "50%",
      "buttons": [{
        label: 'Экспорт',
        name: 'export',
        type: 'primary',
        prependIcon: 'download'
      }, {
        label: 'Закрыть',
        name: 'cancel',
        type: 'secondary'
      }],
      "footerLeft": ""
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h2', {
          staticClass: "export-excel__title"
        }, [_vm._v(" Экспорт данных таблицы ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "export-excel__table-name"
  }, [_c('p', {
    staticClass: "export-excel__text mb-16"
  }, [_vm._v(" Укажите имя экспортируемого файла: ")]), _c('UiInput', {
    staticClass: "export-excel__name",
    attrs: {
      "placeholder": "Укажите имя"
    },
    on: {
      "emitValue": _vm.changeExcelName
    }
  }), !_vm.changedValue ? _c('p', {
    staticClass: "export-excel__text mb-16"
  }, [_vm._v(" Если оставить это поле пустым, умолчательное название файла будет: "), _c('b', [_vm._v(_vm._s(_vm.fileName))])]) : _c('p', {
    staticClass: "export-excel__text mb-16"
  }, [_vm._v(" Название файла будет: "), _c('b', [_vm._v(_vm._s(_vm.changedValue))])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }