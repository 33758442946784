import { DEFAULT_CACHE_LIFETIME, DEFAULT_LOADER_TIMEOUT } from "@/utils/CacheBulder"
import { randomKey } from "@/utils/common";

const state = {
	_cacheData: {},
};

const getters = {
	/**
	 * Получить данные по коду
	 * @param {*} state стэйт
	 * @returns {any[]} кэшированные данные
	 */
	getData: state => (dataCode => {
		if (state._cacheData[dataCode]) {
			return state._cacheData[dataCode].data;
		}
		return [];
	}),
};

const mutations = {
	/**
	 * Зарегистрировать данные в кэше
	 * @param {*} state стэйт
	 * @param {{code: string, loader: () => any[] | Promise<any[]>, lifetime?: number, loaderTimeout?: number}} payload описание данных
	 */
	registerData(state, payload) {
		let obj = state._cacheData[payload.code];
		if (obj) {
			state._cacheData[payload.code] = { ...obj, _loder: payload.loader, _lifetime: payload.lifetime, _loaderTimeout: payload.loaderTimeout };
		} else {
			state._cacheData[payload.code] = { _loder: payload.loader, _lifetime: payload.lifetime, _loaderTimeout: payload.loaderTimeout };
		}
	},

	/**
	 * Блокировка кэша по коду
	 * @param {*} state стэйт
	 * @param {string} dataCode Код данных кэша
	 * @returns {boolean} Результат блокировки. При успешной блокировке - true, если блокировка уже установлена - false.
	 */
	lockCache(state, dataCode) {
		let obj = state._cacheData[dataCode];
		if (obj) {
			if (obj._locked) return false;
			obj._locked = true;
		} else {
			state._cacheData[dataCode] = { _locked: true };
		}
		return true;
	},
	/**
	 * Зазблокировка кэша по коду
	 * @param {*} state стэйт
	 * @param {string} dataCode Код данных кэша
	 */
	unlockCache(state, dataCode) {
		let obj = state._cacheData[dataCode];
		if (obj) {
			if (obj._locked) obj._locked = false;
		}
	},

	/**
	 * Сохранить данные в кэш
	 * @param {*} state стэйт
	 * @param {{code: string, data: any[]}} payload объект с данными (data) и кодом данных (code)
	 */
	setData(state, payload) {
		const obj = { _since: Date.now(), _locked: false, data: payload.data };
		state._cacheData[payload.code] = obj;
	},

	/**
	 * Сбросить данные в кэше
	 * @param {*} state стэйт
	 * @param {string} dataCode Код данных кэша
	 */
	resetData(state, dataCode) {
		let obj = state._cacheData[dataCode];
		if (obj) {
			obj._since = 0;
		}
	},

	/**
	 * Регистрация слушателя 
	 * @param {*} state стэйт
	 * @param {{listener: { handler: (any[]) => void, _id: string, _since: number }, code: string}} payload объект слушателя
	 */
	regListener(state, payload) {
		let obj = state._cacheData[payload.code];
		if (obj) {
			let lstnrs = obj._listeners;
			if (lstnrs && Array.isArray(lstnrs)) {
				lstnrs.push(payload.listener);
			} else {
				obj._listeners = [ payload.listener ];
			}
		}
	},

	/**
	 * Удаление регистрации слушателя
	 * @param {*} state стэйт
	 * @param {{listener: { _id: string, _since: number, code: string}}} payload  объект слушателя
	 */
	unregListener(state, payload) {
		let obj = state._cacheData[payload.code];
		if (obj) {
			let lstnrs = obj._listeners;
			if (lstnrs && Array.isArray(lstnrs)) {
				const idx = lstnrs.findIndex(l => l._id === payload.listener._id && l._since === payload.listener._since);
				if (idx > -1) {
					lstnrs.splice(idx, 1);
				}
			}
		}
	},
};

const actions = {
	/**
	 * Регистрация вида данных
	 * @param {*} param0  коммит
	 * @param {{code: string, loader: () => any[] | Promise<any[]>, lifetime?: number, loaderTimeout?: number}} payload описание данных
	 */
	registerData({ commit }, payload) {
		commit('registerData', payload);
	},

	/**
	 * Получить данные асинхронно.
	 * Данные извлекаются из кэша или запрашиваются из загрузчика, если кэша нет или он устарел.
	 * @param {*} param0 коммит и стэйт
	 * @param {string} dataCode Код данных кэша
	 * @returns {Promise<any[]>} промис данных
	 */
	getData({ commit, state }, dataCode) {
		return new Promise((resolve, reject) => {
			let obj = state._cacheData[dataCode];
			const cacheLifetime = obj._lifetime ?? DEFAULT_CACHE_LIFETIME;
			if (obj && obj.data && obj._since > Date.now() - cacheLifetime) { // кэш есть и он не устарел
				resolve(obj.data);
			} else { // кэш устарел или отсутствует
				if (!obj || !obj._locked) {
					commit('lockCache', dataCode);
					if (obj._loader) {
						obj._loader()
							.then(res => {
								const result = state._cacheData[dataCode];
								const listeners = result ? result._listeners : undefined; // сохраним подписчиков
								commit('setData', { code: dataCode, data: res });
								resolve(res);
								// обработка подписчиков
								if (listeners && Array.isArray(listeners)) {
									listeners.forEach(lstnr => {
										clearTimeout(lstnr._timerId);
										lstnr.handler(res);
									});
								}
							})
							.catch(err => {
								commit('unlockCache', dataCode);
								reject(err);
							});
					} else {
						commit('unlockCache', dataCode);
						reject(`No loader for "${dataCode}" data`);
					}
				} else {
					// уже заблокирован - ожидание
					const waitSince = Date.now();
					const listener = { _id: randomKey(), _since: waitSince, handler: resolve };
					const loaderTimeout = obj._loaderTimeout ?? DEFAULT_LOADER_TIMEOUT;
					const timerId = setTimeout(() => {
						commit("unregListener", { dataCode, listener });
						reject(`Request data timeout for dictionary ${dataCode}!`);
					}, loaderTimeout);
					commit("regListener", { dataCode, listener: { ...listener, _timerId: timerId } });
				}
			}
		});
	},

	resetDataCache({ commit }, dataCode) {
		commit("resetData", dataCode);
	}
};


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
