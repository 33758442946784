var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tabs.length > 0 ? _c('ul', {
    staticClass: "ui-segment",
    class: _vm.getBorderType
  }, _vm._l(_vm.tabs, function (tab, idx) {
    return _c('li', {
      key: idx,
      class: idx === _vm.selectedIdx ? 'active' : '',
      style: tab.styles,
      attrs: {
        "id": tab.id
      },
      on: {
        "click": function ($event) {
          return _vm.select(idx);
        }
      }
    }, [_c('a', [_vm._v(_vm._s(tab.name))])]);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }