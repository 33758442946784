<template>
	<div v-show="visibleFlag">
		<slot/>
	</div>
</template>

<script>
export default {
	name: "UiPage",

	inject: ["registerPage"],

	props: {
		name: {
			type: String,
			required: true
		},
	},

	data() {
		return {
			visibleFlag: false,
		}
	},

	mounted: function(){
		this.registerPage(this, this.name);
	},

	methods: {
		setVisible(vis) {
			this.visibleFlag = vis;
		}
	}

};
</script>

