<template>
	<UiDialog
		ref="exportToExcelModal"
		title="Наличие АО"
		width="50%"
		:buttons="[
			{
				label: 'Экспорт',
				name: 'export',
				type: 'primary',
				prependIcon: 'download'
			},
			{
				label: 'Закрыть',
				name: 'cancel',
				type: 'secondary',
			}
		]"
		footerLeft
		@close="close"
	>
		<template #header>
			<h2 class="export-excel__title">
				Экспорт данных таблицы
			</h2>
		</template>
		<div class="export-excel__table-name">
			<p class="export-excel__text mb-16">
				Укажите имя экспортируемого файла:
			</p>
			<UiInput
				class="export-excel__name"
				placeholder="Укажите имя"
				@emitValue="changeExcelName"
			/>
			<p v-if="!changedValue" class="export-excel__text mb-16">
				Если оставить это поле пустым, умолчательное название файла будет:
				<b>{{ fileName }}</b>
			</p>
			<p v-else class="export-excel__text mb-16">
				Название файла будет:
				<b>{{ changedValue }}</b>
			</p>
		</div>
	</UiDialog>
</template>

<script>
export default {
	name: 'ExportToExcelModal',
	props: {
		fileName: {
			type: String,
			default: ''
		},
		multiple: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		changedValue: null
	}),
	methods: {
		show() {
			return this.$refs.exportToExcelModal.show();
		},
		close() {
			this.changedValue = null;
			return this.$refs.exportToExcelModal.closeModal();
		},
		changeExcelName(val) {
			this.changedValue = val;
			this.$emit('changeExcelName', val);
		}
	},
};
</script>
