var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-comments__list"
  }, [_c('div', {
    staticClass: "ui-comments__list-header"
  }, [_vm._t("header", function () {
    return [_c('h3', {
      staticClass: "ui-comments__list-title"
    }, [_vm._v(" " + _vm._s(_vm.commentsTitle) + " "), _c('span', {
      staticClass: "ui-comments__list-title span"
    }, [_vm._v(" (" + _vm._s(_vm.countComments) + ") ")])])];
  }, null, {
    commentsTitle: _vm.commentsTitle
  })], 2), _c('UiSkeleton', {
    staticClass: "ui-comments__list-skeleton",
    attrs: {
      "showMode": "if",
      "loadingStyle": _vm.skeletonStyles,
      "loading": _vm.currentComments.loader,
      "height": "300px"
    }
  }, [_vm.hasComments ? _c('div', {
    staticClass: "ui-comments__list-items"
  }, _vm._l(_vm.currentComments.items, function (frame, idx) {
    return _c('div', {
      key: idx,
      staticClass: "ui-comments__item"
    }, [_c('UiContentFrame', {
      staticClass: "ui-comments__item-frame",
      attrs: {
        "data": _vm.commentItem,
        "isComment": "",
        "commentsEnale": _vm.commentsEnale,
        "isOwner": _vm.isOwner,
        "frame-content": frame
      },
      on: {
        "answer": function ($event) {
          return _vm.selectAnswer(frame);
        },
        "delete": _vm.deleteComment
      }
    }), frame.commentId === _vm.answerItem.parentId ? _c('div', {
      staticClass: "ui-comments__item-answer add-answer"
    }, [_c('UiForm', {
      staticClass: "add-answer__form",
      attrs: {
        "data": _vm.answerItem,
        "fields": _vm.answerFields
      },
      on: {
        "change": function (data) {
          return _vm.setAnswerItem(data);
        }
      }
    }), _c('div', {
      staticClass: "add-answer__actions"
    }, [_c('UiButton', {
      staticClass: "add-answer__actions-cancel",
      attrs: {
        "label": "Отмена",
        "size": "xs"
      },
      on: {
        "click": _vm.clearAnswer
      }
    }), _c('UiButton', {
      staticClass: "add-answer__actions-submit",
      attrs: {
        "label": "Ответить",
        "size": "xs",
        "disabled": _vm.canSubmitAnswer
      },
      on: {
        "click": _vm.submitAnswer
      }
    })], 1)], 1) : _vm._e(), _vm._l(_vm.getChildrenComments(frame), function (frame, idx) {
      return _c('div', {
        key: idx,
        staticClass: "ui-comments__item-childrens"
      }, [_c('UiContentFrame', {
        staticClass: "ui-comments__item-frame",
        attrs: {
          "data": _vm.commentItem,
          "isOwner": _vm.isOwner,
          "commentsEnale": _vm.commentsEnale,
          "isComment": "",
          "frame-content": frame
        },
        on: {
          "answer": function ($event) {
            return _vm.selectAnswer(frame);
          }
        }
      }), frame.commentId === _vm.answerItem.parentId ? _c('div', {
        staticClass: "ui-comments__item-answer add-answer"
      }, [_c('UiForm', {
        staticClass: "add-answer__form",
        attrs: {
          "data": _vm.answerItem,
          "fields": _vm.answerFields
        },
        on: {
          "change": function (data) {
            return _vm.setAnswerItem(data);
          }
        }
      }), _c('div', {
        staticClass: "add-answer__actions"
      }, [_c('UiButton', {
        staticClass: "add-answer__actions-cancel",
        attrs: {
          "label": "Отмена",
          "size": "xs"
        },
        on: {
          "click": _vm.clearAnswer
        }
      }), _c('UiButton', {
        staticClass: "add-answer__actions-submit",
        attrs: {
          "label": "Ответить",
          "size": "xs",
          "disabled": _vm.canSubmitAnswer
        },
        on: {
          "click": _vm.submitAnswer
        }
      })], 1)], 1) : _vm._e()], 1);
    })], 2);
  }), 0) : _c('div', {
    staticClass: "ui-comments__list-items no-items"
  }, [_vm._v(" " + _vm._s(_vm.noCommentsText) + " ")])]), _vm.isPaginationVisible ? _c('UiPagination', {
    staticClass: "ui-comments__pagination",
    attrs: {
      "currentPage": _vm.currentPage,
      "total": _vm.totalCount,
      "pageSize": _vm.pageSize
    },
    on: {
      "changePage": function (number) {
        return _vm.currentPage = number;
      }
    }
  }) : _vm._e(), _vm.commentsEnale ? _c('div', {
    staticClass: "ui-comments__comment-form"
  }, [_c('UiForm', {
    staticClass: "add-comment__form",
    attrs: {
      "data": _vm.commentItem,
      "fields": _vm.commentFields
    },
    on: {
      "change": function (data) {
        return _vm.setCommentItem(data);
      }
    }
  }), _c('UiButton', {
    staticClass: "add-comment__form-submit",
    attrs: {
      "label": "Отправить комментарий",
      "pending": _vm.commentLoader,
      "disabled": _vm.canSubmit,
      "prepend-icon": "arrow-up"
    },
    on: {
      "click": _vm.submitComment
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }