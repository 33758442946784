
import { getProjects } from "@/services/projects/projectsList";
import { PITCHES, PROJECTS } from '@/router/routes/names';

export default {
	namespaced: true,

	state: {
		pitches: {
			items: [],
			loader: true,
		},
		projects: {
			items: [],
			loader: true,
		},
	},
	actions: {
		async actionGetMainProjects({ commit }) {
			commit('mutationSetMainProjectsLoader', true);
			return getProjects(PROJECTS, 1, 4, Object.entries({ sortBy: 'actual' })).then((res) => {
				commit('mutationSetMainProjects', res?.items ?? []);
			})
		},
		async actionGetMainPitches({ commit }) {
			commit('mutationSetMainPitchesLoader', true);
			return getProjects(PITCHES, 1, 4, Object.entries({ sortBy: 'ACTUAL' })).then((res) => {
				commit('mutationSetMainPitches', res?.items ?? []);
			})
		},
	},
	mutations: {
		mutationSetMainProjects(state, data) {
			state.projects.items = data;
			state.projects.loader = false;
		},
		mutationSetMainPitches(state, data) {
			state.pitches.items = data;
			state.pitches.loader = false;
		},
		mutationSetMainProjectsLoader(state, bool) {
			state.projects.loader = bool;
		},
		mutationSetMainPitchesLoader(state, bool) {
			state.pitches.loader = bool;
		},
	},
	getters: {
		mainPitchesGetter(state) {
			return state.pitches.items;
		},
		mainProjectsGetter(state) {
			return state.projects.items;
		},
		mainPitchesLoader(state) {
			return state.pitches.loader;
		},
		mainProjectsLoader(state) {
			return state.projects.loader;
		},
	},
};