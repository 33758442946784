var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-checkbox__container"
  }, [_c('div', _vm._g({
    ref: "focusable",
    staticClass: "custom-checkbox-form",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "focus": function ($event) {
        return _vm.$emit('focus');
      },
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) { return null; }
        $event.preventDefault();
        return function (e) {
          e.currentTarget.click();
          e.currentTarget.focus();
        }.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) { return null; }
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) { return null; }
        $event.preventDefault();
        return function (e) {
          e.currentTarget.click();
          e.currentTarget.focus();
        }.apply(null, arguments);
      }]
    }
  }, _vm.listeners), [_c('label', {
    staticClass: "form-switch",
    class: !!_vm.isError ? 'custom-checkbox__error' : ''
  }, [_c('input', {
    staticClass: "hidden-checkbox",
    attrs: {
      "id": _vm.linkIdValue,
      "disabled": _vm.disabled,
      "type": "checkbox",
      "name": "b28-1",
      "tabindex": "-1"
    },
    domProps: {
      "checked": _vm.isChecked
    },
    on: {
      "change": _vm.updateInput
    }
  }), _c('i'), _vm._v(_vm._s(_vm.label) + " ")])]), _c('div', [_vm.isError ? _c('span', {
    staticClass: "input-caption",
    class: _vm.isError ? 'input-caption__error' : ''
  }, [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }