const emptyUcp = {
	typeCode: null,
	name: null,
	price: null,
	amount: null,
	maturityTerm: null,
	description: null,
};

export default {
	namespaced: true,

	actions: {
		actionApplyUcpProject({ state, dispatch }) {
			if (state.index !== null) {
				dispatch(
					'project/actionEditUcpProject',
					{
						ucp: state.ucp,
						index: state.index,
					},
					{ root: true },
				);
			} else {
				dispatch('project/actionAddUcpProject', state.ucp, {
					root: true,
				});
			}
			dispatch('actionClearUcpProject');
		},
		actionSetEditUcpProject({ state }, { item, index }) {
			state.ucp = item;
			state.index = index;
			state.showForm = true;
		},
		actionClearUcpProject({ state }) {
			state.ucp = emptyUcp;
			state.index = null;
			state.showForm = false;
		},
		actionPutUcpProject({ state }, data) {
			state.ucp = data;
		},
		actionShowFormUcpProject({ state }) {
			state.showForm = true;
		},
	},

	state: {
		showForm: false,
		index: null,
		ucp: emptyUcp,
	},

	getters: {
		getterShowForm(state) {
			return state.showForm;
		},
		getterUcp(state) {
			return state.ucp;
		},
		getterIsNewRow(state) {
			return state.index === null;
		},
	},
};
