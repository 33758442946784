<template>
	<div class="ui-bookmarks">
		<div
			v-if="showTitle"
			class="ui-bookmarks__title-block ui-bookmarks__item"
		>
			<h2 class="ui-bookmarks__title-name">
				<slot name="title">
					<div class="flex">
						<UiNav class="ui-bookmarks__nav mr-12" navBtnLabel=""/>
						{{ title }}
					</div>
				</slot>
			</h2>
		</div>

		<div v-if="!titleOnly" :class="bookmarkClasses">
			<ul ref="bookmarksList" class="ui-bookmarks__list">
				<slot name="button"/>
				<router-link
					v-for="bookmark in bookmarks"
					:key="bookmark.name"
					v-slot="{ href, navigate }"
					:to="bookmark.to"
					custom
				>
					<li
						:class="getListItemClasses(bookmark)"
					>
						<a :href="href" class="ui-bookmarks__list-link" @click="navigate">
							<template v-if="hasSlot(bookmark)">
								<slot :name="bookmark.slot" :bookmark="bookmark" />
							</template>
							<template v-else>
								{{ bookmark.name }}
								<div v-if="bookmark?.read" class="read-mark ml-4"/>
							</template>
						</a>
					</li>
				</router-link>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'UiBookmarks',
	props: {
		/**
		 * Массив объектов закладок, принимает значение объектов
		 * с полями: name, to (название пути и ссылка соответственно)
		 */
		bookmarks: {
			type: Array,
		},
		/**
		 * Видимость тайтла букмарков
		 */
		showTitle: {
			type: Boolean,
			default: true
		},
		/**
		 * Параметр для селекта по имени параметра, если букмарки на одном роуте
		 */
		paramsQuery: {
			type: String,
			default: ''
		},
		/**
		 * Заголовок закладок
		 */
		title: String,
		/**
		 * Показывать только тайтл букмарков
		 */
		titleOnly: {
			type: Boolean,
			default: false
		},
		/**
		 * Функция определения выделенности закладки
		 * API: function(bookmarkItem): boolean
		 */
		isSelected: {
			type: Function,
			default: null
		},
	},
	computed: {
		bookmarkClasses() {
			return {
				'ui-bookmarks__bookmarks-block': true,
				'ui-bookmarks__item': true,
			}
		}
	},
	methods: {
		isSelectedInternal(bookmark) {
			if (!!this.isSelected) return this.isSelected(bookmark);
			if (this.paramsQuery) {
				return bookmark.to.params[this.paramsQuery] === this.$route.params[this.paramsQuery]
			} 
			return bookmark.to.name === this.$route.name;
		},
		hasSlot(bookmark) {
			return !!this.$scopedSlots[bookmark?.slot];
		},
		getListItemClasses(bookmark) {
			return {
				'ui-bookmarks__list-item': true,
				'ui-bookmarks__list-item--selected': this.isSelectedInternal(bookmark),
				'w-100': bookmark.class === 'w-100'
			}
		},
	}
};
</script>
