var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form"
  }, [_vm._l(_vm.fieldsRows, function (row, rowIdx) {
    return [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm._fieldsInRow(row).some(function (fld) {
          return _vm._isFieldVisible(fld);
        }),
        expression: "_fieldsInRow(row).some((fld) => _isFieldVisible(fld))"
      }],
      key: ("f" + (_vm.formId) + "-r" + rowIdx),
      staticClass: "form__row"
    }, _vm._l(_vm._fieldsInRow(row), function (fld, fldIdx) {
      var _vm$_getDataByProvide;
      return _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm._isFieldVisible(fld),
          expression: "_isFieldVisible(fld)"
        }],
        key: ("f" + (_vm.formId) + "-r" + rowIdx + "-c" + fldIdx + "-n" + (_vm._getFieldProperty(fld, 'name'))),
        staticClass: "form__col",
        class: fld.colClass,
        style: _vm._getColStyle(row, fldIdx),
        attrs: {
          "id": ("form-col-" + (_vm._getFieldProperty(fld, 'name')))
        }
      }, [fld.type === 'string' ? _c('UiInput', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "placeholder": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "nowrap": _vm._isTableListFormat(),
          "disabled": _vm._isFieldDisabled(fld),
          "maxLength": _vm._getFieldPropertyFn(fld, 'maxLength'),
          "mask": _vm._getFieldPropertyFn(fld, 'mask'),
          "size": _vm._getFieldPropertyFn(fld, 'size'),
          "use-masked-value": _vm._getFieldProperty(fld, 'useMaskedValue'),
          "clearable": _vm._getFieldPropertyFn(fld, 'clearable', true),
          "error": _vm._getFieldError(fld),
          "model-value": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "emitValue": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'text' ? _c('UiInput', {
        attrs: {
          "type": "textarea",
          "label": _vm._getFieldLabel(fld),
          "placeholder": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "resize": true,
          "maxLength": _vm._getFieldPropertyFn(fld, 'maxLength'),
          "size": _vm._getFieldPropertyFn(fld, 'size'),
          "nowrap": _vm._isTableListFormat(),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldDisabled(fld),
          "error": _vm._getFieldError(fld),
          "model-value": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "emitValue": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : /^int(eger)?$/.test(fld.type) ? _c('UiNumberInput', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "placeholder": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldDisabled(fld),
          "nowrap": _vm._isTableListFormat(),
          "error": _vm._getFieldError(fld),
          "maxLength": _vm._getFieldPropertyFn(fld, 'maxLength'),
          "limitValue": _vm._getFieldPropertyFn(fld, 'limitValue'),
          "maxValue": _vm._getFieldProperty(fld, 'maxValue', 0),
          "fractions": 0,
          "thousants": _vm._getFieldProperty(fld, 'thousants', false),
          "allowNegative": _vm._getFieldProperty(fld, 'allowNegative', false),
          "value": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "input": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'money' ? _c('UiNumberInput', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "placeholder": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldDisabled(fld),
          "nowrap": _vm._isTableListFormat(),
          "error": _vm._getFieldError(fld),
          "maxLength": _vm._getFieldPropertyFn(fld, 'maxLength'),
          "limitValue": _vm._getFieldPropertyFn(fld, 'limitValue'),
          "maxValue": _vm._getFieldProperty(fld, 'maxValue', 0),
          "fractions": 2,
          "thousants": _vm._getFieldProperty(fld, 'thousants', true),
          "allowNegative": _vm._getFieldProperty(fld, 'allowNegative', false),
          "value": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "input": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'number' ? _c('UiNumberInput', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "placeholder": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldDisabled(fld),
          "nowrap": _vm._isTableListFormat(),
          "error": _vm._getFieldError(fld),
          "fractions": _vm._getFieldProperty(fld, 'fractions', 0),
          "thousants": _vm._getFieldProperty(fld, 'thousants', true),
          "maxLength": _vm._getFieldPropertyFn(fld, 'maxLength'),
          "limitValue": _vm._getFieldPropertyFn(fld, 'limitValue'),
          "maxValue": _vm._getFieldProperty(fld, 'maxValue', 0),
          "allowNegative": _vm._getFieldProperty(fld, 'allowNegative', true),
          "value": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "input": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'date' ? _c('UiDate', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "placeholder": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldDisabled(fld),
          "nowrap": _vm._isTableListFormat(),
          "error": _vm._getFieldError(fld),
          "value": _vm._getObjVal(fld)
        },
        on: {
          "change": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'password' ? _c('UiInput', {
        attrs: {
          "type": "password",
          "label": _vm._getFieldLabel(fld),
          "placeholder": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldDisabled(fld),
          "clearable": _vm._getFieldPropertyFn(fld, 'clearable', true),
          "error": _vm._getFieldError(fld),
          "model-value": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "emitValue": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'button' ? _c('UiButton', {
        staticStyle: {
          "flex": "inherit"
        },
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "disabled": _vm._isFieldReadonly(fld) || _vm._isFieldDisabled(fld),
          "size": _vm._getFieldProperty(fld, 'size'),
          "type": _vm._getFieldProperty(fld, 'btnType'),
          "block": _vm._getFieldProperty(fld, 'block'),
          "prependIcon": _vm._getFieldPropertyFn(fld, 'prependIcon'),
          "prependIconColor": _vm._getFieldPropertyFn(fld, 'prependIconColor'),
          "appendIcon": _vm._getFieldPropertyFn(fld, 'appendIcon'),
          "appendIconColor": _vm._getFieldPropertyFn(fld, 'appendIconColor'),
          "pending": _vm._getFieldPropertyFn(fld, 'pending'),
          "href": _vm._getFieldPropertyFn(fld, 'href'),
          "to": _vm._getFieldPropertyFn(fld, 'to'),
          "error": _vm._getFieldError(fld)
        },
        on: {
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          },
          "click": function () {
            return _vm._onClick(fld);
          }
        }
      }) : fld.type === 'boolean' ? _c('UiCheckbox', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "description": _vm._getFieldPropertyFn(fld, 'hint'),
          "disabled": _vm._isFieldReadonly(fld) || _vm._isFieldDisabled(fld),
          "error": _vm._getFieldError(fld),
          "true-value": _vm._getFieldProperty(fld, 'trueValue', true),
          "false-value": _vm._getFieldProperty(fld, 'falseValue', false),
          "model-value": _vm._getObjVal(fld)
        },
        on: {
          "change": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'switch' ? _c('UiCheckboxSwitch', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "description": _vm._getFieldPropertyFn(fld, 'hint'),
          "disabled": _vm._isFieldReadonly(fld) || _vm._isFieldDisabled(fld),
          "error": _vm._getFieldError(fld),
          "true-value": _vm._getFieldProperty(fld, 'trueValue', true),
          "false-value": _vm._getFieldProperty(fld, 'falseValue', false),
          "model-value": _vm._getObjVal(fld)
        },
        on: {
          "change": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'select' ? _c('UiSelectNew', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "unselected-title": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "hint": _vm._getFieldPropertyFn(fld, 'hint'),
          "readonly": _vm._isFieldReadonly(fld),
          "nowrap": _vm._isTableListFormat(),
          "disabled": _vm._isFieldReadonly(fld) || _vm._isFieldDisabled(fld),
          "options": _vm._getDataByProvider(fld, 'options'),
          "multiple": _vm._getFieldPropertyFn(fld, 'multiple', false),
          "error": _vm._getFieldError(fld),
          "value": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "change": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'radio' ? _c('UiRadioGroup', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "hint": _vm._getFieldPropertyFn(fld, 'hint'),
          "horizontal": _vm._getFieldPropertyFn(fld, 'horizontal'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldReadonly(fld) || _vm._isFieldDisabled(fld),
          "options": _vm._getDataByProvider(fld, 'options'),
          "error": _vm._getFieldError(fld),
          "value": _vm._getObjVal(fld)
        },
        on: {
          "change": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'htmleditor' ? _c('UiQuillEditor', {
        attrs: {
          "label": _vm._getFieldLabel(fld),
          "caption": _vm._getFieldPropertyFn(fld, 'hint'),
          "size": _vm._getFieldPropertyFn(fld, 'size'),
          "readonly": _vm._isFieldReadonly(fld),
          "disabled": _vm._isFieldReadonly(fld) || _vm._isFieldDisabled(fld),
          "error": _vm._getFieldError(fld),
          "value": _vm._getObjVal(fld)
        },
        on: {
          "change": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          }
        }
      }) : fld.type === 'file' ? _c('UiFile', {
        attrs: {
          "title": _vm._getFieldLabel(fld),
          "error": _vm._getFieldError(fld),
          "subtitle": _vm._getFieldPropertyFn(fld, 'hint'),
          "tips": _vm._getFieldPropertyFn(fld, 'placeholder'),
          "accept": _vm._getFieldPropertyFn(fld, 'accept', ''),
          "accept-types": _vm._getFieldPropertyFn(fld, 'acceptTypes', ''),
          "mode": _vm._isFieldReadonly(fld) ? 'view' : 'edit',
          "preview": _vm._getFieldPropertyFn(fld, 'preview', false),
          "width": _vm._getFieldPropertyFn(fld, 'width'),
          "height": _vm._getFieldPropertyFn(fld, 'height'),
          "multiple": _vm._getFieldPropertyFn(fld, 'multiple', false),
          "upload-handler": _vm._getFieldProperty(fld, 'uploadHandler', null),
          "delete-handler": _vm._getFieldProperty(fld, 'deleteHandler', null),
          "prop-mapper": _vm._getFieldProperty(fld, 'propMapper', null),
          "event-mapper": _vm._getFieldProperty(fld, 'eventMapper', null),
          "mix-data": _vm._getFieldProperty(fld, 'mixData', null),
          "files": _vm._getObjVal(fld),
          "tip": _vm._getFieldTip(fld)
        },
        on: {
          "changeValue": function (val) {
            return _vm._setObjVal(fld, val);
          },
          "focus": function () {
            return _vm._onFocusField(fld);
          },
          "blur": function () {
            return _vm._onBlurField(fld);
          },
          "uploaded": _vm._onFileUploaded
        }
      }) : fld.type === 'array' ? _c('UiArrayInput', {
        attrs: {
          "value": _vm._getObjVal(fld, []),
          "error": _vm._getFieldError(fld),
          "label": _vm._getFieldLabel(fld),
          "addModalTitle": _vm._getFieldPropertyFn(fld, 'addModalTitle'),
          "updateModalTitle": _vm._getFieldPropertyFn(fld, 'updateModalTitle'),
          "buttonLabel": _vm._getFieldPropertyFn(fld, 'buttonLabel'),
          "columns": _vm._getFieldPropertyFn(fld, 'columns'),
          "fields": _vm._getFieldProperty(fld, 'fields'),
          "readonly": _vm._getFieldPropertyFn(fld, 'readonly'),
          "tipsProviderKey": _vm._getFieldPropertyFn(fld, 'tipsProviderKey')
        },
        on: {
          "change": function (val) {
            return _vm._setObjVal(fld, val);
          }
        },
        scopedSlots: _vm._u([_vm._l(_vm._getInnerSlotNames(fld.name), function (slotName) {
          return {
            key: slotName,
            fn: function (slotAttrs) {
              return [_vm._t(((fld.name) + ":" + slotName), null, null, slotAttrs)];
            }
          };
        })], null, true)
      }) : fld.type === 'table' ? _c('div', [_c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm._getFieldLabel(fld),
          expression: "_getFieldLabel(fld)"
        }],
        staticClass: "form__table__label"
      }, [_vm._v(_vm._s(_vm._getFieldLabel(fld)))]), _c('div', {
        staticClass: "form__table__frame",
        class: _vm._hasFieldError(fld) ? 'form__table__frame__error' : ''
      }, [_c('UiTable', {
        ref: ("table#" + (fld.name)),
        refInFor: true,
        attrs: {
          "columns": _vm._getDataByProvider(fld, 'columns'),
          "items": (_vm$_getDataByProvide = _vm._getDataByProvider(fld, 'items')) !== null && _vm$_getDataByProvide !== void 0 ? _vm$_getDataByProvide : []
        },
        scopedSlots: _vm._u([_vm._l(_vm._getInnerSlotNames(fld.name), function (slotName) {
          return {
            key: slotName,
            fn: function (slotAttrs) {
              return [_vm._t(((fld.name) + ":" + slotName), null, null, slotAttrs)];
            }
          };
        })], null, true)
      })], 1), _vm._hasFieldError(fld) ? _c('div', {
        staticClass: "form__table__error"
      }, [_vm._v(" " + _vm._s(_vm._getFieldError(fld)) + " ")]) : _vm._e()]) : fld.type === 'subtitle' ? _c('div', [_c('h4', {
        ref: ("subt#" + (fld.name)),
        refInFor: true,
        staticClass: "form__subtitle"
      }, [_vm._v(" " + _vm._s(_vm._getFieldLabel(fld)) + " ")])]) : fld.type === 'block' ? _c('UiBlock', {
        attrs: {
          "title": _vm._getFieldLabel(fld),
          "tip": _vm._getFieldTip(fld),
          "showVisibilityToggle": _vm._getFieldProperty(fld, 'showVisibilityToggle'),
          "visible": _vm._getFieldPropertyFn(fld, 'visible')
        }
      }, [_c('UiForm', {
        ref: ("form#" + (fld.name)),
        refInFor: true,
        attrs: {
          "fields": _vm._getFieldProperty(fld, 'fields', []),
          "data": _vm.dataObj,
          "disableErrorScroll": _vm.disableErrorScroll,
          "name": _vm._getHierarchyFieldName(fld.name),
          "tips-provider": _vm._getHierarchyTipsProvider(fld.name),
          "prepare-errors": _vm.prepareErrors
        },
        on: {
          "change": _vm._setObj,
          "focus": function (fld0) {
            return _vm._onFocusField(fld0);
          },
          "blur": function (fld0) {
            return _vm._onBlurField(fld0);
          },
          "onFileUploaded": _vm._onFileUploaded
        },
        scopedSlots: _vm._u([_vm._l(_vm._getInnerSlotNames(fld.name), function (slotName) {
          return {
            key: slotName,
            fn: function (slotAttrs) {
              return [_vm._t(((fld.name) + ":" + slotName), null, null, slotAttrs)];
            }
          };
        })], null, true)
      })], 1) : fld.type === 'slot' ? _c('div', [_vm._t(fld.name, null, {
        "data": _vm.dataObj,
        "error": _vm._getFieldError(fld),
        "errorsObj": _vm.errorsObj
      })], 2) : _vm._e()], 1);
    }), 0)];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }