/**
 * Функция-обертка для асинхронных операций и управления флагом ожидания ахинхронного ответа (loading).
 * Позволяет формировать вызовы асинхронных операций с автоматическим включением флага.
 * Вызывая переданую функция, возвращает асинхронно ее результат.
 * @param {(boolean) => void} loadingSetter сеттер флага ожидания результата (loading)
 * @param {(...) => Promise} asyncAction функция, возвращающая асинхронных результат (Promise)
 * @param  {...any} args аргументы функции asyncAction
 * @returns {Promise} асинхронный результат asyncAction
 */
export function asyncLoading(loadingSetter, asyncAction, ...args) {
	loadingSetter(true); // включили загрузку
	return new Promise((resolve, reject) => {
		asyncAction(...args)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				reject(err);
			})
			.finally(() => {
				loadingSetter(false); // выключили загрузку
			})
	})
}

/**
 * Функция-обертка для промисов с управлением флагом ожидания ахинхронного ответа (loading).
 * Включает флаг асинхронного ожидания (loading) до разрешения переданного промиса.
 * Возвращает результат переданного промиса.
 * @param {(boolean) => void} loadingSetter сеттер флага ожидания результата (loading)
 * @param {Promise} promise активный промис, который нужно "обернуть"
 * @returns {Promise} асинхронный результат переданного промиса promise
 */
export function promiseLoading(loadingSetter, promise) {
	loadingSetter(true); // включили загрузку
	return new Promise((resolve, reject) => {
		promise
			.then(res => resolve(res))
			.catch(err => reject(err))
			.finally(() => loadingSetter(false)) // выключили загрузку
	})
}
