import Requests from '@utils/Requests';

const METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
};

export default class RequestBuilder {
	/**
	 * @private
	 * @type {object}
	 * */
	#config = {};

	/**
	 * @private
	 * @type {!string}
	 * */
	#url;

	/**
	 * @private
	 * @type {!boolean}
	 */
	#credentials = true;

	/**
	 * @private
	 * @type {?string}
	 */
	#method;

	/**
	 * @private
	 * @type {?string}
	 */
	#baseURL = '/';

	/**
	 * @return {RequestBuilder}
	 */
	get get() {
		this.#method = METHODS.GET;
		return this;
	}

	/**
	 * @return {RequestBuilder}
	 */
	get post() {
		this.#method = METHODS.POST;
		return this;
	}

	/**
	 * @return {RequestBuilder}
	 */
	get put() {
		this.#method = METHODS.PUT;
		return this;
	}

	/**
	 * @return {RequestBuilder}
	 */
	get delete() {
		this.#method = METHODS.DELETE;
		return this;
	}

	/**
	 * Disable credentials for request
	 *
	 * @return {RequestBuilder}
	 */
	get public() {
		this.#credentials = false;
		return this;
	}

	/**
	 * @constructor
	 * @param url {string}
	 */
	constructor(url) {
		this.#url = url;
	}

	/**
	 * Устанавливает заголовки, указанные переданным объектом
	 *
	 * @param {Object} values Объект, свойства которого содержат заголовки запроса
	 * @return {RequestBuilder}
	 */
	headers(values) {
		this.#config.headers = this.#config.headers ?? new Map();

		for (const [k, v] of Object.entries(values)) {
			if (k && v) {
				this.#config.headers.set(k, v);
			}
		}

		return this;
	}

	contentType(type) {
		this.headers({ "Content-Type": type });
		return this;
	}

	responseType(type) {
		this.#config.responseType = type;
		return this;
	}

	/**
	 * Get iterable object with tuple of record name and value.
	 *
	 * @param list {[K: string, V: string | number][]}
	 * @return {RequestBuilder}
	 */
	filters(list) {
		this.query(list);
		return this;
	}

	/**
	 * Get iterable object with tuple of record name and value.
	 *
	 * @param {[K: string, V: string | number][]} list 
	 * @return {RequestBuilder}
	 */
	query(list) {
		if (list == null) return this;

		let query = /\?/.test(this.#url) ? '&' : '?';

		for (const record of list) {
			if (record[0] && record[1]) {
				query += `${record[0]}=${record[1]}&`;
			}
		}

		this.#url += query.slice(0, query.length - 1);
		return this;
	}

	/**
	 * Set request url for request.
	 *
	 * @param url {string}
	 * @return {RequestBuilder}
	 */
	url(url) {
		this.#url = url;
		return this;
	}

	/**
	 * Set baseURL for request.
	 *
	 * @param baseURL {string}
	 * @return {RequestBuilder}
	 */
	baseURL(url) {
		this.#baseURL = url;
		return this;
	}

	/**
	 * Build request and send it.
	 *
	 * @param data {?Object}
	 * @return {Promise<any>}
	 */
	send(data = undefined) {
		return Requests.request(
			this.#method,
			this.#url,
			data,
			this.#config,
			this.#credentials,
			this.#baseURL
		);
	}
}
