import Requests from '@utils/Requests';
import api from '@configs/api';

export { getProject, getProjectsList, approve, reject, getPitches, approvePitch };

function getProjectsList(type,pageNumber, pageSize, status) {
	let queryParams = `pageNumber=${pageNumber}&pageSize=${pageSize}`;

	if (status != undefined && status != null) {
		queryParams += '&status=';

		queryParams +=
            status instanceof Array && status.length > 0
            	? status.join('&status=')
            	: status;
	}

	return Requests.requestWrapper(
		Requests.getRequest(
			`/${api.url}${type}/moderate?${queryParams}`,
			{},
			true
		)
	);
}

function getProject(id) {
	return Requests.requestWrapper(
		Requests.getRequest(`/${api.url}projects/${id}/moderate`, {}, true)
	);
}

function getPitches(id) {
	return Requests.requestWrapper(
		Requests.getRequest(`/${api.url}pitches/${id}/moderate`, {}, true)
	);
}

function approve(type,id, { rating, operatorFee }) {
	return Requests.requestWrapper(
		Requests.postRequest(
			`/${api.url}moderate/${type}/${id}/approve`,
			{ rating, operatorFee },
			{},
			true
		)
	);
}

function approvePitch(id) {
	return Requests.requestWrapper(
		Requests.postRequest(
			`/${api.url}moderate/pitches/${id}/approve`,
			{},
			{},
			true
		)
	);
}

function reject(type,id, reason) {
	return Requests.requestWrapper(
		Requests.postRequest(
			`/${api.url}moderate/${type}/${id}/reject`,
			{ reason },
			{},
			true
		)
	);
}
