import { postApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder, getApiRequestBuilder } from '@/utils/RequestBuilder';

/** Комментарии к новостям проекта */

export function createNewsCommentItem(projectId, newsId, data) {
	return postApiRequestBuilder(`projects/${projectId}/news/${newsId}/comments`).send(data);
}

export function createDiscussCommentItem(projectId, discussId, data) {
	return postApiRequestBuilder(`projects/${projectId}/discussions/${discussId}/comments`).send(data);
}

export function editNewsCommentItem(projectId, newsId, data) {
	return putApiRequestBuilder(`projects/${projectId}/news/${newsId}/comments`).send(data);
}

export function editDiscussCommentItem(projectId, discussId, data) {
	return putApiRequestBuilder(`projects/${projectId}/discussions/${discussId}/comments`).send(data);
}

export function deleteNewsCommentItem(projectId, newsId, commentId) {
	return deleteApiRequestBuilder(`projects/${projectId}/news/${newsId}/comments/${commentId}`).send();
}

export function deleteDiscussCommentItem(projectId, discussId, commentId) {
	return deleteApiRequestBuilder(`projects/${projectId}/discussions/${discussId}/comments/${commentId}`).send();
}

export function resetNewsCommentFlag(projectId, newsId) {
	return getApiRequestBuilder(`projects/${projectId}/news/${newsId}/comments/reset`).send();
}

export function getNewsDiscussionsUnreadNewCount() {
	return getApiRequestBuilder(`/projects/mine/news/count-new`).send();
}

export function resetDiscussCommentFlag(projectId, discussId) {
	return getApiRequestBuilder(`projects/${projectId}/discussions/${discussId}/comments/reset`).send();
}