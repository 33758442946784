import Requests from '../../utils/Requests';
import api from '../../configs/api';
import {getApiRequestBuilder, putApiRequestBuilder} from '@/utils/RequestBuilder';

const END_POINT_CHECK_USER = 'user/password/forgot';
const END_POINT_RESTORE_PASSWORD = 'user/password/request';
const END_POINT_GET_CHARACTERS = 'user/characters';
const END_POINT_SYNC_USER = 'user/data/sync';
const END_POINT_CHANGE_USER_DATA = 'user';

export async function syncUser() {
	return new Promise((res) => {
		setTimeout(async () => {
			res(
				await Requests.requestWrapper(
					Requests.getRequest(
						`/${api.url}${END_POINT_SYNC_USER}`,
						{},
						true
					)
				)
			);
		}, 0);
	});
}

export function hasUser(username) {
	return Requests.requestWrapper(
		Requests.postRequest(`/${api.url}${END_POINT_CHECK_USER}`, {username})
	);
}

export function changeUserData(user) {
	return Requests.requestWrapper(
		Requests.putRequest(`/${api.url}${END_POINT_CHANGE_USER_DATA}`, user, {}, true)
	);
}

export function restorePassword(reqId, password) {
	return Requests.requestWrapper(
		Requests.postRequest(
			`/${api.url}${END_POINT_RESTORE_PASSWORD}/${reqId}`,
			{password}
		)
	);
}

export function getCharacters() {
	return Requests.requestWrapper(
		Requests.getRequest(`/${api.url}${END_POINT_GET_CHARACTERS}`, {}, true)
	);
}

export function getUserInvestments() {
	return Requests.requestWrapper(
		Requests.getRequest(`/${api.url}user/investments`, {}, true)
	);
}

export function getUserDocuments(scope) {
	return Requests.requestWrapper(
		Requests.getRequest(`/${api.url}user/documents/${scope}`, {}, true)
	);
}

/** Заблокировать/разблокировать юзера */
export function userAccessControl(data) {
	return putApiRequestBuilder(`operator/user/enable`).send(data);
}

/** Заблокировать/разблокировать юзера */
export function adminUserAccessControl(data) {
	return putApiRequestBuilder(`admin/user/enable`).send(data);
}

/** Заблокировать/разблокировать юзера бизнес */
export function adminUserAccessControlBusiness(data) {
	return putApiRequestBuilder(`/admin/user/enable/business`).send(data);
}

/** Получить информацию о служебных уведомлениях в Центре уведомлений */
export async function getServiceNotificationsInfo() {
	return getApiRequestBuilder(`user/notifications`).send();
}

/** Получить информацию о всех типах уведомлений для пользователя */
export async function getServiceNotificationTypesInfo() {
	return getApiRequestBuilder(`user/notifications/types`).send();
}

/** Изменить активность уведомления */
export async function enableServiceNotificationTypes(data) {
	return putApiRequestBuilder(`user/notifications/types`).send(data);
}

/** Прочитать массив служебных уведомлений */
export async function readServiceNotification(notificationIds) {
	return putApiRequestBuilder(`user/notifications`).send({notificationIds});
}

/** Прочитать массив служебных уведомлений */
export async function readPlatformNotification(notificationIds) {
	return putApiRequestBuilder(`user/notifications/platform`).send({notificationIds});
}

/** Получить информацию о уведомлениях категорий в Центре уведомлений */
export async function getNewNotificationsInfo() {
	return getApiRequestBuilder(`user/notifications/un-read-count`).send();
}
