var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-progress-bar",
    style: {
      'border-radius': _vm.borderRadius,
      'background': 'var(--grey)'
    }
  }, [!_vm.isPublished && _vm.showProgressBarStatus ? _c('div', {
    staticClass: "ui-progress-bar__progress-status"
  }, [_vm.succesCollect ? _c('span', {
    staticClass: "progress-status__success"
  }, [_vm._v(" Средства собраны ")]) : _c('span', {
    staticClass: "progress-status__unsuccess"
  }, [_vm._v(" Средства не собраны ")])]) : _vm._e(), _c('div', {
    staticClass: "ui-progress-bar__progress",
    style: {
      'background': _vm.progressBarColor,
      'width': _vm.calculatedPercent + '%',
      'height': _vm.thickness + 'px',
      'border-radius': _vm.borderRadius
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }