<template>
	<div v-if="documents.length" class="ui-docs">
		<h3 class="ui-docs__title">{{ title }}</h3>
		<ul class="ui-docs__list">
			<li v-for="(doc, index) in documents" :key="index" class="ui-docs__item"> 
				<a :href="doc.content.link" class="ui-docs__item-link">
					{{ doc.content.file.name }}
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'UiDocs',
	props: {
		title: {
			type: String,
		},
		documents: {
			type: Array,
			required: true
		}
	}
}
</script>
