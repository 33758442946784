var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: Object.assign({
      'ui-content__frame': true
    }, _vm.frameHoveredClass),
    on: {
      "mouseover": function ($event) {
        return _vm.changeFrameHover(true);
      },
      "mouseleave": function ($event) {
        return _vm.changeFrameHover(false);
      },
      "click": _vm.emitClick
    }
  }, [_c('div', {
    staticClass: "ui-content__header"
  }, [!_vm.isComment ? _c('div', {
    staticClass: "ui-content__header-content"
  }, [_c('span', {
    class: Object.assign({
      'ui-content__date': true
    }, _vm.frameHoveredClass)
  }, [_vm._v(_vm._s(_vm.dataTime))]), !_vm.onlyInfo ? _c('div', {
    class: Object.assign({
      'ui-content__comments': true
    }, _vm.frameHoveredClass)
  }, [_c('UiSmartIcon', {
    staticClass: "ui-content__comments-icon",
    attrs: {
      "size": "18",
      "name": "icons-message-square",
      "color": _vm.contentColor
    }
  }), _c('span', {
    class: Object.assign({
      'ui-content__comments-count': true
    }, _vm.frameHoveredClass)
  }, [_vm._v(_vm._s(_vm.frameContent.countComments))])], 1) : _vm._e()]) : _c('div', {
    staticClass: "ui-content__header-comment"
  }, [_c('h3', {
    staticClass: "ui-content__comment-title"
  }, [_vm._v(" " + _vm._s(_vm.frameContent.fio) + " ")]), _c('div', {
    staticClass: "ui-content__subinfo-date"
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.dataTime) + ", ")]), _c('span', {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.hourTime))])]), _vm.canDeleteComment ? _c('UiSmartIcon', {
    staticClass: "ui-content__subinfo-del",
    attrs: {
      "name": "icons-trash",
      "color": "var(--greyscale-5)"
    },
    on: {
      "click": _vm.deleteComment
    }
  }) : _vm._e()], 1)]), !_vm.isComment ? _c('h4', {
    class: _vm.titleClasses
  }, [_vm._v(_vm._s(_vm.frameContent.title))]) : _vm._e(), _c('p', {
    staticClass: "ui-content__content"
  }, [_vm._v(_vm._s(_vm.content))]), _vm.isComment && _vm.commentsEnale ? _c('a', {
    staticClass: "ui-content__answer-btn",
    on: {
      "click": _vm.emitAnwer
    }
  }, [_vm._v(" Ответить ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }