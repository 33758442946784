<template>
	<Portal>
		<transition name="ui-modal--slide-fade" mode="out-in">
			<div v-if="show" class="ui-modal" :class="{'ui-modal__notify': isNotify}">
				<div class="ui-modal__overlay" @click="hideWindow"/>

				<div
					ref="modalContent"
					tabindex="-1"
					class="ui-modal__content custom-scrollbar custom-scrollbar--small"
					:style="styleStr"
				>
					<UiSkeleton style="width: 100%"
						:loading="skeletonLoading"
						:height="skeletonHeight"
						:message="skeletonMessage">
						<slot/>
					</UiSkeleton>
				</div>

			</div>
		</transition>
	</Portal>
</template>

<script>
import useFocusTab from '@utils/focusTab';

export default {
	name: 'UiModal',

	provide: {
		scope: 'dialog',
	},

	props: {
		show: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
		},
		height: {
			type: String,
			default: '',
		},
		styles: {
			type: String,
			default: '',
		},
		closeByClickOutside: {
			type: Boolean,
			default: true,
		},
		isNotify: {
			type: Boolean,
			default: false,
		},
		skeletonMessage: {
			type: String,
		},
		skeletonLoading: {
			type: Boolean,
			default: false,
		},
		skeletonHeight: {
			type: String,
			default: '463px'
		},
	},

	setup() {
		const focusTab = useFocusTab();
		return {focusTab};
	},

	data() {
		return {
			previouslyFocused: null,
		};
	},

	computed: {
		styleStr() {
			const ss = [`width:${this.width}`, `height:${this.height}`, this.styles];
			return ss.filter((s) => s).join(';');
		},
	},

	watch: {
		show(isOpen) {
			if (isOpen) {
				this.onModalOpen();
			} else {
				this.onModalClose();
			}
		},
	},

	beforeDestroy() {
		this.onModalClose();
	},

	methods: {
		/**
     *  @param {KeyboardEvent} e
     */
		onKeypress(e) {
			const {shiftKey, key} = e;

			if (!shiftKey && key === 'Tab') {
				e.preventDefault();
				this.focusTab.move('next');
			} else if (shiftKey && key === 'Tab') {
				e.preventDefault();
				this.focusTab.move('prev');
			} else if (key === 'Escape') {
				e.preventDefault();
				this.hideWindow();
			}
		},

		hideWindow() {
			if (this.closeByClickOutside) this.$emit('show', false);
		},

		onModalClose() {
			document.body.style.overflow = 'auto';
			document.body.style.paddingRight = '0';
			window.removeEventListener('keydown', this.onKeypress);
			this.previouslyFocused?.focus();
		},

		async onModalOpen() {
			const scrollbarWidth =
          window.innerWidth - document.documentElement.clientWidth;

			document.body.style.overflow = 'hidden';
			document.body.style.paddingRight = `${scrollbarWidth}px`;

			window.addEventListener('keydown', this.onKeypress);

			this.previouslyFocused = document.activeElement;

			await this.$nextTick();
			setTimeout(() => {
				this.focusTab.init(this.$refs.modalContent)
			}, 200);
		}
	},
};
</script>
