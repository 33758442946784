export const SERVICE_MESSAGE_GROUP_CODE = 'SERVICE_MESSAGE';
export const PLATFORM_NEWS_MESSAGE_GROUP_CODE = 'PLATFORM_NEWS_MESSAGE';

export const SMS_NOTIFICATION_CHANNEL_CODE = 'SMS';
export const EMAIL_NOTIFICATION_CHANNEL_CODE = 'EMAIL';
export const PUSH_NOTIFICATION_CHANNEL_CODE = 'PUSH_NOTIFICATION';

export const SMS_NOTIFICATION_CHANNEL_NAME = 'СМС';
export const EMAIL_NOTIFICATION_CHANNEL_NAME = 'Электронная почта';
export const PUSH_NOTIFICATION_CHANNEL_NAME = 'Личный кабинет';

/**
 * Человеческие названия каналов уведомления
 * @type {{
 * 	[PUSH_NOTIFICATION_CHANNEL_CODE]: string,
 * 	[SMS_NOTIFICATION_CHANNEL_CODE]: string,
 * 	[EMAIL_NOTIFICATION_CHANNEL_CODE]: string
 * }}
 */
export const notificationChannelCodeToNameMapper = {
	[EMAIL_NOTIFICATION_CHANNEL_CODE]: EMAIL_NOTIFICATION_CHANNEL_NAME,
	[SMS_NOTIFICATION_CHANNEL_CODE]: SMS_NOTIFICATION_CHANNEL_NAME,
	[PUSH_NOTIFICATION_CHANNEL_CODE]: PUSH_NOTIFICATION_CHANNEL_NAME
};
