<template>
	<div>
		<notifications
			position="bottom right"
			width="350px"
			classes="custom-notify"
			:duration="4000"
		>
			<template #body="{ item }">
				<div :class="getClasses(item)">
					<div class="notification-title">{{ item.title }}</div>
					<div class="notification-content">
						<button
							v-if="item.text && item.type === 'error'"
							class="custom-notify__button"
							@click="openErrorDialog(item)"
						>
							Подробнее
						</button>
						<template v-else>
							{{ item.text }}
						</template>
					</div>
				</div>
			</template>
		</notifications>
		<UiDialog ref="dialog" withoutFooter isNotify :title="errorTitle" width="80vw">
			<UiPopup 
				v-if="errorMessage" 
				type="error" 
				class="mt-16" 
				:title="errorMessage"
			>
				<template #title>
					<div class="whitespace-pre-wrap break-words">
						{{ errorMessage }}
					</div>
				</template>
			</UiPopup>
		</UiDialog>
	</div>
</template>

<script>
import { ERROR, SUCCESS, WARN } from '@/configs/ui';

export default {
	name: 'UiNotify',

	data() {
		return {
			errorTitle: '',
			errorMessage: '',
		};
	},

	computed: {},

	methods: {
		openErrorDialog(item) {
			this.$refs.dialog.show();
			this.errorMessage = item.text || 'Отсутсвует описание ошибки';
			this.errorTitle = item.title;
		},
		getClasses(notification) {
			return [
				'vue-notification-template custom-notify',
				{
					warn: notification.type === WARN,
					error: notification.type === ERROR,
					success: notification.type === SUCCESS,
				},
			];
		},
	},
};
</script>
