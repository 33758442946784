var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showEntityConditionStatus ? _c('div', {
    class: {
      'entity-status': true,
      'entity-status--warn': _vm.isParticipantRejected
    }
  }, [_c('h3', {
    staticClass: "entity-status__title",
    class: {
      'entity-status__title--warn': _vm.isParticipantRejected || _vm.isEntityBlocked,
      'entity-status__title--moderation': _vm.isParticipantModeration
    }
  }, [_c('UiSmartIcon', {
    staticClass: "entity-status__title-icon",
    attrs: {
      "name": _vm.conditionStatus.icon,
      "color": _vm.entityIconColor,
      "size": 20
    }
  }), _vm._v(" " + _vm._s(_vm.conditionStatus.title) + " ")], 1), _vm.isEntityRejected ? _c('div', {
    staticClass: "entity-status__reject-block"
  }, [_vm.entity.rejectReason ? _vm._t("default", function () {
    return [_c('p', {
      staticClass: "entity-status__reject-reason",
      domProps: {
        "innerHTML": _vm._s(_vm.convertedString(_vm.entity.rejectReason))
      }
    })];
  }, null, {
    entity: _vm.entity,
    rejectReason: _vm.convertedString(_vm.entity.rejectReason)
  }) : _c('p', {
    staticClass: "entity-status__reject-reason",
    domProps: {
      "innerHTML": _vm._s(_vm.convertedString('- Причина не указана'))
    }
  }), _c('div', {
    staticClass: "entity-status__buttons"
  }, [_vm.enableEditButton ? _c('UiButton', {
    staticClass: "entity-status__reject-btn",
    attrs: {
      "label": "Редактировать анкету",
      "size": "xs"
    },
    on: {
      "click": _vm.submitToEdit
    }
  }) : _vm._e(), _c('UiButton', {
    staticClass: "entity-status__reject-btn",
    attrs: {
      "label": "Подать апелляцию",
      "type": "secondary",
      "size": "xs"
    },
    on: {
      "click": _vm.submitAppeal
    }
  })], 1)], 2) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }