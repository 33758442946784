var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.documents.length ? _c('div', {
    staticClass: "ui-docs"
  }, [_c('h3', {
    staticClass: "ui-docs__title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('ul', {
    staticClass: "ui-docs__list"
  }, _vm._l(_vm.documents, function (doc, index) {
    return _c('li', {
      key: index,
      staticClass: "ui-docs__item"
    }, [_c('a', {
      staticClass: "ui-docs__item-link",
      attrs: {
        "href": doc.content.link
      }
    }, [_vm._v(" " + _vm._s(doc.content.file.name) + " ")])]);
  }), 0)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }