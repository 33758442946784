import { getFile, getFileBySignRequest } from '@services/files';
import { createSignature } from '@services/crypto';
import { postApiRequestBuilder } from '@utils/RequestBuilder';
import { SIGNATURE_TYPES } from '@configs/documents';

export class SignatureBuilder {
	signatureType = SIGNATURE_TYPES.QUALIFIED;
	signerId;
	signerType;

	signatures;

	constructor() {}

	error(message) {
		throw new Error(`SignatureBuilder: ${message}`);
	}

	setSignerType(signerType) {
		this.signerType = signerType;
		return this;
	}

	setSignatureType(signatureType) {
		this.signatureType = signatureType;
		return this;
	}

	setSignerId(signerId) {
		this.signerId = signerId;
		return this;
	}

	setThumbprint(thumbprint) {
		this.thumbprint = thumbprint;
		return this;
	}

	document(document) {
		return this.documents([document]);
	}

	async documents(docs) {
		if (!this.thumbprint) {
			this.error('для вызова `documents` требуется `thumbprint`');
		}

		const signatures = new Array(docs.length);

		for (const [index, doc] of docs.entries()) {
			if (!doc.documentId) {
				this.error('Не определено свойство `documentId`');
			}

			if (!doc.content.fileId) {
				this.error('Не определено свойство `fileId`');
			}

			signatures[index] = await this.makeSignature(doc);
		}

		this.signatures = signatures;

		return this;
	}

	async makeSignature(doc) {
		return {
			documentId: doc.documentId,
			signature: await getFile(doc.content.fileId).then((file) =>
				createSignature(this.thumbprint, file),
			),
		};
	}

	makePayload() {
		const data = {
			signerId: this.signerId ?? undefined,
			signerType: this.signerType ?? undefined,
			signatureType: this.signatureType ?? undefined,
			signatures: this.signatures ?? undefined,
		};

		if (!data.signerId) {
			this.error('Не определено свойство `signerId`');
		}

		if (!data.signerType) {
			this.error('Не определено свойство `signerType`');
		}

		if (!data.signatureType) {
			this.error('Не определено свойство `signatureType`');
		}

		if (!data.signatures || !data.signatures.length) {
			this.error('Не определены документы для подписания');
		}

		return data;
	}

	async send(docs) {
		await this.documents(docs);

		const payload = this.makePayload();
		return postApiRequestBuilder('documents/batch/sign').send(payload);
	}
}

export class ThirdPartySignatureBuilder extends SignatureBuilder {
	requestId;
	signatureType = 'QUALIFIED';

	constructor(requestId) {
		super();

		this.requestId = requestId;
	}

	makePayload() {
		const data = {
			signatureType: this.signatureType ?? undefined,
			signatures: this.signatures ?? undefined,
		};

		if (!data.signatureType) {
			this.error('Не определено свойство `signatureType`');
		}

		if (!data.signatures || !data.signatures.length) {
			this.error('Не определены документы для подписания');
		}

		return data;
	}

	async makeSignature(doc) {
		return {
			documentId: doc.documentId,
			signature: await getFileBySignRequest(doc.content.fileId, this.requestId)
				.then((file) => createSignature(this.thumbprint, file)),
		};
	}

	async send(docs) {
		if (Array.isArray(docs)) {
			await this.documents(docs);
		} else {
			await this.document(docs);
		}

		const payload = this.makePayload();

		return postApiRequestBuilder(
			`sign/request/${this.requestId}/batch`,
		).public.send(payload);
	}
}

export class OperatorSignatureBuilder extends SignatureBuilder {
	signatureType = SIGNATURE_TYPES.QUALIFIED;

	constructor() {
		super();
	}

	makePayload() {
		const data = {
			signatureType: this.signatureType ?? undefined,
			signatures: this.signatures ?? undefined,
		};

		if (!data.signatureType) {
			this.error('Не определено свойство `signatureType`');
		}

		if (!data.signatures || !data.signatures.length) {
			this.error('Не определены документы для подписания');
		}

		return data;
	}

	async send(docs) {
		await this.documents(docs);

		const payload = this.makePayload();
		return postApiRequestBuilder('operator/batch/sign').send(payload);
	}
}
