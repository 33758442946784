<template>
	<div class="pagination">
		<div class="pagination__container">
			<a
				v-show="currentPage > 1"
				class="pagination-button mr-16"
				@click.prevent="prevPage()"
			>
				<span class="prev-icon"/>
				Предыдущая
			</a>

			<ul v-if="lastPage < 4" class="numbers-list">
				<li v-if="lastPage - 2 > 0" :class="currentPage === lastPage - 2 ? 'active' : ''" @click.prevent="setPageNumber(lastPage - 2)"><a href="">{{ lastPage - 2 }}</a></li>
				<li v-if="lastPage - 1 > 0" :class="currentPage === lastPage - 1 ? 'active' : ''" @click.prevent="setPageNumber(lastPage - 1)"><a href="">{{ lastPage - 1 }}</a></li>
				<li :class="currentPage === lastPage ? 'active' : ''" @click.prevent="setPageNumber(lastPage)"><a href="">{{ lastPage }}</a></li>
			</ul>
			<ul v-else class="numbers-list">
				<li v-if="currentPage > 1" @click.prevent="setPageNumber(firstPage)"><a href="">{{ firstPage }}</a></li>
				<li v-if="currentPage > 1" @click.prevent=""><a href="">...</a></li>
				<li v-if="lastPage > 2 && currentPage >= lastPage" @click.prevent="setPageNumber(currentPage - 2)"><a href="">{{ currentPage - 2 }}</a></li>
				<li v-if="lastPage > 2 && currentPage + 1 >= lastPage" @click.prevent="setPageNumber(currentPage - 1)"><a href="">{{ currentPage - 1 }}</a></li>
				<li class="active" @click.prevent="setPageNumber(currentPage)"><a href="">{{ currentPage }}</a></li>
				<li v-if="currentPage < lastPage" @click.prevent="setPageNumber(currentPage + 1)"><a href="">{{ currentPage + 1 }}</a></li>
				<li v-if="currentPage + 1 < lastPage" @click.prevent="setPageNumber(currentPage + 2)"><a href="">{{ currentPage + 2 }}</a></li>
				<li v-if="currentPage + 3 < lastPage" @click.prevent=""><a href="">...</a></li>
				<li v-if="currentPage + 2 < lastPage" @click.prevent="setPageNumber(lastPage)"><a href="">{{ lastPage }}</a></li>
			</ul>

			<a
				v-show="currentPage < this.lastPage"
				class="pagination-button ml-16"
				@click.prevent="nextPage()"
			>
				Следующая
				<span class="next-icon"/>
			</a>
		</div>

		<slot name="total" v-bind="{ total, title }">
			<span v-if="title" class="total">{{ total }} {{ title }}</span>
			<div v-else/>
		</slot>
	</div>
</template>

<script>
export default {
	name: 'UiPagination',
	props: {
		/**
         * Текущая страница.
         *
         * @type Number
		* */
		currentPage: {
			type: Number,
			default: 1
		},
		/**
         * Поле принимает общее количество элементов.
         *
         * @type Number
		* */
		total: {
			type: Number,
			required: true
		},
		/**
         * Поле принимает количество элементов отображаемых на 1 странице.
         *
         * @type Number
         * */
		pageSize: {
			type: Number,
			required: true
		},
		/**
         * Если передано значение, то будет отображать общее кол-во эл-тов справа и сам заголовок.
         * При total = 20, title = 'Максимов': будет отображаться '20 Максимов'.
         *
         * @type String
         * */
		title: {
			type: String,
			required: false
		}
	},
	computed: {
		lastPage() {
			return Math.ceil(this.total/this.pageSize)
		},
		firstPage() {
			return this.lastPage - this.lastPage + 1;
		}
	},
	watch: {
		currentPage() {
			this.$emit('changePage', this.currentPage, this.pageSize)
		}
	},
	methods: {
		nextPage() {
			this.setPageNumber(this.currentPage + 1)
		},
		prevPage() {
			this.setPageNumber(this.currentPage - 1)
		},
		setPageNumber(number) {
			if
			(
				typeof number === 'number'
                && (
                	number > this.lastPage
                    || number <= 0
                )
			) return;

			this.$emit('changePage', number, this.pageSize)
		},
	}
}
</script>
