import {
	isValidSystemSetup,
	getUserCertificates,
} from '@/services/crypto';

export default {
	actions: {
		async isValidCryptoAction(ctx) {
			ctx.commit('setValidationFinished', false);
			const isValid = await isValidSystemSetup();
			ctx.commit('isValidCryptoMutation', isValid);
			ctx.commit('setValidationFinished', true);
		},
		async userCertificatesCryptoAction(ctx) {
			const userCertificates = await getUserCertificates();
			ctx.commit('userCertificatesCryptoMutation', userCertificates);
		},
	},
	mutations: {
		setValidationFinished(state, finished) {
			state.validationFinished = finished;
		},
		isValidCryptoMutation(state, isValid) {
			state.isValidCrypto = isValid;
		},
		userCertificatesCryptoMutation(state, userCertificates) {
			state.userCertificatesCrypto = userCertificates;
		},
	},
	state: {
		isValidCrypto: false,
		userCertificatesCrypto: [],
		validationFinished: false,
	},
	getters: {
		validationFinished(state) {
			return state.validationFinished;
		},
		isValidCryptoGetter(state) {
			return state.isValidCrypto;
		},
		userCertificatesCryptoGetter(state) {
			return state.userCertificatesCrypto;
		},
	},
};
