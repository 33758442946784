// @ts-check

import { getStandardDocs, editStandardDocs } from '@/services/settings';

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,
	state: {
		standardDocs: [],
		standardDocItem: {},
		loading: false,
	},

	getters: {
		getterStandardDocs: (state) => state.standardDocs,
		getterStandardDocItem: (state) => state.standardDocItem,
		getterLoading: (state) => state.loading,
	},

	mutations: {
		setStandardDocs(state, payload) {
			state.partners = payload;
		},
		setLoading(state, bool) {
			state.loading = bool;
		},
		standardDocItemMutation(state, data) {
			state.standardDocItem = data;
		},
		standardDocsMutation(state, data) {
			state.standardDocs = data;
		},
	},

	actions: {
		actionFetchStandardDocs({ commit }) {
			commit('setLoading', true);
			return getStandardDocs().then((res) => {
				commit('standardDocsMutation', res);
				commit('setLoading', false);
			});
		},
		actionCreateStandardDocItem({ commit, state }, data) {
			commit('setLoading', true);
			return editStandardDocs([...state.standardDocs, data]);
		},
		actionEditStandardDocItem({ commit, state }, data) {
			commit('setLoading', true);
			const editedDoc = data;
			const updatedItems = state.standardDocs.map(doc => doc.code === editedDoc.code ? editedDoc : doc);
			return editStandardDocs([...updatedItems]);
		},
		actionDeleteStandardDocItem({ commit, state }, data) {
			commit('setLoading', true);
			const delitedDoc = data;
			const updatedItems = state.standardDocs.filter(doc => doc.code !== delitedDoc.code);
			return editStandardDocs([...updatedItems]);
		},
		actionSetStandardDocItem({ commit }, data) {
			commit('standardDocItemMutation', data);
		},
		actionClearStandardDocItem({ commit }) {
			commit('standardDocItemMutation', {});
		},
	},
};
