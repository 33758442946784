
import {
	createPlatformNewsItem,
	editPlatformNewsItem,
	getPlatformNewsItem,
	deletePlatformNewsItem,
	getGeneralPlatformNewsItem
} from "@services/platformNews";

export default {
	namespaced: true,

	state: {
		platformNewsItem: {
			title: '',
			content: '',
			banner: null,
			generalStartDate: '',
			generalEndDate: '',
			active: false
		},
		generalPlatformNewsItem: {},
		loader: true
	},
	actions: {
		async actionGetPlatformNewsItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return getPlatformNewsItem(data.platformNewsId).then(r => {
				commit('mutationSetPlatformNewsItem', r.data);
			})
		},
		async actionGetGeneralPlatformNewsItem({ commit }) {
			commit('mutationSetLoader', true);
			return getGeneralPlatformNewsItem().then(r => {
				commit('mutationSetGeneralPlatformNewsItem', r.data);
			})
		},
		async actionCreatePlatformNewsItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return createPlatformNewsItem(data.platformNewsData).then(r => {
				commit('mutationSetPlatformNewsItem', r.data);
			})
		},
		async actionEditPlatformNewsItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return editPlatformNewsItem(data.platformNewsData).then(r => {
				commit('mutationSetPlatformNewsItem', r.data);
			})
		},
		async actionDeletePlatformNewsItem({ commit }, data) {
			commit('mutationSetLoader', true);
			return deletePlatformNewsItem(data.platformNewsId).then(r => {
				commit('mutationSetPlatformNewsItem', r.data);
			})
		},
		actionSetPlatformNewsItem({ commit }, data) {
			commit('mutationSetPlatformNewsItem', data);
		},
		actionClearPlatformNewsItem({ commit }) {
			commit('mutationSetPlatformNewsItem', {
				title: '',
				content: '',
				banner: null,
				active: false
			});
		},
	},
	mutations: {
		mutationSetPlatformNewsItem(state, data) {
			state.platformNewsItem = data;
			state.loader = false;
		},
		mutationSetGeneralPlatformNewsItem(state, data) {
			state.generalPlatformNewsItem = data;
			state.loader = false;
		},
		mutationSetLoader(state, bool) {
			state.loader = bool;
		},
	},
	getters: {
		platformNewsItemGetter(state) {
			return state.platformNewsItem;
		},
		generalPlatformNewsItemGetter(state) {
			return state.generalPlatformNewsItem;
		},
		platformNewsItemLoaderGetter(state) {
			return state.loader;
		},
	},
};