var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', [_vm.label ? _c('label', {
    staticClass: "select-label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('select', {
    ref: "select",
    staticClass: "ui-select w-100",
    class: {
      'error': _vm.error,
      'readonly': _vm.readonly
    },
    attrs: {
      "name": _vm.name,
      "disabled": _vm.disabled
    },
    on: {
      "change": _vm.onChange
    }
  }, [_vm._t("default")], 2), _vm.caption || _vm.error ? _c('span', {
    staticClass: "select-caption",
    class: _vm.error ? 'select-caption__error' : ''
  }, [_vm._v(" " + _vm._s(_vm.error ? _vm.error : _vm.caption) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }