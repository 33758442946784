var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.label ? _c('div', {
    staticClass: "radio__label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _vm.hint ? _c('div', {
    staticClass: "radio__hint"
  }, [_vm._v(" " + _vm._s(_vm.hint) + " ")]) : _vm._e(), _c('div', {
    staticClass: "radio__list",
    class: {
      'radio__list--horizontal': _vm.horizontal
    }
  }, [_vm._l(_vm.options, function (opt, idx) {
    return _c('UiRadio', {
      key: ((_vm.groupId) + "-" + idx),
      attrs: {
        "value": opt.value,
        "title": opt.label,
        "group": _vm.groupId,
        "disabled": _vm.disabled || opt.disabled,
        "readonly": _vm.readonly,
        "modelValue": _vm.value
      },
      on: {
        "change": function (val) {
          return _vm.$emit('change', val);
        }
      }
    });
  }), _vm.error ? _c('div', {
    staticClass: "radio__error"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }