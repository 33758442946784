<template>
	<div class="filter-list">
		<div class="filter-list__content">
			<div :class="filterClasses">
				<UiForm
					ref="uiFilterFormFirst"
					:data="filterData"
					class="first-filter__form"
					:fields="firstFilter"
					:tableListFormat="true"
					:disable-error-scroll="true"
					@change="data => setFilterData(data, true)"
				/>
				<UiButton 
					v-if="otherFilters.length"
					:label="otherFiltersLabel"
					type="secondary"
					prependIcon="icons-filter"
					size="xs"
					@click="setShowFilters"
				/>
			</div>
			<div class="filter-list__chips">
				<ul v-if="showChips" class="filter-list__chips-list">
					<li v-for="(chip, idx) in filtersChips" :key="idx" class="filter-list__chips-item">
						{{ chip.label }}
						<UiSmartIcon 
							class="filter-list__chips-close"
							name="icons-close" 
							color="var(--white)" 
							width="22"
							height="22"
							@click="clearFilter(chip.name)"
						/>
					</li>
					<li 
						v-if="hasFiltersChips" 
						class="filter-list__chips-item clear" 
						@click="clearFilters"
					>
						Очистить фильтры
					</li>
				</ul>
			</div>
			<div v-if="showFilters" class="filter-list__other-filters">
				<UiForm
					ref="uiFilterFormSecond"
					:data="filterData"
					class="other-filters__form"
					:fields="otherFilters"
					:tableListFormat="true"
					:disable-error-scroll="true"
					@change="data => setFilterData(data, false)"
				>
					<template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotProps">
						<slot :name="name" v-bind="{ ...slotProps, ...slotMethodsAndProps }"/>
					</template>
				</UiForm>
				<UiButton 
					label="Применить фильтры"
					class="filter-list__accept-filters"
					prependIcon="icons-filter"
					:disabled="!otherFiltersValid"
					size="xs"
					@click="applyFilters(false)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { formatMoney, formatDate } from '@/utils/format';
import { FILTER_CASES } from "@services/tables";
import { CURRENCY_CODE } from '@/utils/format';

export default {
	name: 'UiFilterList',
	/**
	 * Раскрываемый список фильтров
	 * 
	 * Событие изменения (change) происходит при любом изменении значений в объекте данных фильтра.
	 */
	props: {
		/**
		 * Массив описания фильтров структура описания каждого фильтра соответствует 
		 */
		filters: {
			type: Array,
			default: () => [],
		},
		initialFilters: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			dateFormat: 'DD.MM.YYYY',
			diapasons: {
				from: 'From',
				to: 'To'
			},
			filterData: this.initialFilters,
			showFilters: false,
			filtersApplies: false,
			firstFiltersValid: true,
			otherFiltersValid: true,
		}
	},

	computed: {
		filterFields() {
			let flds = this.filters.map(filter => {
				return this.filterMapper(filter);
			});
			return flds.map(f => [
				...f,
				{ type: 'blank', cols: 2 },
			]);
		},
		firstFilter() {
			return this.filterFields.length ? [this.filterFields[0]] : [];
		},
		otherFilters() {
			return this.filterFields.length ? this.filterFields.slice(1, this.filterFields.length) : [];
		},
		filtersChips() {
			return this.filterChipsMapper();
		},
		hasFiltersChips() {
			return this.filtersChips.length;
		},
		showChips() {
			return this.hasFiltersChips && !this.showFilters;
		},
		filterClasses() {
			return {
				'filter-list__first-filter': true,
				'open': this.showFilters,
			}
		},
		otherFiltersLabel() {
			return this.showFilters ? 'Скрыть фильтры' : 'Все фильтры';
		}
	},

	watch: {
		filterData: {
			handler(newVal) {
				this.$emit('filtersChanged', newVal);
			},
			deep: true,
		},
		initialFilters: {
			handler() {
				this.refetch();
			},
			deep: true,
		},
		'$route.path': {
			handler() {
				this.resetFilters();
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		filterMapper(filter, inRow) {
			if (Array.isArray(filter)) {
				const groupedFilters = filter.flatMap((filter) => (this.filterMapper(filter, true)));
				return groupedFilters;
			}
			if (filter.name.length <= 1) return;
			switch (filter.type) {
			case FILTER_CASES.SEARCH:
				return [
					{
						...filter,
						name: filter.name,
						type: 'string',
						placeholder: filter.placeholder || 'Введите значение',
						gridCols: 8,
					},
				];
			case FILTER_CASES.SELECT:
				return [
					{
						...filter,
						name: filter.name,
						type: 'select',
						options: Array.isArray(filter.options) ? this.defaultOptionsMapper(filter.options) : this.defaultOptionsMapper(filter.options()),
						multiple: filter.multiple ?? false,
						gridCols: 8,
					},
				];
			case FILTER_CASES.CHECKBOX:
				return [
					{
						...filter,
						name: filter.name,
						type: 'boolean',
						gridCols: 8,
					},
				];
			case FILTER_CASES.NUMBER:
				return [
					{
						...filter,
						name: filter.name,
						type: 'number',
						placeholder: filter.placeholder || 'Введите значение',
						gridCols: 8,
					},
				];
			case FILTER_CASES.DATEPERIOD:
				return [
					{
						...filter,
						name: `${filter.name}From`,
						type: 'date',
						label: filter.label + ' (с)',
						validator: (v, obj) => filter.validatorFrom ?? this.validatorRules(v, obj, filter.name, FILTER_CASES.DATEPERIOD, this.diapasons.from),
						gridCols: inRow ? 8 : 4,
					},
					{
						...filter,
						name: `${filter.name}To`,
						type: 'date',
						label: filter.label + ' (по)',
						validator: (v, obj) => filter.validatorTo ?? this.validatorRules(v, obj, filter.name, FILTER_CASES.DATEPERIOD, this.diapasons.to),
						gridCols: inRow ? 8 : 4,
					},
				];
			case FILTER_CASES.MONEYPERIOD:
				return [
					{
						...filter,
						name: `${filter.name}From`,
						type: 'money',
						label: filter.label + ' (min)',
						validator: (v, obj) => filter.validatorFrom ?? this.validatorRules(v, obj, filter.name, FILTER_CASES.MONEYPERIOD, this.diapasons.from),
						gridCols: inRow ? 8 : 4,
					},
					{
						...filter,
						name: `${filter.name}To`,
						type: 'money',
						label: filter.label + ' (max)',
						validator: (v, obj) => filter.validatorTo ?? this.validatorRules(v, obj, filter.name, FILTER_CASES.MONEYPERIOD, this.diapasons.to),
						gridCols: inRow ? 8 : 4,
					},
				];
			case FILTER_CASES.INTEGERPERIOD:
				return [
					{
						...filter,
						name: `${filter.name}From`,
						type: 'money',
						label: filter.label + ' (от)',
						placeholder: filter.placeholder || 'Введите значение',
						validator: (v, obj) => filter.validatorFrom ?? this.validatorRules(v, obj, filter.name, FILTER_CASES.INTEGERPERIOD, this.diapasons.from),
						gridCols: inRow ? 8 : 4,
					},
					{
						...filter,
						name: `${filter.name}To`,
						type: 'money',
						label: filter.label + ' (до)',
						placeholder: filter.placeholder || 'Введите значение',
						validator: (v, obj) => filter.validatorTo ?? this.validatorRules(v, obj, filter.name, FILTER_CASES.INTEGERPERIOD, this.diapasons.to),
						gridCols: inRow ? 8 : 4,
					},
				];
			default: 
				return [
					{
						...filter,
						gridCols: 8,
					},
				];
			}
		},
		filterChipsMapper(filters = null) {
			const filtersForChips = filters || this.filters;
			const chipsArray = filtersForChips.flat().flatMap((filter, idx) => {
				// отключаем чипсы для первого фильтра
				if (!filters && idx === 0) return null;
				if (Array.isArray(filter)) {
					return this.filterChipsMapper(filter);
				}
				const label = filter.label;
				let chipLabel = null;
				switch (filter.type) {
				case FILTER_CASES.SEARCH:
					if (this.filterData[filter.name]) chipLabel = this.filterData[filter.name];
					return chipLabel ? {label: `${label}: ${chipLabel}`, name: filter.name}  : null;
				case FILTER_CASES.SELECT:
					if (this.filterData[filter.name]) {
						if (Array.isArray(filter.options)) {
							chipLabel = filter.options.find(option => option.value === this.filterData[filter.name])?.label;
						} else {
							chipLabel = filter.options().find(option => option.value === this.filterData[filter.name])?.label;
						}
					} 
					return chipLabel ? {label: `${label}: ${chipLabel}`, name: filter.name} : null;
				case FILTER_CASES.CHECKBOX:
					if (this.filterData[filter.name]) chipLabel = this.filterData[filter.name];
					return chipLabel ? {label: `${label}: ${chipLabel}`, name: filter.name} : null;
				case FILTER_CASES.NUMBER:
					if (this.filterData[filter.name]) chipLabel = this.filterData[filter.name];
					return chipLabel ? {label: `${label}: ${chipLabel}`, name: filter.name} : null;
				case FILTER_CASES.DATEPERIOD:
					let firstDate = this.filterData[`${filter.name}From`];
					let lastDate = this.filterData[`${filter.name}To`];

					if (!firstDate && !lastDate) return null;
					if (firstDate && !lastDate) chipLabel = `от ${formatDate(firstDate, this.dateFormat)}`;
					if (!firstDate && lastDate) chipLabel = `до ${formatDate(lastDate, this.dateFormat)}`;
					if (firstDate && lastDate) chipLabel = `${formatDate(firstDate, this.dateFormat)} - ${formatDate(lastDate, this.dateFormat)}`
					
					return {label: `${label}: ${chipLabel}`, name: [`${filter.name}From`, `${filter.name}To`]};
				case FILTER_CASES.MONEYPERIOD:
					let firstValue = this.filterData[`${filter.name}From`];
					let lastValue = this.filterData[`${filter.name}To`];
					
					if (!firstValue && !lastValue) return null;
					if (firstValue && !lastValue) chipLabel = `от ${formatMoney(firstValue, CURRENCY_CODE.RUB)}`;
					if (!firstValue && lastValue) chipLabel = `до ${formatMoney(lastValue, CURRENCY_CODE.RUB)}`;
					if (firstValue && lastValue) chipLabel = `${formatMoney(firstValue, CURRENCY_CODE.RUB)} - ${formatMoney(lastValue, CURRENCY_CODE.RUB)}`

					return { label: `${label}: ${chipLabel}`, name: [`${filter.name}From`, `${filter.name}To`] };
				case FILTER_CASES.INTEGERPERIOD:
					let firstInt = this.filterData[`${filter.name}From`];
					let lastInt = this.filterData[`${filter.name}To`];
					
					if (!firstInt && !lastInt) return null;
					if (firstInt && !lastInt) lastInt = '∞';
					if (!firstInt && lastInt) firstInt = 0;
					
					chipLabel = `от ${firstInt} до ${lastInt}`
					return { label: `${label}: ${chipLabel}`, name: [`${filter.name}From`, `${filter.name}To`] };
				}
				return null;
			})
			return chipsArray.filter(chip => chip !== null);
		},
		setFilterData(data, isFirstFilter) {
			this.firstFiltersValid = !this.$refs.uiFilterFormFirst.validate() ? false : true;
			if (this.showFilters) this.otherFiltersValid = !this.$refs.uiFilterFormSecond.validate() ? false : true;
			
			this.filterData = data;
			if (isFirstFilter && this.firstFiltersValid) this.applyFilters(true);
		},
		applyFilters(isFirstFilter) {
			this.changeFilters();
			if (this.otherFiltersValid && !isFirstFilter) this.filtersApplies = true;
			this.showFilters = false;
		},
		refetch() {
			this.$emit('refetch');
		},
		changeFilters() {
			this.$emit('change', this.filterData);
		},
		setShowFilters() {
			this.showFilters = !this.showFilters;
		},
		clearFilter(filterName) {
			if (Array.isArray(filterName)) {
				filterName.forEach((filterName) => this.filterData[filterName] = null);
			} else {
				this.filterData[filterName] = null;
			}
			this.changeFilters();
			if (!this.filtersChips.length) this.filtersApplies = false;
		},
		clearFilters() {
			this.filterData = {};
			this.filtersApplies = false;
			this.changeFilters();
		},
		resetFilters() {
			this.filterData = this.initialFilters;
			this.filtersApplies = false;
			this.changeFilters();
		},
		validatorRules(v, obj, name, type, diapason) {
			// общие правила валидации периодов
			switch (type) {
			case FILTER_CASES.DATEPERIOD:
				switch (diapason) {
				case this.diapasons.from: 
					if (v && obj[`${name}To`] && v > obj[`${name}To`]) {
						return "Дата начала периода должна быть меньше даты окончания периода";
					}
				case this.diapasons.to: 
					if (v && obj[`${name}From`] && v < obj[`${name}From`]) {
						return "Дата окончания периода должна быть больше даты начала периода";
					}
				} 
			case FILTER_CASES.MONEYPERIOD: 
				switch (diapason) {
				case this.diapasons.from: 
					if (obj?.[`${name}To`] && v > obj?.[`${name}To`]) {
						return "Мимимальная сумма должна быть меньше максимальной";
					}
				case this.diapasons.to: 
					if (obj[`${name}From`] && v < obj[`${name}From`]) {
						return "Максимальная сумма должна быть больше минимальной";
					}
				} 
			case FILTER_CASES.INTEGERPERIOD: 
				switch (diapason) {
				case this.diapasons.from: 
					if (obj[`${name}To`] && v > obj[`${name}To`]) {
						return "Мимимальное значение должно быть меньше максимального";
					}
				case this.diapasons.to: 
					if (obj[`${name}From`] && v < obj[`${name}From`]) {
						return "Максимальное значение должно быть больше минимального";
					}
				} 
			}	
		},
		defaultOptionsMapper(options) {
			return options.some((item) => item.value === null ) ? options : [{ label: 'Все', value: null }, ...options]
		}
	}
}  
</script>
