import store from '@/store';
import { ROLE_CODES } from '@/configs/participants';

export const UCP_STATUSES = {
	[ROLE_CODES.FOUNDER]: {
		NOT_CONFIRMED: 'Ожидает погашения',
		REQUESTED: 'Отправлен запрос на гашение',
		OVERDUE: 'Погашение просрочено',
		REJECTED: 'Запрос на гашение отклонен',
		CONFIRMED: 'Погашено',
		AUTO_REDEEMED: 'Погашено автоматически',
	},
	[ROLE_CODES.INVESTOR]: {
		NOT_CONFIRMED: 'Ожидает погашения',
		REQUESTED: 'Поступил запрос на гашение',
		OVERDUE: 'Погашение просрочено',
		REJECTED: 'Запрос на гашение отклонен',
		CONFIRMED: 'Погашено',
		AUTO_REDEEMED: 'Погашено автоматически',
	}
}

export const PROJECT_STATUSES = {
	DRAFT: 'DRAFT',
	PREPARED: 'PREPARED',
	PUBLISHED: 'PUBLISHED',
	REJECTED: 'REJECTED',
	COLLECTED: 'COLLECTED',
	RELEASED: 'RELEASED',
	CLOSED: 'CLOSED',
	CANCELLED: 'CANCELLED',
	ACTIVE: 'ACTIVE',
	ON_115FZ: 'ON_115FZ',
}

export const CONTRACT_STATUSES = {
	PREPARED: 'На модерации',
	VALID: 'Активен',
	TERMINATED: 'Закрыт',
}


const createStatus = ({ name, backgroundColor, color }) =>
	Object.freeze({ name, backgroundColor, color: color ?? '#FFF' });

const baseStatusInfo = {
	DRAFT: createStatus({ name: 'Черновик', backgroundColor: '#7D7D7D' }),
	PREPARED: createStatus({
		name: 'На модерации',
		backgroundColor: '#C3DCF1',
		color: '#2B2B2B',
	}),
};

export const pitchStatusInfo = {
	...baseStatusInfo,
	PUBLISHED: createStatus({
		name: 'Опубликован',
		backgroundColor: '#009C80',
	}),
	REJECTED: createStatus({ name: 'Отклонён', backgroundColor: '#DE2D31' }),
	CLOSED: createStatus({ name: 'Закрыт', backgroundColor: '#7D7D7D' }),
};

const participantStatusInfoFZ = {
	ACTIVE: createStatus({ name: 'Обновлен', backgroundColor: '#48B349' }),
	ON_115FZ: createStatus({ name: 'Ожидает обновления', backgroundColor: '#E96B35' }),
};

export function getPitchStatusName(status) {
	const pitchStatusInfo = store.getters['dictionary/pitchStatusSettingsObject'];

	if (status.toLocaleUpperCase() in pitchStatusInfo)
		return pitchStatusInfo[status.toLocaleUpperCase()].name;

	throw new Error(
		`Invalid pitch status: ${status.toLocaleUpperCase()}.`
	);
}

export function getPitchStatusStyles(status) {
	const pitchStatusInfo = store.getters['dictionary/pitchStatusSettingsObject'];

	if (status.toLocaleUpperCase() in pitchStatusInfo) {
		return {
			'background-color': pitchStatusInfo[status.toLocaleUpperCase()].backgroundColor,
			color: pitchStatusInfo[status.toLocaleUpperCase()].color,
		};
	}

	throw new Error(
		`Invalid pitch status: ${status.toLocaleUpperCase()}.`
	);
}

export function getProjectStatusName(status) {
	const projectStatusInfo = store.getters['dictionary/projectStatusSettingsObject'];

	if (status.toLocaleUpperCase() in projectStatusInfo)
		return projectStatusInfo[status.toLocaleUpperCase()].name;

	throw new Error(
		`Invalid project status: ${status.toLocaleUpperCase()}.`
	);
}

export function getProjectStatusStyles(status) {
	const projectStatusInfo = store.getters['dictionary/projectStatusSettingsObject'];

	if (status.toLocaleUpperCase() in projectStatusInfo) {
		return {
			'background-color': projectStatusInfo[status.toLocaleUpperCase()].backgroundColor,
			color: projectStatusInfo[status.toLocaleUpperCase()].color,
		};
	}

	throw new Error(
		`Invalid project status: ${status.toLocaleUpperCase()}.`
	);
}

export function getParticipantStatusName(status) {
	const participantStatusInfo = store.getters['dictionary/participantStatusSettingsObject'];
	if (status.toLocaleUpperCase() in participantStatusInfo)
		return participantStatusInfo[status.toLocaleUpperCase()].name;

	throw new Error(
		`Participant does not has the status: ${status.toLocaleUpperCase()}.`
	);
}

export function getParticipantStatusStyles(status) {
	const participantStatusInfo = store.getters['dictionary/participantStatusSettingsObject'];
	if (status.toLocaleUpperCase() in participantStatusInfo) {
		return {
			'background-color':
                participantStatusInfo[status.toLocaleUpperCase()]
                	.backgroundColor,
			color: participantStatusInfo[status.toLocaleUpperCase()].color,
		};
	}

	throw new Error(
		`Participant does not has the status: ${status.toLocaleUpperCase()}.`
	);
}

export function getParticipantStatusStylesFZ(status) {
	if (status.toLocaleUpperCase() in participantStatusInfoFZ) {
		return {
			'background-color':
                participantStatusInfoFZ[status.toLocaleUpperCase()]
                	.backgroundColor,
			color: participantStatusInfoFZ[status.toLocaleUpperCase()].color,
		};
	}

	return {};
}

export function getContractStatus(status) {
	return CONTRACT_STATUSES[status.toLocaleUpperCase()];
}