import { postApiRequestBuilder, getApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder } from '@/utils/RequestBuilder';

export function createPlatformQuestionItem(question) {
	return postApiRequestBuilder(`platform-questions`).send(question);
}

export function editPlatformQuestionItem(question) {
	return putApiRequestBuilder(`platform-questions`).send(question);
}

export function getPlatformQuestionItem(questionId) {
	return getApiRequestBuilder(`platform-questions/${questionId}`).public.send();
}

export function getPlatformQuestions(data) {
	return getApiRequestBuilder(`platform-questions`).public.query(Object.entries({ title: data?.title })).send();
}

export function deletePlatformQuestionItem(questionId) {
	return deleteApiRequestBuilder(`platform-questions/${questionId}`).send();
}

export function getGeneralPlatformQuestionItem() {
	return getApiRequestBuilder(`platform-questions/public/general`).send();
}