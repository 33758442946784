var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-document",
    class: {
      'ui-document--wide': _vm.showResendButton
    }
  }, [_c('div', {
    staticClass: "ui-document--name"
  }, [_c('a', {
    staticClass: "black-text",
    attrs: {
      "href": _vm.href,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.name))])]), !_vm.documentModel.signs.length ? _c('span', {
    staticClass: "status nw ui-document__chip",
    style: {
      backgroundColor: '#009C80'
    }
  }, [_vm._v(" Не подписывается ")]) : _vm.finishedSing ? _c('span', {
    staticClass: "status nw ui-document__chip",
    style: {
      backgroundColor: '#009C80'
    }
  }, [_vm._v(" Подписан ")]) : _c('span', {
    staticClass: "status nw ui-document__chip",
    style: {
      backgroundColor: '#7D7D7D'
    }
  }, [_vm._v(" Не подписан ")]), _c('div', {
    staticClass: "flex gap-16 flex-right"
  }, [_vm.showResendButton ? _c('UiButton', {
    attrs: {
      "label": _vm.resendLabel,
      "type": "secondary",
      "size": "xs",
      "pending": _vm.resendPending,
      "disabled": _vm.alreadyResent
    },
    on: {
      "click": _vm.handleResend
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }