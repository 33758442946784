<template>
	<div>
		<div v-if="!readonly" class="mb-16">
			<slot name="addButton" v-bind="{ openForm }">
				<UiButton
					type="secondary"
					:label="buttonLabel"
					prepend-icon="add-icon"
					class="ui-array-input__upload-btn"
					@click="openForm"
				/>
			</slot>
		</div>

		<div>
			<div v-show="label" class="ui-array-input__label">
				{{ label }}
			</div>

			<div :class="{ 'ui-array-input__frame-error': error }">
				<UiTable :columns="columnsWithActions" :items="value">
					<template #actions="row">
						<div class="ui-array-input__table-actions">
							<template v-if="!readonly">
								<span title="Изменить...">
									<UiSmartIcon
										size="20"
										name="icons-edit"
										color="#3589CF"
										@click="openEditForm(row)"
									/>
								</span>
								<span title="Удалить">
									<UiSmartIcon
										size="20"
										name="delete-icon"
										color="#3589CF"
										@click="deleteItem(row.index)"
									/>
								</span>
							</template>
							<template v-else>
								<span title="Посмотреть">
									<UiSmartIcon
										size="20"
										name="icons-eye"
										color="#3589CF"
										@click="openEditForm(row)"
									/>
								</span>
							</template>
						</div>
					</template>
					<template #name="data">
						<p>
							{{ getShareholderName(data) }}
						</p>
					</template>
				</UiTable>
			</div>
			<div v-if="!!error" class="ui-array-input__error">{{ error }}</div>
		</div>

		<UiDialog
			ref="arrayInputModal"
			width="80%"
			:title="title"
			:closeByClickOutside="false"
			@close="clearFormState"
		>
			<template>
				<div class="modal__body mt-16">
					<UiForm
						ref="projectForm"
						:tips-provider="tipsProvider(tipsProviderKey)"
						:fields="modifiedReadonlyFields"
						:data="formState"
						disable-error-scroll
						@onFileUploaded="loading = !$event"
						@change="(data) => (formState = data)"
					>
						<template #findShareholderByInn="{ data }">
							<UiButton
								label="Поиск по ИНН"
								class="ui-array-input__upload-btn"
								:disabled="readonly"
								:pending="searchByInnLoading"
								@click="searchShareholderByINN(data)"
							/>
						</template>
					</UiForm>
				</div>
				<div class="buttons-block mt-16">
					<ul class="list-none">
						<li v-if="!readonly">
							<UiButton
								:pending="loading"
								:label="labelButton"
								type="primary"
								class="mt-0"
								@click="addOrEdit"
							/>
						</li>
					</ul>
				</div>
			</template>
		</UiDialog>
	</div>
</template>

<script>
import store from "@/store/index";
import fileUploadMixin from '@mixins/fileUploadMixin';
import tipsMixin from '@mixins/tipsMixin';
import { conditionalReadonly } from '@utils/common';
import { ENTITY_TYPES } from '@/configs/participants';
import { getEntityDataByINN } from "@services/entity";
import { setValue } from "@utils/common";
import { CONTUR_SETTINGS_CODES } from "@/services/settings";
import { SUCCESS, ERROR } from '@/configs/ui';

export default {
	name: 'UiArrayInput',
	mixins: [fileUploadMixin, tipsMixin],
	model: {
		prop: 'value',
		event: 'change',
	},

	props: {
		value: {
			type: Array,
			required: true,
		},
		addModalTitle: {
			type: String,
			required: true,
		},
		updateModalTitle: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		buttonLabel: {
			type: String,
			default: 'Добавить',
		},
		columns: {
			type: Array,
			required: true,
		},
		fields: {
			type: [Array, Function],
			required: true,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		error: {
			type: [String, Boolean],
			default: false,
		},
		tipsProviderKey: {
			type: Object,
			default: undefined,
		},
	},

	data() {
		return {
			loading: false,
			index: null,
			formState: {},
			searchByInnLoading: false
		};
	},

	computed: {
		modelProxy: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('change', value);
			},
		},
		computedFields() {
			if (typeof this.fields === 'function') {
				return this.fields(this.formState);
			}
			return this.fields;
		},
		modifiedReadonlyFields() {
			return this.readonly
				? conditionalReadonly(true, this.computedFields)
				: this.computedFields;
		},
		title() {
			return this.isNewRow ? this.addModalTitle : this.updateModalTitle;
		},
		labelButton() {
			return this.isNewRow ? 'Добавить' : 'Сохранить';
		},
		columnsWithActions() {
			return [...this.columns, { name: 'actions', label: '...' }];
		},
		isNewRow() {
			return this.index === null;
		},
		isKontur() {
			return store.getters[`dictionary/${'konturSettings'}`]?.filter((item) => item.code === CONTUR_SETTINGS_CODES[item.code])[0]?.enable
		},
	},
	beforeDestroy() {
		this.clearFormState();
	},
	methods: {
		openForm() {
			this.$refs.arrayInputModal.show();
		},
		addOrEdit() {
			if (!this.$refs.projectForm.validate()) {
				return;
			}

			if (this.index !== null) {
				this.modelProxy = this.modelProxy.map((item, i) =>
					i === this.index ? this.formState : item,
				);
			} else {
				this.modelProxy = [...this.modelProxy, this.formState];
			}

			this.clearFormState();
		},
		clearFormState() {
			this.formState = {};
			this.index = null;
			this.$refs.arrayInputModal.closeModal();
		},
		openEditForm({ item, index }) {
			this.formState = item;
			this.index = index;
			this.$refs.arrayInputModal.show();
		},
		deleteItem(index) {
			this.modelProxy = this.modelProxy.filter((_, i) => i !== index);
		},
		getShareholderName(data) {
			if (data.item.type === ENTITY_TYPES.INDIVIDUAL) {
				return 'Физическое лицо'
			} else if (data.item.type === ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR) {
				return 'Индивидуальный предприниматель'
			}
			return data.item.name;
		},
		async searchShareholderByINN(data) {
			if (this.readonly) return;
			this.searchByInnLoading = true;
			const fields = typeof this.fields === 'function' ? this.fields(data) : this.fields;
			const filteredFields = fields.flat().filter((fld) => this.isFieldVisible(fld, data));
			let mappedFieldsNames = [];
			
			mappedFieldsNames = filteredFields.map(fld => fld.name).filter(fld => fld !== null && fld !== undefined);
			
			const INN = data.inn;
			
			let validINN = true;
			
			if (data.type && INN) {
				validINN = this.checkINNbyEntity(data.type, INN);
			} 
			
			const canSearchByINN = this.isKontur && validINN;

			if (canSearchByINN) {
				const type = data.type;
	
				getEntityDataByINN(INN, type).then((searchedData) => {
					let filteredSearchedByINNdata = {}; 
					mappedFieldsNames.forEach((fldName) => {
						const splitedPartName = type === ENTITY_TYPES.LEGAL_ENTITY && fldName.includes('eio') ? fldName.split('.')[1] : '';
						if (splitedPartName && searchedData?.data[splitedPartName]) {
							setValue(filteredSearchedByINNdata, fldName, searchedData?.data[splitedPartName]);
						} else if (searchedData?.data[fldName] && fldName !== 'inn') {
							filteredSearchedByINNdata[fldName] = searchedData?.data[fldName];
						}
					})
					if ([ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR].includes(type) && searchedData?.data?.city) {
						filteredSearchedByINNdata.address = searchedData?.data?.city;
					}
					if ([ENTITY_TYPES.LEGAL_ENTITY].includes(type) && searchedData?.data?.organization) {
						filteredSearchedByINNdata.name = searchedData?.data?.organization?.shortName;
						filteredSearchedByINNdata.ogrn = searchedData?.data?.organization?.ogrn;
						filteredSearchedByINNdata.address = searchedData?.data?.organization?.address;
						filteredSearchedByINNdata.phone = searchedData?.data?.organization?.phone;
					}

					filteredSearchedByINNdata.email = this.formState?.email;

					this.formState = { ...this.formState, ...filteredSearchedByINNdata };
					this.$notify({
						title: 'Поиск по ИНН выполнен успешно',
						type: SUCCESS,
					});
					
				}).catch((e) => {
					this.$notify({
						title: 'Не удалось провести поиск по ИНН',
						type: ERROR,
						text: e.response?.data.message,
					});
				}).finally(() => {
					this.searchByInnLoading = false;
				})
			}
		},
		checkINNbyEntity(userEntityType, INN) {
			const fullINN = [ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR].includes(userEntityType);
			const shortINN = [ENTITY_TYPES.LEGAL_ENTITY].includes(userEntityType);
			
			if (fullINN && INN?.length !== 12) {
				this.searchByInnLoading = false;
				this.$notify({
					type: ERROR,
					title: 'Длина ИНН должна составлять 12 символов',
				});
				return false;
			}
			if (shortINN && INN?.length !== 10) {
				this.searchByInnLoading = false;
				this.$notify({
					type: ERROR,
					title: 'Длина ИНН должна составлять 10 символов',
				});
				return false;
			}
			return true;
		},
		isFieldVisible(field, data) {
			if (field.hidden) {
				if (typeof field.hidden === "function") {
					return !field.hidden(data);
				}
				return !field.hidden;
			}
			return true;
		},
	},
};
</script>
