// @ts-check

import {
	getPlatformPromotions,
	getPlatformPromotionsItem,
	createPlatformPromotionsItem,
	editPlatformPromotionsItem,
	deletePlatformPromotionsItem
} from '@/services/promotions';

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,
	state: {
		promotions: [],
		promotionTab: {},
		promotionItem: {},
		loader: false,
	},

	getters: {
		getterPromotions: (state) => state.promotions,
		getterLoading: (state) => state.loader,
		getterPromotionItem: (state) => state.promotionItem,
		getterPromotionTab: (state) => state.promotionTab,
	},

	mutations: {
		setPromotions(state, payload) {
			state.promotions = payload;
		},
		mutationSetPromotionItem(state, data) {
			state.promotionItem = data;
			state.loader = false;
		},
		mutationSetLoader(state, bool) {
			state.loader = bool;
		},
		mutationSetPromotionTab(state, data) {
			state.promotionTab = data;
		},
	},

	actions: {
		async fetchPromotions({ commit, getters }) {
			commit('mutationSetLoader', true);
			try {
				const res = await getPlatformPromotions()
				const promotionsOptions = res.data.items.map((item) => ({ ...item, name: item.title })).reverse();
				commit('setPromotions', promotionsOptions);
				commit('mutationSetPromotionTab', getters.getterPromotions[0]);
			} finally {
				commit('mutationSetLoader', false);
			}
		},
		async actionGetPromotionItem({ commit }, data) {
			return getPlatformPromotionsItem(data.platformActionId).then(r => {
				commit('mutationSetPromotionItem', r.data);
			})
		},
		async actionDeletePromotionItem({ commit }, data) {
			return deletePlatformPromotionsItem(data.platformActionId).then(r => {
				commit('mutationSetPromotionItem', r.data);
			})
		},
		async actionCreatePromotionItem({ commit }, data) {
			return createPlatformPromotionsItem(data).then(r => {
				commit('mutationSetPromotionItem', r.data);
			})
		},
		async actionEditPromotionItem({ commit }, data) {
			return editPlatformPromotionsItem(data).then(r => {
				commit('mutationSetPromotionItem', r.data);
			})
		},
		actionSetPromotionItem({ commit }, data) {
			commit('mutationSetPromotionItem', data);
		},
		actionSetPromotionTab({ commit }, data) {
			commit('mutationSetPromotionTab', data);
		},
		actionSetLoader({ commit }, bool) {
			commit('mutationSetLoader', bool);
		},
		actionClearPromotionItem({ commit }) {
			commit('mutationSetPromotionItem', {});
		},
	},
};
