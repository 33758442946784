var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.componentToRender, _vm._b({
    tag: "component",
    class: _vm.className,
    on: {
      "click": _vm.click
    }
  }, 'component', _vm.linkProps, false), [_vm.prependIcon ? _c('UiSmartIcon', {
    staticClass: "ui-button__icon ui-button__icon--prepend",
    attrs: {
      "name": _vm.prependIcon,
      "color": _vm.prependIconColor,
      "size": 20
    }
  }) : _vm._e(), _vm._t("default", function () {
    return [_vm._v(" " + _vm._s(_vm.label) + " ")];
  }, null, {
    label: _vm.label
  }), _vm.appendIcon ? _c('UiSmartIcon', {
    staticClass: "ui-button__icon ui-button__icon--append",
    attrs: {
      "name": _vm.appendIcon,
      "color": _vm.appendIconColor,
      "size": 20
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }