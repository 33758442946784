import { postApiRequestBuilder, getApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder } from '@/utils/RequestBuilder';

export function createProjectDiscussItem(projectId, discussData) {
	return postApiRequestBuilder(`projects/${projectId}/discussions`).send({ ...discussData, enableComments: true });
}

export function editProjectDiscussItem(projectId, discussData) {
	return putApiRequestBuilder(`projects/${projectId}/discussions`).send(discussData);
}

export function getProjectDiscussItem(projectId, discussId) {
	return getApiRequestBuilder(`projects/${projectId}/discussions/${discussId}`).send();
}

export function deleteProjectDiscussItem(projectId, discussId) {
	return deleteApiRequestBuilder(`projects/${projectId}/discussions/${discussId}`).send();
}