<template>
	<div :class="className">
		<input
			:id="`${group}#${value}`"
			ref="input"
			type="radio"
			:checked="isChecked"
			:value="value"
			:name="group"
			:disabled="disabled"
			:readonly="readonly"
			tabindex="-1"
			@change="$emit('change', $event.target.value)"
		>
		<label tabindex="0" :for="`${group}#${value}`" @keypress.enter.space.prevent="$refs.input.click()">
			<div class="radio"/>
			{{ title }}
		</label>
	</div>
</template>

<script>
export default {
	name: 'UiRadio',
	/**
	 * В модель передается id выбранного переключателя 
	 */
	model: {
		prop: 'modelValue',
		event: 'change'
	},

	props: {
		modelValue: { default: "" },
		/**
		 * Идентификатор выбора. Значение модели принимает значение id выбранного элемента
		 */
		value: {
			type: [String, Boolean],
			required: true,
		},
		/**
		 * Заголовок элемента выбора
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * Признак блокировки элемента
		 */
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		/**
		 * Идентификатор группы. В группу объединяются элементы с одинаковым значением группы.
		 * В каждой группе можно выбрать только один элемент, но групп может быть много
		 */
		group: {
			type: String,
			default: "radio"
		},
	},
	computed: {
		isChecked() {
			return this.modelValue === this.value
		},
		className() {
			return { 
				'ui-radio--disabled': this.disabled,
				'ui-radio--readonly': this.readonly,
			};
		}
	}

}
</script>
