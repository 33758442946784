import { KC_ROLES } from '@/keycloak';

export default {
	computed: {
		modeType() {
			if (this.$kc.hasRole(KC_ROLES.OPERATOR)) {
				return KC_ROLES.OPERATOR;
			} else if (this.$kc.hasRole(KC_ROLES.ADMINISTRATOR)) {
				return KC_ROLES.ADMINISTRATOR;
			} else {
				return KC_ROLES.USER;
			}
		},
		isOperator() {
			return this.modeType === KC_ROLES.OPERATOR;
		},
		isAdministrator() {
			return this.modeType === KC_ROLES.ADMINISTRATOR;
		},
		isUser() {
			return this.modeType === KC_ROLES.USER;
		},
	},
};
