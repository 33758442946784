export const UNIDENTIFIED_MAX_ANNUAL_INVESTED_SUM = 100_000;
export const QUALIFIED_MAX_ANNUAL_INVESTED_SUM = 600_000;

export const OPF = {
	OOO: 'ООО',
	ZAO: 'ЗАО',
	OAO: 'ПАО',
};

export const OPF_CODES = {
	OOO: 'OOO',
	ZAO: 'ZAO',
	OAO: 'OAO',
};

export const OPF_SELECT = [
	{value: OPF_CODES.ZAO, label: 'Акционерное общество'},
	{value: OPF_CODES.OAO, label: 'Публичное акционерное общество'},
	{value: OPF_CODES.OOO, label: 'Общество с ограниченной ответственностью'},
];

export const ROLES = {
	INVESTOR: 'Инвестор',
	FOUNDER: 'ЛПИ',
};

export const ROLE_CODES = {
	INVESTOR: 'INVESTOR',
	FOUNDER: 'FOUNDER',
};

export const STATUS = {
	DRAFT: 'DRAFT',
	PREPARED: 'PREPARED',
	ON_115FZ: 'ON_115FZ',
	ACTIVE: 'ACTIVE',
	REJECTED: 'REJECTED',
	BLOCKED: 'BLOCKED',
};

export const LPI_RATINGS = {
	NO_RATING: 'NO_RATING',
	A: 'A',
	B: 'B',
	C: 'C',
	D: 'D',
	E: 'E',
};

export const STATUS_NAME = {
	DRAFT: 'Черновик',
	PREPARED: 'На модерации',
	ON_115FZ: 'Подтверждение',
	ACTIVE: 'Одобрен',
	REJECTED: 'Отклонен',
};

export const STATUS_115 = {
	ACTIVE: 'ACTIVE',
	ON_115FZ: 'ON_115FZ',
};

export const STATUS_115_NAME = {
	ACTIVE: 'Обновлен',
	ON_115FZ: 'Ожидает обновления',
};

export const ENTITY_MOBILE_STATUSES = {
	ACTIVE: 'Активен',
	PREPARED: 'На модерации',
	BLOCKED: 'Заблокирован',
	REJECTED: 'Отклонена',
	ON_115FZ: 'Подтверждение',
};

export const ENTITY_PATH = {
	"INDIVIDUAL": "pers",
	"INDIVIDUAL_ENTREPRENEUR": "ip",
	"LEGAL_ENTITY": "org",
};

export const ENTITY_TYPES = {
	INDIVIDUAL: 'INDIVIDUAL',
	INDIVIDUAL_ENTREPRENEUR: 'INDIVIDUAL_ENTREPRENEUR',
	LEGAL_ENTITY: 'LEGAL_ENTITY',
};

export const ENTITY_TYPE_NAMES = {
	INDIVIDUAL: 'ФЛ',
	INDIVIDUAL_ENTREPRENEUR: 'ИП',
	LEGAL_ENTITY: 'ЮЛ',
};

export const ROLE_PATH = {
	"FOUNDER": "lpu",
	"INVESTOR": "invest",
};

export const IDENTIFIED_STATUS = {
	NONE: 'NONE', 
	SIMPLIFIED: 'SIMPLIFIED', 
	FULL: 'FULL',
}

export const SINGER_TYPES = {
	PARTICIPANT: 'PARTICIPANT',
	ENTITY: 'ENTITY',
}

export const APPROVE_HISTORY_EVENTS = {
	REJECT: 'REJECT', 
	APPEAL: 'APPEAL', 
	APPROVE: 'APPROVE',
}

export const APPROVE_HISTORY_EVENT_NAMES = {
	REJECT: 'Отклонена', 
	APPEAL: 'Обжалована', 
	APPROVE: 'Одобрена',
}

export const BOOL_VALUE = {
	YES: 'Да', 
	NO: 'Нет', 
}

export const USERS_STATUS = (enabled) => {
	if (enabled) {
		return {
			name: 'Активен',
			styles: {
				'background-color': '#48B349',
				color: '#FFF'
			}
		}
	} else {
		return {
			name: 'Заблокирован',
			styles: {
				'background-color': '#7D7D7D',
				color: '#FFF'
			}
		}
	}
};

export const RUS_CITIZENSHIP = 'RUS';

export const LABELS_CITIZENSHIP = {
	[RUS_CITIZENSHIP]: 'РФ'
}

export const SELECT_CITIZENSHIP = [
	{ value: RUS_CITIZENSHIP, label: LABELS_CITIZENSHIP[RUS_CITIZENSHIP] }
];