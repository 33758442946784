import Vue from 'vue';

export {
	fio,
	participantName,
	upperCaseFirst,
	fromObjectToArray,
	fromArrayToObject,
	getValue,
	setValue,
	getHash,
	getUTCOffset,
	randomUuid,
	randomKey,
	isFile,
	isDocument,
	priceToNumber,
	sequence,
	getNoun,
	convertedString,
	deleteLineBreaksFromString,
	splitStringByNewLine,
	convertingReportUnit,
	compareValues,
	compareObjects,
	compareArrays,
	getParamValue,
	setParamValue,
	mapPromise,
	conditionalReadonly,
	chunk,
	copyObj,
	saveArrayBufferToFile,
	compareObjectsGetNewValues,
	transformToKeyPathFields,
	getCurrentDateWithoutTime,
	getDateWithoutTime
};

function getDateWithoutTime(date) {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function getCurrentDateWithoutTime() {
	const today = new Date();
	return getDateWithoutTime(today);
}

function getNoun(number, one, two, five) {
	let n = Math.abs(number);
	n %= 100;
	if (n >= 5 && n <= 20) {
		return five;
	}
	n %= 10;
	if (n === 1) {
		return one;
	}
	if (n >= 2 && n <= 4) {
		return two;
	}
	return five;
}

function* sequence(...iterables) {
	if (!iterables[Symbol.iterator]()) return [].values();

	for (const iter of iterables) {
		if (typeof iter[Symbol.iterator] !== 'function') continue;

		for (const el of iter) {
			yield el;
		}
	}
}

function isFile(obj) {
	return typeof obj === 'object' && obj != null && obj.file instanceof Object;
}

function isDocument(obj) {
	return (
		typeof obj === 'object' &&
        obj != null &&
        obj.content instanceof Object &&
        isFile(obj.content)
	);
}

function priceToNumber(number, separator = ' ') {
	if (number == null) return null;

	const re = new RegExp(`${separator}`, 'g');
	const parsedNumber = Number.parseFloat(`${number}`.replace(re, ''));
	// Округляем до двух знаков после запятой
	return parsedNumber.toFixed(2);
}

function fromObjectToArray(obj) {
	return Object.values(obj).flat();
}

function fromArrayToObject(arr, key) {
	let obj = {};
	if (!arr) return obj;

	for (const el of arr) {
		if (obj[el[key]] === undefined) {
			obj[el[key]] = el;
			continue;
		}

		if (!(obj[el[key]] instanceof Array)) {
			obj[el[key]] = [obj[el[key]]];
		}

		obj[el[key]].push(el);
	}

	return obj;
}

function fio(lastName, firstName, middleName, short = false) {
	if (short) {
		firstName = firstName ? firstName[0] + '.' : '';
		middleName = !!middleName && !!firstName ? middleName[0] + '.' : '';

		return `${lastName || ''}${firstName ? String.fromCharCode(160) + firstName : ''}${middleName || ''}`.trim();
		// return `${lastName || ''} ${firstName || ''}${middleName || ''}`.trim();
	}
	return `${lastName || ''} ${firstName || ''} ${middleName || ''}`.trim();
}

const ENTITY_TYPE_NAME = {
	INDIVIDUAL: 'ФЛ',
	INDIVIDUAL_ENTREPRENEUR: 'ИП',
	LEGAL_ENTITY: 'ЮЛ',
};
const ROLE_NAME = {
	FOUNDER: 'ЛПИ',
	INVESTOR: 'Инвестор',
};

/**
 * Формирует строку наименования участника в зависимости от его типа
 *
 * @param {ParticipantDto} participant Объект участника
 * @param {object} settings Настройки формирования строки
 * @param {boolean} settings.appendRolename признак добавления роли участника в конце
 * @param {boolean} settings.appendInn признак добавления ИНН в конце
 * @param {string} settings.separator разделитель наименования и ИНН
 * @param {boolean} settings.short признак короткого наименования (ФИО сокращенное до инициалов)
 * @returns {string} Строка наименования участника
 */
function participantName(
	participant,
	{
		appendRolename = false,
		appendInn = false,
		separator = ', ',
		short = true,
	} = {},
) {
	if (!participant) return undefined;
	const partFio = fio(
		participant.lastName,
		participant.firstName,
		participant.middleName,
		short,
	);
	const orgName = short
		? participant.organization?.shortName ?? participant.organizationShortName ?? participant.shortName
		: participant.organization?.name ?? participant.organizationName ?? participant.name;
	const name = /^INDIVIDUAL.*/.test(participant.entityType ?? participant.type)
		? partFio
		: orgName;
	const prefix =
		(participant.entityType ?? participant.type) === 'INDIVIDUAL_ENTREPRENEUR'
			? ENTITY_TYPE_NAME[participant.entityType ?? participant.type] + ' '
			: '';
	const inn = appendInn && participant.inn ? 'ИНН: ' + participant.inn : '';
	const roleName =
		appendRolename && participant.role ? ROLE_NAME[participant.role] : '';
	return (
		prefix +
		name +
		(roleName ? separator + roleName : '') +
		(inn ? separator + inn : '')
	);
}

function upperCaseFirst(str) {
	if (str && str.length > 0) {
		return str.substring(0, 1).toUpperCase() + str.substring(1);
	}
	return str;
}

function getValue(obj, prop, safety = true, value = {}) {
	if (!safety) return getOrSetValue(obj, prop, value);

	if (!isObject(obj))
		throw new Error('"obj" argument must be an Object instance.');

	if (prop instanceof Object)
		throw new Error('"prop" argument must be a primitive type.');

	if (prop.indexOf('.') === 0) throw new Error('invalid "prop" argument');

	const regex = /(?:\[(\d+)\])|(?:\.?(\w+))/g;
	const props = [];
	let match;

	while ((match = regex.exec(prop)) !== null) {
		props.push(match[1] !== undefined ? parseInt(match[1]) : match[2]);
	}

	// If no elements matched, prop is not well-formed
	if (props.length === 0) throw new Error('invalid "prop" argument');

	let temp = obj;
	try {
		while (props.length > 1) {
			temp = temp[props.shift()];
		}

		return temp[props.shift()];
	} catch (err) {
		return undefined;
	}
}

function setValue(obj, prop, value) {
	if (!isObject(obj))
		throw new Error('"obj" prop must be an Object instance.');

	if (prop instanceof Object)
		throw new Error('"prop" argument must be a primitive type.');

	if (prop.indexOf('.') === 0) throw new Error('invalid "prop" argument');

	if (prop.indexOf('.') === -1) return Vue.set(obj, prop, value);

	const props = prop.split('.');
	let temp = obj;

	while (props.length > 1) {
		const p = props.shift();

		if (temp[p] === undefined) {
			Vue.set(temp, p, {});
		}

		if (!isObject(temp[p]))
			throw new Error(
				`Invalid path. Property "${p}" already defined as a primitive value ${temp[p]}.`
			);

		temp = temp[p];
	}

	Vue.set(temp, props.shift(), value);
}

function getHash(target) {
	try {
		if (isObject(target)) {
			return getObjectHash(target);
		}
	} catch (error) {
		throw new Error('Invalid object. Can not get hash.');
	}
}

function getOrSetValue(obj, prop, value) {
	if (!isObject(obj))
		throw new Error('"obj" argument must be an Object instance.');

	if (prop instanceof Object)
		throw new Error('"prop" argument must be a primitive type.');

	if (prop.indexOf('.') === 0) throw new Error('invalid "prop" argument');

	const props = prop.split('.');
	let temp = obj;

	while (props.length > 0) {
		const key = props.shift();

		if (temp[key]) {
			temp = temp[key];
		} else {
			Vue.set(temp, key, value);
			temp = temp[key];
		}
	}

	return temp;
}

function getObjectHash(obj) {
	let res = 0;

	for (const v of Object.values(obj)) {
		if (v == null || typeof v.toString !== 'function') continue;

		res += Array.from(v.toString()).reduce(
			(sum, char, index) =>
				sum + ((37 ** index * char.codePointAt(0)) % 9973),
			0
		);
	}

	return res;
}

function isObject(obj) {
	return (
		obj instanceof Object &&
        !(obj instanceof Array) &&
        !(obj instanceof Function)
	);
}

function getUTCOffset() {
	return -1 * (new Date().getTimezoneOffset() / 60);
}

function randomUuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		var r = (Math.random() * 16) | 0;
		var v = c == 'y' ? (r & 0x3) | 0x8 : r;
		return v.toString(16);
	});
}
function randomKey(prefix = "#") {
	return prefix + (Math.random() * 2147483648 | 0).toString();
}

function convertedString(string) {
	return string.split(/\n+/).map(e => `\n${e}\n`).join(`<br>`);
}

function deleteLineBreaksFromString(string) {
	return string.split("\\n").join(' ');
}

function splitStringByNewLine(message) {
	return message.split("\\n");
}

function convertingReportUnit(code) {
	switch (code) {
	case "YEAR":
		return "Раз в год"
	case "HALF_YEAR":
		return "Раз в полгода"
	case "QUARTER":
		return "Раз в квартал"
	case "MONTH":
		return "Раз в месяц"
	}
}

function compareValues(val1, val2) {
	if (val1 == null && val2 == null) return true;
	if (val1 == undefined && val2 == undefined) return true;
	let t1 = typeof val1;
	let t2 = typeof val2;
	if (t1 !== t2) return false;
	if (t1 === "object") return compareObjects(val1, val2);
	return val1 === val2;
}

function compareObjects(obj1, obj2, fieldFilter = null) {
	if (!obj1 || !obj2) return false;
	if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;
	if (Array.isArray(obj1) || Array.isArray(obj2)) {
		if (Array.isArray(obj1) && Array.isArray(obj2)) return compareArrays(obj1, obj2);
		return false;
	}
	let keys1 = fieldFilter ? Object.keys(obj1).filter(k => fieldFilter(k)) : Object.keys(obj1);
	let keys2 = fieldFilter ? Object.keys(obj2).filter(k => fieldFilter(k)) : Object.keys(obj2);
	if (keys1.length === keys2.length) {
		for (let k of keys1) {
			if (!compareValues(obj1[k], obj2[k])) return false;
		}
		return true;
	}
	return false;
}

function compareObjectsGetNewValues(obj1, obj2, exceptKeys = []) {
	function compare(obj1, obj2) {
		let result = {};

		for (let key in obj2) {
			if (exceptKeys.includes(key)) {
				continue;
			}
			if (isObject(obj2[key]) && isObject(obj1[key])) {
				const nestedDiff = compare(obj1[key], obj2[key]);
				if (Object.keys(nestedDiff).length > 0) {
					result[key] = nestedDiff;
				}
			} else if (!(key in obj1) || obj1[key] !== obj2[key]) {
				result[key] = obj2[key];
			}
		}

		return result;
	}

	return compare(obj1, obj2);
}

function transformToKeyPathFields(obj, message = "Данные изменены") {
	const result = {};

	function recursiveTransform(obj, path = '') {
		for (let key in obj) {
			const newPath = path ? `${path}.${key}` : key;
			if (isObject(obj[key])) {
				recursiveTransform(obj[key], newPath);
			} else {
				result[newPath] = message;
			}
		}
	}

	recursiveTransform(obj);
	return result;
}


function compareArrays(arr1, arr2, mapper = null) {
	if (!arr1 || !arr2) return false;
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
	if (mapper == null || typeof mapper === "function") {
		if (arr1.length === arr2.length) {
			for (let i = 0; i < arr1.length; i++) {
				if (mapper) {
					if (!compareValues(mapper(arr1[i]), mapper(arr2[i]))) return false;
				} else {
					if (!compareValues(arr1[i], arr2[i])) return false;
				}
			}
			return true;
		}
	}
	return false;
}

/**
 * Извлекает значение из массива параметров по коду.
 * Массив параметров состоит из объектов, имеющих код и значение.
 *
 * @param {{ code: string, type?: 'STRING'|'NUMBER'|'DATE', value: string }[]} paramsArr массив параметров
 * @param {string} paramCode код параметра (значение code элемента массива)
 * @param {string=undefined} defaultValue значение по умолчанию - возвращается функцией, если элемент не найден
 * @returns {string|number} значение (value) параметра с указанным кодом, преобразованное в соответствующий тип (только для NUMBER)
 */
function getParamValue(paramsArr, paramCode, defaultValue = undefined) {
	if (paramCode && paramsArr && Array.isArray(paramsArr)) {
		const param = paramsArr.find(p => p.code === paramCode);
		if (param || (typeof param === "object")) {
			const val = param.value;
			switch (param.type) {
			case "NUMBER":
				return Number.parseFloat(val);
			case "DATE":
			case "STRING":
				return val;
			}
		}
	}
	return defaultValue;
}

/**
 * Сохраняет значение в массив параметров по указанному коду.
 * Массив параметров состоит из объектов, имеющих код и значение.
 *
 * @param {{ code: string, type?: 'STRING'|'NUMBER'|'DATE', value: string }[]} paramsArr массив параметров (изменяется по ссылке)
 * @param {string} paramCode код параметра (значение code элемента массива)
 * @param {string|number} value новое значение параметра
 * @param {'STRING'|'NUMBER'|'DATE'=''} type тип устанавливаемого параметра, если не указан, то будет определен автоматически (по значению)
 * @param {boolean=true} uniqueCode признак контроля уникальности по коду параметров в массиве
 */
function setParamValue(paramsArr, paramCode, value, type = "", uniqueCode = false) {
	if (paramCode && paramsArr && Array.isArray(paramsArr) && value) {
		const typ = type && /STRING|NUMBER|DATE/.test(type)
			? type
			: (typeof value === "number")
				? "NUMBER"
				: /\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?/.test(value)
					? "DATE"
					: "STRING";
		const val = typ === "NUMBER" ? new Number(value).toString() : value;
		const param = {
			code: paramCode,
			type: typ,
			value: val,
			s: [].splice
		}

		let idx;
		while (uniqueCode && (idx = paramsArr.findIndex(p => p.code === paramCode) >= 0)) {
			paramsArr.splice(idx, 1);
		}

		paramsArr.push(param);
	}
}

/**
 * Обертка промиса с мэппингом результата
 *
 * @param {Promise<any>} promise обрабатываемый промис
 * @param {(any) => any} mapper мэппер результата промиса
 * @returns
 */
function mapPromise(promise, mapper) {
	if (!promise) return undefined;
	return new Promise((resolve, reject) => {
		promise
			.then(res => resolve(mapper(res)))
			.catch(err => reject(err))
	});
}

function conditionalReadonly(conditionFn, fields) {
	return fields.map((f) => {
		if (Array.isArray(f)) {
			return conditionalReadonly(conditionFn, f);
		}

		return {
			...f,
			readonly: conditionFn,
		};
	});
}

function chunk(array, chunkSize) {
	const chunks = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		const chunk = array.slice(i, i + chunkSize);
		chunks.push(chunk);
	}
	return chunks;
}

function copyObj(obj) {
	try {
		return JSON.parse(JSON.stringify(obj));
	} catch {
		return structuredClone(obj);
	}
}

/**
 *
 * @param {ArrayBuffer} arrayBuffer
 * @param {string} fileName
 * @param {BlobPropertyBag['type']} type
 */
function saveArrayBufferToFile(arrayBuffer, fileName, type = 'application/octet-stream') {
	const blob = new Blob([arrayBuffer], { type });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.download = fileName;
	link.style.display = 'none';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
}

/**
 * Partitions an array into two arrays based on a condition.
 *
 * @param {Array} arr - The array to partition.
 * @param {Function} condn - The condition function. Should return true or false.
 * @returns {Array} An array of two arrays. The first array contains all elements for which the condition function returns true, and the second array contains all elements for which the condition function returns false.
 */
export function partitionArray(arr, condn) {
	return arr.reduce((acc, i) => (acc[condn(i) ? 0 : 1].push(i), acc), [[], []]);
}

/**
 * Слайс для строки опеределенной длины.
 *
 * @param {string} string - Начальная строка.
 * @param {number} sliceLength - Максимальная необходимая длина строки.
 * @returns {string} Возвращает длину в {sliceLength} от изначальной строки + '...'.
 */
export function stringSlicer(string, sliceLength) {
	return string?.length > sliceLength ? string.slice(0, sliceLength).trim() + '...' : string;
}

/**
 * Array to object.
 * @param {Array} arr
 * @param {string} key
 * @returns {Object}
 */
export function keyBy(arr, key) {
	return arr.reduce((acc, item) => {
		acc[item[key]] = item;
		return acc;
	}, {});
}

export function kebabCase(str) {
	return str
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase();
}

/**
 * Возвращает элемент массива с наименьшим значением, определенным через функцию iteratee.
 *
 * @param {Array} arr - Массив элементов.
 * @param {string|Function} iteratee - Функция, используемая для определения значения элемента.
 * @returns {*} - Элемент массива с наименьшим значением.
 */
export function minBy(arr, iteratee) {
	if (!arr || !arr.length) {
		return undefined;
	}
	let min = arr[0];
	let minValue = typeof iteratee === 'string' ? min[iteratee] : iteratee(min);
	for (let i = 1; i < arr.length; i++) {
		const value = typeof iteratee === 'string' ? arr[i][iteratee] : iteratee(arr[i]);
		if (value < minValue) {
			min = arr[i];
			minValue = value;
		}
	}
	return min;
}

/**
 * Возвращает переданное значение без изменений.
 *
 * @param {*} value - Значение, которое нужно вернуть.
 * @returns {*} - Переданное значение без изменений.
 */
export function identity(value) {
	return value;
}

/**
 * Возвращает Promise с задержкой ms.
 * @param {number} ms
 * @returns {Promise<void>}
 */
export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * @returns {{ promise: Promise<any>, resolve: (value?: any) => void, reject: (reason?: any) => void }}
 */
export function createPromise() {
	let resolve;
	let reject;
	return {
		promise: new Promise((res, rej) => {
			resolve = res;
			reject = rej;
		}),
		resolve,
		reject,
	}
}

/**
 * Возвращает значение CSS переменной.
 *
 * @param {string} name
 * @returns {string}
 */
export function getCssVar(name) {
	return getComputedStyle(document.documentElement).getPropertyValue(name);
}


/**
 * Есть ли participantId в сущности
 *
 * @param entity
 * @returns {boolean}
 */
export function entityHasParticipantId(entity, participantId) {
	return [entity.investor, entity.founder].filter(Boolean).some((p) => p.participantId === participantId);
}

/**
 * Получить userEntityId по participantId из списка UserEntity
 *
 * @param entity
 * @returns {boolean}
 */
export function getUserEntityId(list, participantId) {
	return list.find((entity) => entityHasParticipantId(entity, participantId))?.userEntityId || null;
}

/**
 * Recursive diff between two object
 * @param  {Object} base   Object to compare with
 * @param  {Object} object Object compared
 * @return {Object | false} Return a new object who represent the diff OR Return FALSE if there is no difference
 */
export function differenceBetweenObjects(base, object){
	let diff = false;
	if(typeof object == 'object'){
		for(let k in object){
			if(typeof object[k] != 'object'){
				if(base[k] != object[k]){
					if(diff == false){diff = {};}
					diff[k] = object[k];
				}
			}
			else{
				let subDiff = differenceBetweenObjects(base[k],object[k]);
				if(subDiff !== false){
					if(diff == false){diff = {};}
					diff[k] = subDiff;
				}
			}
		}
	}
	return diff;
}

/**
 * @see https://github.com/NickGard/tiny-isequal/blob/master/src/index.js
 */
export const isEqual = (function() {
	var toString = Object.prototype.toString,
		getPrototypeOf = Object.getPrototypeOf,
		getOwnProperties = Object.getOwnPropertySymbols
			? function(c) {
				return Object.keys(c).concat(Object.getOwnPropertySymbols(c));
			}
			: Object.keys;
	function checkEquality(a, b, refs) {
		var aElements,
			bElements,
			element,
			aType = toString.call(a),
			bType = toString.call(b);

		// trivial case: primitives and referentially equal objects
		if (a === b) return true;

		// if both are null/undefined, the above check would have returned true
		if (a == null || b == null) return false;

		// check to see if we've seen this reference before; if yes, return true
		if (refs.indexOf(a) > -1 && refs.indexOf(b) > -1) return true;

		// save results for circular checks
		refs.push(a, b);

		if (aType != bType) return false; // not the same type of objects

		// for non-null objects, check all custom properties
		aElements = getOwnProperties(a);
		bElements = getOwnProperties(b);
		if (
			aElements.length != bElements.length ||
      aElements.some(function(key) {
      	return !checkEquality(a[key], b[key], refs);
      })
		) {
			return false;
		}

		switch (aType.slice(8, -1)) {
		case "Symbol":
			return a.valueOf() == b.valueOf();
		case "Date":
		case "Number":
			return +a == +b || (+a != +a && +b != +b); // convert Dates to ms, check for NaN
		case "RegExp":
		case "Function":
		case "String":
		case "Boolean":
			return "" + a == "" + b;
		case "Set":
		case "Map": {
			aElements = a.entries();
			bElements = b.entries();
			do {
				element = aElements.next();
				if (!checkEquality(element.value, bElements.next().value, refs)) {
					return false;
				}
			} while (!element.done);
			return true;
		}
		case "ArrayBuffer":
			(a = new Uint8Array(a)), (b = new Uint8Array(b)); // fall through to be handled as an Array
		case "DataView":
			(a = new Uint8Array(a.buffer)), (b = new Uint8Array(b.buffer)); // fall through to be handled as an Array
		case "Float32Array":
		case "Float64Array":
		case "Int8Array":
		case "Int16Array":
		case "Int32Array":
		case "Uint8Array":
		case "Uint16Array":
		case "Uint32Array":
		case "Uint8ClampedArray":
		case "Arguments":
		case "Array":
			if (a.length != b.length) return false;
			for (element = 0; element < a.length; element++) {
				if (!(element in a) && !(element in b)) continue; // empty slots are equal
				// either one slot is empty but not both OR the elements are not equal
				if (
					element in a != element in b ||
            !checkEquality(a[element], b[element], refs)
				)
					return false;
			}
			return true;
		case "Object":
			return checkEquality(getPrototypeOf(a), getPrototypeOf(b), refs);
		default:
			return false;
		}
	}

	return function(a, b) {
		return checkEquality(a, b, []);
	};
})();
