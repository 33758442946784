/**
 * Function that checks the transition using the backward-forward button
 * Using in router methods beforeEach and scrollBehavior
 */
function useScrollPosition() {
	let isBackNavigation = false

	window.addEventListener('popstate', () => {
		isBackNavigation = true;
	});

	/**
	 * Load Y position from storage
	 * @param name {string}
	 * @returns {number}
	 */
	function loadPosition(name) {
		try {
			return  parseInt(sessionStorage.getItem(`${name}-position`));
		} catch (ignored) {
			return 0
		}
	}

	/**
	 * Saved to storage scroll position
	 * @param name {string}- router (page) name
	 */
	function savePosition(name) {
		try {
			sessionStorage.setItem(`${name}-position`, window.scrollY.toString());
		} catch (ignored) {}
	}

	/**
	 *  Wrap position to promise
	 *  https://router.vuejs.org/guide/advanced/scroll-behavior.html#Delaying-the-scroll
	 * @param position {{x: number, y: number}}
	 * @param timeout {number}
	 * @returns {Promise<{x: number, y: number}>}
	 */
	function getPromisePosition(position , timeout = 600) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(position)
			}, timeout)
		})
	}

	/**
	 * Return saved from storage scroll position for backward-forward transition
	 * @param name {string}- router (page) name
	 * @param savedPosition {{x: number, y: number} | void}
	 * @returns {Promise<{x: number, y: number}> |{x: number, y: number}}
	 */
	function getPosition(name, savedPosition) {
		if (isBackNavigation) {
			isBackNavigation = false;
			const y = loadPosition(name)
			return y
				? getPromisePosition({ x: 0, y })
				: {x: 0, y: 0};
		}
		 return savedPosition || {x: 0, y: 0}
	}

	return {
		savePosition,
		getPosition
	}
}

export default useScrollPosition()

