/**
 * @type {import('vue').DirectiveOptions}
 */
const toggleHeight = {
	inserted(el, { value }) {
		el.style.overflow = 'hidden';
		el.dataset.isHidden = 'false';
		const animationSpeed = value || 200;

		el.toggleHeight = (open) => {
			const hiddenState = { opacity: 0, height: 0 };
			const visibleState = { opacity: 1, height: `${el.scrollHeight}px` };

			const shouldOpen = open ?? el.dataset.isHidden === 'true';

			if (shouldOpen) {
				el.dataset.isHidden = 'false';
				el.animate([hiddenState, visibleState], animationSpeed);
				el.style.height = 'auto';
				el.style.opacity = visibleState.opacity;
			} else {
				el.dataset.isHidden = 'true';
				el.animate([visibleState, hiddenState], animationSpeed);
				el.style.height = hiddenState.height;
				el.style.opacity = hiddenState.opacity;
			}
		};
	},
};

export default toggleHeight;
