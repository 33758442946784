import { documentToFile, fileToDocument } from '@services/documents';
import { deleteFile, uploadFile } from '@services/files';

export default {
	methods: {
		uploadFileHandler(file) {
			return uploadFile(file);
		},
		deleteFileHandler(file) {
			return deleteFile(file);
		},

		documentToFileMapper(doc) {
			return documentToFile(doc);
		},
		fileToDocumentMapper(file) {
			return fileToDocument(file);
		},
	},
};
