import { getRequestBuilder } from '@utils/RequestBuilder';
import { getApiRequestBuilder } from '@utils/RequestBuilder';
import api from '@configs/api';

export { getProjectsSorts, getProjects, getPublicProjects, getPitchesSorts };

async function getProjects(type, pageNumber, pageSize, filters = [], sortBy = null) {
	return new getRequestBuilder(`/${api.url}${type}`)
		.query([
			['pageNumber', pageNumber],
			['pageSize', pageSize],
			['sortBy', sortBy]
		])
		.filters(filters)
		.send()
		.then((res) => res.data)
		.catch(console.warn);
}

export function getSuccessProjects(pageNumber, pageSize, sortBy = null) {
	return getApiRequestBuilder('projects/public/released')
		.query([
			['pageNumber', pageNumber],
			['pageSize', pageSize],
			['sortBy', sortBy]
		])
		.send();
}

async function getPublicProjects(type, pageNumber, pageSize, filters = [], sortBy = null) {
	return new getRequestBuilder(`/${api.url}${type}/public`).public
		.query([
			['pageNumber', pageNumber],
			['pageSize', pageSize],
			['sortBy', sortBy]
		])
		.filters(filters)
		.send()

		.then((res) => res.data)
		.catch(console.warn);
}

export function getProjectsBanner(type, isAuthenticated, sortBy) {
	if (isAuthenticated) {
		return getProjects(type, 1, 1, [], sortBy)
	} 
	return getPublicProjects(type, 1, 1, [], sortBy)
	
}

async function getProjectsSorts() {
	return getRequestBuilder(`/${api.url}projects/public/sortings`)
		.public.send()
		.then((res) => res.data)
		.catch(console.warn);
}

async function getPitchesSorts() {
	return getRequestBuilder(`/${api.url}pitches/public/sortings`)
		.public.send()
		.then((res) => res.data)
		.catch(console.warn);
}
