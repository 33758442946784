export default {
	methods: {
		tipsProvider(formName) {
			const prefix = formName + "/";
			const msgTexts = this.$store.getters['dictionary/messageTexts'];
			if (msgTexts) {
				return msgTexts
					.filter(tip => tip.code.startsWith(prefix))
					.map(tip => ({ ...tip, code: tip.code.substr(prefix.length)}))
			}
			return [];
		},
	},
};
