var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UiSkeleton', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_vm.history ? _c('div', {
    staticClass: "pb-16"
  }, [_c('h4', {
    staticClass: "h4 mb-8"
  }, [_vm._v("История изменений")]), _vm._l(_vm.history, function (entry) {
    return _c('p', {
      key: entry.created,
      staticClass: "mb-4 fz-16"
    }, [_c('time', {
      staticClass: "greyscale-5-text",
      attrs: {
        "datetime": "date.created"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDate(entry.created, 'DD.MM.YYYY HH:NN')) + " ")]), _vm._v(" " + _vm._s(entry.text) + " ")]);
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }