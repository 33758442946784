import { postApiRequestBuilder, getApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder } from '@/utils/RequestBuilder';

export function createPlatformNewsItem(newsData) {
	return postApiRequestBuilder(`platform-news`).send(newsData);
}

export function editPlatformNewsItem(newsData) {
	return putApiRequestBuilder(`platform-news`).send(newsData);
}

export function getPlatformNewsItem(newsId) {
	return getApiRequestBuilder(`platform-news/${newsId}`).public.send();
}

export function getPlatformNews() {
	return getApiRequestBuilder(`platform-news`).public.send();
}

export function getPublicPlatformNews() {
	return getApiRequestBuilder(`platform-news/public`).public.send();
}

export function deletePlatformNewsItem(newsId) {
	return deleteApiRequestBuilder(`platform-news/${newsId}`).send();
}

export function getGeneralPlatformNewsItem() {
	return getApiRequestBuilder(`platform-news/public/general`).public.send();
}

export function getPlatformNewsSubscription() {
	return getApiRequestBuilder(`/platform-news/subscription`).send();
}

export function addPlatformNewsSubscription() {
	return postApiRequestBuilder(`/platform-news/subscription`).send();
}

export function deletePlatformNewsSubscription() {
	return deleteApiRequestBuilder(`/platform-news/subscription`).send();
}