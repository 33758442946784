class Captcha {
    generateNumber() {
        const x = 1000;
        const y = 9999;
        return Math.floor(x + (y - x) * Math.random());
    }
}

export default function CaptchaService(Vue) {
    Vue.mixin({
        created: function () {
            this.$captcha = new Captcha();
        },
    });
}
