var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "upload-block mb-16"
  }, [_c('span', {
    staticClass: "upload-block-title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.subTitile ? _c('span', {
    staticClass: "upload-block-subtitle"
  }, [_vm._v(_vm._s(_vm.subTitile) + " ")]) : _vm._e(), _c('UiButton', {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      "prependIcon": _vm.prependIconName,
      "appendIcon": _vm.appendIconName,
      "type": _vm.btnType,
      "label": _vm.btnLabel
    },
    on: {
      "click": _vm.click
    }
  }), _c('input', {
    staticClass: "input-file",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "file-input",
      "type": "file",
      "name": "name"
    }
  }), _c('span', {
    staticClass: "input-tips"
  }, [_vm._v(_vm._s(_vm.fileType) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }