<template>
	<div :class="wrapperClasses">
		<div class="input-header">
			<label v-if="label" :class="labelClasses">
				{{ label }}
				<div v-if="tip" class="input__tip form__tip">
					<UiTip
						:message="tip.message"
					/>
				</div>
			</label>
		</div>
		<div 
			class="ui-input__wrapper" 
			:class="{ 'ui-textarea__wrapper': type === 'textarea' }"
		>
			<textarea
				v-if="type === 'textarea'"
				ref="input"
				:maxlength="maxLength"
				class="ui-input ui-input--textarea"
				:class="additionalClasses"
				:value="modelProxy"
				:disabled="disabled"
				:placeholder="placeholder"
				:readonly="readonly"
				:name="name"
				@input="modelProxy = $event.target.value"
				@keydown="onKeydown"
				v-on="$listeners"
			/>
			<input
				v-else
				ref="input"
				v-model="maskedValue"
				v-maska
				:maxlength="maxLength"
				:data-maska="maskJson"
				:data-maska-eager="maskEager ? '' : undefined"
				:data-maska-tokens="maskTokens"
				:data-maska-reversed="maskReversed ? '' : undefined"
				class="ui-input"
				:class="additionalClasses"
				:type="'text'"
				:disabled="disabled"
				:placeholder="placeholder"
				:readonly="readonly"
				:name="name"
				@keydown="onKeydown"
				@maska="onMaska"
				v-on="$listeners"
			>
			<div v-if="showLimitWatcher" :class="limitWatcherClasses">
				{{ limitWatcher }}
			</div>
			<div v-if="type!='textarea'" class="icons__wrapper" @click="inputFocus">
				<div class="prefix">
					<div v-if="appendIcon" class="append-icon"/>
				</div>
				<div class="postfix">
					<div
						v-if="type === 'password'"
						class="toggle-btn"
						:class="passwordClasses"
						@click="togglePasswordVisibility"
					/>
					<div
						v-else-if="clearable"
						type="reset" 
						class="clear-btn"
						title="Click me to clear the input field"
						@click="resetInput($event)"
					>×</div>
					<div v-if="prependIcon" class="prepend-icon"/>
				</div>
			</div>
		</div>
		<span 
			v-if="caption || error"
			class="input-caption"
			:class="error ? 'input-caption__error' : ''"
		>
			{{ error ? error : caption }}
		</span>
	</div>
</template>

<script>
import SIZES from "../sizes.js";
import debounce from 'lodash.debounce';

export default {
	name: 'UiInput',
	model: {
		prop: 'modelValue',
		event: 'emitValue'
	},
	props: {
		/**
		 * Значение поля
		 * тип может быть любой
		 * @type {any}
		 */
		modelValue: {},
		/**
		 * Изначальное значение поля
		 * @type {string}
		 */
		initialValue: {
			type: String,
			default: undefined,
		},
		/**
		 * Использовать замаскированое значение инпута
		 * @type {boolean}
		 */
		useMaskedValue: {
			type: Boolean,
			default: false,
		},
		/**
		 * Тип инпута
		 * для более тонкой настройки используйте `mask`
		 * type="money" => 100 000 000 000 000
		 * @type {'text' | 'money' | 'textarea'}
		 */
		type: {
			type: String,
			default: 'text'
		},
		/**
		 * Аттрибут [name]
		 * @type {string}
		 */
		name: {
			type: String,
			default: undefined,
		},
		/**
		 * Аттрибут [disabled]
		 * @type {boolean}
		 */
		disabled: {
			type: Boolean,
			default: false
		},
		/**
		 * Сообщение об ошибке
		 * @type {string}
		 */
		error: {
			type: String,
			default: ''
		},
		/**
		 * Аттрибут [readonly]
		 * @type {boolean}
		 */
		readonly: {
			type: Boolean,
			default: false
		},
		/**
		 * Аттрибут [placeholder]
		 * @type {string}
		 */
		placeholder: {
			type: String,
			default: ' '
		},
		/**
		 * Текст для <label>
		 * @type {string}
		 */
		label: {
			type: String,
			default: ''
		},
		/**
		 * Подсказка
		 * @type {string}
		 */
		caption: {
			type: String,
			default: ''
		},
		/**
		 * Размер
		 * @type {'xl' | 'l' | 'm' | 's' | 'xs' | 'text_xl'}
		 */
		size: {
			type: String,
			default: 'l'
		},
		/**
		 * Добавить инонку справа
		 * @type {boolean}
		 */
		appendIcon: {
			type: Boolean,
			default: false
		},
		/**
		 * Добавить инонку слева
		 * @type {boolean}
		 */
		prependIcon: {
			type: Boolean,
			default: false
		},
		/**
		 * Добавить кнопку сброса поля
		 * @type {boolean}
		 */
		clearable: {
			type: Boolean,
			default: true
		},
		/**
		 * Маска, передается в аттрибут data-mask
		 * @see https://beholdr.github.io/maska/#/?id=mask-options
		 * @type {boolean}
		 */
		mask: {
			type: [String, Array],
			default: undefined
		},
		/**
		 * Аттрибут data-maska-eager
		 * @see https://beholdr.github.io/maska/#/?id=mask-options
		 * @type {boolean}
		 */
		maskEager: {
			type: Boolean,
			default: false
		},
		/**
		 * Аттрибут data-maska-eager
		 * @see https://beholdr.github.io/maska/#/?id=mask-options
		 * @type {string}
		 */
		maskTokens: {
			type: String,
			default: undefined,
		},
		/**
		 * Аттрибут data-maska-reversed
		 * @see https://beholdr.github.io/maska/#/?id=mask-options
		 * @type {string}
		 */
		maskReversed: {
			type: Boolean,
			default: false,
		},
		/**
		 * Функция трансформации
		 * принимает незамаскированное значение
		 * событие `emitValue` будет отправлять трансформированное значение
		 * @type {(unmaskedValue: string) => any}
		 */
		transform: {
			type: Function,
			default: undefined,
		},

		/**
		 * Функция трансформации modelValue в string
		 * обратная функция `transform`
		 * принимает значение modelValue
		 * @type {(modelValue: any) => string}
		 */
		transformValue: {
			type: Function,
			default: (v) => v,
		},

		/**
		 * Устанавливает задержку подъема события input в миллисекундах (ms), 
		 * накапливая изменения до тех пор, 
		 * пока пользователь не прекратит ввод и не пройдет delay
		 * (debounce)
		 * @type {boolean}
		 */
		delay: {
			type: Number,
			default: 0,
		},
		/**
		 * Объект подсказки
		 */
		tip: {
			type: Object,
			default: null,
		},
		/**
		 * Возможность "растягивать" окно textarea
		 */
		resize: {
			type: Boolean,
			default: false,
		},
		/**
		 * Label nowrap
		 */
		 nowrap: {
			type: Boolean,
			default: false,
		},
		/**
		 * Максималое кол-во вводимых символов
		 */
		 maxLength: {
			type: Number,
			default: Infinity,
		},

	},
	data() {
		return {
			passwordVisible: false,
			maskedValue: this.initialValue ?? this.transformValue(this.modelValue),
		}
	},
	computed: {
		modelProxy: {
			get() {
				return this.transformValue(this.modelValue);
			},
			set(v) {
				this.debouncedEmit(v);
			},
		},
		debouncedEmit() {
			return debounce(function(v) {
				// eslint-disable-next-line vue/no-use-computed-property-like-method
				this.$emit('emitValue', this.computedTransform(v));
			}, this.delay);
		},
		wrapperClasses() {
			return { 'ui-input__container': true, 'flex-form': this.type === 'textarea' };
		},
		computedTransform() {
			const identity = v => v;
			const transformByType = {
				text: identity,
				money: Number,
			};
			return this.transform || transformByType[this.type] || identity;
		},
		labelClasses() {
			return {
				'input-label': true,
				'nw': this.nowrap
			}
		},
		additionalClasses() {
			const res = [];

			if (this.error) res.push('error');

			if (this.appendIcon) res.push('input__append-icon')
			if (this.prependIcon) res.push('input__prepend-icon')
			if (this.clearable) res.push('ui-input--clearable')

			if (this.resize) res.push('input__resize');

			let size = '';

			if (this.type === 'textarea') {
				size = SIZES[this.size.toLowerCase()] ? `size_text_${SIZES[this.size]}` : 'size_text_l'
			} else {
				size = SIZES[this.size.toLowerCase()] ? `size_${SIZES[this.size]}` : 'size_l'
			}
			
			res.push(size.toLowerCase())

			return res;
		},
		passwordClasses() {
			return this.passwordVisible ? 'hide-password-btn' : 'show-password-btn';
		},
		getMask() {
			return this.type === 'money' 
				? getIntegerMask(15, true)
				: this.mask;
		},
		maskJson() {
			return typeof this.getMask === 'string' ? this.getMask : JSON.stringify(this.getMask)
		},
		isPassword() {
			return this.type === 'password';
		},
		valueLength() {
			return this.maskedValue?.length || 0;
		},
		limitWatcher() {
			return `${this.valueLength}/${this.maxLength}`;
		},
		isMaxLenght() {
			return this.valueLength === this.maxLength;
		},
		limitWatcherClasses() {
			return {
				'ui-input__limit-watcher': true,
				'max-length': this.isMaxLenght
			}
		},
		showLimitWatcher() {
			return this.maxLength && this.maxLength !== Infinity && this.type === 'textarea'
		}
	},
	watch: {
		modelValue(modelValue) {
			this.maskedValue = this.transformValue(modelValue);
		},
	},
	mounted() {
		if (this.isPassword) this.$refs.input.type = 'password';
	},
	methods: {
		onMaska(event) {
			if (this.useMaskedValue) {
				this.modelProxy = event.detail.masked;
			} else {
				this.modelProxy = event.detail.unmasked;
			}
		},
		resetInput() {
			this.modelProxy = '';
			this.maskedValue = '';
		},
		inputFocus() {
			this.$refs.input.focus()
		},
		togglePasswordVisibility() {
			if (this.$refs.input.type === 'password') {
				this.$refs.input.type = 'text'
				this.passwordVisible = true
			} else {
				this.$refs.input.type = 'password'
				this.passwordVisible = false
			}
		},
		onKeydown(e) {
			if (e.key === 'Enter' && this.isMaxLenght) {
				e.preventDefault();
			} else if (e.key === 'Enter' && !this.isMaxLenght) {
				this.$emit('send', this.maskedValue);
			}
		}
	}
}

export function getIntegerMask(intDigits, thousants) {
	if (intDigits <= 0) return [];
	if (!thousants) {
		let masks = getIntegerMask(intDigits - 1, thousants);
		masks.push("#".repeat(intDigits));
		return masks;
	}
	const rest = intDigits % 3;
	const groups = (intDigits - rest) / 3;
	let masks = getIntegerMask(intDigits - 1, thousants);
	masks.push(("#".repeat(rest) + " ###".repeat(groups)).trim());
	return masks;
}

function reverseStr(str) {
	if (!str) return "";
	return str.split("").reverse().join("");
}

export function getDecimalMask(intDigits, fractions, thousants) {
	const intMasks = getIntegerMask(intDigits, thousants);
	let fracMasks = getIntegerMask(fractions, thousants);
	fracMasks = fracMasks.map(msk => reverseStr(msk));
	fracMasks.push("");

	let masks = [];
	intMasks.forEach(im => {
		fracMasks.forEach(fm => {
			if (fm)
				masks.push(im + "," + fm);
			else
				masks.push(im);
		});
	});
	return masks;
}

</script>
