var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "ui-info__block"
  }, [_c('span', {
    staticClass: "ui-info__block-label"
  }, [_vm._t("label", function () {
    return [_vm._v(" " + _vm._s(_vm.label) + " ")];
  })], 2), _c('span', {
    staticClass: "ui-info__block-value",
    style: _vm.valueStyles
  }, [_vm._t("value", function () {
    return [_vm._v(" " + _vm._s(_vm.infoValue) + " ")];
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }