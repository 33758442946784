var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('UiButton', {
    staticClass: "ui-export__btn",
    attrs: {
      "label": _vm.exportLabel,
      "type": "secondary",
      "prependIcon": "icons-list",
      "size": "xs"
    },
    on: {
      "click": _vm.exportTable
    }
  }), _c('ExportToExcelModal', {
    ref: "exportToExcelModal",
    attrs: {
      "fileName": _vm.sheetTitle,
      "multiple": _vm.multiple
    },
    on: {
      "changeExcelName": _vm.changeExcelName
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }