var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Portal', [_c('transition', {
    attrs: {
      "name": "ui-modal--slide-fade",
      "mode": "out-in"
    }
  }, [_vm.show ? _c('div', {
    staticClass: "ui-modal",
    class: {
      'ui-modal__notify': _vm.isNotify
    }
  }, [_c('div', {
    staticClass: "ui-modal__overlay",
    on: {
      "click": _vm.hideWindow
    }
  }), _c('div', {
    ref: "modalContent",
    staticClass: "ui-modal__content custom-scrollbar custom-scrollbar--small",
    style: _vm.styleStr,
    attrs: {
      "tabindex": "-1"
    }
  }, [_c('UiSkeleton', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "loading": _vm.skeletonLoading,
      "height": _vm.skeletonHeight,
      "message": _vm.skeletonMessage
    }
  }, [_vm._t("default")], 2)], 1)]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }