import Requests from '@utils/Requests.js';
import api from "@configs/api";

const DEFAULT_CHUNK_SIZE = 6291456; // 6Mb - минимальный размер чанка для S3
const DEFAULT_POOL_SIZE = 4;

/** Количество повторных попыток загрузки при ошибках */
const MAX_RETRIES = 3;

/** Ожидание загрузки */
const CHUNK_STATUS_PENDING = "pending";
/** Загрузка в процессе. Для файла - если хотя бы одна часть PROCESSING или SUCCEED */
const CHUNK_STATUS_PROCESSING = "processing";
/** Загрузка успешно завершена. Для файла - когда все его части SUCCEED */
const CHUNK_STATUS_SUCCEED = "succeed";
/** Не удалось провести загрузку. Для части - после MAX_RETRIES неудачных попыток. Для файла - если хоть одна часть FAILED */
const CHUNK_STATUS_FAILED = "failed";

/**
 * Часть файла загружаемая за один раз
 */
class FileChunk {
	__task;
	__status; // pending, processing, failed, succseeded
	__retries;
	__index;
	__offset;
	__size;

	constructor (task, index) {
		this.__task = task;
		this.__index = index;
		this.__offset = task.getChunkSize() * index;
		this.__size = Math.min(task.getChunkSize(), task.getFile().size - this.__offset);
		this.__status = CHUNK_STATUS_PENDING;
		this.__retries = 0;
	}

	__processError(err, rejectFunc) {
		if (++this.__retries > MAX_RETRIES) { // количество попыток
			this.__status = CHUNK_STATUS_FAILED; // ошибка, если превышено...
		} else {
			this.__status = CHUNK_STATUS_PENDING; // повтор, если не превышено...
		}
		rejectFunc(err);
	}

	/**
	 * Загрузить часть файла
	 * @returns {Promise<FileSpec>} промис загрузки части файла, разрешается по окончании загрузки
	 */
	process() {
		this.__status = CHUNK_STATUS_PROCESSING;
		let isLastChunk = this.__chunkSize === this.__index;
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append('chunk', this.__task.getFile().slice(this.__offset, this.__offset + this.__size));
			Requests.postRequest(
				`/${api.url}files/chunk/${this.__task.getFileId()}`,
				formData,
				{
					headers: {
						"chunk-offset": this.__offset,
						"chunk-size": this.__size,
						"chunk-number": this.__index,
						"chunk-last-part": isLastChunk,
					},
					timeout: 0,
					processData: false,
					mimeType: 'multipart/form-data',
					contentType: false,
				},
				true
			)
				.then(res => {
					if (res.status === 200 || res.status === 201) {
						this.__status = CHUNK_STATUS_SUCCEED;
						resolve(res);
					} else {
						this.__processError(res, reject);
					}
				})
				.catch(err => {
					this.__processError(err, reject);
				})
		});
	}

	/**
	 * Статус загрузки части файла. См. CHUNK_STATUS_*
	 * @returns {string} Статус загрузки части файла
	 */
	getStatus() {
		return this.__status;
	}

	/**
	 * @returns {number} Размер части файла
	 */
	getSize() {
		return this.__size;
	}

	/**
	 * @returns {FileTask} Задание загрузки файла, которому принадлежит часть
	 */
	getTask() {
		return this.__task;
	}
}

/**
 * Задание загрузки файла
 */
class FileTask {
	__file;
	__fileId;
	__chunks;
	__chunkSize;
	__promise;
	__promiseResolve;
	__promiseReject;

	constructor(file, fileId, chunkSize = DEFAULT_CHUNK_SIZE) {
		this.__file = file;
		this.__fileId = fileId;
		this.__chunkSize = chunkSize;
		this.__chunks = [];
		let chunkCount = Math.ceil(this.__file.size / this.__chunkSize);
		for (let i = 0; i < chunkCount; i++) {
			this.__chunks.push(new FileChunk(this, i));
		}
	}

	/**
	 * Статус загрузки задания. Вычисляется на основании статусов его частей.
	 * @returns {string} Статус загрузки задания
	 */
	getStatus() {
		let count_process = 0;
		let count_succeed = 0;
		let count_failed = 0;
		for (let idx in this.__chunks) {
			const ch = this.__chunks[idx];
			switch (ch.getStatus()) {
			case CHUNK_STATUS_PROCESSING:
				count_process++; break;
			case CHUNK_STATUS_SUCCEED:
				count_succeed++; break;
			case CHUNK_STATUS_FAILED:
				return CHUNK_STATUS_FAILED;
			}
			if (count_failed > 0) return CHUNK_STATUS_FAILED;
		}
		if (count_succeed === this.__chunks.length) return CHUNK_STATUS_SUCCEED;
		if (count_process > 0 || count_succeed > 0) return CHUNK_STATUS_PROCESSING;
		return CHUNK_STATUS_PENDING;
	}

	/**
	 * @returns {Promise} Промис загрузки задания, разрешается при успешном завершении загрузки всех частей или при прерывании загрузки
	 */
	getPromise() {
		if (!this.__promise) {
			this.__promise = new Promise((resolve, reject) => {
				this.__promiseResolve = resolve;
				this.__promiseReject = reject;
			});
		}
		return this.__promise;
	}
	/**
	 * Разрешает промис задания
	 * @param {*} res результат промиса
	 */
	doResolve(res) {
		if (this.__promiseResolve) {
			this.__promiseResolve(res);
		}
	}
	/**
	 * Отменяет промис задания
	 * @param {*} res ошибка промиса
	 */
	doReject(res) {
		if (this.__promiseReject) {
			this.__promiseReject(res);
		}
	}

	/**
	 * Признак завершения задачи. Задача либо успешно завершена, либо прервана из-за ошибки.
	 * @returns {boolean} задача завершена
	 */
	isFinished() {
		const status = this.getStatus();
		return status === CHUNK_STATUS_SUCCEED || status === CHUNK_STATUS_FAILED;
	}

	/**
	 * @returns {File} Загружаемый файл
	 */
	getFile() {
		return this.__file;
	}

	/**
	 * @returns {string|number} Id загружаемого файла
	 */
	getFileId() {
		return this.__fileId;
	}

	/**
	 * @returns {FileChunk} Следующая часть файла, ожидающая загрузки
	 */
	getNextChunk() {
		const chunk = this.__chunks.find(ch => ch.getStatus() === CHUNK_STATUS_PENDING);
		return chunk;
	}

	/**
	 * @returns {number} Размер частей файла
	 */
	getChunkSize() {
		return this.__chunkSize;
	}

	/**
	 * @returns {number} Количество частей файла
	 */
	getChunkCount() {
		return this.__chunks.length;
	}
	/**
	 * @returns {number} Размер файла
	 */
	getSize() {
		return this.__chunks.reduce((prev, ch) => prev + ch.getSize(), 0);
	}

	/**
	 * @returns {{totalChunks:number,totalBytes:number,doneChunks:number,doneBytes:number,status:string}} Состояние загрузки файла
	 */
	getProgress() {
		let totalChunks = this.__chunks.length;
		let totalBytes = getSize();
		let doneChunks = 0;
		let doneBytes = 0;
		this.__chunks.forEach(ch => {
			if (t.getStatus() === CHUNK_STATUS_SUCCEED) {
				doneChunks++;
				doneBytes += ch.getSize();
			}
		})
		return {
			totalChunks,
			totalBytes,
			doneChunks,
			doneBytes,
			status: this.getStatus(),
		}
	}
}

/**
 * Элемент пула загрузки
 */
class FilePoolItem {
	__chunk;
	__promise;

	/**
	 * Конструктор элемента загрузки файла
	 * @param {FileChunk} chunk Часть файла запланированная к загрузке
	 * @param {{resolve, reject}} _ Обработчики завершения загрузки элемента
	 */
	constructor(chunk, {resolve, reject}) {
		this.__chunk = chunk;
		this.__promise = this.__chunk.process();
		this.__promise
			.then(res => resolve(this.__chunk, res))
			.catch(err => reject(this.__chunk, err))
	}

	/**
	 * @returns {FileChunk} Загружаемая часть файла
	 */
	getChunk() {
		return this.__chunk;
	}
}

/**
 * Пул загрузки файлов
 */
class FileUploadPool {
	__poolSize;
	__queue;
	__pool;
	__chunkSize;
	__queueIndex

	/**
	 * Конструктор пула
	 * @param {number} size Размер пула. Устанавливает количество одновременный загрузок
	 * @param {number} chunkSize Размер частей, на которые разбиваются файлы для загрузки
	 */
	constructor(size = DEFAULT_POOL_SIZE, chunkSize = DEFAULT_CHUNK_SIZE) {
		this.__poolSize = size;
		this.__queue = [];
		this.__pool = [];
		this.__chunkSize = chunkSize;
		this.__incQueueIndex();
	}

	/**
	 * Переместить указатель очередного файла на следующий файл в очереди
	 */
	__incQueueIndex() {
		if (this.__queue.length === 0) return this.__queueIndex = -1;
		if (this.__queue.length === 1) return this.__queueIndex = 0;
		if (++this.__queueIndex >= this.__queue.length) {
			this.__queueIndex = 0;
		}
		return this.__queueIndex;
	}

	/**
	 * Поиск очередной части для загрузки
	 */
	__findNextChunk() {
		const lastQueueIndex = this.__queueIndex;
		while (true) {
			this.__incQueueIndex(); // следующая задача
			if (this.__queueIndex < 0) return null; // нет задач
			const task = this.__queue[this.__queueIndex];
			if (!task.isFinished()) { // завершенная задача (или прерванная) - продолжаем
				const chunk = task.getNextChunk(); // следующий чанк задачи
				if (chunk) return chunk;
			}
			if (this.__queueIndex === lastQueueIndex) return null; // проверка на цикл
		}
	}

	/**
	 * Обработчик завершения загрузки очередной части
	 * @param {FileChunk} chunk Загруженная (или прерванная) часть файла
	 * @param {*} res Результат промиса загрузки или ошибка загрузки
	 * @param {boolean} flag Признак успешного завершения загрузки
	 */
	__onChunkFinished(chunk, res, flag) {
		// освобождение пула
		const poolIdx = this.__pool.findIndex(p => p.getChunk() === chunk);
		if (poolIdx > -1) {
			this.__pool.splice(poolIdx, 1);
		}
		// проверка состояния всей задачи
		if (flag) {
			if (chunk.getTask().getStatus() === CHUNK_STATUS_SUCCEED) {
				chunk.getTask().doResolve(res);
			}
		} else {
			if (chunk.getTask().getStatus() === CHUNK_STATUS_FAILED) {
				chunk.getTask().doReject(res);
			}
		}
		// продолжение
		this.__nexTick();
	}

	/**
	 * Запуск очередного шага планировщика пула
	 */
	__nexTick() {
		while (this.__pool.length < this.__poolSize) {
			const chunk = this.__findNextChunk();
			if (chunk) {
				const handlers = {
					resolve: (chunk, res) => this.__onChunkFinished(chunk, res, true),
					reject: (chunk, err) => this.__onChunkFinished(chunk, err, false),
				}
				this.__pool.push(new FilePoolItem(chunk, handlers));
			} else {
				break;
			}
		}
	}

	/**
	 * Поставить в очередь задание на загрузку файла
	 * @param {File} file Файл для загрузки
	 * @param {string|number} fileId Id загружаемого файла
	 * @returns {Promise} Промис загрузки файла разрешается при успешном завершении загрузки, отменяется при ошибке загрузки.
	 */
	enqueue(file, fileId) {
		const task = new FileTask(file, fileId, this.__chunkSize);
		this.__queue.push(task);
		this.__queueIndex = this.__queue.length - 1;
		this.__nexTick();
		return task.getPromise();
	}

	/**
	 * @returns {{index:number,file:FileSpec,status:any}[]} Задания в очереди и их состояние
	 */
	getQueue() {
		return this.__queue.map((t, idx) => {
			return {
				index: idx,
				file: {
					name: t.getFile().name,
					type: t.getFile().type,
					size: t.getSize(),
				},
				status: t.getProgress(),
			}
		})
	}

	/**
	 * Удалить задание из очереди.
	 * @param {number} idx Индекс удаляемого задания.
	 */
	dequeue(idx) {
		if (idx >= 0 && idx < this.__queue.length) {
			this.__queue.splice(idx, 1);
			if (this.__queueIndex > idx) this.__queueIndex--;
		}
	}

	/**
	 * Удалить из очереди все завершенные задания.
	 */
	queueClearFinished() {
		let i = 0;
		while (i < this.__queue.length) {
			const task = this.__queue[i];
			if (task.isFinished()) {
				this.__queue.splice(i, 1);
				if (this.__queueIndex > i) this.__queueIndex--;
			} else {
				i++;
			}
		}
	}

	/**
	 * @returns {{totalChunks:number,totalBytes:number,doneChunks:number,doneBytes:number}} Общий прогресс состояния загрузки по всей очереди
	 */
	getProgress() {
		let totalChunks = 0;
		let totalBytes = 0;
		let doneChunks = 0;
		let doneBytes = 0;
		this.__queue.forEach(t => {
			const tCount = t.getChunkCount();
			const tBytes = t.getSize();
			totalChunks += tCount;
			totalBytes += tBytes;
			if (t.getStatus() === CHUNK_STATUS_SUCCEED) {
				doneChunks += tCount;
				doneBytes += tBytes;
			}
		})
		return {
			totalChunks,
			totalBytes,
			doneChunks,
			doneBytes,
		}
	}
}

export {
	FileChunk,
	FileTask,
	FilePoolItem,
	FileUploadPool,
};

const _instance = new FileUploadPool();

/**
 * @returns {FileUploadPool} Синглтон пула загрузки
 */
export default function pool() {
	return _instance;
}
