import { SINGER_TYPES } from '@/configs/participants';

export function isDocumentSigned(document) {
	return !!document?.signs?.length && document.signs.every((sign) => !!sign.type);
}

export function isDocumentSignedByThirdParty(document) {
	return document.legalDoc && document.signs.some(sign => sign.signerType !== SINGER_TYPES.PARTICIPANT);
}

export function getDocumentSignedAt(document) {
	const sign = document?.signs.find(sign => sign.acceptedAt || sign.signedAt);
	return sign?.acceptedAt || sign?.signedAt;
}

export function isDocumentSignedByParticipant(document, participantId) {
	return !!document?.signs?.length && document.signs.some(
		sign => sign.signerType === SINGER_TYPES.PARTICIPANT 
		&& sign.signerId === participantId
		&& !!sign.type
	);
}