import {getNotificationPatterns, updateNotificationPattern} from "@services/notificationPatterns";
import {EMAIL_NOTIFICATION_CHANNEL_CODE} from "@configs/notifications";

const fetch = (state) => {
	state.patternsLoading = true;
	return getNotificationPatterns().then(res => {
		state.patterns = [
			...res.data.items
		];
	}).finally(() => {
		state.patternsLoading = false;
	});
};

export default {
	state: {
		patterns: null,
		patternsLoading: false
	},

	actions: {
		async actionSaveNotificationPattern({state}, pattern) {
			state.patternsLoading = true;
			const dto = {
				id: pattern.id,
				type: pattern.type,
				channel: pattern.channel,
				subjectPattern: pattern.subjectPattern,
				messagePattern: pattern.channel.code === EMAIL_NOTIFICATION_CHANNEL_CODE ? pattern.messagePatternHtml : pattern.messagePattern
			};
			return updateNotificationPattern(dto)
				.then(() => fetch(state))
				.catch((e => {
					state.patternsLoading = false;
					throw e;
				}));
		},

		async actionFetchNotificationPatterns({state}) {
			if (!state.patterns) {
				state.patterns = [];
				return fetch(state);
			}
		}
	},

	getters: {
		getterPatterns(state) {
			return () => state.patterns;
		},

		getterPatternsLoading(state) {
			return () => state.patternsLoading;
		}
	}
}
