var render = function () {
  var _vm$currentTable$item2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-table-list"
  }, [_vm.isHeaderShown ? _c('div', {
    staticClass: "ui-table-list__header"
  }, [_vm._t("header", function () {
    return [_vm._t("table-title", function () {
      return [_vm.tableTitle ? _c('div', [_c('div', {
        staticClass: "flex flex-sb gap-24"
      }, [_c('h2', {
        class: _vm.tableTitleClasses
      }, [_vm._v(_vm._s(_vm.tableTitle))]), _vm.showVisibilityToggle ? _c('UiButton', {
        attrs: {
          "label": _vm.isTableVisible ? 'Скрыть' : 'Показать',
          "size": "xs",
          "type": "link"
        },
        on: {
          "click": _vm.toggleVisibility
        }
      }) : _vm._e()], 1)]) : _vm._e()];
    }, null, Object.assign({
      tableTitle: _vm.tableTitle
    }, _vm.slotMethodsAndProps)), _c('UiFilterList', {
      attrs: {
        "filters": _vm.filters,
        "initialFilters": _vm.filterData
      },
      on: {
        "change": _vm.setFilterData,
        "filtersChanged": _vm.filtersChanged,
        "refetch": _vm.fetchTableData
      }
    })];
  }, null, Object.assign({
    tableTitle: _vm.tableTitle
  }, _vm.slotMethodsAndProps))], 2) : _vm._e(), _c('div', {
    directives: [{
      name: "toggle-height",
      rawName: "v-toggle-height"
    }],
    ref: "toggleHeightTarget"
  }, [_vm._t("table-prepend", null, null, Object.assign({}, _vm.currentTable)), _vm._t("content", function () {
    var _vm$currentTable$item;
    return [!_vm.hideTable ? _c('UiTable', _vm._b({
      ref: "table",
      staticClass: "ui-table-list__table",
      attrs: {
        "id": "ui-table-list__table",
        "borderless": _vm.borderless,
        "columns": _vm.tableColumns,
        "emptyDataLabel": _vm.emptyDataLabel,
        "items": (_vm$currentTable$item = _vm.currentTable.items) !== null && _vm$currentTable$item !== void 0 ? _vm$currentTable$item : [],
        "loading": _vm.currentTable.loader,
        "rowStyle": _vm.getRowStyle,
        "sortBy": _vm.sortBy,
        "sortDirection": _vm.sortDirection,
        "sticky": _vm.sticky,
        "touchable": _vm.touchable,
        "tableListFormat": ""
      },
      on: {
        "open": function (item) {
          return _vm.$emit('openItem', item);
        },
        "selectedRowKeyChange": function ($event) {
          return _vm.$emit('selectedRowKeyChange', $event);
        },
        "sortByChange": _vm.setSortBy,
        "sortDirectionChange": _vm.setSortDirection
      },
      scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (slot, name) {
        return {
          key: name,
          fn: function (slotProps) {
            return [_vm._t(name, null, null, Object.assign({}, slotProps, _vm.slotMethodsAndProps))];
          }
        };
      })], null, true)
    }, 'UiTable', _vm.$attrs, false)) : _vm._e()];
  }, null, Object.assign({
    items: (_vm$currentTable$item2 = _vm.currentTable.items) !== null && _vm$currentTable$item2 !== void 0 ? _vm$currentTable$item2 : [],
    tableColumns: _vm.tableColumns
  }, _vm.slotMethodsAndProps)), _vm._t("empty", null, null, Object.assign({
    emptyDataLabel: _vm.emptyDataLabel
  }, _vm.slotMethodsAndProps)), _vm._t("table-append", null, null, Object.assign({}, _vm.currentTable)), !_vm.hideTable ? _c('div', {
    staticClass: "ui-table-list__pagination"
  }, [_vm.isPaginationVisible ? _c('UiPagination', {
    staticClass: "ui-table-list__pagination mt-10 mb-6",
    attrs: {
      "currentPage": _vm.currentPage,
      "pageSize": _vm.pageSize,
      "title": _vm.paginationTitle,
      "total": _vm.currentTable.totalCount
    },
    on: {
      "changePage": function (number) {
        return _vm.currentPage = number;
      }
    },
    scopedSlots: _vm._u([{
      key: "total",
      fn: function (_ref) {
        var total = _ref.total,
          title = _ref.title;
        return [title && _vm.mediaForDesktopVal ? _c('span', {
          staticClass: "total",
          staticStyle: {
            "margin-left": "auto",
            "margin-top": "0"
          }
        }, [_vm._v(_vm._s(total) + " " + _vm._s(title))]) : _vm._e(), _vm.mediaForDesktopVal ? _c('UiSelectNew', {
          staticClass: "ml-16",
          attrs: {
            "options": _vm.pageSizeOptions
          },
          on: {
            "change": _vm.updatePageIndex
          },
          model: {
            value: _vm.pageSize,
            callback: function ($$v) {
              _vm.pageSize = $$v;
            },
            expression: "pageSize"
          }
        }) : _vm._e()];
      }
    }], null, false, 1352740793)
  }) : _vm._e(), _vm.isWatchMoreVisible ? _c('UiButton', {
    staticClass: "ui-table-list__watchmore-btn",
    attrs: {
      "pending": _vm.currentTable.lazyLoader,
      "label": "Посмотреть еще"
    },
    on: {
      "click": _vm.watchMore
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.canExport && _vm.isTableVisible ? _c('UiExportToExcel', {
    staticClass: "mt-16",
    attrs: {
      "sheets": _vm.sheets
    }
  }) : _vm._e(), _vm._t("actions", null, null, Object.assign({}, _vm.slotMethodsAndProps, {
    canExport: _vm.canExport
  })), _vm._t("additional", null, null, Object.assign({}, _vm.slotMethodsAndProps))], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }