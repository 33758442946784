import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import router from './router';
import store from './store';
import ApiService from './api';
import CaptchaService from './services/captcha';
import App from './App.vue';
import UIComponents from '@/components/UI';
import KC, {install} from '@/keycloak';
import {vMaska} from "maska"
import Notifications from 'vue-notification';
import reactive from '@/directives/reactive';
import vClickOutside from 'v-click-outside'
import vBlur from 'v-blur';
import VueMeta from 'vue-meta'
import CipherLink from '@/components/CipherLink';
import VuePortal from '@linusborg/vue-simple-portal'
import {initSentry} from './sentry';
import {fetchEnv} from "@services/env";

require('./assets/scss/main.scss');

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;
Vue.use(ApiService);
Vue.use(CaptchaService);
Vue.use(VueCompositionAPI);
Vue.directive("maska", vMaska)
Vue.use(Notifications);
Vue.use(Vue.directive('reactive', reactive));
// Директива чтобы слушать нажатия вне компонента
Vue.use(Vue.directive('click-outside', vClickOutside.directive));
Vue.use(vBlur);
Vue.use(VueMeta);
Vue.use(VuePortal, {selector: '#portal', name: 'Portal'});
Vue.component('CipherLink', CipherLink);

// глобальная регистрация UI-компонентов
UIComponents.forEach((component) => {
	Vue.component(component.name, component);
});

fetchEnv().then(() => {
	Vue.use(install);
	KC.instance()
		.setCallbacks({
			onAuthSuccess: App.methods.onAuthSuccess,
			onAuthRefreshSuccess: App.methods.onTokenChange,
			onAuthError: App.methods.onTokenChange,
			onAuthRefreshError: App.methods.onTokenChange,
			onAuthLogout: App.methods.onAuthLogout,
			//onTokenExpired: App.methods.tokenChangeHandler,
		})
		.init()
		.then(async () => {
			await initSentry(Vue, router);

			const tokenValidityMilliseconds = KC._TOKEN_MIN_VALIDITY_SECONDS * 1000 - 10 * 1000;

			setInterval(() => {
				KC.instance()
					.updateToken(KC._TOKEN_MIN_VALIDITY_SECONDS)
			}, tokenValidityMilliseconds);

			new Vue({
				router,
				store,
				render: (h) => h(App),
			}).$mount('#app');
		});

	// Регистрация ServiceWorker'а
	(() => {
		if (!('serviceWorker' in navigator)) {
			console.log('Service workers are not supported.');
			return;
		}

		navigator.serviceWorker.addEventListener('message', async (event) => {
			if (event.data.type === 'REQUEST_NEW_TOKEN') {
				let kc = KC.instance();
				let newToken = null;
				if (kc.isAuthenticated()) {
					newToken = await kc.ensureToken();
				}
				event.ports[0].postMessage({type: 'NEW_TOKEN', newToken});
			}
		});

		navigator.serviceWorker
			.register(`/background.js`)
			.then((registration) => {
				console.log('ServiceWorker registration scope: ', registration.scope);
			})

			.catch((registrationError) => {
				console.log('ServiceWorker registration failed:', registrationError);
			});
	})();
})