
const member = {
	name: null,
	role: null,
	about: null,
	photo: null,
}

export default {
	namespaced: true,

	actions: {
		actionApplyMemberProject({ state, dispatch }) {
			if (state.index !== null) {
				dispatch('project/actionEditMemberProject', {
					member: state.member,
					index: state.index,
				}, { root: true });
			} else {
				dispatch('project/actionAddMemberProject', state.member, { root: true });
			}
			dispatch('actionClearMemberProject');
		},
		actionSetEditMemberProject({ state }, {item, index}) {
			state.member = item;
			state.index = index;
			state.showForm = true;
		},
		actionClearMemberProject({ state }) {
			state.member = member;
			state.index = null;
			state.showForm = false;
		},
		actionPutMemberProject({ state }, data) {
			state.member = data;
		},
		actionShowFormMemberProject({ state }) {
			state.showForm = true;
		},
	},
	state: {
		showForm: false,
		index: null,
		member
	},
	getters: {
		getterShowForm(state) {
			return state.showForm;
		},
		getterMember(state) {
			return state.member;
		},
		getterIsNewRow(state) {
			return state.index === null;
		},
	},
};