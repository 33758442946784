var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UiBlock', {
    attrs: {
      "withoutVisibleAnimation": "",
      "title": _vm.title || undefined
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._t("title")];
      },
      proxy: true
    }], null, true)
  }, [_vm.documentsSignedByLpi.length ? _c('div', {
    staticClass: "ui-documents"
  }, [_c('div', {
    staticClass: "mb-8 flex flex-sb gap-16"
  }, [_vm.title ? _c('h5', [_vm._v(_vm._s(_vm.forSignLabel))]) : _c('h3', [_vm._v(_vm._s(_vm.forSignLabel))]), _vm.showSignAll(_vm.documentsSignedByLpi) ? _c('UiButton', {
    attrs: {
      "size": "xs",
      "label": "Подписать все",
      "to": _vm.signAllHref
    }
  }) : _c('UiButton', {
    attrs: {
      "size": "xs",
      "label": "Посмотреть все",
      "to": _vm.viewAllLpiHref,
      "target": "_blank"
    }
  })], 1), _vm._l(_vm.documentsSignedByLpi, function (document) {
    return _c('UiDocument', _vm._b({
      key: document.documentId + 'lpi',
      attrs: {
        "showFinishedOnlyParticipantSelf": ""
      },
      on: {
        "changeDocument": function ($event) {
          return _vm.changeDocument($event, document.documentId);
        }
      }
    }, 'UiDocument', _vm.makeUiDocumentProps(document), false));
  })], 2) : _vm._e(), _vm._l(_vm.additionalLpiBlocks, function (additionalBlock) {
    return _c('div', {
      key: additionalBlock.title,
      staticClass: "ui-documents"
    }, [_c('div', {
      staticClass: "mb-8 flex flex-sb gap-16"
    }, [_vm.title ? _c('h5', [_vm._v(_vm._s(additionalBlock.title))]) : _c('h3', [_vm._v(_vm._s(additionalBlock.title))]), _vm.showSignAll(additionalBlock.documents) ? _c('UiButton', {
      attrs: {
        "size": "xs",
        "label": "Подписать все",
        "to": _vm.signAllHref
      }
    }) : _c('UiButton', {
      attrs: {
        "size": "xs",
        "label": "Посмотреть все",
        "to": _vm.viewAllLpiHref,
        "target": "_blank"
      }
    })], 1), _vm._l(additionalBlock.documents, function (document) {
      return _c('UiDocument', _vm._b({
        key: document.documentId,
        attrs: {
          "showFinishedOnlyParticipantSelf": ""
        },
        on: {
          "changeDocument": function ($event) {
            return _vm.changeDocument($event, document.documentId);
          }
        }
      }, 'UiDocument', _vm.makeUiDocumentProps(document, additionalBlock === null || additionalBlock === void 0 ? void 0 : additionalBlock.call), false));
    })], 2);
  }), _vm.documentsSignedByThirdParty.length ? _c('div', {
    staticClass: "ui-documents"
  }, [_c('div', {
    staticClass: "mb-8 flex flex-sb gap-16"
  }, [_c('h5', [_vm._v("Подписываемые третьими лицами")]), _c('UiButton', {
    attrs: {
      "size": "xs",
      "label": "Посмотреть все",
      "to": _vm.viewAllThirdPartyHref,
      "target": "_blank"
    }
  })], 1), _vm._l(_vm.documentsSignedByThirdParty, function (document) {
    return _c('UiDocument', _vm._b({
      key: document.documentId,
      attrs: {
        "showFinishedOnlyEntitySelf": ""
      },
      on: {
        "changeDocument": function ($event) {
          return _vm.changeDocument($event, document.documentId);
        }
      }
    }, 'UiDocument', _vm.makeUiDocumentProps(document), false));
  })], 2) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }