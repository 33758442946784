// @ts-check

import {
	getPlatformQuestions,
	getPlatformQuestionItem,
	createPlatformQuestionItem,
	deletePlatformQuestionItem,
	editPlatformQuestionItem
} from '@/services/platformQuestions';

import store from '@/store';

export default {
	namespaced: true,
	state: {
		questions: [],
		faqTab: {},
		faqItem: {},
		loader: true,
	},

	getters: {
		getterQuestions: (state) => state.questions,
		getterLoading: (state) => state.loader,
		getterFaqItem: (state) => state.faqItem,
		getterFaqTab: (state) => state.faqTab,
	},

	mutations: {
		mutationSetFaqItem(state, data) {
			state.faqItem = data;
			state.loader = false;
		},
		mutationSetQuestions(state, data) {
			state.questions = data;
		},
		mutationSetLoader(state, bool) {
			state.loader = bool;
		},
		mutationSetFaqTab(state, data) {
			state.faqTab = data;
		},
	},

	actions: {
		async fetchQuestions({ commit }, data) {
			commit('mutationSetLoader', true);
			try {
				await getPlatformQuestions(data)
					.then((res) => {
						const questions = res.data.items.sort((a, b) => a.title.localeCompare(b.title));
						const faqOptions = [];
						store.getters['dictionary/faqCategories']?.forEach(category => {
							faqOptions.push({
								name: category.name,
								content: [...questions.filter((question) => question.type === category.code)]
									.map(question => ({ title: question.title, questionContent: question.content, platformQuestionId: question.platformQuestionId }))
							})
						});
						const selectedTab = faqOptions[0].content.length ? faqOptions[0] : faqOptions.find((option) => option.content.length > 0);
						commit('mutationSetQuestions', faqOptions);
						commit('mutationSetFaqTab', selectedTab);
					});
			} finally {
				commit('mutationSetLoader', false);
			}
		},
		async actionGetFaqItem({ commit }, data) {
			return getPlatformQuestionItem(data.platformQuestionId).then(r => {
				commit('mutationSetFaqItem', r.data);
			})
		},
		async actionDeleteFaqItem({ commit }, data) {
			return deletePlatformQuestionItem(data.platformQuestionId).then(r => {
				commit('mutationSetFaqItem', r.data);
			})
		},
		async actionCreateFaqItem({ commit }, data) {
			return createPlatformQuestionItem(data).then(r => {
				commit('mutationSetFaqItem', r.data);
			})
		},
		async actionEditFaqItem({ commit }, data) {
			return editPlatformQuestionItem(data).then(r => {
				commit('mutationSetFaqItem', r.data);
			})
		},
		actionSetFaqItem({ commit }, data) {
			commit('mutationSetFaqItem', data);
		},
		actionSetFaqTab({ commit }, data) {
			commit('mutationSetFaqTab', data);
		},
		actionSetLoader({ commit }, bool) {
			commit('mutationSetLoader', bool);
		},
		actionClearFaqItem({ commit }) {
			commit('mutationSetFaqItem', {});
		},
	},
};
