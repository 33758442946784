var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickOut,
      expression: "clickOut"
    }],
    staticClass: "dropdown",
    on: {
      "mouseover": function ($event) {
        return _vm.triggerHover(true);
      },
      "mouseleave": function ($event) {
        return _vm.triggerHover(false);
      },
      "click": function ($event) {
        return _vm.triggerHover(true);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickOutActivator,
      expression: "clickOutActivator"
    }],
    staticClass: "dropdown__activator",
    on: {
      "click": _vm.triggerClick
    }
  }, [_vm._t("activator", null, null, {
    show: _vm.show
  })], 2), !_vm.lazy || _vm.lazy && _vm.show ? _c('div', {
    staticClass: "dropdown__menu",
    class: _vm.menuClasses
  }, [_vm._t("dropdown", null, null, {
    show: _vm.show
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }