var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-html-page__block"
  }, [_vm.title ? _c('div', {
    staticClass: "ui-html-page__block--title"
  }, [_c('h1', [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm.html ? _c('div', {
    staticClass: "ui-html-page__block--content",
    domProps: {
      "innerHTML": _vm._s(_vm.html)
    }
  }) : _c('div', {
    staticClass: "ui-html-page__block--content"
  }, [_vm._t("html")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }