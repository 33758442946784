import { getRequestBuilder, postRequestBuilder, postApiRequestBuilder } from '@/utils/RequestBuilder';
import {getEnv, VUE_APP_CHAT_BASE_URL} from "@services/env";

function getBaseUrl() {
	return getEnv(VUE_APP_CHAT_BASE_URL);
}
/**
 * @returns {Promise<ChatsDto[]>}
 */
export function getChats(searchValue = null) {
	let query = [['search', searchValue]];
	return getRequestBuilder(`/chats`)
		.baseURL(getBaseUrl())
		.query(query)
		.send()
		.then((res) => res.data.chatDtoList);
}

/**
 * @returns {Promise<ChatsDto[]>}
 */
export function getAdminUserChats(chatUser, searchValue) {
	let query = [['chatUser', chatUser], ['search', searchValue]];
	return getRequestBuilder(`/admin/chats-by-chatuser`)
		.baseURL(getBaseUrl())
		.query(query)
		.send()
		.then((res) => res.data.chatDtoList);
}

/**
 * @returns {Promise<count>}
 */
export function getUnreadChats() {
	return getRequestBuilder(`/chats/un-read-count`)
		.baseURL(getBaseUrl())
		.send()
		.then((res) => res.data);
}

/**
 * @returns {Promise<ChatDto[]>}
 */
export function getChat(chatId) {
	return getRequestBuilder(`/chats/${chatId}`)
		.baseURL(getBaseUrl())
		.send()
		.then((res) => res.data);
}

export function linkFileChat(chatId, fileId) {
	return postApiRequestBuilder(`chats/file`)
		.send({chatId, fileId});
}

/**
 * @returns {Promise<ChatDto[]>}
 */
export function getOperatorChat() {
	return postApiRequestBuilder(`chats/with-operator`)
		.send()
		.then((res) => res.data.chatId);
}

/**
 * @returns {Promise<MessagesDto[]>}
 */
export function getChatMessages(chatId, messageOwner = null) {
	let query = [['messageOwner', messageOwner]];
	return getRequestBuilder(`chats/${chatId}/messages`)
		.baseURL(getBaseUrl())
		.query(query)
		.send()
		.then((res) => res.data.messages);
}

/**
 * @returns {Promise<MessageDto[]>}
 */
export function sendMessage(chatId, message, attachments = []) {
	return postRequestBuilder(`/chats/${chatId}/messages`)
		.baseURL(getBaseUrl())
		.send({ message, attachments })
}

/**
 * @returns {Promise<DecreaseUnReadCountDto[]>}
 */
export function updateUnreads(chatId, readCountDecrease) {
	return postRequestBuilder(`/chats/${chatId}/decrease-unread-count`)
		.baseURL(getBaseUrl())
		.send({readCountDecrease})
}

/**
 * @returns {Promise<ChatIdDto>}
 */
export function createChat(newChatUsers) {
	return postRequestBuilder(`/chats`)
		.baseURL(getBaseUrl())
		.send({ newChatUsers })
		.then((res) => res.data.chatId);
}

/**
 * @returns {Promise<ChatIdDto>}
 */
export function createChatDialog(projectId, participantIdList) {
	return postApiRequestBuilder(`chats/dialog`)
		.send({ projectId, participantIdList })
		.then((res) => res.data.chatId);
}
