<template>
	<div v-if="hasHistory" class="ui-nav">
		<UiButton
			:label="navBtnLabel"
			type="secondary"
			prepend-icon="prev-button"
			:size="navBtnSize"
			title="Назад"
			@click="navigateBack"
		/>
	</div>
</template>

<script>
import SIZES from '../sizes.js';

export default {
	name: 'UiNav',
	props: {
		navBtnLabel: {
			type: String,
			default: '',
		},
		navBtnSize: {
			type: String,
			default: SIZES.m,
		},
		backRouteParams: {
			type: Object,
			default: undefined,
		},
	},
	computed: {
		hasHistory() {
			return window.history.length > 2;
		}
	},
	methods: {
		navigateBack() {
			if (this.backRouteParams) {
				return this.$router.push(this.backRouteParams);
			}
			return this.hasHistory
				? this.$router.go(-1) 
				: this.$router.push('/')
		},
	}
}
</script>
