// @ts-check
import { editPartnersSettings, getPartnersSettings } from '@/services/settings';
import { randomUuid } from '@/utils/common';

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,
	state: {
		partners: [],
		partnerItem: {},
		loading: false,
	},

	getters: {
		getterPartners: (state) => state.partners,
		getterPartnerItem: (state) => state.partnerItem,
		getterLoading: (state) => state.loading,
	},

	mutations: {
		setPartners(state, payload) {
			state.partners = payload;
		},
		setLoading(state, bool) {
			state.loading = bool;
		},
		setPartnerItem(state, payload) {
			state.partnerItem = { ...payload };
			if (!state.partnerItem.id) {
				state.partnerItem.id = randomUuid();
			}
		},
	},

	actions: {
		async fetchPartners({ commit }) {
			commit('setLoading', true);
			const res = await getPartnersSettings();
			commit('setPartners', res);
			commit('setLoading', false);
		},

		async actionCreatePartnerItem({ commit, state, dispatch }) {
			commit('setLoading', true);
			const newPartners = state.partners.concat([state.partnerItem]);
			await editPartnersSettings(newPartners);
			return dispatch('fetchPartners');
		},

		async actionEditPartnerItem({ commit, state, dispatch }) {
			commit('setLoading', true);
			let newPartners;
			const idx = state.partners.findIndex(
				(p) => p.id === state.partnerItem.id,
			);
			if (idx !== -1) {
				newPartners = state.partners.slice();
				newPartners.splice(idx, 1, state.partnerItem);
			} else {
				newPartners = state.partners.concat([state.partnerItem]);
			}
			await editPartnersSettings(newPartners);
			return dispatch('fetchPartners');
		},

		async actionDeletePartnerItem({ commit, state, dispatch }) {
			commit('setLoading', true);
			const newPartners = state.partners.filter(
				(p) => p.id !== state.partnerItem.id,
			);
			await editPartnersSettings(newPartners);
			return dispatch('fetchPartners');
		},

		actionClearPartnerItem({ commit }) {
			commit('setPartnerItem', {});
		},

		actionSetPartnerItem({ commit }, payload) {
			commit('setPartnerItem', payload);
		},
	},
};
