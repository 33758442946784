export const INVESTMENT_DOCUMENTS_REGISTRY = 'investment-documents-registry';

export const PLATFORM_DOCUMENTS_REGISTRY = 'platform-documents-registry';

export const SIGN_QUESTIONNAIRE_DOCS = 'sign-questionnaire-documents';

export const PROJECT_UCPS = 'project-ucps';

export const CERTIFICATES = 'certificates';

export const OPERATOR_CERTIFICATES = 'operator-certificates';

export const CONTACTS = 'contacts';

export const SIGN_PROJECT_DOCS = 'sign-project-documents';

export const CHATS = 'chats';

export const CIPHER_LINK = 'cipher-link';

export const PAGE_404 = '404-page';

export const PAGE_403 = '403-page';

export const MAIN = 'main';

export const TERMS_OF_USE = 'terms-of-use';

export const TERMS_OF_USE_INVESTOR = 'terms-of-use-investor';

export const LPI_HOME = 'lpi-home';

export const INVEST_HOME = 'invest-home';

export const LOGIN_SIGNATURE = 'login-signature';

export const REGISTRATION_SIGNATURE = 'registration-signature';

export const VERSIONS = 'versions';

export const COMPONENTS = 'components';

export const PROFILE = 'profile';

export const USER_PROJECTS_NEWS_DISCUSS = 'user-project-news';

export const USER_SERVICE_MESSAGES = 'user-service-messages';

export const USER_PLATFORM_NEWS = 'user-platform-news';

export const INVESTOR_CABINET = 'investor-cabinet';



export const MOBILE_PROJECTS_INVESTMENTS = 'mobile-projects-investments';

export const QUESTIONNAIRES_PAGE = 'questionnaires-page';

export const INVESTOR_CABINET_FINANCES = 'investor-cabinet-finances';

export const FOUNDER_CABINET = 'founder-cabinet';

export const FOUNDER_CABINET_FINANCES = 'founder-cabinet-finances';

export const PITCHES = 'pitches';

export const MOBILE_PITCHES = 'mobile-pitches';

export const PROJECTS = 'projects';

export const MOBILE_PROJECTS = 'mobile-projects';

export const SUCCESS_PROJECTS = 'success-projects';

export const SETTINGS = 'settings';

export const FOLLOWED_PROJECTS = 'followed-projects';

export const HELP = 'help';

export const ABOUT = 'about';

export const ADVICE = 'advice';

export const QUESTION = 'question';

export const PROJECT = 'project';

export const PROJECT_NEWS = 'project-news';

export const PROJECT_NEWS_VIEW = 'project-news-view';

export const PROJECT_DISCUSS = 'project-discuss';

export const PROJECT_DISCUSS_VIEW = 'project-discuss-view';

export const PROJECT_SUCCESS = 'project-success';

export const PROJECT_INVEST = 'project-invest';

export const PROJECT_INVESTORS = 'project-investors';

export const PROJECT_INVESTOR_PROGRESS = 'project-investor-progress';

export const PROJECT_INVESTOR_LOAN_TURNOVER = 'project-investor-loan-turnover';

export const PROJECT_INVESTOR_UCP_TURNOVER = 'project-investor-ucp-turnover';

export const PROJECT_CREATE = 'project-create';

export const PITCH_EDIT = 'pitch-edit';

export const PROJECT_EDIT = 'project-edit';

export const PITCH_CREATE = 'pitch-create';

export const PITCH = 'pitch';

export const OPERATIONS_HISTORY = 'operations-history';

export const CRYPTOPRO = 'cryptopro';

export const CONFIRM_EMAIL = 'confirm-email';

export const LPI = 'lpi';

export const OUTSIDE_DOCUMENT_SIGN_PAGE = 'outside-document-sign-page';

export const DOCUMENT_SIGN_PAGE = 'document-sign-page';

export const DOCUMENT_SIGN_PAGE_OPERATOR = 'document-sign-page-operator';

export const DOCUMENT_SIGN_WATCH_PAGE = 'document-sign-watch-page';

export const OPERATOR_NBKI_EVENTS = 'admin-nbki-events';

export const OPERATOR_NBKI_ARCHIVE = 'admin-nbki-archive';

export const OPERATOR_PROFILE = 'operator-profile';

export const OPERATOR_TASKS = 'operator-tasks';

export const OPERATOR_PARTICIPANTS = 'operator-participants';

export const OPERATOR_USERS = 'operator-users';

export const OPERATOR_PARTICIPANTS_MODERATE = 'operator-participants-moderate';

export const OPERATOR_ENTITY_MODERATE = 'operator-entity-moderate';

export const OPERATOR_ENTITY = 'operator-entity';

export const TARIFFS = 'tariffs';

export const OPERATOR_PARTICIPANT = 'operator-participant';

export const OPERATOR_PARTICIPANT_FINANCES = 'operator-participant-finances';

export const OPERATOR_PARTICIPANT_DOCS = 'operator-participant-docs';

export const OPERATOR_PROJECTS = 'operator-projects';

export const OPERATOR_PROJECTS_MODERATE = 'operator-projects-moderate';

export const OPERATOR_PITCHES = 'operator-pitches';

export const OPERATOR_PITCHES_MODERATE = 'operator-pitches-moderate';

export const OPERATOR_UCPS = 'operator-ucps';

export const OPERATOR_PROJECT_MODERATION = 'operator-project-moderation';

export const OPERATOR_PITCH_MODERATION = 'operator-pitch-moderation';

export const OPERATOR_PROJECT_VIEW = 'operator-project-view';

export const OPERATOR_PROJECT_VIEW_INVESTORS = 'operator-project-view-investors';

export const OPERATOR_CHATS = 'operator-chats';

export const OPERATOR_NEWS = 'operator-news';

export const OPERATOR_EDIT_NEWS = 'operator-edit-news';

export const ADMIN_MANAGE_USERS = 'admin-manage-users';

export const ADMIN_USER_CHATS = 'admin-user-chats';

export const ADMIN_USERS_RIGHTS = 'admin-users-rights';

export const ADMIN_PLATFORM_SETTINGS = 'admin-platform-settings';

export const ADMIN_DICTIONARIES = 'admin-dictionaries';

export const ADMIN_DOCUMENTS = 'admin-documents';

export const ADMIN_DOCUMENT_TYPES = 'admin-document-types';

export const ADMIN_PLATFORM_COMMISSION = 'admin-platform-commission';

export const ADMIN_PROJECTS_COMMON_SETTINGS = 'admin-projects-common-settings';

export const ADMIN_PROJECTS_LOAN_SETTINGS = 'admin-project-loan-settings';

export const ADMIN_PROJECTS_CONVERTIBLE_LOAN_SETTINGS = 'admin-project-convertible-loan-settings';

export const ADMIN_PROJECTS_STOCK_UCP_SETTINGS = 'admin-project-stock-ucp-settings';

export const ADMIN_NOMINAL_ACCOUNT = 'admin-nominal-account';

export const ADMIN_USERS_STATS = 'admin-users-stats';

export const ADMIN_ISSUES = 'admin-issues';

export const ADMIN_INVESTS_STATS = 'admin-invests-stats';

export const ADMIN_OBLIGATIONS_STATS = 'admin-obligations-stats';

export const ADMIN_PROFILE = 'admin-profile';

export const ADMIN_PARTICIPANTS_INFO = 'admin-participants-info';

export const ADMIN_PROJECTS_INFO = 'admin-projects-info';

export const FAQ = 'faq';

export const ABOUT_US = 'about-us';

export const PLATFORM_NEWS = 'platform-news';

export const DISCLOSE_INFO = 'disclose-info';

export const PERSONAL_INFO = 'personal-info';

export const RISK_DECLARATION = 'risk-declaration';

export const PROMOTIONS = 'promotions';

export const PARTNERS = 'partners';

export const SAFETY = 'safety';

export const STANDARD_DOCS = 'standard-docs';

export const CONTRACT_INVESTMENT_REQUEST = 'contract-investment-request';

export const WITHDRAWAL_FUNDS = 'withdrawal-funds';
