<template>
	<div class="upload-block mb-16">
		<span 
			class="upload-block-title"
		>{{title}}</span>
		<span 
			v-if="subTitile" 
			class="upload-block-subtitle"
		>{{subTitile}}
		</span>
		<UiButton 
			style="margin-top: 15px;"
			:prependIcon="prependIconName"
			:appendIcon="appendIconName" 
			:type="btnType" 
			:label="btnLabel"
			@click="click"
		/>
		<input 
			id="file-input" 
			class="input-file" 
			type="file" 
			name="name" 
			style="display: none;" 
		>
		<span 
			class="input-tips"
		>{{fileType}}
		</span>
	</div>
</template>

<script>
export default {
	name: "UiOperatorContract",
	props: {
		title: {
			type:String,
			default:''
		},
		fileType: {
			type:String,
			default: ''
		},
		appendIconName: {
			type:String,
			default:''
		},
		prependIconName: {
			type:String,
			default:''
		},
		btnLabel: {
			type:String,
			default:''
		},
		btnType: {
			type:String,
			default:''
		},
		subTitile: {
			type:String,
			default:''
		}
	},

	methods: {
		click() {
			this.$emit('click')
		}
	},
}
</script>
