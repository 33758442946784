<template>
	<div 
		v-click-outside="clickOut"
		class="dropdown"
		v-on:mouseover="triggerHover(true)"
		v-on:mouseleave="triggerHover(false)"
		@click="triggerHover(true)"
	>
		<div v-click-outside="clickOutActivator" class="dropdown__activator" v-on:click="triggerClick">
			<slot name="activator" v-bind="{ show }"/>
		</div>
		<div v-if="!lazy || (lazy && show)" class="dropdown__menu" :class="menuClasses">
			<slot name="dropdown" v-bind="{ show }"/>
		</div>
	</div>
</template>

<script>
export default {
	name: "UiDropdown",
	props: {
		/**
     * Выравнивание выпадающего элемента справа
     *
     * @values 'left' | 'right'
     */
		align: {
			type: String,
			default: 'left',
		},
		/**
     * Обработка события click вместо hover
     *
     * @values true | false
     */
		clickEvent: {
			type: Boolean,
			required: false,
			default: false
		},
		/**
     * true Авто закрытие окна при клике на любую область
     * false Авто закрытие окна при клике на любую область кроме выпадающего элемента
     *
     * @values true | false
     */
		clickAutoClose: {
			type: Boolean,
			required: false,
			default: true
		},
		/**
     * Ленивая загрузка
     *
     * @values true | false
     */
		lazy: {
			type: Boolean,
			required: false,
			default: false
		},
		/**
     * Для мобильных устройств
     *
     * @values true | false
     */
		mobileDropdown: {
			type: Boolean,
			required: false,
			default: false
		},
	},
	data: () => ({
		show: false,
		documentClickListener: null,
	}),
	computed: {
		menuClasses() {
			return {
				'dropdown__menu--show': this.show,
				'dropdown__menu--right': this.align === 'right',
			};
		},
	},
	watch: {
		show(newVal) {
			this.$emit('show', newVal);
		}
	},
	methods: {
		clickOut() {
			if (this.clickEvent && this.show) {
				this.show = !this.show;
			}
		},
		clickOutActivator() {
			if (this.clickEvent && this.show && this.clickAutoClose) {
				this.show = !this.show;
			}
		},
		triggerClick() {
			if (this.clickEvent || this.mobileDropdown) {
				this.show = !this.show;
			}
		},
		triggerHover(hover) {
			if (this.clickEvent || this.mobileDropdown) {
				return;
			}

			this.show = hover;
		}
	},
}
</script>
