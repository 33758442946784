import Vue from 'vue';
import Vuex from 'vuex';

import cache from '@/store/modules/cache';
import dictionary from '@/store/modules/dictionary';
import crypto from '@/store/modules/crypto';
import participantForm from '@/store/modules/participantForm';
import userParticipant from '@/store/modules/userParticipant';
import entity from "@/store/modules/entity";
import pitch from '@/store/modules/pitch';
import project from '@/store/modules/project';
import validation from '@/store/modules/validation';
import questionnaireProfile from '@/store/modules/questionnaireProfile';
import projectPaymentSchedule from '@/store/modules/projectPaymentSchedule';
import projectMemberForm from '@/store/modules/projectMemberForm';
import projectUcpForm from '@/store/modules/projectUcpForm';
import userDocs from "@/store/modules/userDocs";
import lists from '@/store/modules/lists';
import projectNews from '@/store/modules/projectNews';
import mainProjects from '@/store/modules/mainProjects';
import platformNews from '@/store/modules/platformNews';
import projectDiscuss from '@/store/modules/projectDiscuss';
import comments from '@/store/modules/comments';
import contacts from '@/store/modules/footer/contacts';
import news from '@/store/modules/footer/news';
import faq from '@/store/modules/footer/faq';
import chats from '@/store/modules/chats';
import promotions from '@/store/modules/footer/promotions';
import partners from '@/store/modules/footer/partners';
import standardDocs from '@/store/modules/footer/standardDocs';
import projectBanner from "@/store/modules/projectBanner";
import issues from "@/store/modules/issues";
import { PITCHES } from '@/router/routes/names';
import notificationPattern from "@/store/modules/notificationPattern";
import operatorCounter from "@/store/modules/operatorCounter";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		cache,
		dictionary,
		participantForm,
		crypto,
		userParticipant,
		entity,
		pitch,
		project,
		validation,
		questionnaireProfile,
		projectPaymentSchedule,
		projectMemberForm,
		projectUcpForm,
		userDocs,
		lists,
		projectNews,
		mainProjects,
		platformNews,
		projectDiscuss,
		comments,
		contacts,
		news,
		faq,
		chats,
		promotions,
		partners,
		projectBanner,
		issues,
		standardDocs,
		operatorCounter,
		notificationPattern
	},

	state() {
		return {
			projectsPageType: PITCHES,
			BROWSER_LIST: {
				Opera: 'opera',
				Firefox: 'firefox',
				Safari: 'safari',
				Chrome: 'chrome',
				Edge: 'edge',
			},
		};
	},

	getters: {
		currentBrowser(state) {
			// eslint-disable-next-line
            if (
				(!!window.opr && !!window.opr.addons) ||
                !!window.opera ||
                navigator.userAgent.indexOf(' OPR/') >= 0
			)
				return state.BROWSER_LIST.Opera;

			if (typeof InstallTrigger !== 'undefined')
				return state.BROWSER_LIST.Firefox;

			// eslint-disable-next-line
            if (
				/constructor/i.test(window.HTMLElement) ||
                (function (p) {
                	return p.toString() === '[object SafariRemoteNotification]';
                })(
                	!window['safari'] ||
                        (typeof safari !== 'undefined' &&
                            window['safari'].pushNotification)
                )
			)
				return state.BROWSER_LIST.Safari;

			// eslint-disable-next-line
            if (
				!(/*@cc_on!@*/ false) ||
                (!!document.documentMode && !!window.StyleMedia)
			)
				return state.BROWSER_LIST.Edge;

			// eslint-disable-next-line
            if (
				!!window.chrome &&
                (!!window.chrome.webstore || !!window.chrome.runtime)
			)
				return state.BROWSER_LIST.Chrome;
		},

		getProjectsPageType(state) {
			return state.projectsPageType
		},
	},

	mutations: {
		setProjectsPageType(state, projectsPageType){
			state.projectsPageType = projectsPageType
		}
	}
})
