// @ts-check
import {
	DELIMITER,
	CIPHER_PREFIXES,
	CIPHER_PREFIXES_ARRAY,
	CIPHER_PROJECT_TYPES,
	CIPHER_PARTICIPANT_ROLES,
	CIPHER_PARTICIPANT_ENTITY_TYPES,
	CIPHER_PARTICIPANT_PREFIXES,
} from './config';
import {
	PROJECT,
	PITCH,
	OPERATOR_PROJECT_MODERATION,
	OPERATOR_PITCH_MODERATION,
	LPI,
	OPERATOR_PARTICIPANT,
	CIPHER_LINK,
} from '@/router/routes/names';
import { ROLE_CODES } from '@configs/participants';

/**
 *
 * @param {string} cipher
 * @returns {{prefix: string, id: string}}
 */
export function decipher(cipher) {
	const prefix = CIPHER_PREFIXES_ARRAY.find((c) => cipher.startsWith(c));
	if (!prefix) {
		return null;
	}

	return { prefix, id: cipher.slice(prefix.length + DELIMITER.length) };
}

/**
 *
 * @param {*} obj
 * @returns {string}
 */
export function cipher(obj) {
	if (isPitch(obj)) {
		return `ПРП.${obj.id ?? obj.pitchId}`;
	}

	if (isModeratePitch(obj)) {
		return `ПРП.${obj.id ?? obj.pitchId}`;
	}

	if (isProject(obj)) {
		return `${CIPHER_PROJECT_TYPES[obj.type]}.${obj.id}`;
	}

	if (isFollowedProject(obj)) {
		return `${CIPHER_PROJECT_TYPES[obj.type]}.${obj.projectId}`;
	}

	if (isModerateProject(obj)) {
		return `${CIPHER_PROJECT_TYPES[obj.type]}.${obj.projectId}`;
	}

	if (isParticipant(obj)) {
		return `${CIPHER_PARTICIPANT_ROLES[obj.role]}-${
			CIPHER_PARTICIPANT_ENTITY_TYPES[obj.entityType ?? obj.type]
		}.${obj.participantId}`;
	}

	if (isParticipantModerate(obj)) {
		return `${CIPHER_PARTICIPANT_ROLES[obj.role]}-${
			CIPHER_PARTICIPANT_ENTITY_TYPES[obj.entityType ?? obj.type]
		}.${obj.participantId}`;
	}

	throw new Error('cipher: Unknown object type');
}

/**
 * @param {*} obj
 * @returns {string}
 */
export function objectDisplayCipher(obj) {
	const cphr = cipher(obj);
	return `#${cphr}`;
}

/**
 *
 * @typedef {{ operator?: boolean }} Options
 *
 * @param {string} cipher
 * @param {Options} options
 *
 * @returns {import('vue-router').Location}
 */
export function getCipherLocation(cipher, { operator = false } = {}) {
	const { prefix, id } = decipher(cipher);

	if (isPrefixPitch(prefix)) {
		if (operator) {
			return { name: OPERATOR_PITCH_MODERATION, params: { id } };
		}

		return { name: PITCH, params: { id } };
	}

	if (isPrefixProject(prefix)) {
		if (operator) {
			return { name: OPERATOR_PROJECT_MODERATION, params: { id } };
		}

		return { name: PROJECT, params: { id } };
	}

	if (isPrefixParticipant(prefix)) {
		if (operator) {
			return { name: OPERATOR_PARTICIPANT, params: { id } };
		}

		const { role } = decipherParticipantPrefix(cipher);

		if (role === ROLE_CODES.INVESTOR) {
			console.warn('getCipherLocation: INVESTOR is not implemented yet');
			return null;
		}

		return { name: LPI, params: { id } };
	}

	console.warn('getCipherLocation: Unknown cipher prefix', prefix);
	return null;
}

/**
 *
 * @param {*} obj
 * @returns {import('vue-router').Location}
 */
export function getObjectLocation(obj) {
	const cphr = cipher(obj);

	return { name: CIPHER_LINK, params: { cipher: cphr } };
}

/**
 * @param {string} prefix
 * @returns {{role: string, entityType: string}}
 */
function decipherParticipantPrefix(prefix) {
	const [roleValue, entityTypeValue] = prefix.split('-');

	const [role] = Object.entries(CIPHER_PARTICIPANT_ROLES).find(
		([_, value]) => value === roleValue,
	);

	const [entityType] =
		Object.entries(CIPHER_PARTICIPANT_ENTITY_TYPES).find(
			([_, value]) => value === entityTypeValue,
		) ?? [];

	return { role, entityType };
}

/**
 * @param {string} prefix
 * @returns {boolean}
 */
function isPrefixProject(prefix) {
	return Object.values(CIPHER_PROJECT_TYPES).includes(prefix);
}

/**
 * @param {string} prefix
 * @returns {boolean}
 */
function isPrefixParticipant(prefix) {
	return Object.values(CIPHER_PARTICIPANT_PREFIXES).includes(prefix);
}

/**
 * @param {string} prefix
 * @returns {boolean}
 */
function isPrefixPitch(prefix) {
	return prefix === CIPHER_PREFIXES.ПРП;
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
function isProject(obj) {
	const requiredKeys = ['id', 'type', 'name', 'brief', 'collected'];
	return requiredKeys.every((key) => key in obj);
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
function isFollowedProject(obj) {
	const requiredKeys = ['projectId', 'name', 'projectType'];
	return requiredKeys.every((key) => key in obj);
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
function isPitch(obj) {
	const requiredKeys = ['supposedType', 'name', 'brief', 'status'];
	return requiredKeys.every((key) => key in obj);
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
function isModeratePitch(obj) {
	const requiredKeys = ['type', 'pitchId', 'name', 'status'];
	return requiredKeys.every((key) => key in obj);
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
function isModerateProject(obj) {
	const requiredKeys = ['type', 'projectId', 'name', 'status'];
	return requiredKeys.every((key) => key in obj);
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
function isParticipant(obj) {
	const requiredKeys = [
		'participantId',
		'role',
		'lastName',
		'passportNumber',
		'passportIssueDate',
	];
	return requiredKeys.every((key) => key in obj);
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
function isParticipantModerate(obj) {
	const requiredKeys = [
		'participantId',
		'role',
		'lastName',
	];
	return requiredKeys.every((key) => key in obj);
}
