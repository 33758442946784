export * from './SignatureBuilder';
import { getAllUserCertificates } from 'crypto-pro-js';


const getCryptoPro = (() => {
	let cryptoPro = null;
	return async () => {
		if (!cryptoPro) {
			cryptoPro = await import('crypto-pro');
		}
		return cryptoPro;
	}
})();

export async function isValidSystemSetup() {
	const cryptoPro = await getCryptoPro();
	return cryptoPro.isValidSystemSetup();
}

export async function getUserCertificates() {
	return getAllUserCertificates();
}

export async function createSignature(thumbprint, messageHash) {
	const cryptoPro = await getCryptoPro();

	return cryptoPro.createDetachedSignature(
		thumbprint,
		await cryptoPro.createHash(messageHash)
	);
}
