// @ts-check

import { getPublicPlatformNews } from '@/services/platformNews';

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,
	state: {
		news: [],
		loading: false,
	},

	getters: {
		getterNews: (state) => state.news,
		getterLoading: (state) => state.loading,
	},

	mutations: {
		setNews(state, payload) {
			state.news = payload;
		},
		setLoading(state, bool) {
			state.loading = bool;
		},
	},

	actions: {
		async fetchNews({ commit }) {
			commit('setLoading', true);
			try {
				await getPublicPlatformNews()
					.then((res) => {
						commit('setNews', res.data.items);
					});
			} finally {
				commit('setLoading', false);
			}
		},
	},
};
