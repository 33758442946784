<template>
	<div>
		<div class="content-pages">
			<slot>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "UiPages",

	provide: function () {
		return {
			registerPage: this.registerPage
		};
	},

	props: {
		page: String,
	},

	data() {
		return {
			pageMap: {},
		};
	},

	computed: {
		pageCount() {
			return Object.keys(this.pageMap).length;
		},
	},

	watch: {
		page() {
			this.setVisibility();
		},
		pageMap() {
			this.setVisibility();
		},
	},

	mounted() {
		this.setVisibility();
	},

	methods: {
		registerPage(page, name) {
			this.$set(this.pageMap, name, page);
			this.setVisibility();
		},

		setVisibility() {
			// видимость
			Object.entries(this.pageMap).forEach(ent => {
				ent[1].setVisible(ent[0] === this.page);
			});
		},
	}
};
</script>
