<template>
	<div v-if="!isOperator" class="ui-tip">
		<popper
			trigger="hover"
			:options="options">
			<div class="popper" v-html="message" />

			<div slot="reference">
				<UiSmartIcon
					ref="tipIcon"
					name="question"
					color="var(--greyscale-5)"
					class="ui-tip__icon"
				/>
			</div>
		</popper>
	</div>
</template>

<script>
import 'vue-popperjs/dist/vue-popper.css';
import kcRolesMixin from '@/mixins/kcRolesMixin';
  
export default {
	name: 'UiTip',
	components: {
		'popper': () => import('vue-popperjs'),
	},
	mixins: [kcRolesMixin],
	props: {
		message: {
			type: String,
			required: true,
		},
	},
	computed: {
		options() {
			return {
				modifiers: {
					placement: 'top',
					offset: { offset: '0,10px' },
				}
			} 
		}
	},
};
</script>

