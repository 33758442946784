'use strict';

// import Vue from "vue";
import axios from 'axios';
import 'core-js/actual/promise';

import apiConfig from '../configs/api';
import DocumentType from '@/api/models/document-type';

export class Api {
    static _instance = null;

    static instance() {
        if (!Api._instance) {
            Api._instance = new Api();
        }
        return this._instance;
    }

    _request(method, url, data, config) {
        return new Promise((resolve, reject) => {
            config = config || {};
            // запрос
            const request = (tok) => {
                if (tok) {
                    if (!config.headers) config.headers = {};
                    config.headers.Authorization = 'bearer ' + tok;
                }
                Object.assign(config, {
                    method,
                    url: apiConfig.url + url,
                    withCredentials: true,
                });
                if (data) {
                    Object.assign(config, { data });
                }
                axios
                    .request(config)
                    .then((res) => resolve(res))
                    .catch((err) => {
                        reject(err);
                    });
            };
            // авторизация
            this.ensureToken()
                .then((tok) => {
                    request(tok);
                })
                .catch((rej) => {
                    console.error('Error requesting API: ' + rej);
                    request(null);
                });
        });
    }

    _get(url, config = undefined) {
        return this._request('GET', url, undefined, config);
    }

    _post(url, data = undefined, config = undefined) {
        return this._request('POST', url, data, config);
    }

    _put(url, data = undefined, config = undefined) {
        return this._request('PUT', url, data, config);
    }

    _delete(url, data = undefined, config = undefined) {
        return this._request('DELETE', url, data, config);
    }

    _wrapRequest(requestPromise, progressMsg = '') {
        if (progressMsg) {
        }
        return new Promise((result, reject) => {
            requestPromise
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        console.debug('success');
                        result(res.data);
                    } else {
                        console.warn('wrong status: ' + res.status);
                        reject(res.statusText);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    ensureToken() {
        return this.$kc.ensureToken();
    }

    version() {
        return this._wrapRequest(this._get('/version'));
    }

    listDocuments() {
        return this._wrapRequest(
            axios.get(
                apiConfig.url +
                    '/documents?type=${DocumentType.PLATFORM_RULES}&type=${DocumentType.PERSONAL_AGREEMENT}'
            )
        );
    }

    listRoles() {
        return this._wrapRequest(axios.get(apiConfig.url + '/user/characters'));
    }

    async documentLink(documentType) {
        // TODO: implement
        switch (documentType) {
            case DocumentType.PLATFORM_RULES:
                return { link: '/docs/platform_rules.pdf' };
            case DocumentType.PERSONAL_AGREEMENT:
                return { link: '/docs/personal_agreement.pdf' };
            default:
                throw new Error('documentType is not supported');
        }
    }

    registerUser(user) {
        return this._wrapRequest(
            axios.post(apiConfig.url + '/user/register', user),
            'registering new user...'
        );
    }
}

export default function ApiService(Vue) {
    Vue.mixin({
        created: function () {
            this.$api = Api.instance();
        },
    });
}

// Vue.use(install);
