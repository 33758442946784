/*eslint no-dupe-class-members: "off"*/
//? eslint не умеет работать с приватными полями, ругается на то, что они дублируются

import Requests from '@utils/Requests.js';
import api from '@configs/api.js';

const END_POINT_BASE = 'dictionaries/';

/**
 * Создает экземпляр TypesDictionary.
 *
 * @param {string} endpoint стандартный эндпоинт (обычно у всех типов одинаковый эндпоинт, отличается только типом запроса).
 * @param {string=} getEP эндпоинт для получения (если не задан, то будет стандартный).
 * @param {string=} addEP эндпоинт для добавления (если не задан, то будет стандартный).
 * @param {string=} editEP эндпоинт для изменения (если не задан, то будет стандартный).
 * @param {string=} deleteEP эндпоинт для удаления (если не задан, то будет стандартный).
 *
 * @return {TypesDictionary}
 */
export default function createDictionary(
	{ endpoint, getEP, addEP, editEP, deleteEP } = {
		endpoint,
		getEP,
		addEP,
		editEP,
		deleteEP,
	}
) {
	return new TypesDictionary({ endpoint, getEP, addEP, editEP, deleteEP });
}

export class TypesDictionary {
	#getEP = undefined;
	#addEP = undefined;
	#editEP = undefined;
	#deleteEP = undefined;

	constructor({ endpoint, getEP, addEP, editEP, deleteEP }) {
		this.#getEP = getEP ?? endpoint;
		this.#addEP = addEP ?? endpoint;
		this.#editEP = editEP ?? endpoint;
		this.#deleteEP = deleteEP ?? endpoint;
	}

	/**
     * Возвращает все значения справочника.
     *
     * @return {Promise<{items: {name:  string, code: string}[]}>}
     */
	getTypes() {
		return this.#getDictionary(this.#getEP);
	}

	/**
     * Добавляет в справочник запись с указанным кодом и именем.
     *
     * @param {string} recordData.name
     * @param {string | number} recordData.code
     * */
	addType(recordData) {
		return this.#addDictionaryRecord(this.#addEP, recordData);
	}

	/**
     * Изменяет имя записи с переданным кодом на переданное.
     *
     * @param {string | number} recordCode
     * @param {string} newRecordData
     * */
	editType(recordCode, newRecordData) {
		return this.#editDictionaryRecord(
			this.#editEP,
			recordCode,
			newRecordData
		);
	}

	/**
     * Удаляет запись справочника по переданному коду.
     *
     * @param {string | number} recordCode
     * */
	deleteType(recordCode) {
		return this.#deleteDictionaryRecord(this.#deleteEP, recordCode);
	}

	async #getDictionary(endpoint) {
		const url = `/${api.url}${END_POINT_BASE}public/${endpoint}`;
		const res = await Requests.requestWrapper(
			Requests.getRequest(url, {}, false)
		);
		if (!res.success)
			throw new Error(`Request failed with status ${res.status}.`);
		return res.data;
	}

	async #addDictionaryRecord(endpoint, data) {
		const url = `/${api.url}${END_POINT_BASE}${endpoint}`;
		const res = await Requests.requestWrapper(
			Requests.postRequest(url, data, {}, true)
		);
		if (!res.success)
			throw new Error(`Request failed with status ${res.status}.`);
		return res.data;
	}

	async #editDictionaryRecord(endpoint, id, data) {
		const url = `/${api.url}${END_POINT_BASE}${endpoint}`;
		const res = await Requests.requestWrapper(
			Requests.putRequest(url, { ...data, id }, {}, true)
		);
		if (!res.success)
			throw new Error(`Request failed with status ${res.status}.`);
		return res.data;
	}

	async #deleteDictionaryRecord(endpoint, id) {
		const url = `/${api.url}${END_POINT_BASE}${endpoint}/${id}`;
		const res = await Requests.requestWrapper(
			Requests.deleteRequest(url, {}, true)
		);
		if (!res.success)
			throw new Error(`Request failed with status ${res.status}.`);
		return res.data;
	}
}
