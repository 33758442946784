var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b26 form-block"
  }, [_c('div', {
    staticClass: "form-block__header"
  }, [_vm.title || _vm.$slots.title ? _c('h3', {
    class: _vm.titleClasses
  }, [_vm.tip ? _c('div', {
    class: _vm.tipClasses
  }, [_c('UiTip', {
    attrs: {
      "position": _vm.tip.position,
      "message": _vm.tip.message,
      "tip-styles": _vm.tip.styles
    }
  })], 1) : _vm._e(), _vm._t("title", function () {
    return [_c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.title)
      }
    })];
  })], 2) : _vm._e(), _vm.showVisibilityToggle ? _c('UiButton', {
    attrs: {
      "size": "xs",
      "type": "link",
      "label": _vm.isBlockVisible ? 'Скрыть' : 'Показать'
    },
    on: {
      "click": _vm.toggleVisibility
    }
  }) : _vm._e()], 1), _c('div', [_vm._t("additionalSlot")], 2), _c('div', {
    directives: [{
      name: "toggle-height",
      rawName: "v-toggle-height"
    }],
    ref: "toggleHeightTarget",
    staticClass: "form-block__content"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }