var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "filter-list"
  }, [_c('div', {
    staticClass: "filter-list__content"
  }, [_c('div', {
    class: _vm.filterClasses
  }, [_c('UiForm', {
    ref: "uiFilterFormFirst",
    staticClass: "first-filter__form",
    attrs: {
      "data": _vm.filterData,
      "fields": _vm.firstFilter,
      "tableListFormat": true,
      "disable-error-scroll": true
    },
    on: {
      "change": function (data) {
        return _vm.setFilterData(data, true);
      }
    }
  }), _vm.otherFilters.length ? _c('UiButton', {
    attrs: {
      "label": _vm.otherFiltersLabel,
      "type": "secondary",
      "prependIcon": "icons-filter",
      "size": "xs"
    },
    on: {
      "click": _vm.setShowFilters
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "filter-list__chips"
  }, [_vm.showChips ? _c('ul', {
    staticClass: "filter-list__chips-list"
  }, [_vm._l(_vm.filtersChips, function (chip, idx) {
    return _c('li', {
      key: idx,
      staticClass: "filter-list__chips-item"
    }, [_vm._v(" " + _vm._s(chip.label) + " "), _c('UiSmartIcon', {
      staticClass: "filter-list__chips-close",
      attrs: {
        "name": "icons-close",
        "color": "var(--white)",
        "width": "22",
        "height": "22"
      },
      on: {
        "click": function ($event) {
          return _vm.clearFilter(chip.name);
        }
      }
    })], 1);
  }), _vm.hasFiltersChips ? _c('li', {
    staticClass: "filter-list__chips-item clear",
    on: {
      "click": _vm.clearFilters
    }
  }, [_vm._v(" Очистить фильтры ")]) : _vm._e()], 2) : _vm._e()]), _vm.showFilters ? _c('div', {
    staticClass: "filter-list__other-filters"
  }, [_c('UiForm', {
    ref: "uiFilterFormSecond",
    staticClass: "other-filters__form",
    attrs: {
      "data": _vm.filterData,
      "fields": _vm.otherFilters,
      "tableListFormat": true,
      "disable-error-scroll": true
    },
    on: {
      "change": function (data) {
        return _vm.setFilterData(data, false);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function (slotProps) {
          return [_vm._t(name, null, null, Object.assign({}, slotProps, _vm.slotMethodsAndProps))];
        }
      };
    })], null, true)
  }), _c('UiButton', {
    staticClass: "filter-list__accept-filters",
    attrs: {
      "label": "Применить фильтры",
      "prependIcon": "icons-filter",
      "disabled": !_vm.otherFiltersValid,
      "size": "xs"
    },
    on: {
      "click": function ($event) {
        return _vm.applyFilters(false);
      }
    }
  })], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }