<template>
	<UiBlock withoutVisibleAnimation :title="title || undefined">
		<template #title>
			<slot name="title" />
		</template>
		<div v-if="documentsSignedByLpi.length" class="ui-documents">
			<div class="mb-8 flex flex-sb gap-16">
				<h5 v-if="title">{{ forSignLabel }}</h5>
				<h3 v-else>{{ forSignLabel }}</h3>
				<UiButton
					v-if="showSignAll(documentsSignedByLpi)"
					size="xs"
					label="Подписать все"
					:to="signAllHref"
				/>
				<UiButton
					v-else
					size="xs"
					label="Посмотреть все"
					:to="viewAllLpiHref"
					target="_blank"
				/>
			</div>
			<UiDocument
				v-for="document in documentsSignedByLpi"
				:key="document.documentId + 'lpi'"
				showFinishedOnlyParticipantSelf
				v-bind="makeUiDocumentProps(document)"
				@changeDocument="changeDocument($event, document.documentId)"
			/>
		</div>
		<div v-for="additionalBlock in additionalLpiBlocks"
			:key="additionalBlock.title"
			class="ui-documents">
			<div class="mb-8 flex flex-sb gap-16">
				<h5 v-if="title">{{additionalBlock.title}}</h5>
				<h3 v-else>{{additionalBlock.title}}</h3>
				<UiButton
					v-if="showSignAll(additionalBlock.documents)"
					size="xs"
					label="Подписать все"
					:to="signAllHref"
				/>
				<UiButton
					v-else
					size="xs"
					label="Посмотреть все"
					:to="viewAllLpiHref"
					target="_blank"
				/>
			</div>
			<UiDocument
				v-for="document in additionalBlock.documents"
				:key="document.documentId"
				showFinishedOnlyParticipantSelf
				v-bind="makeUiDocumentProps(document, additionalBlock?.call)"
				@changeDocument="changeDocument($event, document.documentId)"
			/>
		</div>
		<div v-if="documentsSignedByThirdParty.length" class="ui-documents">
			<div class="mb-8 flex flex-sb gap-16">
				<h5>Подписываемые третьими лицами</h5>
				<UiButton
					size="xs"
					label="Посмотреть все"
					:to="viewAllThirdPartyHref"
					target="_blank"
				/>
			</div>
			<UiDocument
				v-for="document in documentsSignedByThirdParty"
				:key="document.documentId"
				showFinishedOnlyEntitySelf
				v-bind="makeUiDocumentProps(document)"
				@changeDocument="changeDocument($event, document.documentId)"
			/>
		</div>
	</UiBlock>
</template>

<script>
import {
	DOCUMENT_SIGN_PAGE,
	DOCUMENT_SIGN_WATCH_PAGE,
} from '@/router/routes/names';
import { partitionArray } from '@/utils/common';
import { SINGER_TYPES } from "@configs/participants";

export default {
	name: 'UiDocuments',
	model: {
		prop: 'documents',
		event: 'changeDocuments',
	},
	props: {
		documents: {
			type: Array,
			required: true,
		},
		participantId: {
			type: Number,
			required: true,
		},
		projectId: {
			type: Number,
			required: false,
		},
		title: {
			default: 'Юридические документы',
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		ignoreLpi: {
			type: Boolean,
			default: false,
		},
		forSignLabel: {
			type: String,
			default: 'Для подписи ЛПИ',
		},
		redirectRoute: {
			type: Object,
			default: null,
		},
		additionalLpiBlocks: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		documentsModel: undefined,
	}),
	computed: {
		showSignAll() {
			return (documents) => {
				const notSignedByLpi = documents.some((doc) => {
					return doc.signs.some(sing => {
						return sing.type === null && sing.signerType === SINGER_TYPES.PARTICIPANT && sing.signerId === this.participantId
					});
				})
				return (
					documents.length >= 1 &&
            notSignedByLpi &&
            !this.readonly
				);
			}
		},
		viewAllThirdPartyHref() {
			return {
				name: DOCUMENT_SIGN_WATCH_PAGE,
				query: {
					documentId: this.documentsSignedByThirdParty.map(
						(doc) => doc.documentId,
					),
				},
				params: {
					redirectRoute: this.redirectRoute || null,
				},
			};
		},
		viewAllLpiHref() {
			return {
				name: DOCUMENT_SIGN_WATCH_PAGE,
				query: {
					documentId: this.documentsSignedByLpi.map((doc) => doc.documentId),
				},
				params: {
					redirectRoute: this.redirectRoute || null,
				},
			};
		},
		signAllHref() {
			return {
				name: DOCUMENT_SIGN_PAGE,
				params: {
					participantId: this.participantId,
					redirectRoute: this.redirectRoute || null,
				},
				query: {
					documentId: this.documentsSignedByLpi.map((doc) => doc.documentId),
				},
			};
		},
		partitionedDocs() {
			return partitionArray(this.documentsModel, this.isDocSignedByLpi);
		},
		documentsSignedByLpi() {
			return this.documentsModel.filter(document => this.isDocSignedByLpi(document));
		},
		documentsSignedByThirdParty() {
			return this.documentsModel.filter(document => this.isDocSignedNotByLpi(document));
		},
	},
	watch: {
		documents(documents) {
			this.documentsModel = documents;
		},
	},
	beforeMount() {
		this.documentsModel = this.documents;
	},
	methods: {
		isDocSignedByLpi(doc) {
			if (!doc.signs || !doc.signs.length) {
				return false;
			}

			if (this.ignoreLpi) {
				return true;
			}
			
			return doc.signs.some((sign) => {
				return (sign.signerId ?? sign.participantId) === this.participantId;
			});
		},
		isDocSignedNotByLpi(doc) {
			if (!doc.signs || !doc.signs.length) {
				return false;
			}

			return doc.signs.some((sign) => {
				return sign.signerType === SINGER_TYPES.ENTITY;
			});
		},
		sign() {
			window.open(this.signAllHref, '_blank');
		},
		makeUiDocumentProps(document, call) {
			if (call) {
				return call(document);
			}
			return {
				document,
				participantId: this.participantId,
				readonly: this.readonly,
			};
		},
		changeDocument(data, documentId) {
			const idx = this.documentsModel.findIndex(
				(doc) => doc.documentId === documentId,
			);
			this.$set(this.documentsModel, idx, data);

			this.$emit('changeDocuments', this.documentsModel);
		},
	},
};
</script>
