import { setValue, getValue } from '@utils/common';

export default {
    bind(el, binding, vnode) {
        if (vnode.componentOptions == null) return;

        const prop = vnode.componentOptions?.Ctor?.options.model.prop,
            event = vnode.componentOptions?.Ctor?.options.model.event;

        vnode.componentInstance[prop] = getValue(
            vnode.context,
            binding.expression
        );
        vnode.componentInstance.$on(event, (v) =>
            setValue(vnode.context, binding.expression, v)
        );
    },
    unbind() {},
};
