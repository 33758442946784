import {PROJECT_VIEW_TYPES} from "@configs/investProject";

export default {
	computed: {
		modeType() {
			return this.$kc.hasRole('operator') ? PROJECT_VIEW_TYPES.OPERATOR : PROJECT_VIEW_TYPES.OWNER;
		},
		isOperator() {
			return this.modeType === PROJECT_VIEW_TYPES.OPERATOR;
		},
	},
};
