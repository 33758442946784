<template>
	<div class="ui-comments__list">
		<div class="ui-comments__list-header">
			<slot name="header" v-bind="{ commentsTitle }">
				<h3 class="ui-comments__list-title">
					{{ commentsTitle }}
					<span class="ui-comments__list-title span">
						({{ countComments }})
					</span>
				</h3>
			</slot>
		</div>
		<UiSkeleton 
			showMode="if"
			:loadingStyle="skeletonStyles" 
			class="ui-comments__list-skeleton" 
			:loading="currentComments.loader" 
			height="300px"
		>
			<div v-if="hasComments" class="ui-comments__list-items">
				<div v-for="(frame, idx) in currentComments.items" :key="idx" class="ui-comments__item">
					<UiContentFrame
						:data="commentItem" 
						isComment
						:commentsEnale="commentsEnale"
						:isOwner="isOwner"
						:frame-content="frame"
						class="ui-comments__item-frame"
						@answer="selectAnswer(frame)"
						@delete="deleteComment"
					/>
					<div 
						v-if="frame.commentId === answerItem.parentId" 
						class="ui-comments__item-answer add-answer"
					>
						<UiForm
							:data="answerItem"
							:fields="answerFields"
							class="add-answer__form"
							@change="data => setAnswerItem(data)"
						/>
						<div class="add-answer__actions">
							<UiButton 
								label="Отмена"
								size="xs"
								class="add-answer__actions-cancel"
								@click="clearAnswer"
							/>
							<UiButton 
								label="Ответить"
								size="xs"
								:disabled="canSubmitAnswer"
								class="add-answer__actions-submit"
								@click="submitAnswer"
							/>
						</div>
					</div>
					<div v-for="(frame, idx) in getChildrenComments(frame)" :key="idx" class="ui-comments__item-childrens">
						<UiContentFrame
							:data="commentItem" 
							:isOwner="isOwner"
							:commentsEnale="commentsEnale"
							isComment
							:frame-content="frame"
							class="ui-comments__item-frame"
							@answer="selectAnswer(frame)" 
						/>
						<div 
							v-if="frame.commentId === answerItem.parentId" 
							class="ui-comments__item-answer add-answer"
						>
							<UiForm
								:data="answerItem"
								:fields="answerFields"
								class="add-answer__form"
								@change="data => setAnswerItem(data)"
							/>
							<div class="add-answer__actions">
								<UiButton 
									label="Отмена"
									size="xs"
									class="add-answer__actions-cancel"
									@click="clearAnswer"
								/>
								<UiButton 
									label="Ответить"
									size="xs"
									:disabled="canSubmitAnswer"
									class="add-answer__actions-submit"
									@click="submitAnswer"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="ui-comments__list-items no-items">
				{{ noCommentsText }}
			</div>
		</UiSkeleton>
		<UiPagination
			v-if="isPaginationVisible"
			:currentPage="currentPage"
			:total="totalCount"
			:pageSize="pageSize"
			class="ui-comments__pagination"
			@changePage="(number) => (currentPage = number)"
		/>
		<div v-if="commentsEnale" class="ui-comments__comment-form">
			<UiForm
				:data="commentItem"
				:fields="commentFields"
				class="add-comment__form"
				@change="data => setCommentItem(data)"
			/>
			<UiButton 
				label="Отправить комментарий"
				:pending="commentLoader"
				:disabled="canSubmit"
				prepend-icon="arrow-up"
				class="add-comment__form-submit"
				@click="submitComment"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ORDER_BY } from '@/services/tables';

export default {
	name: 'UiCommentsFrameList',
	props: {
		/** Именованный акшен для вызова списка */
		commentsAction: {
			type: String,
			required: true
		},
		/** Именованный геттер для обращения к списку */
		commentsGetter: {
			type: String,
			required: true
		}, 
		/** Заголовок списка комментариев */
		commentsTitle: {
			type: String,
			default: ''
		},
		/** Что отображать если элементы отсутствуют */
		noCommentsText: {
			type: String,
			default: ''
		},
		/** Количество элементов в списке */
		pageSize: {
			type: Number,
			default: 10
		},
		/** Дополнительные параметры запроса */
		mixData: {
			type: Object,
			default: null
		},
		/** Сущность к которой относятся комментарии */
		entityType: {
			type: String,
			default: ''
		},
		/** id сущности к которой относится комментарий */
		entityId: {
			type: Number,
			default: null
		},
		/** Кол-во комментариев */
		countComments: {
			type: Number,
			default: null
		},
		/** Признак владельца новости/обсуждения */
		isOwner: {
			type: Boolean,
			default: false
		},
		/** Признак возможности оставлять комментарии */
		commentsEnale: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			currentPage: 1,
			answeredComment: null,
			sortDirection: ORDER_BY.ASC,
			commentFields: [
				{
					name: "content",
					label: "Ваш комментарий",
					type: "text",
					placeholder: "Напишите ваш комментарий"
				},
			],
			answerFields: [
				{
					name: "content",
					label: "",
					type: "string",
					placeholder: "Введите ответ"
				},
			]
		}
	},
	computed: {
		...mapGetters({
			commentItem: 'comments/projectCommentItemGetter',
			answerItem: 'comments/projectAnswerItemGetter',
			commentLoader: 'comments/projectCommentLoader',
		}),
		skeletonStyles() {
			return {
				'margin': 'auto',
				'width': '80%',
				'min-width': '600px',
				'max-width': '1200px',
			}
		},
		/** Текущий список комментариев */
		currentComments() {
			return this.$store.getters[this.commentsGetter];
		},
		totalCount() {
			return this.currentComments?.totalCount;
		},
		hasComments() {
			return this.currentComments?.items?.length;
		},
		refetchDependencies() { 
			return `${this.currentPage} ${this.pageSize} ${this.tableAction}`;
		},
		canSubmit() {
			return !this.commentItem.content.length;
		},
		canSubmitAnswer() {
			return !this.answerItem.content.length;
		},
		isPaginationVisible() {
			return this.pageSize <= this.totalCount;
		},
	},

	watch: {
		refetchDependencies() {
			this.fetchCommentsData();
		},
	},

	beforeMount() {
		this.fetchCommentsData();
		this.setCommentData();
	},

	methods: {
		...mapActions({
			setCommentEntity: 'comments/actionSetCommentsEntity',
			setComment: 'comments/actionSetCommentItem',
			setAnswer: 'comments/actionSetAnswerItem',
			clearComment: 'comments/actionClearCommentItem',
			clearAnswer: 'comments/actionClearAnswerItem',
			addComment: 'comments/actionCreateCommentItem',
		}),
		setCommentData() {
			const data = {
				projectId: this.mixData.projectId,
				entityType: this.entityType,
				entityId: this.entityId
			}
			this.setCommentEntity(data);
		},
	  async	fetchCommentsData() {
			const data = {
				currentPage: this.currentPage,
				pageSize: this.pageSize,
				filters: {},
				sortDirection: this.sortDirection,
				mixData: this.mixData
			}
				
		 return this.$store.dispatch(this.commentsAction, {data});
		},
		selectAnswer(frame) {
			const data = { content: '', parentId: frame.commentId };
			this.setAnswer(data);
		},
		setCommentItem(data) {
			this.setComment(data);
		},
		setAnswerItem(data) {
			this.setAnswer(data);
		},
		getChildrenComments(frame) {
			let childrens = [];
			let secondChildrens = [];
			frame.children.forEach((comment) => {
				if (comment.children.length) {
					secondChildrens.push(...this.getChildrenComments({ ...comment, isChild: true }));
				}
				childrens.push({ ...comment, content: frame.isChild ? `${frame.fio + ", " + comment.content}` : comment.content }); 
			})
			const allComments = [...childrens, ...secondChildrens];
			allComments.sort((a, b) => Date.parse(a.created) - Date.parse(b.created));
			return allComments;
		},
		submitComment() {
			this.submitCommentData(this.commentItem);
			this.clearComment();
		},
		submitAnswer() {
			this.submitCommentData(this.answerItem);
			this.clearAnswer();
		},
		submitCommentData(data) {
			let commentItem = { ...data, projectId: this.mixData.projectId };
			this.addComment(commentItem).then(() => {
				this.fetchCommentsData().then(() => {
					this.$emit('update');
				});

			});
		},
		deleteComment() {
			this.fetchCommentsData().then(() => {
				this.$emit('update');
			});
		},
	}
}
</script>
