<template>
	<div 
		v-if="showStatus"
		:class="{
			'entity-status': true,
			'entity-status--warn': isWarnStatus
		}"
	>
		<h3 class="entity-status__title" 
			:class="{
				'entity-status__title--warn': isWarnStatus,
				'entity-status__title--moderation': isProjectModeration
			}"
		>
			<UiSmartIcon 
				:name="conditionStatus.icon"
				:color="projectIconColor" 
				:size="20"
				class="entity-status__title-icon" 
			/>
			{{ conditionStatus.title }}
		</h3>

		<div v-if="isProjectRejected" class="entity-status__reject-block">
			<slot v-if="project.rejectReason" v-bind="{ project, rejectReason: convertedString(project.rejectReason) }">
				<p class="entity-status__reject-reason" v-html="convertedString(project.rejectReason)" />
			</slot>
			<p v-else class="entity-status__reject-reason" v-html="convertedString('- Причина не указана')"/>
			<div class="entity-status__buttons">
				<UiButton
					v-if="enableEditButton"
					label="Редактировать проект"
					size="xs"
					class="entity-status__reject-btn"
					@click="submitToEdit"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { STATUS } from '@/configs/participants';
import { convertedString } from '@utils/common';

export default {
	name: 'UiProjectStatus',
	props: {
		/**
		 * Информационный объект участника/проекта, который передается в компонент и односторонне отображает информацию о ее статусе
		 */
		project: {
			type: Object,
			required: true
		}, 
		/**
		 * Показ кнопки редактирования при статусе STATUS.REJECTED
		 */
		enableEditButton: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	data() {
		return {
			convertedString,
		}
	},
	
	computed: {
		projectStatus() {
			return {
				[STATUS.REJECTED]: {
					title: 'Проект не прошел модерацию по причине:',
					icon: 'icons-attention',
				},
				[STATUS.PREPARED]: {
					title: `Проект находится на модерации`,
					icon: 'icons-clock'
				},
				[STATUS.BLOCKED]: {
					title: `Данный проект заблокирован`,
					icon: 'icons-off'
				},
			}
		},
		showStatus() {
			return this.isProjectRejected || this.isProjectModeration || this.isProjectBlocked;
		},
		isWarnStatus() {
			return this.isProjectRejected || this.isProjectBlocked;
		},
		isProjectBlocked() {
			return this.project?.status === STATUS.BLOCKED;
		},
		isProjectModeration() {
			return this.project?.status === STATUS.PREPARED;
		},
		isProjectRejected() {
			return this.project?.status === STATUS.REJECTED;
		},
		projectIconColor() {
			return (this.isProjectRejected || this.isProjectBlocked)
				? '#DE2D31'
				: '#818586'
		},
		conditionStatus() {
			return this.projectStatus[this.project.status];
		},
	},

	methods: {
		submitAppeal() {
			this.$emit('appealRequest')
		},
		submitToEdit() {
			this.$emit('toEdit')
		},
	}
}
</script>
