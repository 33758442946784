var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.readonly ? _c('div', {
    staticClass: "mb-16"
  }, [_vm._t("addButton", function () {
    return [_c('UiButton', {
      staticClass: "ui-array-input__upload-btn",
      attrs: {
        "type": "secondary",
        "label": _vm.buttonLabel,
        "prepend-icon": "add-icon"
      },
      on: {
        "click": _vm.openForm
      }
    })];
  }, null, {
    openForm: _vm.openForm
  })], 2) : _vm._e(), _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.label,
      expression: "label"
    }],
    staticClass: "ui-array-input__label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('div', {
    class: {
      'ui-array-input__frame-error': _vm.error
    }
  }, [_c('UiTable', {
    attrs: {
      "columns": _vm.columnsWithActions,
      "items": _vm.value
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function (row) {
        return [_c('div', {
          staticClass: "ui-array-input__table-actions"
        }, [!_vm.readonly ? [_c('span', {
          attrs: {
            "title": "Изменить..."
          }
        }, [_c('UiSmartIcon', {
          attrs: {
            "size": "20",
            "name": "icons-edit",
            "color": "#3589CF"
          },
          on: {
            "click": function ($event) {
              return _vm.openEditForm(row);
            }
          }
        })], 1), _c('span', {
          attrs: {
            "title": "Удалить"
          }
        }, [_c('UiSmartIcon', {
          attrs: {
            "size": "20",
            "name": "delete-icon",
            "color": "#3589CF"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItem(row.index);
            }
          }
        })], 1)] : [_c('span', {
          attrs: {
            "title": "Посмотреть"
          }
        }, [_c('UiSmartIcon', {
          attrs: {
            "size": "20",
            "name": "icons-eye",
            "color": "#3589CF"
          },
          on: {
            "click": function ($event) {
              return _vm.openEditForm(row);
            }
          }
        })], 1)]], 2)];
      }
    }, {
      key: "name",
      fn: function (data) {
        return [_c('p', [_vm._v(" " + _vm._s(_vm.getShareholderName(data)) + " ")])];
      }
    }])
  })], 1), !!_vm.error ? _c('div', {
    staticClass: "ui-array-input__error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()]), _c('UiDialog', {
    ref: "arrayInputModal",
    attrs: {
      "width": "80%",
      "title": _vm.title,
      "closeByClickOutside": false
    },
    on: {
      "close": _vm.clearFormState
    }
  }, [[_c('div', {
    staticClass: "modal__body mt-16"
  }, [_c('UiForm', {
    ref: "projectForm",
    attrs: {
      "tips-provider": _vm.tipsProvider(_vm.tipsProviderKey),
      "fields": _vm.modifiedReadonlyFields,
      "data": _vm.formState,
      "disable-error-scroll": ""
    },
    on: {
      "onFileUploaded": function ($event) {
        _vm.loading = !$event;
      },
      "change": function (data) {
        return _vm.formState = data;
      }
    },
    scopedSlots: _vm._u([{
      key: "findShareholderByInn",
      fn: function (_ref) {
        var data = _ref.data;
        return [_c('UiButton', {
          staticClass: "ui-array-input__upload-btn",
          attrs: {
            "label": "Поиск по ИНН",
            "disabled": _vm.readonly,
            "pending": _vm.searchByInnLoading
          },
          on: {
            "click": function ($event) {
              return _vm.searchShareholderByINN(data);
            }
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "buttons-block mt-16"
  }, [_c('ul', {
    staticClass: "list-none"
  }, [!_vm.readonly ? _c('li', [_c('UiButton', {
    staticClass: "mt-0",
    attrs: {
      "pending": _vm.loading,
      "label": _vm.labelButton,
      "type": "primary"
    },
    on: {
      "click": _vm.addOrEdit
    }
  })], 1) : _vm._e()])])]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }