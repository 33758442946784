<template>
	<div
		v-blur="blurConfig"
		class="ui-skeleton"
		:class="{
			'ui-skeleton--shaded': shaded,
			'ui-skeleton--container': container,
		}"
	>
		<div
			v-if="vIfFlag"
			v-show="vShowFlag"
			:key="`${key}_spinner`"
			class="ui-skeleton__spinner"
			:style="loadingStyleCalc"
		>
			<div
				class="ui-skeleton__spinner-wrapper"
			>
				<UiSpinner class="ui-skeleton__spinner" :size="spinnerSize" />
				<span v-if="message" class="ui-skeleton__spinner-message" v-html="message"/>
			</div>
		</div>

		<div
			v-if="vIfFlagNegative"
			v-show="vShowFlagNegative"
			:key="`${key}_content`"
			class="ui-skeleton__content"
		>
			<slot />
		</div>
	</div>
</template>

<script>
import { randomKey } from "@/utils/common";

export default {
	name: 'UiSkeleton',
	inheritAttrs: false,
	props: {
		/**
		 * passed down to UiSpinner
		 * size of the spinner
		 * number or string without unit will be treated as pixels
		 * @example 'l', 'm', 'xs', 42, '42', '42px', '42rem'
		 * @values l, m, xs
		 * @type {number | string}
		 */
		spinnerSize: {
			type: [Number, String],
			default: 'm',
		},
		/**
		 * Skeleton width css style value,
		 * @example '42%', '42px', '42rem'
		 * @type {string}
		 */
		width: {
			type: String,
			default: undefined,
		},
		/**
		 * Skeleton height css style value,
		 * @example '42%', '42px', '42rem'
		 * @type {string}
		 */
		height: {
			type: String,
			default: 'auto',
		},
		/**
		 * Skeleton style css style value,
		 * @example '42%', '42px', '42rem'
		 * @type {string|{*}}
		 */
		loadingStyle: {
			type: [String,Object],
		},
		/**
		 * Skeleton style css style value,
		 * @example '40px 48px'
		 * @type {string}
		 */
		loadingPadding: {
			type: String,
		},
		/**
		 * Spinner will be shown when loading is true
		 * slot content will be shown otherwise
		 * @type boolean
		 */
		loading: {
			type: Boolean,
			default: false,
		},
		blur: {
			type: Boolean,
			default: false,
		},
		/**
		 * Set background to light gray color
		 * @type {boolean}
		 */
		shaded: {
			type: Boolean,
			default: false,
		},
		/**
		 * Режим скрывания элементов:
		 * @type {string}
		 * @value show - используется v-show
		 * @value if - используется v-if
		 */
		showMode: {
			type: String,
			default: "show",
			validator: (value) => /^(show|if)$/.test(value),
		},
		message: {
			type: String,
			default: null,
		},
		container: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return {
			key: randomKey("sklt#"),
		}
	},

	computed: {
		blurConfig() {
			return {
				isBlurred: this.blur,
				opacity: 0.5,
				filter: 'blur(15px)',
				transition: 'all .3s linear'
			}
		},
		vShowFlag() {
			if (this.showMode === "show") {
				return !!this.loading;
			}
			return true;
		},
		vIfFlag() {
			if (this.showMode === "if") {
				return !!this.loading;
			}
			return true;
		},
		vShowFlagNegative() {
			if (this.showMode === "show") {
				return !this.loading;
			}
			return true;
		},
		vIfFlagNegative() {
			if (this.showMode === "if") {
				return !this.loading;
			}
			return true;
		},

		loadingStyleCalc() {
			if (typeof this.loadingStyle === "string") {
				return [
					this.loadingStyle,
					this.width ? "width: " + this.width : undefined,
					this.height ? "height: " + this.height : undefined,
					this.loadingPadding ? "padding: " + this.loadingPadding : undefined,
				].join(";");
			}
			return { ...this.loadingStyle, width: this.width, height: this.height, padding: this.loadingPadding };
		},
	},
};
</script>
