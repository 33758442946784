var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "cipher-link"
  }, [_c('router-link', _vm._g(_vm._b({
    attrs: {
      "to": _vm.linkLocation
    }
  }, 'router-link', _vm.$attrs, false), _vm.$listeners), [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.displayName))];
  }, null, {
    displayName: _vm.displayName,
    linkLocation: _vm.linkLocation
  })], 2), _c('UiSmartIcon', {
    staticClass: "cipher-link__icon",
    attrs: {
      "name": "icons-copy",
      "width": "20",
      "height": "18"
    },
    on: {
      "click": _vm.copyLinkToClipboard
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }