var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.wrapperClasses
  }, [_c('div', {
    staticClass: "input-header"
  }, [_vm.label ? _c('label', {
    class: _vm.labelClasses
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.tip ? _c('div', {
    staticClass: "input__tip form__tip"
  }, [_c('UiTip', {
    attrs: {
      "message": _vm.tip.message
    }
  })], 1) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "ui-input__wrapper",
    class: {
      'ui-textarea__wrapper': _vm.type === 'textarea'
    }
  }, [_vm.type === 'textarea' ? _c('textarea', _vm._g({
    ref: "input",
    staticClass: "ui-input ui-input--textarea",
    class: _vm.additionalClasses,
    attrs: {
      "maxlength": _vm.maxLength,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "readonly": _vm.readonly,
      "name": _vm.name
    },
    domProps: {
      "value": _vm.modelProxy
    },
    on: {
      "input": function ($event) {
        _vm.modelProxy = $event.target.value;
      },
      "keydown": _vm.onKeydown
    }
  }, _vm.$listeners)) : 'text' === 'checkbox' ? _c('input', _vm._g({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.maskedValue,
      expression: "maskedValue"
    }, {
      name: "maska",
      rawName: "v-maska"
    }],
    ref: "input",
    staticClass: "ui-input",
    class: _vm.additionalClasses,
    attrs: {
      "maxlength": _vm.maxLength,
      "data-maska": _vm.maskJson,
      "data-maska-eager": _vm.maskEager ? '' : undefined,
      "data-maska-tokens": _vm.maskTokens,
      "data-maska-reversed": _vm.maskReversed ? '' : undefined,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "readonly": _vm.readonly,
      "name": _vm.name,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.maskedValue) ? _vm._i(_vm.maskedValue, null) > -1 : _vm.maskedValue
    },
    on: {
      "keydown": _vm.onKeydown,
      "maska": _vm.onMaska,
      "change": function ($event) {
        var $$a = _vm.maskedValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.maskedValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.maskedValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.maskedValue = $$c;
        }
      }
    }
  }, _vm.$listeners)) : 'text' === 'radio' ? _c('input', _vm._g({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.maskedValue,
      expression: "maskedValue"
    }, {
      name: "maska",
      rawName: "v-maska"
    }],
    ref: "input",
    staticClass: "ui-input",
    class: _vm.additionalClasses,
    attrs: {
      "maxlength": _vm.maxLength,
      "data-maska": _vm.maskJson,
      "data-maska-eager": _vm.maskEager ? '' : undefined,
      "data-maska-tokens": _vm.maskTokens,
      "data-maska-reversed": _vm.maskReversed ? '' : undefined,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "readonly": _vm.readonly,
      "name": _vm.name,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.maskedValue, null)
    },
    on: {
      "keydown": _vm.onKeydown,
      "maska": _vm.onMaska,
      "change": function ($event) {
        _vm.maskedValue = null;
      }
    }
  }, _vm.$listeners)) : _c('input', _vm._g({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.maskedValue,
      expression: "maskedValue"
    }, {
      name: "maska",
      rawName: "v-maska"
    }],
    ref: "input",
    staticClass: "ui-input",
    class: _vm.additionalClasses,
    attrs: {
      "maxlength": _vm.maxLength,
      "data-maska": _vm.maskJson,
      "data-maska-eager": _vm.maskEager ? '' : undefined,
      "data-maska-tokens": _vm.maskTokens,
      "data-maska-reversed": _vm.maskReversed ? '' : undefined,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "readonly": _vm.readonly,
      "name": _vm.name,
      "type": 'text'
    },
    domProps: {
      "value": _vm.maskedValue
    },
    on: {
      "keydown": _vm.onKeydown,
      "maska": _vm.onMaska,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.maskedValue = $event.target.value;
      }
    }
  }, _vm.$listeners)), _vm.showLimitWatcher ? _c('div', {
    class: _vm.limitWatcherClasses
  }, [_vm._v(" " + _vm._s(_vm.limitWatcher) + " ")]) : _vm._e(), _vm.type != 'textarea' ? _c('div', {
    staticClass: "icons__wrapper",
    on: {
      "click": _vm.inputFocus
    }
  }, [_c('div', {
    staticClass: "prefix"
  }, [_vm.appendIcon ? _c('div', {
    staticClass: "append-icon"
  }) : _vm._e()]), _c('div', {
    staticClass: "postfix"
  }, [_vm.type === 'password' ? _c('div', {
    staticClass: "toggle-btn",
    class: _vm.passwordClasses,
    on: {
      "click": _vm.togglePasswordVisibility
    }
  }) : _vm.clearable ? _c('div', {
    staticClass: "clear-btn",
    attrs: {
      "type": "reset",
      "title": "Click me to clear the input field"
    },
    on: {
      "click": function ($event) {
        return _vm.resetInput($event);
      }
    }
  }, [_vm._v("×")]) : _vm._e(), _vm.prependIcon ? _c('div', {
    staticClass: "prepend-icon"
  }) : _vm._e()])]) : _vm._e()]), _vm.caption || _vm.error ? _c('span', {
    staticClass: "input-caption",
    class: _vm.error ? 'input-caption__error' : ''
  }, [_vm._v(" " + _vm._s(_vm.error ? _vm.error : _vm.caption) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }