
import {
	createNewsCommentItem,
	createDiscussCommentItem,
	deleteNewsCommentItem,
	deleteDiscussCommentItem
} from "@services/comments";

export default {
	namespaced: true,

	state: {
		commentItem: {
			content: '',
			parentId: null,
		},
		answerItem: {
			content: '',
			parentId: null,
		},
		projectId: null,
		entityId: null,
		entityType: '',
		loader: null
	},
	actions: {
		async actionCreateCommentItem({ state, commit }, data) {
			const projectId = data.projectId;
			const entityId = state.entityId;
			commit('mutationSetLoader', true);
			switch (state.entityType) {
			case 'news':
				return createNewsCommentItem(projectId, entityId, data)
					.then(() => {
						commit('mutationSetLoader', false);
					});
			case 'discuss':
				return createDiscussCommentItem(projectId, entityId, data)
					.then(() => {
						commit('mutationSetLoader', false);
					});
			}
		},
		actionSetCommentItem({ commit }, data) {
			commit('mutationSetCommentItem', data);
		},
		actionSetAnswerItem({ commit }, data) {
			commit('mutationSetAnswerItem', data);
		},
		actionClearCommentItem({ commit }) {
			commit('mutationSetCommentItem', { content: '', parentId: null });
		},
		actionClearAnswerItem({ commit }) {
			commit('mutationSetAnswerItem', { content: '', parentId: null });
		},
		actionDeleteNewsCommentItem({ }, data) {
			return deleteNewsCommentItem(data.projectId, data.newsId, data.commentId);
		},
		actionDeleteDiscussCommentItem({ }, data) {
			return deleteDiscussCommentItem(data.projectId, data.discussionId, data.commentId);
		},
		actionSetCommentsEntity({ commit }, data) {
			commit('mutationSetCommentsEntity', data);
		},
	},
	mutations: {
		mutationSetCommentItem(state, data) {
			state.commentItem = data;
			state.loader = false;
		},
		mutationSetAnswerItem(state, data) {
			state.answerItem = data;
		},
		mutationSetCommentsEntity(state, data) {
			state.projectId = data.projectId;
			state.entityType = data.entityType;
			state.entityId = data.entityId;
		},
		mutationSetLoader(state, bool) {
			state.loader = bool;
		},
	},
	getters: {
		projectCommentItemGetter(state) {
			return state.commentItem;
		},
		projectAnswerItemGetter(state) {
			return state.answerItem;
		},
		projectCommentLoader(state) {
			return state.loader;
		},
	},
};