<template>
	<div>
		<UiButton 
			:label="exportLabel"
			type="secondary"
			prependIcon="icons-list"
			size="xs"
			class="ui-export__btn"
			@click="exportTable"
		/>
		<ExportToExcelModal 
			ref="exportToExcelModal" 
			:fileName="sheetTitle"
			:multiple="multiple"
			@changeExcelName="changeExcelName"
		/>
	</div>
</template>

<script>
import ExportToExcelModal from '@components/ExportToExcelModal.vue'
import { getValue } from '@/utils/common';
import { stringSlicer } from '@/utils/common';
import { FORMAT_EXPORT_CODE } from '@utils/format';

export default {
	name: 'UiExportToExcel',
	components: { ExportToExcelModal },
	props: {
		/** Лейбл кнопки экспорта */
		exportLabel: {
			type: String,
			default: 'Экспортировать в Excel',
		},
		/**
		 * Множественный экспорт
		 *
		 * @values true | false
		 */
		multiple: {
			type: Boolean,
			default: false,
		},
		/**
		 * Массив с таблицами
		 *
		 * @type Array
		 */
		sheets: {
			type: Array,
			default: () => [],
		},
		/**
		 * Название excel файла после экспорта
		 *
		 * @type String
		 */
		fileName: {
			type: String,
			default: 'table',
		}
	},
	data: () => ({
		sheetsArr: [],
	}),
	computed: {
		sheetTitle() {
			return !this.multiple ? this.sheetsArr[0]?.label || this.fileName : this.fileName;
		},
	},
	methods: {
		formatColumn(XLSX, worksheet, col, fmt) {
			const range = XLSX.utils.decode_range(worksheet['!ref'])
			// range.s.r + 1 пропускает ряд хэдера
			for (let row = range.s.r + 1; row <= range.e.r; ++row) {
				const ref = XLSX.utils.encode_cell({ r: row, c: col })
				if (worksheet[ref] && worksheet[ref].t === 'n') {
					worksheet[ref].z = fmt
				}
			}
		},
		formatToNumItem(item, currency) {
			let cur_re = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/;
			let parts = cur_re.exec(item);
			let number;
			number = parseFloat(parts[1].replace(/\D+/g, '') + '.' + (parts[2] ? parts[2] : '00'));
			if (currency === FORMAT_EXPORT_CODE.PERCENT) {
				return Number(number.toFixed(2)) / 100;
			} else {
				return number;
			}
		},
		async exportTable() {
			const XLSX = await import('xlsx');

			this.sheetsArr = JSON.parse(JSON.stringify(this.sheets));
			const modalAnswer = await this.$refs.exportToExcelModal.show();
			if (modalAnswer !== 'export') return;
            
			const workbook = XLSX.utils.book_new();
            
			for (let i = 0; i < this.sheets.length; i++) {
				const headers = this.sheets[i].columns.map(column => column.label);
				const data = [];

				this.sheets[i].items.forEach((item) => {
					const newDataArr = [];
					this.sheets[i].columns.forEach((column) => {
						if (column.mapper) {
							const mappedItem = column.mapper(getValue(item, column.name));
							if (mappedItem && typeof mappedItem === 'string' && mappedItem?.includes(',') && this.formatToNumItem(mappedItem, column?.currency)) {
								newDataArr.push(this.formatToNumItem(mappedItem, column?.currency));
							} else {
								newDataArr.push(mappedItem);
							}
						} else if (column.mapperItem) {
							const mappedItem = column.mapperItem(item);
							if (mappedItem && typeof mappedItem === 'string' && mappedItem?.includes(',') && this.formatToNumItem(mappedItem)) {
								newDataArr.push(this.formatToNumItem(mappedItem));
							} else {
								newDataArr.push(mappedItem);
							}
						} else {
							newDataArr.push(getValue(item, column.name));
						}
					})
					data.push(newDataArr);
				})
				data.unshift(headers);

				const worksheet = XLSX.utils.aoa_to_sheet(data);

				// форматирование
				for (let col = 0; col < headers.length; col++) {
					const currency = this.sheets[i]?.columns[col].currency || FORMAT_EXPORT_CODE.GENERAL;
					this.formatColumn(XLSX, worksheet, col, currency);
				}

				let sheetName = this.sheetsArr[i].label;
				if (sheetName) {
					// обрезаем слишком длинные слова
					sheetName = sheetName.split(' ').map((string) => string.length > 4 ? string.slice(0, 4) : string).join('.');
					// обрезаем по максимальной длине в 31 символ
					sheetName = stringSlicer(sheetName, 28);
				} else {
					sheetName = this.fileName;
				}

				XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
			}

			const exportName = !this.multiple ? this.sheetsArr[0].label || this.fileName : this.fileName;
			XLSX.writeFile(workbook, exportName + ".xlsx");
		},
		changeExcelName(val) {
			if (!this.multiple) this.sheetsArr[0].label = val;
		},
	} 
}
</script>
