// @ts-check
import * as names from './names';
import { getCipherLocation } from '@services/cipher';
import KC, { KC_ROLES, ALL_ROLES_ARR } from '@/keycloak';

/**
 * @typedef {Object} CustomMeta
 * @property {boolean} [CustomMeta.allowGuest] - разрешить гостям (по умолчанию - false, только авторизованным)
 * @property {string[]} [CustomMeta.allowedRoles] - разрешенные роли (по умолчанию - [])
 * @property {string} [CustomMeta.title] - заголовок страницы
 * @property {any[]} [CustomMeta.breadcrumb] - хлебные крошки (по умолчанию - [])
 * @property {string} [CustomMeta.layout] - компонент-обертка (по умолчанию - 'DefaultLayout')
 * 
 * @typedef {import('vue-router').RouteConfig & { meta?: CustomMeta }} RouteConfig
 */

/**
 * @type {RouteConfig[]}
 */
export default [
	{
		name: names.SIGN_PROJECT_DOCS,
		path: '/projects/:id(\\d+)/sign-documents',
		component: () => import('@/pages/ProjectsPage/SignProjectDocs.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Подписать документы`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'SignLayout'
		},
	},
	{
		name: names.CIPHER_LINK,
		path: '/link/:cipher',
		redirect: (to) => {
			return getCipherLocation(to.params.cipher, {
				operator: KC.instance().hasRole('operator'),
			});
		},
	},
	{
		path: '*',
		name: names.PAGE_404,
		component: () => import('@/pages/ServicePages/Page404.vue'),
		meta: {
			title: ` 404`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/403',
		name: names.PAGE_403,
		component: () => import('@/pages/ServicePages/Page403.vue'),
		meta: {
			title: `403`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/',
		name: names.MAIN,
		component: () => import('@/pages/HomePage/DefaultPage/DefaultPage.vue'),
		meta: {
			allowGuest: true,
		},
	},
	{
		path: '/lpi-home',
		name: names.LPI_HOME,
		component: () => import('@/pages/HomePage/LoginedPage/LoginedLpiPage.vue'),
		meta: {
			title: `Собрать средства`,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/invest-home',
		name: names.INVEST_HOME,
		component: () =>
			import('@/pages/HomePage/LoginedPage/LoginedInvestPage.vue'),
		meta: {
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/versions',
		name: names.VERSIONS,
		component: () => import('@/pages/VersionPage/VersionPage.vue'),
		meta: {
			title: `Версия приложения`,
			layout: 'EmptyLayout',
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/components',
		name: names.COMPONENTS,
		component: () => import('@pages/ComponentsPage/ComponentsPage.vue'),
		meta: {
			title: `Компоненты`,
			layout: 'EmptyLayout',
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/profile',
		name: names.PROFILE,
		component: () => import('@/pages/UserPage/UserPage.vue'),
		meta: {
			title: `Профиль`,
			layout: 'ProfileLayout',
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/profile/certificates',
		name: names.CERTIFICATES,
		component: () => import('@/pages/UserPage/CertificatesPage.vue'),
		meta: {
			title: `Сертификаты`,
			layout: 'ProfileLayout',
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/user-service-messages',
		name: names.USER_SERVICE_MESSAGES,
		component: () => import('@/pages/UserPage/UserServiceMessagesPage.vue'),
		meta: {
			title: `Центр уведомлений`,
			layout: 'NotificationsLayout',
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/user-platform-news',
		name: names.USER_PLATFORM_NEWS,
		component: () => import('@/pages/UserPage/UserPlatformNewsPage.vue'),
		meta: {
			title: `Новости платформы`,
			layout: 'NotificationsLayout',
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/user-projects-news-discuss',
		name: names.USER_PROJECTS_NEWS_DISCUSS,
		component: () => import('@/pages/UserPage/UserProjectsNewsDiscussPage.vue'),
		meta: {
			title: `Новости и обсуждения проектов`,
			layout: 'NotificationsLayout',
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/investor-cabinet',
		name: names.INVESTOR_CABINET,
		component: () => import('@/pages/InvestorCabinet/InvestmentsPage.vue'),
		meta: {
			title: `Инвестиции`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout',
		},
	},
	{
		path: '/investor-cabinet/finances/:participantId(\\d+)?',
		name: names.INVESTOR_CABINET_FINANCES,
		component: () => import('@/pages/InvestorCabinet/FinancesPage.vue'),
		props: (route) => ({
			participantId: Number(route.params.participantId),
			backPath: route.query.backPath,
		}),
		meta: {
			title: `Финансы`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout'
		},
	},
	{
		path: '/investor-cabinet/followed-projects',
		name: names.FOLLOWED_PROJECTS,
		component: () => import('@/pages/UserPage/FollowedProjectsPage.vue'),
		meta: {
			title: `Отслеживаемые проекты`,
			layout: 'ProfileLayout',
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/investor-cabinet/projects/:id?',
		name: names.MOBILE_PROJECTS_INVESTMENTS,
		component: () =>
			import('@/pages/InvestorCabinet/MobileProjectsInvestmentsPage.vue'),
		meta: {
			title: `Проекты`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout',
		},
	},
	{
		path: '/questionnaires/:id?',
		name: names.QUESTIONNAIRES_PAGE,
		component: () =>
			import('@/pages/InvestorCabinet/QuestionnairesPage.vue'),
		meta: {
			title: `Анкеты`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout',
		},
	},
	{
		name: names.SIGN_QUESTIONNAIRE_DOCS,
		path: '/documents/questionnaires/:id(\\d+)/sign-documents',
		component: () => import('@/components/Profile/Questionnaire/QuestionnaireDocsPage.vue'),
		props: (route) => ({ questionnaireId: Number(route.params.id) }),
		meta: {
			title: `Подписать документы`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'SignLayout'
		},
	},
	{
		path: '/founder-cabinet',
		name: names.FOUNDER_CABINET,
		component: () => import('@/pages/FounderCabinet/ProjectsPage.vue'),
		meta: {
			title: `Кабинет ЛПИ`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout',
		},
	},
	{
		path: '/founder-cabinet/finances/:participantId(\\d+)?',
		name: names.FOUNDER_CABINET_FINANCES,
		component: () => import('@/pages/FounderCabinet/FinancesPage.vue'),
		props: (route) => ({ 
			participantId: Number(route.params.participantId),
			backPath: route.query.backPath,
		}),
		meta: {
			title: `Финансы`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout',
		},
	},
	{
		path: '/pitches',
		name: names.PITCHES,
		component: () => import('@/pages/ProjectsPage/AllProjects.vue'),
		props: (route) => ({ projectType: route.query.projectTypeCode }),
		meta: {
			title: `Драфт-проекты`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/my-pitches',
		name: names.MOBILE_PITCHES,
		component: () => import('@/pages/UserPage/MyPitchesPage.vue'),
		meta: {
			title: `Драфт-проекты`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout',
		},
	},
	{
		path: '/projects',
		name: names.PROJECTS,
		component: () => import('@/pages/ProjectsPage/AllProjects.vue'),
		props: (route) => ({ projectType: route.query.projectType }),
		meta: {
			title: `Проекты`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/my-projects',
		name: names.MOBILE_PROJECTS,
		component: () => import('@/pages/UserPage/MyProjectsPage.vue'),
		meta: {
			title: `Проекты`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProfileLayout',
		},
	},
	{
		path: '/success-projects',
		name: names.SUCCESS_PROJECTS,
		component: () => import('@/pages/ProjectsPage/SuccessProjectsPage.vue'),
		meta: {
			title: `Успешные проекты`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/profile/settings',
		name: names.SETTINGS,
		component: () => import('@/pages/UserPage/SettingsPage.vue'),
		meta: {
			title: `Настройки`,
			layout: 'ProfileLayout',
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/profile/chats/:chatId?',
		name: names.CHATS,
		component: () => import('@/pages/UserPage/ChatsPage.vue'),
		props: (route) => ({ chatId: route.params.chatId }),
		meta: {
			title: `Чаты`,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/help/:articleName?',
		name: names.HELP,
		component: () => import('@/pages/Help/HelpPage.vue'),
		props: (route) => ({ articleName: route.params.articleName }),
		meta: {
			title: `Энциклопедия`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout',

			breadcrumb: [
				{
					text: 'Энциклопедия',
					to: '/help',
				},
			],
		},
	},
	{
		path: '/help/about',
		name: names.ABOUT,
		component: () => import('@/pages/Help/About/AboutCrf.vue'),
		meta: {
			title: `Помощь`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/help/about/advice',
		name: names.ADVICE,
		component: () => import('@/pages/Help/About/AllAdvices.vue'),
		meta: {
			title: `Помощь`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/help/about/question',
		name: names.QUESTION,
		component: () => import('@/pages/Help/About/IndividualQuestion.vue'),
		meta: {
			title: `Помощь`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/projects/:id(\\d+)',
		name: names.PROJECT,
		component: () => import('@pages/ProjectsPage/ProjectView.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Страница инвестиционного проекта`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
			breadcrumb: [
				{
					text: 'Все проекты',
					to: '/projects',
					type: 'projects',
				},
				{
					text: 'Инвестиционное предложение',
					to: '',
					type: 'project',
				},
			],
		},
	},
	{
		path: '/projects/:id(\\d+)/ucps/:ownerId?',
		name: names.PROJECT_UCPS,
		component: () => import('@pages/UcpPage/UcpPage.vue'),
		props: (route) => ({ projectId: Number(route.params.id), ownerId: Number(route.params.ownerId) }),
		meta: {
			title: `Реестр УЦП`,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/projects/:id(\\d+)/news',
		name: names.PROJECT_NEWS,
		component: () => import('@pages/ProjectsPage/ProjectNews.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Новости проекта`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR],
			layout: 'FooterLayout'
		},
	},
	{
		path: '/projects/:id(\\d+)/news/:frameId',
		name: names.PROJECT_NEWS_VIEW,
		component: () => import('@pages/ProjectsPage/ProjectNewsView.vue'),
		props: (route) => ({
			projectId: Number(route.params.id),
			projectNewsId: Number(route.params.frameId),
		}),
		meta: {
			title: `Новости проекта`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/projects/:id(\\d+)/discuss',
		name: names.PROJECT_DISCUSS,
		component: () => import('@pages/ProjectsPage/ProjectDiscussions.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Обсуждения проекта`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR],
			layout: 'FooterLayout'
		},
	},
	{
		path: '/projects/:id(\\d+)/discuss/:frameId',
		name: names.PROJECT_DISCUSS_VIEW,
		component: () => import('@pages/ProjectsPage/ProjectDiscussView.vue'),
		props: (route) => ({
			projectId: Number(route.params.id),
			projectDiscussId: Number(route.params.frameId),
		}),
		meta: {
			title: `Обсуждения проекта`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/projects/:id(\\d+)/success',
		name: names.PROJECT_SUCCESS,
		component: () => import('@pages/ProjectsPage/ProjectView.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Страница успешного проекта`,
			allowedRoles: [KC_ROLES.USER],
			breadcrumb: [
				{
					text: 'Все проекты',
					to: '/projects',
					type: 'projects',
				},
				{
					text: 'Инвестиционное предложение',
					to: '',
					type: 'project',
				},
			],
		},
	},
	{
		path: '/projects/:id(\\d+)/invest',
		name: names.PROJECT_INVEST,
		component: () =>
			import('@pages/ProjectsPage/ProjectInvest/ProjectInvest.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Окно для инвестиций`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'ProjectInvestLayout'
		},
	},
	{
		path: '/projects/:id(\\d+)/investors',
		name: names.PROJECT_INVESTORS,
		component: () => import('@pages/ProjectsPage/ProjectInvestors.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Инвесторы проекта`,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/projects/:id(\\d+)/investors/:investorId(\\d+)/progress',
		name: names.PROJECT_INVESTOR_PROGRESS,
		component: () => import('@pages/ProjectsPage/ProjectInvestorConversionProgress.vue'),
		props: (route) => ({ projectId: Number(route.params.id), investorId: Number(route.params.investorId) }),
		meta: {
			title: `Ход конвертации`,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR, KC_ROLES.ADMINISTRATOR],
		},
	},
	{
		path: '/projects/:id(\\d+)/investors/:investorId(\\d+)/loan-turnover',
		name: names.PROJECT_INVESTOR_LOAN_TURNOVER,
		component: () => import('@pages/ProjectsPage/ProjectInvestorLoanTurnover.vue'),
		props: (route) => ({ projectId: Number(route.params.id), investorId: Number(route.params.investorId) }),
		meta: {
			title: `Обороты по займу`,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR, KC_ROLES.ADMINISTRATOR],
		},
	},
	{
		path: '/projects/:id(\\d+)/investors/:investorId(\\d+)/ucp-turnover',
		name: names.PROJECT_INVESTOR_UCP_TURNOVER,
		component: () => import('@pages/ProjectsPage/ProjectInvestorUcpTurnover.vue'),
		props: (route) => ({ projectId: Number(route.params.id), investorId: Number(route.params.investorId) }),
		meta: {
			title: `Обороты по гашению УЦП`,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR, KC_ROLES.ADMINISTRATOR],
		},
	},
	{
		path: '/project/create',
		name: names.PROJECT_CREATE,
		component: () => import('@pages/ProjectsPage/AddProjectView.vue'),
		meta: {
			title: `Создание проекта`,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/pitches/:id(\\d+)/edit',
		name: names.PITCH_EDIT,
		component: () => import('@/pages/ProjectsPage/PitchEdit.vue'),
		props: (route) => ({ pitchId: Number(route.params.id) }),
		meta: {
			title: `Редактирование драфт-проекта`,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/projects/:id(\\d+)/edit',
		name: names.PROJECT_EDIT,
		component: () => import('@/pages/ProjectsPage/ProjectEdit.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Редактирование проекта`,
			allowedRoles: [KC_ROLES.USER],
		},
	},

	{
		path: '/pitch/create',
		name: names.PITCH_CREATE,
		component: () => import('@pages/ProjectsPage/AddPitchView.vue'),
		props: () => ({ pitchId: null }),
		meta: {
			title: `Регистрация проекта для изучения спроса`,
			allowedRoles: [KC_ROLES.USER],
		},
	},

	{
		path: '/pitches/:id(\\d+)',
		name: names.PITCH,
		component: () => import('@pages/ProjectsPage/PitchView.vue'),
		props: (route) => ({ pitchId: Number(route.params.id) }),
		meta: {
			title: `Страница драфт-проекта`,
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
			breadcrumb: [
				{
					text: 'Все драфт-проекты',
					to: '/pitches',
					type: 'pitches',
				},
				{
					text: 'Инвестиционное предложение',
					to: '',
					type: 'pitch',
				},
			],
		},
	},

	{
		path: '/profile/participant/:participantId/account/:accountNumber/history',
		name: names.OPERATIONS_HISTORY,
		component: () => import('@/pages/UserPage/OperationsHistory.vue'),
		props: (route) => ({
			participantId: Number(route.params.participantId),
			accountNumber: Number(route.params.accountNumber),
		}),
		meta: {
			title: `История операций`,
			allowedRoles: [KC_ROLES.USER, KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/cryptopro',
		name: names.CRYPTOPRO,
		component: () => import('@/components/cryptopro/Cryptopro.vue'),
		meta: {
			title: `Тест криптопро`,
			layout: 'EmptyLayout',
		},
	},
	{
		path: '/confirm/email',
		name: names.CONFIRM_EMAIL,
		component: () => import('@/pages/ConfirmEmailPage/ConfirmEmail.vue'),
		meta: {
			title: `Подтвердить email`,
			layout: 'EmptyLayout',
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/lpi/:id(\\d+)',
		name: names.LPI,
		component: () => import('@/pages/LpiPage/LpiPage.vue'),
		props: (route) => ({ lpiId: Number(route.params.id) }),
		meta: {
			title: `Страница ЛПИ`,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/outside-document-sign/:requestId',
		name: names.OUTSIDE_DOCUMENT_SIGN_PAGE,
		component: () =>
			import('@/pages/OutsideDocumentSignPage/OutsideDocumentSignPage.vue'),
		props: (route) => ({ requestId: route.params.requestId }),
		meta: {
			title: `Подписать документ`,
			layout: 'EmptyLayout',
			allowGuest: true,
			allowedRoles: [KC_ROLES.USER],
		},
	},
	{
		path: '/documents/view',
		name: names.DOCUMENT_SIGN_WATCH_PAGE,
		component: () => import('@/pages/DocumentPages/SignDocumentPage.vue'),
		props: (route) => ({ 
			documentIds: Array.isArray(route.query.documentId)
				? route.query.documentId.map(Number)
				: [Number(route.query.documentId)],
			redirectRoute: route.params?.redirectRoute || undefined,
		}),
		meta: {
			title: `Просмотр документа`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'SignLayout'
		},
	},
	{
		path: '/documents/sign/participant/:participantId/:projectId?',
		name: names.DOCUMENT_SIGN_PAGE,
		component: () => import('@/pages/DocumentPages/SignDocumentPage.vue'),
		props: (route) => ({
			participantId: Number(route.params.participantId),
			projectId: Number(route.params.projectId),
			documentIds: Array.isArray(route.query.documentId)
				? route.query.documentId.map(Number)
				: [Number(route.query.documentId)],
			back: route.query?.back || false,
			redirectRoute: route.params?.redirectRoute || undefined,
		}),
		meta: {
			title: `Документы`,
			allowedRoles: [KC_ROLES.USER],
			layout: 'SignLayout'
		},
	},
	{
		path: '/terms-of-use',
		name: names.TERMS_OF_USE,
		component: () => import('@/pages/Help/TermsOfUsePage.vue'),
		meta: {
			title: `Как это работает - ЛПИ`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	{
		path: '/terms-of-use-investor',
		name: names.TERMS_OF_USE_INVESTOR,
		component: () => import('@/pages/Help/TermsOfUsePage.vue'),
		props: () => ({
			currentUser: 'investor',
		}),
		meta: {
			title: `Как это работает - Инвестор`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
		},
	},
	// Оператор
	{
		path: '/operator/documents/sign',
		name: names.DOCUMENT_SIGN_PAGE_OPERATOR,
		component: () => import('@/pages/DocumentPages/SignDocumentPage.vue'),
		props: (route) => ({
			participantId: undefined,
			documentIds: Array.isArray(route.query.documentId)
				? route.query.documentId.map(Number)
				: [Number(route.query.documentId)],
			forceNoValidation: true,
			back: route.query?.back || false,
			redirectRoute: route.params?.redirectRoute || undefined,
		}),
		meta: {
			title: `Документы`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'SignLayout'
		},
	},
	{
		path: '/operator/registry/platform-documents/:type',
		name: names.PLATFORM_DOCUMENTS_REGISTRY,
		component: () => import('@pages/OperatorPages/PlatformDocumentsRegistry.vue'),
		props: true,
		meta: {
			title: `Реестр договоров платформы`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'PlatformDocumentsLayout'
		},
	},
	{
		path: '/operator/registry/invest-documents/:type',
		name: names.INVESTMENT_DOCUMENTS_REGISTRY,
		component: () => import('@pages/OperatorPages/InvestmentDocumentsRegistry.vue'),
		props: true,
		meta: {
			title: `Реестр договоров инвестирования`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'InvestmentDocumentsLayout'
		},
	},
	{
		path: '/operator/contract-investment/requests',
		name: names.CONTRACT_INVESTMENT_REQUEST,
		component: () => import('@pages/OperatorPages/ContractInvestmentRequests.vue'),
		props: true,
		meta: {
			title: `Запросы выписок ДИ`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'DefaultLayout',
		},
	},
	{
		path: '/operator/withdrawal-funds',
		name: names.WITHDRAWAL_FUNDS,
		component: () => import('@pages/OperatorPages/WithdrawalFundsRequests.vue'),
		props: true,
		meta: {
			title: `Запросы на вывод средств`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'WithdrawalLayout',
		},
	},
	{
		path: '/operator/nbki/events',
		name: names.OPERATOR_NBKI_EVENTS,
		component: () => import('@pages/OperatorPages/NbkiEvents.vue'),
		meta: {
			title: `События для отправки в НБКИ`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'NbkiLayout'
		},
	},
	{
		path: '/operator/nbki/archive',
		name: names.OPERATOR_NBKI_ARCHIVE,
		component: () => import('@pages/OperatorPages/NbkiArchive.vue'),
		meta: {
			title: `Архив событий для отправки в НБКИ`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'NbkiLayout'
		},
	},
	{
		path: '/operator/profile',
		name: names.OPERATOR_PROFILE,
		component: () => import('@/pages/OperatorPages/OperatorProfile.vue'),
		meta: {
			title: `Анкета оператора`,
			layout: 'ProfileLayout',
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/operator/certificates',
		name: names.OPERATOR_CERTIFICATES,
		component: () => import('@/pages/OperatorPages/OperatorCertificates.vue'),
		meta: {
			title: `Сертификаты оператора`,
			layout: 'ProfileLayout',
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/operator/tasks',
		name: names.OPERATOR_TASKS,
		component: () => import('@/pages/OperatorPages/OperatorTasks.vue'),
		meta: {
			title: `Мои задачи`,
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/operator/participants',
		name: names.OPERATOR_PARTICIPANTS,
		component: () => import('@/pages/OperatorPages/OperatorParticipants.vue'),
		meta: {
			title: `Анкеты`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ParticipantsLayout',
		},
	},
	{
		path: '/operator/users',
		name: names.OPERATOR_USERS,
		component: () => import('@/pages/OperatorPages/OperatorUsers.vue'),
		meta: {
			title: `Профили`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ParticipantsLayout',
		},
	},
	{
		path: '/operator/participants/moderate',
		name: names.OPERATOR_PARTICIPANTS_MODERATE,
		component: () => import('@/pages/OperatorPages/OperatorParticipantsModerate.vue'),
		meta: {
			title: `Модерация участников - Новые участники`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ParticipantsModerateLayout',
		},
	},
	{
		path: '/operator/entity/moderate',
		name: names.OPERATOR_ENTITY_MODERATE,
		component: () => import('@/pages/OperatorPages/OperatorEntityModerate.vue'),
		meta: {
			title: `Модерация участников - Заявки на изменение анкетных данных`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ParticipantsModerateLayout',
		},
	},
	{
		path: '/operator/entity/:id',
		name: names.OPERATOR_ENTITY,
		component: () => import('@/pages/OperatorPages/OperatorEntity.vue'),
		props: (route) => ({ entityId: Number(route.params.id) }),
		meta: {
			title: `Анкета участника`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'EntityLayout',
		},
	},
	{
		path: '/operator/participants/:id',
		name: names.OPERATOR_PARTICIPANT,
		component: () => import('@/pages/OperatorPages/OperatorParticipant.vue'),
		props: (route) => ({ participantId: Number(route.params.id) }),
		meta: {
			title: `Анкета участника`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ParticipantLayout'
		},
	},
	{
		path: '/operator/participants/:id/finances',
		name: names.OPERATOR_PARTICIPANT_FINANCES,
		component: () => import('@/pages/OperatorPages/OperatorParticipantFinances.vue'),
		props: (route) => ({ participantId: Number(route.params.id) }),
		meta: {
			title: `Финансы участника`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ParticipantLayout'
		},
	},
	{
		path: '/operator/participants/:id/docs',
		name: names.OPERATOR_PARTICIPANT_DOCS,
		component: () => import('@/pages/OperatorPages/OperatorParticipantDocs.vue'),
		props: (route) => ({ participantId: Number(route.params.id) }),
		meta: {
			title: `Документы участника`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ParticipantLayout'
		},
	},
	{
		path: '/operator/pitches',
		name: names.OPERATOR_PITCHES,
		component: () => import('@/pages/OperatorPages/OperatorPitches.vue'),
		meta: {
			title: `Драфт-проекты`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ProjectsLayout'
		},
	},
	{
		path: '/operator/projects',
		name: names.OPERATOR_PROJECTS,
		component: () => import('@/pages/OperatorPages/OperatorProjects.vue'),
		meta: {
			title: `Инвестиционные предложения`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ProjectsLayout'
		},
	},
	{
		path: '/operator/projects/:id(\\d+)',
		name: names.OPERATOR_PROJECT_VIEW,
		component: () => import('@pages/ProjectsPage/ProjectView.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Инвестиционные предложение`,
			allowedRoles: [KC_ROLES.OPERATOR],
			breadcrumb: [
				{
					text: 'Все проекты',
					to: '/operator/projects',
					type: 'projects',
				},
				{
					text: 'Инвестиционное предложение',
					to: '',
					type: 'project',
				},
			],
		},
	},
	{
		path: '/operator/projects/:id(\\d+)/investors',
		name: names.OPERATOR_PROJECT_VIEW_INVESTORS,
		component: () => import('@pages/ProjectsPage/ProjectInvestors.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Инвесторы проекта`,
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/operator/pitches/moderate',
		name: names.OPERATOR_PITCHES_MODERATE,
		component: () => import('@/pages/OperatorPages/OperatorPitchesModerate.vue'),
		meta: {
			title: `Модерация драфт-проектов`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ProjectsModerateLayout'
		},
	},
	{
		path: '/operator/projects/moderate',
		name: names.OPERATOR_PROJECTS_MODERATE,
		component: () => import('@/pages/OperatorPages/OperatorProjectsModerate.vue'),
		meta: {
			title: `Модерация инвестиционных предложений`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'ProjectsModerateLayout'
		},
	},
	{
		path: '/operator/pitches/moderate/:id(\\d+)',
		name: names.OPERATOR_PITCH_MODERATION,
		component: () => import('@/pages/OperatorPages/OperatorPitchModeration.vue'),
		props: (route) => ({ pitchId: Number(route.params.id) }),
		meta: {
			title: `Предложение`,
			allowedRoles: [KC_ROLES.OPERATOR],
			breadcrumb: [
				{
					text: 'Модерация инв. предложений',
					to: '/projects/moderate',
					type: 'offers',
				},
				{
					text: 'Инвестиционное предложение',
					to: '',
					type: 'project',
				},
			],
		},
	},
	{
		path: '/operator/projects/moderate/:id(\\d+)',
		name: names.OPERATOR_PROJECT_MODERATION,
		component: () => import('@/pages/OperatorPages/OfferModeration.vue'),
		props: (route) => ({ projectId: Number(route.params.id) }),
		meta: {
			title: `Инвестиционное предложение`,
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/operator/ucps',
		name: names.OPERATOR_UCPS,
		component: () => import('@/pages/OperatorPages/UcpsList.vue'),
		meta: {
			title: `База УЦП`,
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/operator/chats/:chatId?',
		name: names.OPERATOR_CHATS,
		component: () => import('@/pages/OperatorPages/OperatorChats.vue'),
		props: (route) => ({ chatId: route.params.chatId }),
		meta: {
			title: `Чаты с участниками`,
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},
	{
		path: '/operator/news',
		name: names.OPERATOR_NEWS,
		component: () => import('@/pages/OperatorPages/OperatorNews.vue'),
		meta: {
			title: `Новости`,
			allowedRoles: [KC_ROLES.OPERATOR],
			layout: 'NewsLayout'
		},
	},
	{
		path: '/operator/news/edit',
		name: names.OPERATOR_EDIT_NEWS,
		component: () => import('@/pages/OperatorPages/EditNews.vue'),
		meta: {
			title: `Новости`,
			allowedRoles: [KC_ROLES.OPERATOR],
		},
	},

	// Администратор
	{
		path: '/admin/users-rights',
		name: names.ADMIN_USERS_RIGHTS,
		component: () => import('@pages/AdminPages/AdminUsersRights.vue'),
		meta: {
			title: `Права доступа операторов`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'AdminingLayout',
		},
	},
	{
		path: '/admin/platform-commission',
		name: names.ADMIN_PLATFORM_COMMISSION,
		component: () => import('@pages/AdminPages/AdminPlatformCommission.vue'),
		meta: {
			title: `Комиссия платформы`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'AdminingLayout',
		},
	},
	{
		path: '/admin/manage-users',
		name: names.ADMIN_MANAGE_USERS,
		component: () => import('@pages/AdminPages/AdminManageUsers.vue'),
		meta: {
			title: `Управление пользователями`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'AdminingLayout',
		},
	},
	{
		path: '/admin/user-chats',
		name: names.ADMIN_USER_CHATS,
		component: () => import('@pages/AdminPages/AdminUserChats.vue'),
		meta: {
			title: `Чаты пользователя`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'AdminUserChatsLayout',
		},
	},
	{
		path: '/admin/platform-settings',
		name: names.ADMIN_PLATFORM_SETTINGS,
		component: () => import('@pages/AdminPages/AdminPlatformSettings.vue'),
		meta: {
			title: `Настройки платформы`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'AdminingLayout',
		},
	},
	{
		path: '/admin/documents',
		name: names.ADMIN_DOCUMENTS,
		component: () => import('@pages/AdminPages/Documents.vue'),
		meta: {
			title: `Типы и шаблоны документов`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'AdminingLayout',
		},
	},
	{
		path: '/admin/dictionaries',
		name: names.ADMIN_DICTIONARIES,
		component: () => import('@pages/AdminPages/Dictionaries.vue'),
		meta: {
			title: `Справочники`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
		},
	},
	{
		path: '/admin/projects-common',
		name: names.ADMIN_PROJECTS_COMMON_SETTINGS,
		component: () => import('@pages/AdminPages/AdminProjectsCommonSettings.vue'),
		meta: {
			title: `Настройки проектов`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'ProjectsSettingsLayout',
		},
	},
	{
		path: '/admin/projects-loan',
		name: names.ADMIN_PROJECTS_LOAN_SETTINGS,
		component: () => import('@pages/AdminPages/AdminProjectsLoanSettings.vue'),
		meta: {
			title: `Настройки Займов`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'ProjectsSettingsLayout',
		},
	},
	{
		path: '/admin/projects-convertible-loan',
		name: names.ADMIN_PROJECTS_CONVERTIBLE_LOAN_SETTINGS,
		component: () => import('@pages/AdminPages/AdminProjectsConvertibleLoanSettings.vue'),
		meta: {
			title: `Настройки Конвертируемых займов`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'ProjectsSettingsLayout',
		},
	},
	{
		path: '/admin/projects-stock-ucp',
		name: names.ADMIN_PROJECTS_STOCK_UCP_SETTINGS,
		component: () => import('@pages/AdminPages/AdminProjectsStockUcpSettings.vue'),
		meta: {
			title: `Настройки Акций и УЦП`,
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'ProjectsSettingsLayout',
		},
	},
	{
		path: '/admin/nominal-account',
		name: names.ADMIN_NOMINAL_ACCOUNT,
		component: () => import('@/pages/AdminPages/AdminNominalAccount.vue'),
		meta: {
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			title: `Номинальный счёт`,
		},
	},
	{
		path: '/admin/users-stats',
		name: names.ADMIN_USERS_STATS,
		component: () => import('@/pages/AdminPages/AdminUsersStats.vue'),
		meta: {
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'StatisticLayout',
			title: `Статистика по пользователям`,
		},
	},
	{
		path: '/admin/issues',
		name: names.ADMIN_ISSUES,
		component: () => import('@/pages/AdminPages/IssuesPage.vue'),
		meta: {
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			title: `Вопросы-Советы`,
		},
	},
	{
		path: '/admin/invests-stats',
		name: names.ADMIN_INVESTS_STATS,
		component: () => import('@/pages/AdminPages/AdminInvestsStats.vue'),
		meta: {
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'StatisticLayout',
			title: `Статистика по инвестированию`,
		},
	},
	{
		path: '/admin/obligations-stats',
		name: names.ADMIN_OBLIGATIONS_STATS,
		component: () => import('@/pages/AdminPages/AdminObligationsStats.vue'),
		meta: {
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
			layout: 'StatisticLayout',
			title: `Статистика по исполнению обязательств`,
		},
	},
	{
		path: '/admin/profile',
		name: names.ADMIN_PROFILE,
		component: () => import('@/pages/OperatorPages/OperatorProfile.vue'),
		meta: {
			title: `Анкета администратора`,
			layout: 'ProfileLayout',
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
		},
	},
	{
		path: '/admin/participants-info',
		name: names.ADMIN_PARTICIPANTS_INFO,
		component: () => import('@/pages/AdminPages/AdminParticipantsInfo.vue'),
		props: (route) => ({ participantId: Number(route.params.participantId), username: route.params.username }),
		meta: {
			title: `Анкеты пользователя`,
			layout: 'ParticipantLayout',
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
		},
	},
	{
		path: '/admin/projects-info',
		name: names.ADMIN_PROJECTS_INFO,
		component: () => import('@/pages/AdminPages/AdminProjectsInfo.vue'),
		props: (route) => ({ participantId: Number(route.params.participantId), username: route.params.username }),
		meta: {
			title: `Проекты пользователя`,
			layout: 'ProjectsLayout',
			allowedRoles: [KC_ROLES.ADMINISTRATOR],
		},
	},

	// FOOTER
	{
		path: '/disclose-info/:chapter?',
		name: names.DISCLOSE_INFO,
		component: () => import('@/components/Footer/Pages/DiscloseInfo.vue'),
		props: (route) => ({ initialChapterName: route.params.chapter }),
		meta: {
			title: `Раскрытие информации`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout'
		},
	},
	{
		path: '/tariffs',
		name: names.TARIFFS,
		component: () => import('@/components/Footer/Pages/TariffsPage.vue'),
		meta: {
			title: `Тарифы`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout',
			gray: true,
		},
	},
	{
		path: '/faq',
		name: names.FAQ,
		component: () => import('@/components/Footer/Pages/FaqPage.vue'),
		meta: {
			title: `ЧАВО`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout'
		},
	},
	{
		name: names.CONTACTS,
		path: '/contacts',
		component: () => import('@/components/Footer/Pages/ContactsPage.vue'),
		meta: {
			title: `Контакты`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout',
			gray: true,
		},
	},
	{
		path: '/about-us',
		name: names.ABOUT_US,
		component: () => import('@/components/Footer/Pages/AboutUsPage.vue'),
		meta: {
			title: `О нас`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout',
			gray: true,
		},
	},
	{
		path: '/platform-news',
		name: names.PLATFORM_NEWS,
		component: () => import('@/components/Footer/Pages/PlatformNewsPage.vue'),
		meta: {
			title: `Новости платформы`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout'
		},
	},
	{
		path: '/personal-info',
		name: names.PERSONAL_INFO,
		component: () => import('@/components/Footer/Pages/PersonalInfoPage.vue'),
		meta: {
			title: `Политика обработки ПД`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout'
		},
	},
	{
		path: '/risk-declaration',
		name: names.RISK_DECLARATION,
		component: () => import('@/components/Footer/Pages/RiskDeclarationPage.vue'),
		meta: {
			title: `Декларация о рисках`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout',
			gray: true,
		},
	},
	{
		path: '/promotions',
		name: names.PROMOTIONS,
		component: () => import('@/components/Footer/Pages/PromotionsPage.vue'),
		meta: {
			title: `Акции`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout'
		},
	},
	{
		path: '/partners',
		name: names.PARTNERS,
		component: () => import('@/components/Footer/Pages/PartnersPage.vue'),
		meta: {
			title: `Партнеры`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout'
		},
	},
	{
		path: '/safety',
		name: names.SAFETY,
		component: () => import('@/components/Footer/Pages/SafetyPage.vue'),
		meta: {
			title: `Информация о безопасности`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout',
			gray: true,
		},
	},
	{
		path: '/standard-docs',
		name: names.STANDARD_DOCS,
		component: () => import('@/components/Footer/Pages/StandardDocsPage.vue'),
		meta: {
			title: `Типовые документы`,
			allowGuest: true,
			allowedRoles: ALL_ROLES_ARR,
			layout: 'FooterLayout'
		},
	},
];
