var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-date__wrapper"
  }, [_vm.label ? _c('p', {
    class: _vm.labelClasses
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('div', {
    staticClass: "ui-date__inner"
  }, [_c('DatePicker', _vm._g({
    ref: "uiDate",
    staticClass: "ui-date",
    attrs: {
      "singleDatePicker": true,
      "ranges": false,
      "locale-data": _vm.DatePickerConfig,
      "linked-calendars": false,
      "show-dropdowns": true,
      "auto-apply": true,
      "disabled": _vm.isDisabled
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('input', {
          class: _vm.inputStyles,
          attrs: {
            "type": "date",
            "placeholder": _vm.placeholder,
            "readonly": _vm.readonly,
            "disabled": _vm.isDisabled
          },
          domProps: {
            "value": _vm.value
          },
          on: {
            "focus": function ($event) {
              return _vm.$emit('focus');
            },
            "blur": function ($event) {
              return _vm.$emit('blur');
            },
            "input": _vm.convertInputDate
          }
        }), !_vm.isDisabled ? _c('div', {
          staticClass: "ui-date__icons"
        }, [_vm.clearable && !_vm.isPlaceholderActive ? _c('UiSmartIcon', {
          staticClass: "ui-date__clear",
          attrs: {
            "size": "16",
            "name": "close",
            "color": "var(--body-color)"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.clearDateRange.apply(null, arguments);
            }
          }
        }) : _vm._e(), _c('div', {
          staticClass: "ui-date__calendar"
        }, [_c('UiSmartIcon', {
          staticClass: "ui-date__calendar-icon",
          attrs: {
            "size": "16",
            "name": "icons-calendar",
            "color": "var(--body-color)"
          },
          on: {
            "click": _vm.toggleDate
          }
        }), _c('div', {
          staticClass: "ui-date__calendar-background"
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }, _vm.$listeners))], 1), _vm.caption || _vm.error ? _c('span', {
    staticClass: "input-caption",
    class: _vm.error ? 'input-caption__error' : ''
  }, [_vm._v(" " + _vm._s(_vm.error ? _vm.error : _vm.caption) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }