<template>
	<div class="showcase">
		<h1 class="showcase__title">
			<slot name="title" />
		</h1>
		<slot />
		<div class="showcase__inner">
			<div class="showcase__inner-left">
				<slot name="left" />
			</div>
			<div class="showcase__inner-right">
				<slot name="right" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'UiShowcase',
}
</script>
