var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "tableContainer",
    staticClass: "table-container custom-scrollbar",
    class: {
      'table-container--sticky': _vm.sticky
    }
  }, [_c('table', {
    staticClass: "ui-table"
  }, [!_vm.hasAggregateColumn || _vm.isNotSmallAggregate ? _c('thead', {
    staticClass: "ui-table__head",
    class: {
      'ui-table__head--sticky': _vm.sticky
    }
  }, [_vm.hasGroupHeader ? _c('tr', [_vm._l(_vm.columnsGroup, function (col) {
    return [col !== null && col !== void 0 && col.colspan ? _c('th', {
      key: ("col#" + (col.grope)),
      staticClass: "ui-table__heading ui-table__heading__group",
      attrs: {
        "colspan": col.colspan
      }
    }, [_c('span', [_vm._v(" " + _vm._s(col.grope) + " ")])]) : _c('th', {
      key: ("col#" + (col.name)),
      ref: ("col__" + (col.name)),
      refInFor: true,
      staticClass: "ui-table__heading",
      class: _vm.colHeadClass(col),
      style: _vm.colHeadStyle(col),
      attrs: {
        "rowspan": 2,
        "title": col.title
      }
    }, [_vm._t("label", function () {
      return [col.sortable ? _c('span', {
        staticClass: "flex flex-sb",
        class: {
          'cursor-pointer': !_vm.loading
        },
        on: {
          "click": function ($event) {
            return _vm.handleSortChange(col);
          }
        }
      }, [_vm._v(" " + _vm._s(col.label) + " "), _c('UiSmartIcon', {
        staticClass: "flex-shrink-0 ml-8",
        attrs: {
          "name": _vm.getSortIconName(col.name),
          "color": _vm.getSortIconColor(col.name)
        }
      })], 1) : _c('span', [_vm._v(" " + _vm._s(col.label) + " ")])];
    }, null, {
      col: col
    })], 2)];
  })], 2) : _vm._e(), _c('tr', [_vm._l(_vm.columns, function (col) {
    return _vm.showCol(col) ? _c('th', {
      key: ("col#" + (col.name)),
      ref: ("col__" + (col.name)),
      refInFor: true,
      staticClass: "ui-table__heading",
      class: _vm.colHeadClass(col),
      style: _vm.colHeadStyle(col),
      attrs: {
        "title": col.title
      }
    }, [_vm._t("label", function () {
      return [col.sortable ? _c('span', {
        staticClass: "flex flex-sb",
        class: {
          'cursor-pointer': !_vm.loading
        },
        on: {
          "click": function ($event) {
            return _vm.handleSortChange(col);
          }
        }
      }, [_vm._v(" " + _vm._s(col.label) + " "), _c('UiSmartIcon', {
        staticClass: "flex-shrink-0 ml-8",
        attrs: {
          "name": _vm.getSortIconName(col.name),
          "color": _vm.getSortIconColor(col.name)
        }
      })], 1) : _c('span', [_vm._v(" " + _vm._s(col.label) + " ")])];
    }, null, {
      col: col
    })], 2) : _vm._e();
  }), _vm.hasGroupHeader ? _c('th', {
    staticStyle: {
      "display": "none"
    }
  }) : _vm._e()], 2)]) : _vm.isSmallAggregate ? _c('thead', {
    staticClass: "ui-table__head",
    class: {
      'ui-table__head--sticky': _vm.sticky
    }
  }, [_vm._l(_vm.columns, function (col) {
    return _vm.notInGrouped(col) && _vm.notInAggregate(col) && _vm.isColVisible(col) ? _c('th', {
      key: ("col#" + (col.name)),
      staticClass: "ui-table__heading",
      class: _vm.colHeadClass(col),
      style: _vm.colHeadStyle(col),
      attrs: {
        "title": col.title
      }
    }, [_vm._t("label", function () {
      return [col.sortable ? _c('span', {
        staticClass: "flex flex-sb",
        class: {
          'cursor-pointer': !_vm.loading
        },
        on: {
          "click": function ($event) {
            return _vm.handleSortChange(col);
          }
        }
      }, [_vm._v(" " + _vm._s(col.label) + " "), _c('UiSmartIcon', {
        staticClass: "flex-shrink-0 ml-8",
        attrs: {
          "name": _vm.getSortIconName(col.name),
          "color": _vm.getSortIconColor(col.name)
        }
      })], 1) : _c('span', [_vm._v(" " + _vm._s(col.label) + " ")])];
    }, null, {
      col: col
    })], 2) : _vm._e();
  }), _vm.isSmallAggregate ? _c('th', {
    key: ("col#" + (_vm.aggregateColumn.name)),
    staticClass: "ui-table__heading",
    class: _vm.colHeadClass(_vm.aggregateColumn),
    style: _vm.colHeadStyle(_vm.aggregateColumn),
    attrs: {
      "title": _vm.aggregateColumn.title
    }
  }, [_vm._t("label", function () {
    return [_vm.aggregateColumn.label ? _c('span', [_vm._v(" " + _vm._s(_vm.aggregateColumn.label) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.aggregateColumnLabels) + " ")])];
  }, null, {
    aggregateColumn: _vm.aggregateColumn
  })], 2) : _vm._e()], 2) : _vm._e(), (!_vm.hasAggregateColumn || _vm.isNotSmallAggregate) && _vm.hasItems ? _c('tbody', {
    staticClass: "ui-table__body",
    class: {
      'ui-table__body--borderless': _vm.borderless,
      'ui-table__body--loading': _vm.loading
    }
  }, [_vm._l(_vm.items, function (item, idx) {
    return [_c('tr', {
      key: ("row#" + (_vm.getKey(item, idx))),
      class: _vm.getRowClass(item, idx),
      style: _vm.rowStyle(item),
      on: {
        "mousedown": function ($event) {
          return _vm.openItemDown();
        },
        "mouseup": function ($event) {
          return _vm.openItemUp(item, idx);
        }
      }
    }, _vm._l(_vm.columns, function (col) {
      return _vm.isColVisible(col) ? _c('td', {
        key: ("cell#" + (col.name)),
        staticClass: "ui-table__cell",
        class: _vm.colClass(col, item),
        style: _vm.colStyle(col, item),
        attrs: {
          "title": _vm.colTitle(col, item)
        },
        on: {
          "click": function ($event) {
            return _vm.$emit('cellClick', item, col);
          }
        }
      }, [_vm.hasSlot(col) ? [_vm._t(col.name, null, {
        "item": item,
        "index": idx
      })] : [_vm._v(" " + _vm._s(_vm.cellValue(col, item)) + " ")]], 2) : _vm._e();
    }), 0), _vm.$scopedSlots['row-append'] ? _c('tr', {
      key: ("row#" + (_vm.getKey(item, idx)) + "-append"),
      class: _vm.getRowAppendClass(item, idx)
    }, [_vm.showAppend(item, idx) ? _c('td', {
      staticClass: "ui-table__row-append-content ui-table__cell",
      attrs: {
        "colspan": "100"
      }
    }, [_vm._t("row-append", null, {
      "item": item,
      "index": idx
    })], 2) : _vm._e()]) : _vm._e()];
  })], 2) : _vm.isSmallAggregate && _vm.hasItems ? _c('tbody', {
    staticClass: "ui-table__body",
    class: {
      'ui-table__body--borderless': _vm.borderless,
      'ui-table__body--loading': _vm.loading
    }
  }, [_vm._l(_vm.items, function (item, idx) {
    return [_c('tr', {
      key: ("row#" + (_vm.getKey(item, idx))),
      class: _vm.getRowClass(item, idx),
      style: _vm.rowStyle(item),
      on: {
        "mousedown": function ($event) {
          return _vm.openItemDown();
        },
        "mouseup": function ($event) {
          return _vm.openItemUp(item, idx);
        }
      }
    }, [_vm._l(_vm.columns, function (col) {
      return _vm.notInGrouped(col) && _vm.notInAggregate(col) && _vm.isColVisible(col) ? _c('td', {
        key: ("cell#" + (col.name)),
        staticClass: "ui-table__cell",
        class: _vm.colClass(col, item),
        style: _vm.colStyle(col, item),
        on: {
          "click": function ($event) {
            return _vm.$emit('cellClick', item, col);
          }
        }
      }, [_vm.hasSlot(col) ? [_vm._t(col.name, null, {
        "item": item,
        "index": idx
      })] : [_vm._v(" " + _vm._s(_vm.cellValue(col, item)) + " ")]], 2) : _vm._e();
    }), _vm.isSmallAggregate ? _c('td', {
      key: ("cell#" + (_vm.aggregateColumn.name)),
      staticClass: "ui-table__cell",
      class: _vm.colClass(_vm.aggregateColumn, item),
      style: _vm.colStyle(_vm.aggregateColumn, item),
      on: {
        "click": function ($event) {
          return _vm.$emit('cellClick', item, _vm.aggregateColumn);
        }
      }
    }, [_vm.isAggregateGrope ? _c('div', {
      staticClass: "ui-table__cell-aggregate"
    }, [_vm._l(_vm.columnsGroup, function (col) {
      return col !== null && col !== void 0 && col.names ? _c('p', {
        key: ("aggrow#" + (col.name || col.grope)),
        class: _vm.aggregateRowClasses(col)
      }, [_c('span', [_vm._v(_vm._s(col.grope))]), _c('span', {
        staticClass: "ui-table__cell-groupspan"
      }, _vm._l(col.names, function (colName) {
        return _vm.rowHasValue(col, item, colName) ? _c('span', {
          staticClass: "ui-table__cell-grouppart"
        }, [_vm._v(" " + _vm._s(_vm.groupCellName(colName)) + ": " + _vm._s(_vm.cellValue(col, item, colName)) + " ")]) : _vm._e();
      }), 0)]) : _vm._e();
    }), _vm._l(_vm.aggregateColumnCels, function (col) {
      return _vm.rowHasValue(col, item) ? _c('p', {
        key: ("aggrow#" + (col.name)),
        class: _vm.aggregateRowClasses(col)
      }, [_vm._v(" " + _vm._s(col.label) + ": "), _vm.hasSlot(col) ? [_vm._t(col.name, null, {
        "item": item,
        "index": idx
      })] : [_vm._v(" " + _vm._s(_vm.cellValue(col, item)) + " ")]], 2) : _vm._e();
    }), _c('p')], 2) : _c('div', {
      staticClass: "ui-table__cell-aggregate"
    }, _vm._l(_vm.aggregateColumnCels, function (col) {
      return _vm.rowHasValue(col, item) ? _c('p', {
        key: ("aggrow#" + (col.name)),
        class: _vm.aggregateRowClasses(col)
      }, [_vm._v(" " + _vm._s(col.label) + ": "), _vm.hasSlot(col) ? [_vm._t(col.name, null, {
        "item": item,
        "index": idx
      })] : [_vm._v(" " + _vm._s(_vm.cellValue(col, item)) + " ")]], 2) : _vm._e();
    }), 0)]) : _vm._e()], 2), _vm.$scopedSlots['row-append'] ? _c('tr', {
      key: ("row#" + (_vm.getKey(item, idx)) + "-append"),
      class: _vm.getRowAppendClass(item, idx)
    }, [_vm.showAppend(item, idx) ? _c('td', {
      staticClass: "ui-table__row-append-content ui-table__cell",
      attrs: {
        "colspan": "100"
      }
    }, [_vm._t("row-append", null, {
      "item": item,
      "index": idx
    })], 2) : _vm._e()]) : _vm._e()];
  })], 2) : _vm.loading ? _c('tbody', {
    staticClass: "ui-table__body"
  }, [_c('tr', {
    staticClass: "ui-table__row"
  }, [_c('td', {
    staticClass: "ui-table__cell ui-table__cell--loading",
    attrs: {
      "colspan": _vm.columnCount
    }
  }, [_c('UiSpinner')], 1)])]) : !_vm.hasItems ? _c('tbody', {
    staticClass: "ui-table__body"
  }, [_c('tr', {
    staticClass: "ui-table__row"
  }, [_c('td', {
    staticClass: "ui-table__cell ui-table__cell--loading",
    attrs: {
      "colspan": _vm.columnCount
    }
  }, [_vm._v(" " + _vm._s(_vm.emptyDataLabel) + " ")])])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }